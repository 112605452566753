import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { PersonPlus } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { fast_api_axios } from '../../utils/axiosFast';
import { personColorCodes } from '../../utils/constants';
import ShareModal from './ShareModal';
import ChangeDashboardModal from './ChangeDashboardModal';
import Loader from '../Loader';
/*import tableau from '../../assets/images/tableau.png';
import qlik from '../../assets/images/qlik.png';
import powerbi from '../../assets/images/powerbi.png';
import superset from '../../assets/images/superset.png';
import grafana from '../../assets/images/grafana.png';*/

export default function EditDashboard() {
  let history = useHistory();
  const params = useParams();
  const { updateMessage } = useToast()
  const { logout } = useAuth();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  //const [changeToType, setChangeToType] = useState(null);
  const [dashboardType, setDashboardType] = useState('TABLEAU');
  const [workspace, setWorkspace] = useState('');
  const [view, setView] = useState('');
  const [username, setUsername] = useState('');
  const [dashboardName, setDashboardName] = useState('');
  const [dName, setDName] = useState('');
  const [explanation, setExplanation] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [personView, setPersonView] = useState(null);
  const [countView, setCountView] = useState(null);
  const [reviewedPeople, setReviewedPeople] = useState([]);

  const handleCloseShareModal = () => {
    setShowShareModal(false);
    setIsLoading(true);
    getDashboardDetail();
  }
  const handleShowShareModal = () => setShowShareModal(true);

  const handleCloseChangeModal = () => setShowChangeModal(false);
  /*const handleShowChangeModal = (type) => {
    if (type !== dashboardType) {
      setChangeToType(type);
      setShowChangeModal(true);
    }
  }*/

  const goToDashboards = (id) => {
    history.push('/dashboards')
  }

  const getDashboardDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/dashboards/' + params.id
      })
      .then(res => {
        setDashboardType(res.data.dashboard_type)
        setWorkspace(res.data.workspace_name);
        setView(res.data.view_name);
        setUsername(res.data.username)
        setDashboardName(res.data.dashboard_name)
        setDName(res.data.dashboard_name)
        setExplanation(res.data.explanation)
        setIsPublic(res.data.is_public)
        setReviewedPeople(res.data.users)
        setCanSave(true);
        handleReviewedPeopleDisplay(res.data.users);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getDashboardDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editDashboard = () => {
    setIsLoading(true)
    fast_api_axios(
      {
        method: 'patch',
        url: '/api/v1/dashboards/' + params.id,
        data: {
          "dashboard_type": dashboardType,
          "security_key": 'security_key',
          "username": username,
          "password": 'password',
          "workspace_name": workspace,
          "view_name": view,
          "dashboard_name": dashboardName,
          "explanation": explanation,
          "is_public": isPublic
        }
      })
      .then(res => {
        setDashboardType(res.data.dashboard_type)
        setWorkspace(res.data.workspace_name);
        setView(res.data.view_name);
        setUsername(res.data.username)
        setDashboardName(res.data.dashboard_name)
        setExplanation(res.data.explanation)
        setIsPublic(res.data.is_public)
        setCanSave(true);
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const changeDashboardType = (type) => {
    setDashboardType(type)
    setWorkspace('');
    setView('');
    setUsername('')
    setDashboardName('')
    setExplanation('')
    setIsPublic(false)
  }

  useEffect(() => {
    if (dashboardType !== null && workspace !== null && view !== null && username !== null && dashboardName !== null) {
      if (workspace.length > 0 && view.length > 0 && username.length > 0 && dashboardName.length > 0 ) {
        setCanSave(true);
      }
      else {
        setCanSave(false);
      }
    }
    else {
      setCanSave(false);
    }
  }, [dashboardType, workspace, view, username, dashboardName])

  const onMouseover = () => {
    let personCircles = null;

    personCircles = reviewedPeople.map((person, index) => {
      return (
        <OverlayTrigger
          key={index}
          placement='bottom'
          overlay={
            <Tooltip id='tooltip-bottom'>
              {person.email}
            </Tooltip>
          }
        >
          <div className='people' style={{ backgroundColor: personColorCodes[index].color }}>
            {
              person.email.charAt(0).toUpperCase()
            }
          </div>
        </OverlayTrigger>
      )
    })

    setPersonView(personCircles)
    setCountView(null)
  }

  const onMouseout = () => {
    handleReviewedPeopleDisplay(reviewedPeople)
  }

  const handleReviewedPeopleDisplay = (reviewedBy) => {
    let count = 0;
    let personCircles = null;

    reviewedBy.length > 3 ?
      personCircles = reviewedBy.slice(0, 3).map((person, index) => {
        return (
          <OverlayTrigger
            key={index}
            placement='bottom'
            overlay={
              <Tooltip id='tooltip-bottom'>
                {person.email}
              </Tooltip>
            }
          >
            <div className='people' style={{ backgroundColor: personColorCodes[index].color }}>
              {
                person.email.charAt(0).toUpperCase()
              }
            </div>
          </OverlayTrigger>
        )
      })
      :
      personCircles = reviewedBy.map((person, index) => {
        return (
          <OverlayTrigger
            key={index}
            placement='bottom'
            overlay={
              <Tooltip id='tooltip-bottom'>
                {person.email}
              </Tooltip>
            }
          >
            <div className='people' style={{ backgroundColor: personColorCodes[index].color }}>
              {
                person.email.charAt(0).toUpperCase()
              }
            </div>
          </OverlayTrigger>
        )
      })


    if (reviewedBy.length > 3) {
      count = reviewedBy.length - 3;
      setCountView('+' + count)
    }

    setPersonView(personCircles)
  }

  return (
    <>
      <Container fluid>
        {isLoading ?
          <Loader />
          :
          <Row>
            <Col>
              <h5 className='add-dashboard-header mt-3 mb-3'><span onClick={goToDashboards} className="dashboards-link">Dashboards</span> {'>'} <span className='db-name'> Edit {dName}</span></h5>
              {/*<div className='add-box'>
                <h5>Select Dashboard Type
                  <OverlayTrigger
                    key={'tt'}
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top'>
                        Select Dashboard Type
                      </Tooltip>
                    }
                  >
                    <span className="mx-1 info-overlay"><InfoCircle /></span>
                  </OverlayTrigger>
                </h5>
                <div className='d-flex'>
                  <div className={`dashboard-type ${dashboardType === 'TABLEAU' ? `selected-dashboard-type` : `unselected-dashboard-type`}`} onClick={() => handleShowChangeModal('TABLEAU')}><img src={tableau} alt="tableau" /></div>
                  <div className={`dashboard-type not-available ${dashboardType === 'QLIK' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={qlik} alt="qlik" /></div>
                  <div className={`dashboard-type not-available ${dashboardType === 'POWER' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={powerbi} alt="powerbi" /></div>
                  <div className={`dashboard-type not-available ${dashboardType === 'SUPERSET' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={superset} alt="superset" style={{ width: '90%' }} /></div>
                  <div className={`dashboard-type not-available ${dashboardType === 'GRAFANA' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={grafana} alt="grafana" /></div>
                </div>
                  </div>*/}

              <div className='tableau-box'>
                <h4>Enter Tableau Information</h4>
                <Row className='mt-3'>
                  <Col>
                    <h5>Username</h5>
                    <Form.Control className='input-box' type="text" placeholder="Enter your username" value={username} onChange={(e) => setUsername(e.target.value)} />
                  </Col>
                  <Col>
                    <h5>Workspace</h5>
                    <Form.Control className='input-box' type="text" placeholder="Enter your workspace name" value={workspace} onChange={(e) => setWorkspace(e.target.value)} />
                  </Col>
                  <Col>
                    <h5>View</h5>
                    <Form.Control className='input-box' type="text" placeholder="Enter your view name" value={view} onChange={(e) => setView(e.target.value)} />
                  </Col>
                </Row>
              </div>

              <div className='add-box'>
                <Row className='mt-3'>
                  <Col>
                    <h5>Dashboard Name</h5>
                    <Form.Control className='input-box' type="text" placeholder="Dashboard name" value={dashboardName} onChange={(e) => setDashboardName(e.target.value)} />
                  </Col>
                  <Col>
                    <h5>Explanation</h5>
                    <Form.Control className='input-box' type="text" placeholder="Write your explanation" value={explanation} onChange={(e) => setExplanation(e.target.value)} />
                  </Col>

                  <div className='d-flex'>
                    <Form.Group className="mt-3 d-flex" controlId="formBasicCheckbox">
                      {/*<Form.Check type="checkbox" label="Make Public" checked={isPublic} onChange={() => setIsPublic(!isPublic)} className='me-5' />*/}
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        key='3'
                        placement='bottom'
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body>Share With</Popover.Body>
                          </Popover>
                        }
                      >
                        <PersonPlus color={'#F7941D'} className='mt-1 me-2 share-icon' onClick={handleShowShareModal} />
                      </OverlayTrigger>
                      <div className="video-reviewed-by mx-3" onMouseEnter={onMouseover} onMouseLeave={onMouseout}>
                        <div className='d-flex'>
                          {
                            personView
                          }
                          <div>{countView}</div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Row>
              </div>

              <Button
                className={`add-dashboard-btn w-100 ${canSave ? `active-dashboard` : null}`}
                onClick={editDashboard}
                disabled={canSave ? false : true}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      </Container >

      <ShareModal
        show={showShareModal}
        handleClose={handleCloseShareModal}
        dashboardId={params.id}
      />

      <ChangeDashboardModal
        show={showChangeModal}
        handleClose={handleCloseChangeModal}
        changeDashboardType={changeDashboardType}
        type={null}
      />
    </>
  )
}
