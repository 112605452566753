import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';

export default function Dashboard() {
  let history = useHistory();
  const params = useParams();
  const { updateMessage } = useToast()
  const { logout, currentUser } = useAuth();
  const [canEmbed, setCanEmbed] = useState(false);
  const [myUrl, setMyUrl] = useState(null);
  const [dashboardName, setDashboardName] = useState(null);

  const goToDashboards = (id) => {
    history.push('/dashboards')
  }

  const getDashboardDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/dashboards/' + params.id
      })
      .then(res => {
        setDashboardName(res.data.dashboard_name)
        getTableauTicket(res.data.base_url, res.data.workspace_name, res.data.view_name, res.data.username);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getTableauTicket = (base, workspace, view, username) => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/dashboards/tableau/trusted_token/' + username
      })
      .then(res => {
        setMyUrl(base + 'trusted/' + res.data.token + '/views/' + workspace + '/' + view + '?:embed=yes')
        setCanEmbed(true)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    currentUser.isEnterprise && getDashboardDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col>
          <h5 className='add-dashboard-header mt-3 mb-3'><span onClick={goToDashboards} className="dashboards-link">Dashboards</span> {'>'} Public Dashboards <span className='db-name'> {'>'} {dashboardName}</span></h5>
          <div className='embedded-content'>
            {canEmbed ? <iframe title='uniq' src={myUrl} width="100%" height="600"></iframe> : null}
          </div>

          {/*
          <tableau-viz id="tableauViz"
            src='http://35.171.129.83/views/Superstore/Forecast'>
          </tableau-viz>
          */}

          {/*<iframe src="https://3.229.122.81/single/?appid=1ef8a474-b3e7-4cea-98fc-bcb8a075ef12&obj=PKgrj&opt=ctxmenu,currsel" width="100%" height="600"></iframe>*/}

        </Col>
      </Row>
    </Container>
  )
}
