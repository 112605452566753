import React, { useState, useEffect, useRef } from 'react';
import { Table, Dropdown, Button, Overlay, Tooltip, } from 'react-bootstrap';
import {
  CaretDownFill,
  CaretUpFill,
  PersonPlus,
  PlusLg,
  QuestionCircle,
  ThreeDotsVertical,
  X
} from 'react-bootstrap-icons';
import AddUserModal from './AddUserModal'
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader'
import Select from 'react-select';
import Switch from "react-switch";
import { getHoursInDay } from '../../utils/generic';
import AddDashboardReportModal from './AddDashboardReportModal';

const dashboardOptions = [
  {
    label: 'Counting',
    value: 'REPORT_REALTIME_COUNT'
  }
]

const frequencyOptions = [
  {
    label: 'Daily',
    value: 'DAILY'
  }
]

export default function DashboardReports() {
  let history = useHistory();
  const { updateMessage } = useToast();
  const { logout } = useAuth();
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [dashboardReports, setDashboardReports] = useState([]);
  const [isEditReportActive, setIsEditReportActive] = useState(false);
  const [selectedReportSettingId, setSelectedReportSettingId] = useState(null);
  const [selectedReportTime, setSelectedReportTime] = useState([]);
  const [addedUserList, setAddedUserList] = useState([]);
  const [userModalType, setUserModalType] = useState(null);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddPeriodicDashboardReportModal, setShowAddPeriodicDashboardReportModal] = useState(false);
  const [questionActive, setQuestionActive] = useState(false);
  const questionTarget = useRef(null);

  const handleShowPeriodicDashboardReportModal = () => {
    setShowAddPeriodicDashboardReportModal(true);
  }

  const handleClosePeriodicDashboardReportModal = () => {
    setShowAddPeriodicDashboardReportModal(false);
  }

  const getReports = () => {
    setIsReportsLoading(true);
    discardEditChanges();
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/setting/report'
      })
      .then(res => {

        const data = res.data.filter(setting => setting.report_type === 'REPORT_REALTIME_COUNT')
        setDashboardReports(data)
        setIsReportsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const addReport = (selectedReportTimes) => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/report',
        data: {
          "report_frequency": "DAILY",
          "is_active": true,
          "report_time": selectedReportTimes,
          "report_type": 'REPORT_REALTIME_COUNT'
        }
      })
      .then(res => {
        getReports();
        updateMessage('The dashboard report have been successfully created.', 'success');
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        if (error.response.data.detail === 'Rule id already exists') {
          updateMessage('Dashboard report already exists.', 'error');
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const editReport = (selectedSettingId, isActive) => {
    fast_api_axios(
      {
        method: 'patch',
        url: '/api/v1/setting/report/' + selectedSettingId,
        data: {
          "report_type": 'REPORT_REALTIME_COUNT',
          "report_frequency": 'DAILY',
          "is_active": isActive,
          "report_time": selectedReportTime.value,
        }
      })
      .then(res => {
        updateMessage('Successfully edited dashboard report setting.', 'success');
        getReports();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const deleteReport = (selectedSettingId) => {
    fast_api_axios(
      {
        method: 'delete',
        url: '/api/v1/setting/report/' + selectedSettingId,
      })
      .then(res => {
        updateMessage('Dashboard report deleted successfully.', 'success');
        getReports();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const editReportEmailSending = (selectedSettingId, isActive) => {
    fast_api_axios(
      {
        method: 'patch',
        url: '/api/v1/setting/report/' + selectedSettingId,
        data: {
          "is_active": isActive,
          "is_active_change": true
        }
      })
      .then(res => {
        if (isActive) {
          updateMessage('Successfully turned on dashboard report.', 'success');
        }
        else {
          updateMessage('Successfully turned off dashboard report.', 'success');
        }
        getReports()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const activateEditChanges = (selectedReportItem) => {
    setSelectedReportSettingId(selectedReportItem.id);
    setIsEditReportActive(true);
  }

  const discardEditChanges = () => {
    setIsEditReportActive(false);
    setSelectedReportSettingId(null);
    setSelectedReportTime([]);
  }

  const showAddUserModal = (selectedAlertSetting, type) => {
    discardEditChanges()
    setUserModalType(type)
    setAddedUserList(selectedAlertSetting.users);
    setSelectedReportSettingId(selectedAlertSetting.id)
    setShowAddUser(true);
  }

  const closeAddUserModal = () => {
    setAddedUserList([]);
    setSelectedReportSettingId(null);
    setShowAddUser(false);
  }
  const doneAddUserModal = () => {
    setAddedUserList([]);
    setSelectedReportSettingId(null);
    getReports();
    setShowAddUser(false);
  }

  useEffect(() => {
    getReports();
  }, [])

  return (
    <div className='instant-settings-content'>
      {isReportsLoading ?
        <Loader />
        :
        dashboardReports.length > 0 ?
          <div className='instant-settings-content-active'>
            <div className='settings-content-header'>
              <Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                <QuestionCircle size={24} />
              </Button>
              <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                {(props) => (
                  <Tooltip {...props} className='manage-email-alerts'>
                    <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', marginBottom: '0.5rem' }}>Dashboard Reports</p>
                    You can configure the frequency of dashboard reports and receive them via email.
                  </Tooltip>
                )}
              </Overlay>
              Manage E-mail Alerts
              <Button className='purple-bordered-transparent-btn ms-auto' onClick={() => handleShowPeriodicDashboardReportModal()}>
                <PlusLg />
                Add
              </Button>
            </div>
            <Table className='alert-settings-table'>
              <thead>
                <tr>
                  <th>Dashboard</th>
                  <th>Frequency</th>
                  <th>Report Time</th>
                  <th>Shared With</th>
                  <th>Send E-mail</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dashboardReports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>Real-Time Counting</td>
                      <td>{item.report_frequency.charAt(0).toUpperCase() + item.report_frequency.slice(1).toLowerCase()}</td>
                      <td>
                        {
                          isEditReportActive && selectedReportSettingId === item.id ?
                            <Select
                              options={getHoursInDay()}
                              className="basic-single table-select"
                              components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
                              classNamePrefix="select"
                              placeholder={item.report_time || 'Select Time'}
                              onChange={(item) => setSelectedReportTime(item)}
                              value={getHoursInDay().find(x => x.value === selectedReportTime.value)}
                            />
                            :
                            item.report_time
                        }
                      </td>
                      <td>
                        <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'report')}>
                          <PersonPlus size={22} color='#352B63' className='me-3' />
                          {item.users.length > 0 ?
                            <div className="user-count mx-1">
                              {item.users.length}
                              <span className='tooltiptext'>
                                <h5>E-mails ({item.users.length})</h5>
                                {item.users.map((useritem, index) => {
                                  return <p key={index}>{useritem.email}</p>
                                })}
                              </span>
                            </div>
                            :
                            <div className='add-user-text'>Add User</div>
                          }
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center me-5'>
                          <Switch
                            className='me-2 mt-1'
                            offColor='#9B9B9B'
                            onColor='#8020CF'
                            height={20}
                            width={40}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={item.is_active}
                            onChange={() => editReportEmailSending(item.id, !item.is_active)}
                          />
                          {item.is_active ?
                            <div className='switch-text active'>ON</div>
                            :
                            <div className='switch-text'>OFF</div>
                          }
                        </div>
                      </td>
                      <td>
                        {isEditReportActive && selectedReportSettingId === item.id ?
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='mb-0 me-5 save-text' onClick={() => editReport(item.id, item.is_active)}>Save</p>
                            <X size={30} className="discard" onClick={() => discardEditChanges()} />
                          </div>
                          :
                          <Dropdown className="actions-dropdown d-inline">
                            <Dropdown.Toggle id="dropdown-autoclose-true">
                              <ThreeDotsVertical />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => activateEditChanges(item)}>Edit</Dropdown.Item>
                              <Dropdown.Item onClick={() => deleteReport(item.id)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          :
          <div className='add-first'>
            <h5><PlusLg size={20} color='#210835' /> Add Dashboard Report</h5>
            <p>You can configure the frequency of dashboard reports and receive them via email.</p>
            <Button className='purple-btn' onClick={() => handleShowPeriodicDashboardReportModal()}>Add Report</Button>
          </div>
      }

      <AddDashboardReportModal
        show={showAddPeriodicDashboardReportModal}
        handleClose={handleClosePeriodicDashboardReportModal}
        handleDone={addReport}
        dashboardOptions={dashboardOptions}
        frequencyOptions={frequencyOptions}
      />

      <AddUserModal
        show={showAddUser}
        type={userModalType}
        handleClose={closeAddUserModal}
        handleDone={doneAddUserModal}
        addedUserList={addedUserList}
        settingId={selectedReportSettingId}
      />
    </div>
  )
}
