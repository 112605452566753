import { Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import Chart from "react-apexcharts";

const pieColors = [
  '#492CE6',
  '#22FCDB',
  '#60EFFF',
  '#FF00F8',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#26909C'
]
export default function StaffDwellModalExample(props) {
  const chartLabels = ['01:00', '03:00', '05:00', '07:00', '09:00', '11:00', '13:00', '15:00', '17:00', '19:00', '21:00', '23:00'];
  const chartDataset = [{
    name: "Dwell Time",
    data: [5, 10, 12, 4, 6, 2, 1, 8, 5, 9, 6, 2],
    fill: false,
    borderColor: pieColors[0],
    backgroundColor: pieColors[0],
    tension: 0.1
  }];
  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  const closeModal = () => {
    props.handleClose();
  }

  return (
    <Modal show={props.show} onHide={closeModal} size='xl' centered className='staff-dwell-detail-modal'>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">{props.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <p className='average-time'>Average Daily Dwell Time: <span>00:01:00</span></p>

        <Chart
          options={{
            chart: {
              animations: {
                enabled: false,
              },
              fontFamily: 'Lato, sans-serif',
              type: 'line',
              toolbar: {
                show: false
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
              width: 3
            },
            grid: {
              row: {
                colors: ['transparent']
              },
              padding: {
                top: 0,
                right: 20,
                bottom: 50,
                left: 20
              },
            },

            xaxis: {
              categories: lineData.labels,
              tooltip: {
                formatter: function (value, opts) {
                  const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                  if (v === undefined || v === '') {
                    return ''
                  } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                    return opts.w.globals.categoryLabels[opts.dataPointIndex]
                  } else {
                    return v;
                  }
                },
              },
              labels: {
                rotate: -70,
                style: {
                  fontSize: '11px',
                  fontWeight: 400,
                },
                rotateAlways: true,
                formatter: function (value) {
                  return value;
                }
              }
            },
            yaxis: {
              title: {
                text: 'Dwell Time'
              }
            },
            tooltip: {
              fixed: {
                enabled: false
              },
              enabled: true,
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                const hoverIndexes = w.globals.seriesX.map(seriesX => {
                  return seriesX.findIndex(xData => xData === hoverXaxis);
                });

                let hoverList = '';
                hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                  if (hoverIndex >= 0) {
                    hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                });

                return `<div class="card">
                                <div class="card-body p-1">
                                 ${hoverList}
                                </div>
                              </div>`;
              }
            },
            colors: pieColors,
            legend: {
              showForSingleSeries: false
            },
          }}
          series={
            lineData.datasets
          }
          height={350}
          type="line"
        />
        <div className='scrollableTable'>
          <Table className='modal-time-table'>
            <thead>
              <tr>
                <th>ENTER TIME</th>
                <th>EXIT TIME</th>
                <th>DWELL TIME</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>{moment().add(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>00:01:00</td>
              </tr>
              <tr>
                <td>{moment().subtract(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>00:01:00</td>
              </tr>
              <tr>
                <td>{moment().subtract(2, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>{moment().subtract(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>00:01:00</td>
              </tr>
              <tr>
                <td>{moment().subtract(3, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>{moment().subtract(2, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>00:01:00</td>
              </tr>
              <tr>
                <td>{moment().subtract(4, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>{moment().subtract(3, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                <td>00:01:00</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  )
}
