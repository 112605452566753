import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import Select from 'react-select';
import CustomTimeRangePicker from '../CustomTimeRangePicker';
import CustomTimePickerHourly
  from '../CustomTimePickerHourly';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import {
  findRuleNameByRuleId,
  findStreamNameByStreamId
} from '../../utils/generic';

const weeklyOptions = [
  {
    label: 'Monday',
    value: 'Monday'
  },
  {
    label: 'Tuesday',
    value: 'Tuesday'
  },
  {
    label: 'Wednesday',
    value: 'Wednesday'
  },
  {
    label: 'Thursday',
    value: 'Thursday'
  },
  {
    label: 'Friday',
    value: 'Friday'
  },
  {
    label: 'Saturday',
    value: 'Saturday'
  },
  {
    label: 'Sunday',
    value: 'Sunday'
  }
]

export default function GroupPeriodicReportModal({
  show,
  handleClose,
  handleDone,
  frequencyOptions,
  weeklyOptions,
  presets
}) {
  let history = useHistory();
  const { logout, currentUser } = useAuth();
  const { updateMessage } = useToast()
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const [presetData, setPresetData] = useState(localStorage.getItem('presets') ? JSON.parse(localStorage.getItem('presets')) : []);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupStreams, setSelectedGroupStreams] = useState(null);
  const [selectedGroupEvents, setSelectedGroupEvents] = useState(null);



  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedReportAlert, setSelectedReportAlert] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedWeekly, setSelectedWeekly] = useState([]);

  const [selectedReportTimes, setSelectedReportTimes] = useState(null);

  const [timeRangeStart, setTimeRangeStart] = useState(null);
  const [timeRangeEnd, setTimeRangeEnd] = useState(null);

  const groupOptions = useMemo(() => {
    if (!presets?.results) return [];

    return presets.results.map(preset => ({
      value: preset.id,
      label: preset.name
    }));
  }, [presets]);

  const closeModal = () => {
    setSelectedDevice(null);
    setSelectedStream(null);
    setSelectedFrequency(null);
    setSelectedReportTimes(null);
    setSelectedWeekly([]);
    setTimeRangeStart(null);
    setTimeRangeEnd(null);
    handleClose();
  }

  const handleSelectedGroup = (item) => {
    setSelectedGroup(item);
  }

  const handleSelectedStream = (item) => {
    setSelectedStream(item);
  }

  const handleSelectedAlert = (item) => {
    setSelectedReportAlert(item);
  }

  const handleAddAlert = (device, stream, reportAlert, frequency, reportTimes, weeklyDays, timeRangeStart, timeRangeEnd, preset_id ) => {
    handleDone(device, stream, reportAlert, frequency, reportTimes, weeklyDays, timeRangeStart, timeRangeEnd, preset_id);
    closeModal();
  }

  // for create group case
  /*useEffect(() => {
    if (selectedGroup !== null) {
      let selectedGroupData = presetData.results.find(p => p.id === selectedGroup.value)
      let newStreamOptions = [];
      selectedGroupData.filters.cameras.forEach(element => {
        newStreamOptions.push({
          value: element,
          label: findStreamNameByStreamId(element)
        })
      })
      setSelectedGroupStreams(newStreamOptions)
      setSelectedStream(null);
    }
  }, [selectedGroup])

  useEffect(() => {
    if (selectedGroup !== null && selectedStream !== null) {
      let selectedGroupData = presetData.results.find(p => p.id === selectedGroup.value)
      let newAlertOptions = [];
      selectedGroupData.filters.rules.forEach(element => {
        newAlertOptions.push({
          value: element,
          label: findRuleNameByRuleId(element)
        })
      })
      setSelectedGroupEvents(newAlertOptions)
      setSelectedReportAlert(null);
    }
  }, [selectedGroup, selectedStream])


  useEffect(() => {
    const isValid = selectedGroup && selectedStream && selectedReportAlert &&
                   selectedFrequency &&
                   (selectedFrequency.value === 'WEEKLY' ? selectedWeekly : selectedReportTimes);

    setIsSaveDisabled(!isValid);
  }, [selectedGroup, selectedStream, selectedReportAlert, selectedFrequency, selectedWeekly, selectedReportTimes]);

   */

  useEffect(() => {
    let isValid = false;

    if (selectedGroup && selectedFrequency) {
      switch (selectedFrequency.value) {
        case 'DAILY':
          isValid = selectedReportTimes !== null;
          break;
        case 'WEEKLY':
          isValid = selectedWeekly.length > 0;
          break;
        case 'MONTHLY':
          isValid = true;
          break;
        default:
          isValid = false;
      }
    }

    setIsSaveDisabled(!isValid);
  }, [selectedGroup, selectedFrequency, selectedWeekly, selectedReportTimes]);

  return (
    <Modal className='add-report-modal' show={show} onHide={closeModal} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">
          Group Periodic Report
        </Modal.Title>

      </Modal.Header>
      <span style={{ textAlign: 'center' }}> You can add periodic report for all alerts.</span>
      <Modal.Body>
          <p className='select-asset-label'>Group Name</p>
          <Select
            name="groupOptions"
            options={groupOptions}
            className="basic-single table-select"
            classNamePrefix="select"
            placeholder="Select Group"
            onChange={(item) => handleSelectedGroup(item)}
            value={selectedGroup}
          />

        {/* for create group case */}
        {/*<p className="select-asset-label">Stream</p>
        <Select
          isDisabled={selectedGroupStreams === null}
          components={{
            DropdownIndicator: ({ selectProps }) =>
              selectProps.menuIsOpen ?
                <CaretUpFill className='select-icon me-2' /> :
                <CaretDownFill className='select-icon me-2' />
          }}
          name="streamOptions"
          options={selectedGroupStreams}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Stream"
          onChange={handleSelectedStream}
          value={selectedStream}
        />
        <p className="select-asset-label">Alert Type</p>
        <Select
          components={{
            DropdownIndicator: ({ selectProps }) =>
              selectProps.menuIsOpen ?
                <CaretUpFill className='select-icon me-2' /> :
                <CaretDownFill className='select-icon me-2' />
          }}
          name="alertOptions"
          options={selectedGroupEvents}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Alert Type"
          onChange={handleSelectedAlert}
          value={selectedReportAlert}
        />*/}
        <p className="select-asset-label">Report Frequency</p>
        <Select
          isDisabled={selectedGroup === null}
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          name="alertOptions"
          options={frequencyOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Frequency"
          onChange={(item) => setSelectedFrequency(item)}
          value={selectedGroup === null ? null : frequencyOptions.find(f => f.value === selectedFrequency?.value)}
        />

        {/* if frequency is daily */}
        {
          selectedFrequency?.value === 'DAILY' &&
          <>
            <div>
              <p className="select-asset-label">Custom Time Ranges for Data <i>(UTC)</i></p>
              <div className='d-flex align-items-center gap-3'>
                <CustomTimeRangePicker
                  selectedTime={timeRangeStart}
                  handleSelectedTime={setTimeRangeStart}
                />
                <p>to</p>
                <CustomTimeRangePicker
                  selectedTime={timeRangeEnd}
                  handleSelectedTime={setTimeRangeEnd}
                />
              </div>
            </div>
            <p className="select-asset-label">Report Time <i>(UTC)</i></p>
            <CustomTimePickerHourly
              selectedReportTimes={selectedReportTimes}
              handleSelectedReportTimes={setSelectedReportTimes}
              selectedFrequency={selectedFrequency}
              isEditMode={false}
            />
          </>
        }
        {
          selectedFrequency?.value === 'WEEKLY' &&
          <>
            <p className="select-asset-label">Report Day <i>(07:00 AM UTC)</i></p>
            <Select
              isDisabled={selectedGroup === null}
              components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
              name="weeklyOptions"
              options={weeklyOptions}
              className="basic-single table-select"
              classNamePrefix="select"
              placeholder="Select a Day"
              onChange={(item) => setSelectedWeekly(item?.value)}
              value={weeklyOptions.find(w => w.value === selectedWeekly)}
            />
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {isSaveDisabled ?
          <Button className="add-report-disabled-btn w-50">Save</Button>
          :
          <Button className="add-report-active-btn w-50" onClick={() => handleAddAlert(selectedDevice, selectedStream, selectedReportAlert, selectedFrequency, selectedReportTimes, selectedWeekly, timeRangeStart, timeRangeEnd, selectedGroup.value)}>Save</Button>
        }
      </Modal.Footer>
    </Modal >
  )
}
