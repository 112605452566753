import React, { useState, useEffect, useRef } from 'react';
import Chart from "react-apexcharts";
import { Container, Row, Button, Accordion, Table, Modal, Overlay, Tooltip, Form } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Search, Filter, ChevronDown, QuestionCircle, X, Calendar4 } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import moment from 'moment';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import Select from 'react-select';
import { useAuth } from '../../contexts/AuthContext';
import { getDevices, getStreams, getZones, getClasses, findStreamNameByStreamId } from '../../utils/generic';
import momentTimezone from 'moment-timezone';
import 'moment-duration-format';
import StaffDwellModal from './StaffDwellModal';
import NoDataBoard from '../exampleDashboards/NoDataBoard';

export default function StaffDwell() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { updateMessage } = useToast();
  const { logout } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [questionActive, setQuestionActive] = useState(false);
  const questionTarget = useRef(null);

  const [showStaffDwellModal, setShowStaffDwellModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null)

  const [averageDwellTime, setAverageDwellTime] = useState(0);
  const [staffData, setStaffData] = useState([]);
  const [filteredStaffData, setFilteredStaffData] = useState([]);

  const handleShowModal = (id) => {
    setSelectedId(id)
  }

  useEffect(() => {
    selectedId !== null ? setShowStaffDwellModal(true) : setShowStaffDwellModal(false)
  }, [selectedId])

  const handleCloseModal = () => {
    setSelectedId(null)
  }

  const displayHourFormat = totalSeconds => {
    let totalSecondsRounded = Math.round(totalSeconds)
    let hours = Math.floor(totalSecondsRounded / 3600);
    totalSecondsRounded = totalSecondsRounded % 3600;
    let minutes = Math.floor(totalSecondsRounded / 60);
    let seconds = totalSecondsRounded % 60;

    return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
  }

  const getStaffData = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/eyewatch/dwell',
        params: {
          //'date': moment().unix()
        }
      })
      .then(res => {
        const arr = res.data.result.map(obj => ({ ...obj, "name": findStreamNameByStreamId(obj.camera_id) }))
        setStaffData(arr)
        setFilteredStaffData(arr)
        setAverageDwellTime(displayHourFormat(res.data.avg_dwell_time))
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getStaffData()
  }, [])

  const filterSearch = (text) => {
    var all = staffData
    const filteredData = all.filter(value => {
      const searchStr = text.toLowerCase();
      const nameMatches = value.name.toLowerCase().includes(searchStr);
      //const oneItemMatches = value.items.some(item => item.toLowerCase().includes(searchStr));

      return nameMatches;
    });

    setFilteredStaffData(filteredData)
  }

  useEffect(() => {
    filterSearch(searchText)
  }, [searchText])

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Staff Dwell Time</span>
            </p>

            <div className='current-time'>
              <Calendar4 color='#F2E9FA' size={18} />
              {moment().tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}
            </div>
          </div>

          <div className='page-wrapper'>
            {isLoading ?
              <Loader />
              :
              staffData.length > 0 ?
                <>
                  <div className='summary-area'>
                    <h5>Summary</h5>
                    <div className='d-flex'>
                      <div className='summary-box'>
                        <div className='name'>Number of Room</div>
                        <div className='result'>{staffData.length}</div>
                      </div>
                      <div className='summary-box'>
                        <div className='name'>Current Average Dwell Time</div>
                        <div className='result'>{averageDwellTime}</div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='staff-search-group'>
                      <Search className='search-icon' />
                      <Form.Control placeholder="Search Room Name" className='staff-search-box' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                      {searchText.length > 0 && <X size={24} color='#535353' className='delete-icon' onClick={(e) => setSearchText('')} />}
                    </div>

                    {/*<Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                    <QuestionCircle size={24} />
                  </Button>
                  <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                    {(props) => (
                      <Tooltip {...props} className='manage-email-alerts'>
                        <strong>asdasdasdasdsa</strong>
                      </Tooltip>
                    )}
                  </Overlay>*/}
                  </div>
                  <div className='scrollableTable'>
                    <Table className='staff-dwell-table'>
                      <thead>
                        <tr>
                          <th>ROOM NAME</th>
                          <th>ENTER TIME</th>
                          <th>EXIT TIME</th>
                          <th>DWELL TIME</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredStaffData.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.name}</td>
                              <td>{moment(item.enter_time).format('MM/DD/YYYY HH:mm:ss')}</td>
                              <td>{moment(item.exit_time).format('MM/DD/YYYY HH:mm:ss')}</td>
                              <td>{displayHourFormat(item.avg_dwell_time)}</td>
                              <td>
                                <div className='details-btn' onClick={() => handleShowModal(item.camera_id)}>Details</div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
                :
                <NoDataBoard exampleRoute='/dashboards/sample/staff-dwell' />
            }
          </div>
        </div>
      </Row>
      <StaffDwellModal show={showStaffDwellModal} handleClose={handleCloseModal} selectedId={selectedId} />
    </Container>

  )
}
