import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../components/login/Login";
import ForgotPassword from "../components/login/ForgotPassword";
import { useAuth } from "../contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import Content from "../components/alerts/Content";
import Trends from "../components/trends/Trends";
import GroupAlerts from "../components/group-alerts/GroupAlerts";
import TrendsTable from "../components/trends/TrendsTable";
import TrendsComment from "../components/trends/TrendsComment";
import Heatmap from "../components/heatmap/Heatmap";
import DemographicsGraph from "../components/demographics/DemographicsGraph";
import CountingNew from "../components/counting/CountingNew";
import Ppe from "../components/ppe/Ppe";
import Dashboards from "../components/dashboard/Dashboards";
import Dashboard from "../components/dashboard/Dashboard";
import AddDashboard from "../components/dashboard/AddDashboard";
import EditDashboard from "../components/dashboard/EditDashboard";
import Settings from "../components/settings/Settings";
import ConfirmEmail from "../components/login/ConfirmEmail";
import Overview from "../components/overview/Overview";
import Logs from '../components/overview/Logs';
import Dwell from "../components/dwell/Dwell";
import DemographicsExample from "../components/exampleDashboards/DemographicsExample";
import CountingExample from "../components/exampleDashboards/CountingExample";
import DwellExample from "../components/exampleDashboards/DwellExample";
import SettingsContent from "../components/settings/SettingsContent";
import InventoryManagement from "../components/inventoryManagement/InventoryManagement";
import TimeBasedTable from "../components/inventoryManagement/TimeBasedTable";
import RealTimeCounting from '../components/realTimeCounting/RealTimeCounting';
import StaffDwell from "../components/staff/StaffDwell";
import StaffDwellExample from "../components/staff/StaffDwellExample";
import ProductDetail from "../components/inventoryManagement/ProductDetail";

export default function Routes() {
  const { currentUser, isAuthenticated } = useAuth()

  return (
    <Switch>
        <Route exact path="/login">
            {isAuthenticated === "true" ? (
              currentUser.name === "choochkaiserpilot@gmail.com" ? (
                <Redirect to="/dashboards/inventory-management" />
              ) : currentUser.name === "cbalci@cdt.com.tr" ? (
                <Redirect to="/dashboards/real-time-counting" />
              ) : (
                <Redirect to="/overview" />
              )
            ) : (
              <Login />
            )}
        </Route>
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/confirm/:uuid" component={ConfirmEmail} />
      <PrivateRoute roles={['USER']} exact path="/overview" comp={Overview} />
      <PrivateRoute roles={['USER']} exact path="/overview/stream-status" comp={Logs} />
      <PrivateRoute roles={['USER']} exact path="/alerts" comp={Content} />
      <PrivateRoute roles={['USER']} exact path="/group-alerts" comp={GroupAlerts} />
      <PrivateRoute roles={['USER']} exact path="/trends" comp={Trends} />
      <PrivateRoute roles={['USER']} exact path="/trends/details" comp={TrendsTable} />
      <PrivateRoute roles={['USER']} exact path="/trends/comment/:device/:camera/:rule" comp={TrendsComment} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/heatmap" comp={Heatmap} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/demographics" comp={DemographicsGraph} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/counting" comp={CountingNew} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/ppe" comp={Ppe} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/dwell" comp={Dwell} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/inventory-management" comp={InventoryManagement} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/inventory-management/time-based-usage" comp={TimeBasedTable} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/inventory-management/:productID" comp={ProductDetail} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/sample/demographics" comp={DemographicsExample} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/sample/counting" comp={CountingExample} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/sample/dwell" comp={DwellExample} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/sample/staff-dwell" comp={StaffDwellExample} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/real-time-counting" comp={RealTimeCounting} />
      <PrivateRoute roles={['USER']} exact path="/dashboards/staff-dwell" comp={StaffDwell} />
      <PrivateRoute roles={['USER']} exact path="/dashboards" comp={Dashboards} />
      <PrivateRoute roles={['USER']} exact path="/dashboard/add" comp={AddDashboard} />
      <PrivateRoute roles={['USER']} exact path="/dashboard/edit/:id" comp={EditDashboard} />
      <PrivateRoute roles={['USER']} exact path="/dashboard/:id" comp={Dashboard} />
      <PrivateRoute roles={['USER']} exact path="/settings" comp={Settings} />
      <PrivateRoute roles={['USER']} exact path="/settingsnew" comp={SettingsContent} />
      {isAuthenticated !== "true" ?
        <Redirect from="/" to="/login" />
        :
        <Redirect from="/" to="/overview" />
      }
    </Switch>
  );
}
