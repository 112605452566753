import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function NoticeModal(props) {

  const goToChoochMain = () => {
    window.open('https://app.chooch.ai', "_self")
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} size='lg' centered>
      <Modal.Header closeButton className='text-center'>
        <Modal.Title className='w-100'>Important Notice for Google or Facebook Sign-In Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='notice-modal-text'>
          If you initially signed up for an AI Vision Studio account using your Google or Facebook account, 
          you'll need to set up a unique password for direct ImageChat-Web access. 
          This step is essential to enhance your account security and streamline your login process. 
          Here's how you can create your AI Vision Studio password:
          <br></br><br></br>
          <strong>1. Log in to your <span>AI Vision Studio</span> Account Using Google or Facebook:</strong> First, access the AI Vision Studio website and log in using your Google or Facebook account, as you usually do. 
          <br></br><br></br>
          <strong>2. Navigate to Profile:</strong> Once logged in, go to your Profile. This option is found in the upper right corner of the screen, under your profile or account icon. 
          <br></br><br></br>
          <strong>3. Select 'Security & Privacy’:</strong> In your account settings, look for an option labeled 'Create Password'. 
          <br></br><br></br>
          <strong>4. Follow the Instructions:</strong> Enter a new password as per the provided guidelines (ensure it's strong and unique for security purposes). 
          <br></br><br></br>
          <strong>5. Save Changes:</strong> After setting your new password, save the changes. 
          <br></br><br></br>
          You can now log in to your ImageChat-Web account using the newly created password. If you encounter any issues or need further assistance, please <span>contact</span> our support team.
        </div>
        
        <Button className="show-latest w-100" onClick={goToChoochMain}>
          Go to AI Vision Studio
        </Button>
      </Modal.Body>
    </Modal>
  )
}
