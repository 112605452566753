import React from 'react'
import { Toast } from 'react-bootstrap'
import { useToast } from '../contexts/ToastContext'
import success from "../assets/images/success.svg";
import closeicon from "../assets/images/close.svg";


export default function ToastMessage() {
  const { type, message, show, close } = useToast()

  return (
    <Toast className={type === 'success' ? "toast-message-success" : "toast-message-error"} onClose={() => close()} show={show} delay={5000} autohide >
      <Toast.Body className='d-flex justify-content-between'>
        <div className='me-3'>
          {type === 'success' && <img className="success" src={success} alt="success" />}
          {message}
        </div>
        {type === 'success' && <img onClick={close} className="closeicon" src={closeicon} alt="close" />}
      </Toast.Body>
    </Toast>
  )
}
