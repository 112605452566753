import React, { useState, useEffect } from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from "react-router-dom";
import Loader from '../Loader';
import Pagination from "../../utils/Pagination";
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import success from '../../assets/images/success-check.svg';
import warningIcon from '../../assets/images/warning-red.svg';

export default function Logs() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [logData, setLogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  const changePage = (page) => {
    setIsLoading(true);
    setCurrentPage(page);
    getLogs(page);
  };

  const getLogs = (page) => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/camera/logs',
        params: {
          size: 10,
          page: page
        }
      })
      .then(res => {
        setTotalCount(res.data.count)
        setLogData(res.data.results);
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getLogs()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='alerts-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/overview')}>Overview</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Stream Status</span>
            </p>
          </div>
          <div className='page-wrapper'>
            <div className='table-box px-4 mt-3'>
              <h5 className='mb-3'>Stream Logs</h5>
              {isLoading ?
                <Loader />
                :
                <>
                  <Table className='logs-table'>
                    <thead>
                      <tr>
                        <th>STREAM NAME</th>
                        <th>DEVICE NAME</th>
                        <th>STATUS</th>
                        <th>DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        logData.map((logItem, index) => {
                          return (
                            <tr key={index}>
                              <td>{logItem.camera_name}</td>
                              <td>{logItem.device_name}</td>
                              <td>
                                {logItem.status === "active" ?
                                  <div className='d-flex align-items-center'>
                                    <img src={success} alt="success" width={20} height={20}  style={{marginRight: '3px'}}/>
                                    <span style={{color: '#210835'}}>Live</span>
                                  </div> :
                                  <div className='d-flex align-items-center'>
                                    <img src={warningIcon} alt="success" width={20} height={20}  style={{marginRight: '3px'}}/>
                                    <span style={{color: '#210835'}}>Offline</span>
                                  </div>}
                              </td>
                              <td>{moment(logItem.log_date + '+00:00').format("MM/DD/YYYY HH:mm")}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>

                  <div className="paginationBlock">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={totalCount}
                      pageSize={10}
                      showItemsPerPage={false}
                      onPageChange={(page) => changePage(page)}
                    />
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}
