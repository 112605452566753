import React from 'react';
import Chart from "react-apexcharts";
import { ChevronRight } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";

const pieColors = ['#1F54CF', '#FF00F8', /*'#0C0043',*/ '#FFD000'];
const barColors = ['#1F54CF', '#FF00F8', '#FFD000'];
const emotionColors = ['#8020CF', '#22FCDB', '#0C0043', '#FF00F8', '#FFD000', '#1F54CF', '#D47235']
const pieLabels = ['Men', 'Women', /*'Masked',*/ 'Children'];
const emotionLabels = ['Happy', 'Sad', 'Neutral', 'Angry', 'Fear', 'Disgust', 'Surprise'];
const barLabels = ['0-4', '5-12', '13-17', '18-24', '25-34', '35-44', '45-54', '55+'];

export default function DemographicsExample() {
  let history = useHistory();
  const pieData = [1310, 1450, 820]; //
  const emotionData = [45.71, 13.48, 11.31, 20.57, 6.85, 0.22, 1.82]; //2000, 590, 495, 900, 300, 10, 80
  const barData = [{
    name: 'Men',
    data: [0, 0, 150, 250, 350, 250, 300, 10]
  }, {
    name: 'Women',
    data: [0, 0, 150, 200, 250, 350, 450, 50]
  }, {
    name: 'Children',
    data: [230, 590, 0, 0, 0, 0, 0, 0]
  }];
  const lineData = [
    {
      name: "Men",
      data: [10, 5, 2, 1, 0, 0, 5, 20, 25, 30, 40, 60, 50, 80, 120, 180, 150, 90, 130, 145, 80, 50, 30, 7] //1310
    },
    {
      name: 'Women',
      data: [15, 10, 0, 8, 0, 0, 5, 20, 80, 50, 30, 25, 30, 80, 280, 150, 170, 70, 130, 145, 40, 60, 50, 2] //1450
    },
    /*{
      name: 'Masked',
      data: [10, 0, 0, 0, 0, 0, 15, 25, 10, 15, 5, 25, 75, 85, 65, 45, 25, 50, 20, 10, 5, 0, 10, 0] //495
    },*/
    {
      name: 'Children',
      data: [5, 0, 0, 0, 0, 0, 0, 0, 10, 20, 50, 80, 50, 120, 90, 75, 100, 80, 90, 20, 10, 0, 20, 0] //820
    }
  ];
  const lineLabels = [
    "01/01/2023 00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "01/01/2023 23:00"
  ];
  const chartDraw = [
    "05/04/2023 00:00",
    "05/04/2023 01:00",
    "05/04/2023 02:00",
    "05/04/2023 03:00",
    "05/04/2023 04:00",
    "05/04/2023 05:00",
    "05/04/2023 06:00",
    "05/04/2023 07:00",
    "05/04/2023 08:00",
    "05/04/2023 09:00",
    "05/04/2023 10:00",
    "05/04/2023 11:00",
    "05/04/2023 12:00",
    "05/04/2023 13:00",
    "05/04/2023 14:00",
    "05/04/2023 15:00",
    "05/04/2023 16:00",
    "05/04/2023 17:00",
    "05/04/2023 18:00",
    "05/04/2023 19:00",
    "05/04/2023 20:00",
    "05/04/2023 21:00",
    "05/04/2023 22:00",
    "05/04/2023 23:00",
  ];

  return (
    <>
      <div className='page-content-header d-flex justify-content-between align-items-center'>
        <p className='breadcrumb'>
          <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
          <ChevronRight size={14} />
          <span className='main-breadcrumb' onClick={() => history.push('/dashboards/demographics')}>Demographics</span>
          <ChevronRight size={14} />
          <span className='inner-breadcrumb'>Sample Dashboard</span>
        </p>
      </div>

      <div className='page-wrapper'>
        <div className='example-dashboard-header mb-3'>
          Example Dashboard
        </div>

        <div className='d-flex mt-2 justify-content-between w-100'>
          <div className="demographics-column-pie">
            <div className='w-100'>
              <h5 className='demographics-column-header'>Total Gender Distribution of Visitors (%)</h5>

              <Chart
                series={pieData}
                type="pie"
                height="370"
                options={{
                  chart: {
                    toolbar: {
                      show: false
                    },
                    type: 'pie',
                    height: 450,
                    fontFamily: 'Lato, sans-serif',
                  },
                  tooltip: {
                    y: {
                      formatter: function (val, opt) {
                        var total = opt.config.series.reduce((a, b) => a + b, 0)
                        var percent = (opt.config.series[opt.seriesIndex] / total * 100).toFixed(1)
                        return opt.config.series[opt.seriesIndex] + " - " + percent + "%"
                      }
                    }
                  },
                  labels: pieLabels,
                  dataLabels: {
                    style: {
                      fontWeight: 'normal',
                      colors: ["#F7F7F7", "#F7F7F7", "#F7F7F7", "#303030"],
                    },
                  },
                  legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                  },
                  colors: pieColors
                }}
              />
            </div>
          </div>

          <div className="demographics-column-bar">
            <div className='demographics-bar'>
              <h5 className='demographics-column-header'>Age and Gender <br /> Distribution of Visitors (#)</h5>

              <Chart
                series={barData}
                type="bar"
                height="350"
                options={{
                  chart: {
                    toolbar: {
                      show: false,
                    },
                    type: 'bar',
                    height: 550,
                    stacked: true,
                    fontFamily: 'Lato, sans-serif',
                    events: {
                      legendClick: (chartContext, seriesIndex, config) => {
                        const { series } = config.config;
                        const selectedSeriesItemName = series[seriesIndex].name; // seçilen
                        var falseCount = 0;
                        series.forEach((seriesItem, index) => {
                          const currentSeriesItemName = seriesItem.name;
                          if (selectedSeriesItemName === currentSeriesItemName) {
                            if (!chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                              falseCount++;
                            };
                          } else {
                            if (chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                              falseCount++;
                            };
                          }
                          if (falseCount === series.length) {
                            setTimeout(function () { chartContext.showSeries(selectedSeriesItemName) }, 0);
                          }
                        });
                      }
                    },
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    onDatasetHover: {
                      highlightDataSeries: false
                    },
                    y: {
                      formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                    }
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ["#F7F7F7", "#F7F7F7", "#303030"],
                    },
                    formatter: function (value) {
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                  },
                  stroke: {
                    width: 1,
                    colors: ['#fff']
                  },
                  xaxis: {
                    categories: barLabels,
                  },
                  yaxis: {
                    title: {
                      text: '# of Visitors'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                  },
                  colors: barColors
                }}
              />
            </div>
          </div>
        </div>

        <div className="demographics-column-chart mt-3">

          <div className='d-flex justify-content-between'>
            <h5 className='demographics-column-header'>Gender Distribution of Visitors by Hour</h5>
          </div>


          <Chart
            options={{
              chart: {
                toolbar: {
                  show: false,
                  tools: {
                    download: !lineLabels.every(item => item === 0) // <== line to add // <== line to add
                  }
                },
                fontFamily: 'Lato, sans-serif',
                height: 350,
                type: 'line',
                zoom: {
                  enabled: false
                },
                events: {
                  legendClick: (chartContext, seriesIndex, config) => {
                    const { series } = config.config;
                    const selectedSeriesItemName = series[seriesIndex].name; // seçilen
                    var falseCount = 0;
                    series.forEach((seriesItem, index) => {
                      const currentSeriesItemName = seriesItem.name;
                      if (selectedSeriesItemName === currentSeriesItemName) {
                        if (!chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                          falseCount++;
                        };
                      } else {
                        if (chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                          falseCount++;
                        };
                      }
                      if (falseCount === series.length) {
                        setTimeout(function () { chartContext.showSeries(selectedSeriesItemName) }, 300);
                      }
                    });
                  }
                },
              },
              tooltip: {
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map(seriesX => {
                    return seriesX.findIndex(xData => xData === hoverXaxis);
                  });

                  let hoverList = '';
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (hoverIndex >= 0) {
                      hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  });
                  const formatHoverX = chartDraw[dataPointIndex]
                  return `<div class="card">
                        <div class="card-header p-1">${formatHoverX}</div>
                        <div class="card-body p-1">
                          ${hoverList}
                        </div>
                      </div>`;
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight',
                width: 3
              },
              grid: {
                row: {
                  colors: ['transparent']
                },
              },
              xaxis: {
                categories: lineLabels,
                labels: {
                  rotate: -70,
                  rotateAlways: true,
                  style: {
                    fontSize: '11px',
                    fontWeight: 400,
                  },
                }
              },
              yaxis: {
                title: {
                  text: '# of Visitors'
                }
              },
              colors: pieColors,
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
              },
            }}
            series={lineData}
            type="line"
            height={350}
          />
        </div>

        <div className='demographics-column-chart mt-3'>

          <h5 className='demographics-column-header'>Sentiment Distribution of Visitors (%)</h5>
          <Chart
            series={
              [{
                name: '',
                data: emotionData
              }]
            }
            type="bar"
            height="350"
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                type: 'bar',
                height: 550,
                fontFamily: 'Lato, sans-serif',
              },
              tooltip: {
                intersect: false,
                marker: {
                  show: false
                },
                y: {
                  formatter: function (value) {
                    return value + '%';
                  }
                }
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: [
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                      if (w.globals.seriesLog[0][dataPointIndex] < 5) {
                        return "#303030";
                      }
                      else {
                        if (dataPointIndex % 7 === 1 || dataPointIndex % 7 === 4) {
                          return "#303030";
                        } else {
                          return "#F7F7F7";
                        }
                      }

                    }
                  ]
                },
                formatter: function (value) {
                  return value + '%';
                }
              },
              stroke: {
                width: 1,
                colors: ['#F7F7F7']
              },
              xaxis: {
                categories: emotionLabels,
              },
              yaxis: {
                title: {
                  text: '# of Visitors'
                }
              },
              fill: {
                opacity: 1
              },
              colors: [
                function ({ value, seriesIndex, dataPointIndex, w }) {
                  return emotionColors[dataPointIndex % 7];
                }
              ]
            }}
          />
        </div>

      </div>
    </>
  )
}
