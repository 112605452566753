import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select'
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";

export default function ModalCreatePreset(props) {
  const { currentUser, logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const [presetName, setPresetName] = useState('');

  const [devices, setDevices] = useState([]);
  const [streams, setStreams] = useState([]);
  const [zones, setZones] = useState([]);
  const [events, setEvents] = useState([]);
  const [canCreate, setCanCreate] = useState(false);

  const createPreset = () => {
    let presetFilter = {
      "devices": devices.map(item => item.value),
      "cameras": streams.map(item => item.value),
      "zones": zones.map(item => item.value),
      "rules": events.map(item => item.value)
    }

    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/presets/',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("fast_api_token"),
        },
        data: {
          "user_id": currentUser.id,
          "name": presetName,
          "filters": presetFilter,
        },
      })
      .then(res => {
        updateMessage('Saved filter is complete !', 'success');
        props.getNewPresetDataAndApply(res.data.id, res.data.name);
        let presetData = JSON.parse(localStorage.getItem('presets'));
        presetData.results.push(res.data);
        localStorage.setItem('presets', JSON.stringify(presetData));
        closeModal();
      })
      .catch(error => {
        if (error.response.status === 422) {
          updateMessage(presetName + ' already exists!', 'error')
        }
        else if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }


  const closeModal = () => {
    setPresetName('')
    setDevices([])
    setStreams([])
    setZones([])
    setEvents([])
    setCanCreate(false)
    props.handleClose()
  }

  const handleNameChange = (val) => {
    if (val !== null && val !== '') {
      if (devices.length > 0 || streams.length > 0 || zones.length > 0 || events.length > 0) {
        setCanCreate(true)
      }
      else {
        setCanCreate(false)
      }
    }
    else {
      setCanCreate(false)
    }

    setPresetName(val)
  }

  const handleDeviceSelect = items => {
    if (items.length > 0 || streams.length > 0 || zones.length > 0 || events.length > 0) {
      if (presetName !== null && presetName !== '') {
        setCanCreate(true);
      }
      else {
        setCanCreate(false);
      }
    }
    else {
      setCanCreate(false);
    }
    setDevices(items)
  }
  const handleStreamSelect = items => {
    if (items.length > 0 || devices.length > 0 || zones.length > 0 || events.length > 0) {
      if (presetName !== null && presetName !== '') {
        setCanCreate(true);
      }
      else {
        setCanCreate(false);
      }
    }
    else {
      setCanCreate(false);
    }
    setStreams(items)
  }
  const handleZoneSelect = items => {
    if (items.length > 0 || streams.length > 0 || devices.length > 0 || events.length > 0) {
      if (presetName !== null && presetName !== '') {
        setCanCreate(true);
      }
      else {
        setCanCreate(false);
      }
    }
    else {
      setCanCreate(false);
    }
    setZones(items)
  }
  const handleEventSelect = items => {
    if (items.length > 0 || streams.length > 0 || zones.length > 0 || devices.length > 0) {
      if (presetName !== null && presetName !== '') {
        setCanCreate(true);
      }
      else {
        setCanCreate(false);
      }
    }
    else {
      setCanCreate(false);
    }
    setEvents(items)
  }

  return (
    <Modal show={props.show} onHide={closeModal} centered>
      <Modal.Header closeButton className='text-center'>
        <Modal.Title className='w-100'>Create Group</Modal.Title>
      </Modal.Header>

      <Modal.Body className='modal-delete-alert-text'>
        <div className='my-3'>Create and save your own customize filter</div>

        <Form.Group className="mb-3">
          <Form.Label>Group Name*</Form.Label>
          <Form.Control placeholder="Write Group Name" value={presetName} onChange={e => handleNameChange(e.target.value)} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Devices</Form.Label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            name="options"
            options={JSON.parse(localStorage.getItem('deviceData'))}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Choose Device"
            onChange={items => handleDeviceSelect(items)}
            value={devices}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Streams</Form.Label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            name="options"
            options={JSON.parse(localStorage.getItem('streamData'))}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Choose Streams"
            onChange={items => handleStreamSelect(items)}
            value={streams}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Zones</Form.Label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            name="options"
            options={JSON.parse(localStorage.getItem('zoneData'))}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Choose Zones"
            onChange={items => handleZoneSelect(items)}
            value={zones}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Events</Form.Label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            name="options"
            options={JSON.parse(localStorage.getItem('eventData'))}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Choose Events"
            onChange={items => handleEventSelect(items)}
            value={events}
          />
        </Form.Group>

        {canCreate ?
          <Button className="purple-button w-100" onClick={createPreset}>
            Save
          </Button>
          :
          <Button className="modal-action-button w-100" disabled>
            Save
          </Button>
        }

      </Modal.Body>
    </Modal>
  );
}
