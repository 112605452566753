import React, { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from '../Loader';
import moment from 'moment';
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { CaretUpFill, CaretDownFill, CaretRightFill, CameraVideo, InfoCircle, ExclamationCircleFill } from 'react-bootstrap-icons';
import { fast_api_axios } from '../../utils/axiosFast';
import Statistic from './Statistic';
import { useAuth } from '../../contexts/AuthContext'
import RuleInfoBox from './RuleInfoBox'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";
import {
  PromptInfoBox,
  ResultInfoBox
} from './ImageAlertInfoBoxes';

export default function Alert(props) {
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))
  const { currentUser, logout } = useAuth()
  const { updateMessage } = useToast();
  let history = useHistory();
  const [alertsData, setAlertsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  var settings = {
    nextArrow: <Arrow type="next" activeSlide={activeSlide} />,
    prevArrow: <Arrow type="prev" activeSlide={activeSlide} />,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const getAllAlerts = () => {
    setIsLoading(true)
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/summary',
        params: {
          "application": props.appId,
          "is_reviewed": props.isReviewed,
          "alert_type": props.alertType,
          "start": moment(props.dateFilter['start']).unix(),
          "end": moment(props.dateFilter['end']).unix(),
          "devices": null,
          "cameras": null,
          "zones": null,
          "rules": null,
        },
      })
      .then(res => {
        let newArray = []
        let alerts = res.data
        alerts.forEach(function (element) {
          if (element.rule_id === 0) {
            element.name = 'All Alerts'
            newArray.push(element)
          }
        })
        getAlertSummary(newArray)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getAlertSummary = (all) => {

    setIsLoading(true)
    let devices = props.filterDevices.map(device => device.value);
    let cameras = props.filterStreams.map(camera => camera.value);
    //let zones = props.filterZones.map(zone => zone.value);
    //let rules = props.filterEvents.map(rule => rule.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    props.filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);

    let selectedEventIds = [];
    props.filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })
    let rules = selectedEventIds.map(rule => rule.value);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/summary',
        params: {
          "application": props.appId,
          "is_reviewed": props.isReviewed,
          "alert_type": props.alertType,
          "start": moment(props.dateFilter['start']).unix(),
          "end": moment(props.dateFilter['end']).unix(),
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
        },
      })
      .then(res => {
        let alerts = res.data
        //let eventsInfo = JSON.parse(localStorage.getItem('allEventData'))
        let assetsInfo = JSON.parse(localStorage.getItem('assets'))
        alerts.forEach(function (element) {
          assetsInfo.forEach(function (device) {
            device.cameras.forEach(function (stream) {
              stream.zones.forEach(function (zone) {
                let newname = null;
                if (zone.rules.find(x => x.rule_id === element.rule_id)) {
                  //newname = stream.camera_name + '-' + zone.rules.find(x => x.rule_id === element.rule_id).event
                  newname = zone.rules.find(x => x.rule_id === element.rule_id).event
                  element.strname = stream.camera_name
                  element.dvcname = device.device_name
                }
                else {
                  newname = '-'
                }
                if (newname !== '-') {
                  element.name = newname
                }

                if (element.rule_id === 0) {
                  element.name = 'All Alerts'
                }
              })
            })
          })
          //element.name = eventsInfo.find(x => x.value === element.rule_id) ? eventsInfo.find(x => x.value === element.rule_id).label : element.rule_id === 0 ? 'All Alerts' : '-';
        });

        alerts = alerts.filter(function (item) {
          return item.name !== 'All Alerts'
        })


        //let newArray = []
        alerts.forEach(function (element) {
          if (all.find(x => x.rule_id === element.rule_id) === undefined) {
            all.push(element)
          }
          else {
            all.find(x => x.rule_id === element.rule_id).count += element.count
            all.find(x => x.rule_id === element.rule_id).percentage += element.percentage
          }
        })

        all = all.filter(function (item) {
          return item.name !== '-'
        })

        all = all.filter(function (item) {
          return item.name !== undefined
        })

        setAlertsData(all)
        setIsLoading(false)

      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    currentUser.isEnterprise && getAllAlerts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterObj]);

  const filterAlerts = (item, index) => {
    if (currentUser.name !== 'smartanalytics@chooch.com') {
      let filterDevice = [];
      let filterStream = [];
      let filterRule = [];
      let filterAlertType = props.alertType;

      let assetsInfo = JSON.parse(localStorage.getItem('assets'))
      assetsInfo.forEach(function (device) {
        device.cameras.forEach(function (stream) {
          stream.zones.forEach(function (zone) {
            if (zone.rules.find(x => x.rule_id === item.rule_id)) {
              filterDevice = [{ label: device.device_name, value: device.device_id }]
              filterStream = [{ label: stream.camera_name, value: stream.camera_id }]
              filterRule = [{ label: zone.rules.find(x => x.rule_id === item.rule_id).event, value: zone.rules.find(x => x.rule_id === item.rule_id).rule_id }]
            }
          })
        })
      });


      if (item.rule_id === 0) {
        let newFilterObj = {
          'start': moment(JSON.parse(localStorage.getItem('filterObj')).start),
          'end': moment(JSON.parse(localStorage.getItem('filterObj')).end),
          'devices': [],
          'streams': [],
          'zones': [],
          'events': [],
          'alertType': filterAlertType
        }

        localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
        window.location.reload();
      }
      else if (item.rule_id === 10001) {
        window.location.reload();
      }
      else {
        let newFilterObj = {
          'start': moment(JSON.parse(localStorage.getItem('filterObj')).start),
          'end': moment(JSON.parse(localStorage.getItem('filterObj')).end),
          'devices': filterDevice,
          'streams': filterStream,
          'zones': [],
          'events': filterRule,
          'alertType': filterAlertType
        }

        localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
        window.location.reload();
      }

    }
    else {
      if (index !== 1 && index !== 2) {
        let filterDevice = [];
        let filterStream = [];
        let filterRule = [];
        let filterAlertType = props.alertType;

        let assetsInfo = JSON.parse(localStorage.getItem('assets'))
        assetsInfo.forEach(function (device) {
          device.cameras.forEach(function (stream) {
            stream.zones.forEach(function (zone) {
              if (zone.rules.find(x => x.rule_id === item.rule_id)) {
                filterDevice = [{ label: device.device_name, value: device.device_id }]
                filterStream = [{ label: stream.camera_name, value: stream.camera_id }]
                filterRule = [{ label: zone.rules.find(x => x.rule_id === item.rule_id).event, value: zone.rules.find(x => x.rule_id === item.rule_id).rule_id }]
              }
            })
          })
        });


        if (item.rule_id === 0) {
          let newFilterObj = {
            'start': moment(JSON.parse(localStorage.getItem('filterObj')).start),
            'end': moment(JSON.parse(localStorage.getItem('filterObj')).end),
            'devices': [],
            'streams': [],
            'zones': [],
            'events': [],
            'alertType': filterAlertType
          }

          localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
          window.location.reload();
        }
        else if (item.rule_id === 10001) {
          window.location.reload();
        }
        else {
          let newFilterObj = {
            'start': moment(JSON.parse(localStorage.getItem('filterObj')).start),
            'end': moment(JSON.parse(localStorage.getItem('filterObj')).end),
            'devices': filterDevice,
            'streams': filterStream,
            'zones': [],
            'events': filterRule,
            'alertType': filterAlertType
          }

          localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
          window.location.reload();
        }
      }
    }
  }

  return (
    <>
      {
        currentUser.isEnterprise && isLoading ?
          <Loader />
          :
          <>
            <div className={activeSlide === 0 ? 'pe-4 mt-3' : 'px-4 mt-3'}>
              {alertsData.length === 0 ?
                <div className='w-100 d-flex justify-content-center mt-4'>
                  <ExclamationCircleFill size={20} color="#8020CF" className="mx-2" />
                  <p className='no-data-text'>{props.noDataText}</p>
                </div>
                :
                <Slider {...settings}>
                  {(alertsData).map((item, index) => {
                    return (
                      <div className={props.isClickable ? `alert-box clickable` : `alert-box`} key={item.rule_id} onClick={() => props.isClickable && filterAlerts(item, index)}>
                        <div className='info'>
                          <div className='d-flex justify-content-between w-100'>
                            {(item.name || item.alert_name) === 'All Alerts' || index === 0 ?
                              <div className='name'>{item.name || item.alert_name || 'All Alerts'}</div>
                              :
                              <div className='name'>
                                <p title={item.name || item.alert_name + ' - ' + item.class_title}>
                                  {
                                    item.alert_type ?
                                      item.alert_name || item.name
                                      :
                                      `${item.name || item.alert_name} - ${item.class_title}`
                                  }
                                </p>
                                &nbsp;
                                {item.alert_type !== 1 &&
                                  <OverlayTrigger
                                    placement="bottom-end"
                                    overlay={
                                      <Popover id="properties-tooltip" arrowProps={null}>
                                        <RuleInfoBox data={item} />
                                      </Popover>
                                    }
                                  >
                                    <InfoCircle color='#0052CC' size={16} />
                                  </OverlayTrigger>
                                }
                              </div>
                            }


                            <div className='d-flex'>
                              {item.type === 'increase' ?
                                item.old_count === 0 ?
                                  <>
                                    <CaretRightFill size={30} color="#9B9B9B" />
                                    <div className='count gray-text'>{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                  </>
                                  :
                                  <>
                                    <CaretUpFill size={30} color="#BF2600" />
                                    <div className='count red-text'>{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                  </>
                                :
                                item.type === 'decrease' ?
                                  <>
                                    <CaretDownFill size={30} color="#18B099" />
                                    <div className='count green-text'>{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                  </>
                                  :
                                  <>
                                    <CaretRightFill size={30} color="#9B9B9B" />
                                    <div className='count gray-text'>{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                  </>
                              }
                            </div>
                          </div>
                          {
                            item?.prompt &&
                            <div className='d-flex w-100'>
                              <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                  <Popover id="properties-tooltip" arrowProps={null}>
                                    <PromptInfoBox data={item.prompt} />
                                  </Popover>
                                }
                              >
                                <span className='summary-card-info'>{item?.prompt}</span>
                              </OverlayTrigger>
                            </div>
                          }
                          <div className='d-flex w-100 pt-3 justify-content-between'>
                            {(item.name || item.alert_name) === 'All Alerts' || index === 0 ?
                              null
                              :
                              <div className='stream-box'><CameraVideo size={16} color='#352B63' className='ms-1 me-2' />{item.strname}</div>
                            }
                            <div className="device-box">{item.dvcname}</div>
                          </div>
                        </div>
                        <div className='old-data'>
                          <div className='against-text'>
                            <div>previous period</div>
                            <div className='old-date'>
                              {
                                moment(props.dateFilter['start']).subtract(moment.duration(moment(props.dateFilter['end']).diff(moment(props.dateFilter['start']))).asDays(), 'd').format('MMM DD YYYY')
                              }
                              &nbsp;-&nbsp;
                              {
                                moment(props.dateFilter['start']).format('MMM DD YYYY')
                              }
                            </div>
                          </div>

                          <div className='old-count'>{item.old_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                          <Statistic type={item.type} value={item.percentage} old={item.old_count} />
                        </div>
                      </div>
                    )
                  })
                  }
                </Slider>
              }
            </div>
          </>
      }
    </>

  );
}

function Arrow(props) {
  const { className, style, onClick, activeSlide, type } = props;

  if (type === "prev" && activeSlide === 0) {
    return null;
  }
  else {
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "gray" }}
        onClick={onClick}
      />
    );
  }
}
