import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";

export default function ModalDeleteAlerts(props) {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const deleteAlerts = () => {
    setIsBtnLoading(true);
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/alerts/batch_delete/',
        data: {
          'alerts': props.selectedCheckboxes,
        }
      })
      .then(res => {
        updateMessage('You successfully deleted the selected items.', 'success')
        setIsBtnLoading(false);
        props.handleRefresh()
        props.handleCloseUpdate()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          setIsBtnLoading(false);
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-delete-alert-text'>
        <p>Are you sure you want to delete <span>"Selected Items"</span>. <br></br>This action cannot be undone and the alerts will no longer be accessible. </p>
        <div className='d-flex justify-content-end mt-3'>
          <Button className="transparent-btn me-1" onClick={props.handleClose}>
            Cancel
          </Button>
          {
            isBtnLoading ?
              <Button className="purple-button w-25" disabled={true} ><Spinner animation="border" /></Button>
              :
              <Button className="purple-button w-25" onClick={deleteAlerts}>
                Delete
              </Button>
          }
        </div>

      </Modal.Body>
    </Modal>
  );
}
