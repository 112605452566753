import React, { useState } from 'react';
import { fast_api_axios } from '../../utils/axiosFast';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";

export default function ResendButton(props) {
  const [isResent, setIsResent] = useState(false);
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();

  const resendConfirmEmail = () => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/send_activate_mail',
        data: {
          "email": props.email
        }
      })
      .then(res => {
        setIsResent(true);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('A verification email has already been sent. You should wait for a while to resend.', 'error');
        }
      });
  }

  return (
    <>
      {
        isResent ?
          <p className='resend-success-btn me-3 mb-0'>Email Sent!</p>
          :
          <p className='resend-btn me-3 mb-0' onClick={resendConfirmEmail}>Resend</p>
      }
    </>
  )
}
