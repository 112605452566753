import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Spinner, Accordion, Button } from 'react-bootstrap'
import { Fullscreen, Search, ExclamationCircleFill, ChevronRight, Filter, ChevronDown } from 'react-bootstrap-icons';
import Loader from '../Loader';
import { fast_api_axios } from '../../utils/axiosFast';
import moment from "moment";
import ModalFullScreen from './ModalFullScreen';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import ModalFullScreenTutorial from './ModalFullScreenTutorial';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import momentTimezone from 'moment-timezone';
import { findZoneByStream, findClassTitlesByStreamId } from '../../utils/generic';

const {
  afterToday,
  allowedMaxDays,
  combine
} = RDateRangePicker;

export default function Heatmap() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  const { currentUser, logout } = useAuth();
  const { updateMessage } = useToast()
  const deviceData = JSON.parse(localStorage.getItem('deviceData'));
  const streamOptions = JSON.parse(localStorage.getItem("streamData"));
  const [zoneOptions, setZoneOptions] = useState(findZoneByStream(streamOptions[localStorage.getItem('activePage') ? parseInt(localStorage.getItem('activePage')) - 1 : 0].value));
  const [classOptions, setClassOptions] = useState(findClassTitlesByStreamId(streamOptions[localStorage.getItem('activePage') ? parseInt(localStorage.getItem('activePage')) - 1 : 0].value));
  let history = useHistory();
  const [heatmapItems, setHeatmapItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dateFilter, setDateFilter] = useState({
    'start': moment().tz(localStorage.getItem('timezone')).subtract(1, 'hour'),
    'end': moment().tz(localStorage.getItem('timezone'))
  })
  const [filterStream, setFilterStream] = useState(streamOptions[localStorage.getItem('activePage') ? parseInt(localStorage.getItem('activePage')) - 1 : 0] || '');
  const [filterZone, setFilterZone] = useState('');
  const [filterClasses, setFilterClasses] = useState([]);
  const [isApplyActive, setIsApplyActive] = useState(false);

  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [showFullScreenModalTutorial, setShowFullScreenModalTutorial] = useState(false);

  const [fullScreenType, setFullScreenType] = useState(null);
  const [url, setUrl] = useState(null);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [imageReqId, setImageReqId] = useState(null);

  const [isHeatmapAgain, setIsHeatmapAgain] = useState(false);
  const [canContinue, setCanContinue] = useState(true);
  const [heatmapImage, setHeatmapImage] = useState(null);
  const [isHeatmapImageLoading, setIsHeatmapImageLoading] = useState(true);
  const [heatmapState, setHeatmapState] = useState(null);

  const [isPathAgain, setIsPathAgain] = useState(false);
  const [pathImage, setPathImage] = useState(null);
  const [isPathImageLoading, setIsPathImageLoading] = useState(true);
  const [pathState, setPathState] = useState(null);
  const [isHeatmapLastFailed, setIsHeatmapLastFailed] = useState(false);
  const [isPathLastFailed, setIsPathLastFailed] = useState(false);
  const [isCreatingData, setIsCreatingData] = useState(JSON.parse(localStorage.getItem('creatingdata')));
  const [hasStream, setHasStream] = useState(true);

  const handleCloseFullScreenModal = () => setShowFullScreenModal(false);
  const handleShowFullScreenModal = (imgurl, type) => {
    setFullScreenType(type);
    setUrl(imgurl);
    setShowFullScreenModal(true);
  }

  const handleShowFullScreenModalTutorial = () => {
    setShowFullScreenModalTutorial(false);
  }

  const getBaseData = (sdate = moment(dateFilter['start'].startOf('hour')), edate = moment(dateFilter['end'].startOf('hour'))) => {
    setIsHeatmapLastFailed(false);
    setIsPathLastFailed(false);

    if (sdate.isSame(edate, 'hour')) {
      // if the start and end are in the same hour, subtract 1 hour
      sdate = edate.clone().subtract(1, 'hour');
    } else {
      // Otherwise, round to the previous full hour
      sdate = sdate.clone().startOf('hour');
      edate = edate.clone().startOf('hour');
    }

    setCanContinue(true);
    setIsLoading(true);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/coordinates/',
        params: {
          'start': moment(sdate).unix(),
          'end': moment(edate).unix(),
          'camera_id': filterStream.value,
          'zone_id': filterZone.value,
          'class_title': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        setHeatmapItems(res.data);
        setImageReqId(res.data.id);
        if (res.data.base_image_url !== null) {
          setImageReqId(res.data.id);
          //getLatestData(res.data.id);
          getHeatmapData(res.data.id);
          getPathData(res.data.id);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getHeatmapData = (id) => {
    setIsHeatmapImageLoading(true);
    setHeatmapState(null);
    setIsHeatmapAgain(false);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/coordinates/images/',
        params: {
          'heatmap_path_id': id,
          'image_type': 'HEATMAP',
          'completed': false,
          'zone_id': filterZone.value,
          'class_title': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        if (res.data.state === 'FAILED') {
          setHeatmapState('FAILED');
          setIsHeatmapImageLoading(false);
          getHeatmapDataLast(id);
        }
        else if (res.data.state === 'PROCESSING') {
          setIsHeatmapAgain(true);
        }
        else {
          setHeatmapImage(res.data.image_url);
          setIsHeatmapImageLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getHeatmapDataLast = (id) => {
    setIsHeatmapImageLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/coordinates/images/',
        params: {
          'heatmap_path_id': id,
          'image_type': 'HEATMAP',
          'completed': true,
          'zone_id': filterZone.value,
          'class_title': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        if (res.data.state === 'FAILED') {
          setHeatmapState('FAILED');
          setIsHeatmapLastFailed(true);
          setIsHeatmapImageLoading(false);
        }
        else {
          setHeatmapImage(res.data.image_url);
          setHeatmapState('COMPLETED');
          setIsHeatmapImageLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    if (canContinue && isHeatmapAgain) {
      setTimeout(function () {
        getHeatmapData(imageReqId);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeatmapAgain])

  const getPathData = (id) => {
    setIsPathImageLoading(true);
    setPathState(null);
    setIsPathAgain(false);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/coordinates/images/',
        params: {
          'heatmap_path_id': id,
          'image_type': 'PATH',
          'completed': false,
          'zone_id': filterZone.value,
          'class_title': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        if (res.data.state === 'FAILED') {
          setPathState('FAILED');
          setIsPathImageLoading(false);
          getPathDataLast(id);
        }
        else if (res.data.state === 'PROCESSING') {
          setIsPathAgain(true);
        }
        else {
          setPathImage(res.data.image_url);
          setIsPathImageLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getPathDataLast = (id) => {
    setIsPathImageLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/coordinates/images/',
        params: {
          'heatmap_path_id': id,
          'image_type': 'PATH',
          'completed': true,
          'zone_id': filterZone.value,
          'class_title': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        if (res.data.state === 'FAILED') {
          setPathState('FAILED');
          setIsPathLastFailed(true);
          setIsPathImageLoading(false);
        }
        else {
          setPathImage(res.data.image_url);
          setPathState('COMPLETED');
          /*setDateFilter({
            'start': moment(res.data.start_time).add(moment().utcOffset() / 60 , 'hours'),
            'end': moment(res.data.end_time).add(moment().utcOffset() / 60 , 'hours')
          })*/
          setIsPathImageLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    if (canContinue && isPathAgain) {
      setTimeout(function () {
        getPathData(imageReqId);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPathAgain])


  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  const handleDateChange = (e) => {
    setDateFilter({
      'start': moment(e[0]).add(getDiffer(), 'hours'),
      'end': moment(e[1]).add(getDiffer(), 'hours')
    })
    let index = streamOptions.findIndex(o => o.value === filterStream.value)
    localStorage.setItem('activePage', index + 1);
    getBaseData(moment(e[0]).startOf('hour'), moment(e[1]).startOf('hour'));
  }

  useEffect(() => {
    let index = streamOptions.findIndex(o => o.value === filterStream.value)
    localStorage.setItem('activePage', index + 1);
    if (index >= 0) {
      getBaseData();
    }
    else {
      setHasStream(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLatestData = (imr) => {
    setIsHeatmapImageLoading(true);
    setIsPathImageLoading(true);
    alert(imageReqId)
    getHeatmapDataLast(imageReqId);
    getPathDataLast(imageReqId);
    //getHeatmapDataLast(imr);
    //getPathDataLast(imr);
  }

  const handleStreamSelect = item => {
    setFilterStream(item)
    setZoneOptions(findZoneByStream(item.value))
    setClassOptions(findClassTitlesByStreamId(item.value))

    setFilterZone([])
    setFilterClasses([])

    let index = streamOptions.findIndex(o => o.value === item.value)
    localStorage.setItem('activePage', index + 1);

    if(index < 0 ){
      setHasStream(false)
    }
  }

  const handleZoneSelect = item => {
    setFilterZone(item)
  }

  const handleClassSelect = (items) => {
    setFilterClasses(items);
  };

  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }

  document.addEventListener('mousedown', closeOpenMenus)


  const handleFilterApply = () => {
    setIsApplyActive(false);
    setShowFilterBox(false);
    getBaseData();
  }

  const clearAllFilters = () => {
    //setFilterStreams([]);
    setFilterZone('');
    setFilterClasses([]);
    setDateFilter({
      'start': moment().tz(localStorage.getItem('timezone')).subtract(1, 'hour'),
      'end': moment().tz(localStorage.getItem('timezone'))
    });
    //setShowFilterBox(false);
    //localStorage.removeItem('filterObj');
  }

  useEffect(() => {
    setIsApplyActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStream, filterZone, filterClasses])

  return (
    <>
      <Container fluid>
        <Row className='d-flex'>
          <div className='heatmap-content-wide'>

            <div className='page-content-header d-flex justify-content-between align-items-center'>
              <p className='breadcrumb'>
                <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
                <ChevronRight size={14} />
                <span className='inner-breadcrumb'>Heatmap & Common Paths</span>
              </p>

              <div className='d-flex'>
                <div className='filter-dropdown' ref={dropdownRef}>
                  <Button className="light-purple-btn me-3" onClick={() => setShowFilterBox(!showFilterBox)}>
                    <Filter size={24} />
                    &nbsp;
                    Filter
                    &nbsp;
                    <span className='filter-selected-count'>{(Object.keys(filterZone).length === 0 ? filterClasses.length + 1 : filterClasses.length + 2)}</span>
                    &nbsp;
                    <ChevronDown size={16} />
                  </Button>
                  {showFilterBox ?
                    <div className='filters-box'>
                      <Accordion>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className={filterStream !== null && 'selected-filters-name'}>
                            {filterStream !== null ? filterStream.label : 'Stream'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              name="streamOptions"
                              options={streamOptions}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Stream</span>}
                              onChange={(items) => handleStreamSelect(items)}
                              value={filterStream}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header className={Object.keys(filterZone).length !== 0 && 'selected-filters-name'}>
                            {Object.keys(filterZone).length !== 0 ? filterZone.label : 'Zone'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              name="zoneOptions"
                              options={zoneOptions}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Zone</span>}
                              onChange={(items) => handleZoneSelect(items)}
                              value={filterZone}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header className={filterClasses.length > 0 && 'selected-filters-name'}>
                            {filterClasses.length > 0 ? filterClasses.map(itm => itm.label).join(',') : 'Classes'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              name="classOptions"
                              options={classOptions}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Classes</span>}
                              onChange={(items) => handleClassSelect(items)}
                              value={filterClasses}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className='d-flex justify-content-between align-items-center px-4 py-2'>
                        <div className='clear-filter-active' onClick={clearAllFilters}>Clear Filter</div>
                        <div className='d-flex justify-content-between'>
                          <Button className="cancel-filter me-3" onClick={() => setShowFilterBox(false)}>Cancel</Button>
                          <Button className="apply-filter-enabled"
                            disabled={!isApplyActive}
                            onClick={handleFilterApply}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>

                <RDateRangePicker
                  editable={false}
                  placeholder="Default"
                  className="date-filter-button"
                  placement="bottomEnd"
                  disabledDate={combine(allowedMaxDays(90), afterToday())}
                  format="MM/dd/yyyy HH:mm"
                  value={[
                    new Date(moment(dateFilter['start']).format('MM/DD/YYYY HH:mm')),
                    new Date(moment(dateFilter['end']).format('MM/DD/YYYY HH:mm'))
                  ]}
                  ranges={[
                    {
                      label: 'Current Time',
                      value: [
                        new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'yesterday',
                      value: [
                        new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last Hour',
                      value: [
                        new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last 3 Days',
                      value: [
                        new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last 15 Days',
                      value: [
                        new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last 30 Days',
                      value: [
                        new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                  ]}
                  cleanable={false}
                  onChange={(e) => handleDateChange(e)}
                />
              </div>
            </div>

            <div className='page-wrapper'>
              <div className='heatmap-images-box'>
                <div className='box-names'>
                  {/*<div className='box-name'>Base Image</div>*/}
                  <div className='box-name'>ACTIVITY HEATMAP</div>
                  <div className='box-name'>COMMON  PATHS</div>
                </div>
              </div>

              {!hasStream ?
                <>
                  <div className='w-100 d-flex justify-content-center mt-5'>
                    <ExclamationCircleFill size={20} color="#8020CF" className="mx-2" />
                    <p className='no-data-text'>You do not have any stream to generate heatmap!</p>
                  </div>
                </>
                :
                <>
                  {isLoading ?
                    <Loader />
                    :
                    <>
                      {heatmapItems.base_image_url === null || heatmapItems.device_id === null ?
                        <>
                          {isCreatingData ?
                            <div className='heatmap-images-box'>
                              <div className='heatmap-details'>
                                <div className='heatmap-device-info'>Stream: <span>{streamOptions.find(x => x.value === heatmapItems.camera_id) ? streamOptions.find(x => x.value === heatmapItems.camera_id).label : null}</span></div>
                              </div>
                              <div className='creating-data'>
                                <Spinner animation="border" style={{ width: "27px", height: "27px", "marginRight": "10px", "marginLeft": "-38px", "--bs-spinner-border-width": "3px" }} />Creating Data
                                <p>This may take 1 to 2 hours. Thank you for your understanding!</p>
                              </div>
                            </div>
                            :
                            <div className='heatmap-images-box'>
                              <div className='heatmap-details'>
                                <div className='heatmap-device-info'>Stream: <span>{streamOptions.find(x => x.value === heatmapItems.camera_id) ? streamOptions.find(x => x.value === heatmapItems.camera_id).label : null}</span></div>
                              </div>
                              <div className='w-100 text-center fetch-warning'>In order to visualize the data, please go to Chooch Inference Engine and Fetch Frame from the camera.
                                <div className='see-how' onClick={() => setShowFullScreenModalTutorial(true)}>See How to Fetch Frame</div>
                              </div>
                            </div>
                          }
                        </>
                        :
                        <>

                          <div className='heatmap-images-box'>
                            <div className='heatmap-details'>
                              <div className='heatmap-device-info'>Device: <span>{deviceData.find(x => x.value === heatmapItems.device_id) ? deviceData.find(x => x.value === heatmapItems.device_id).label : null}</span></div>
                              <div className='heatmap-device-info'>Stream: <span>{heatmapItems.camera_name}</span></div>
                              {filterZone.value !== undefined && <div className='heatmap-device-info'>Zone: <span>{filterZone.label}</span></div>}
                              {filterClasses.length > 0 && <div className='heatmap-device-info'>Classes: <span>{filterClasses.map(itm => itm.label).join(',')}</span></div>}
                            </div>
                            <div className='images-box'>
                              {/*<div className='heatmap-img' onClick={() => handleShowFullScreenModal(heatmapItems.base_image_url, "base")}>
                                <img src={heatmapItems.base_image_url} alt="base" />
                                <span><Fullscreen /></span>
                              </div>*/}
                              {isHeatmapImageLoading ?
                                <div className='loading-box'>
                                  <Loader />
                                  <p>This may take a while.</p>
                                </div>
                                :
                                heatmapState === 'FAILED' ?
                                  <div className='no-data-image'>
                                    {isHeatmapLastFailed ?
                                      <>
                                        <p>HEATMAP</p>
                                        <div className='info-text'>
                                          The path data is not visible because it is turned off in the settings. If you want to see the path data, go to <span className=''>Chooch AI Vision Studio</span>, navigate to the Device tab, find the relevant Stream, and then enable it in the ‘Analytics Options’ under ‘Analytics Settings’.
                                        </div>
                                      </>
                                      :
                                      <p>No data found! <span onClick={getLatestData}>Show latest data</span></p>
                                    }
                                  </div>
                                  :
                                  <div className='heatmap-img' onClick={() => handleShowFullScreenModal(heatmapImage, "heatmap")}>
                                    <img src={heatmapImage} alt="heatmap" />
                                    <span><Fullscreen /></span>
                                  </div>
                              }
                              {isPathImageLoading ?
                                <div className='loading-box'>
                                  <Loader />
                                  <p>This may take a while.</p>
                                </div>
                                :
                                pathState === 'FAILED' ?
                                  <div className='no-data-image'>
                                    {isPathLastFailed ?
                                      <>
                                        <p>PATH</p>
                                        <div className='info-text'>
                                          The path data is not visible because it is turned off in the settings. If you want to see the path data, go to <span>Chooch AI Vision Studio</span>, navigate to the Device tab, find the relevant Stream, and then enable it in the ‘Analytics Options’ under ‘Analytics Settings’.
                                        </div>
                                      </>
                                      :
                                      <p>No data found! <span onClick={getLatestData}>Show latest data</span></p>
                                    }
                                  </div>
                                  :
                                  <div className='heatmap-img' onClick={() => handleShowFullScreenModal(pathImage, "path")}>
                                    <img src={pathImage} alt="heatmap" />
                                    <span><Fullscreen /></span>
                                  </div>
                              }
                            </div>
                          </div>
                        </>
                      }
                    </>
                  }
                </>
              }
            </div>
          </div>
        </Row>
      </Container>

      <ModalFullScreen
        url={url}
        show={showFullScreenModal}
        type={fullScreenType}
        handleClose={handleCloseFullScreenModal}
        handleShowFullScreenModal={handleShowFullScreenModal}
      />
      <ModalFullScreenTutorial
        show={showFullScreenModalTutorial}
        handleClose={handleShowFullScreenModalTutorial}
      />
    </>
  )
}
