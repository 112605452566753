import React, { useContext, useState } from 'react'
import { IdleTimerProvider } from 'react-idle-timer'
import moment from 'moment'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true' ? 'true' : 'false')
  const [userTimezone, setUserTimezone] = useState(null);

  const setUser = (id, username, role, companyId, dashboardId, isEnterprise, timezone) => {
    let user = {
      "id": id,
      "name": username,
      "role": role,
      "isEnterprise": isEnterprise === undefined || isEnterprise ? true : false // undefined will be deleted when backend development is finished.
    }

    localStorage.setItem('companyId', companyId)
    localStorage.setItem('dashboardId', dashboardId)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('isAuthenticated', 'true')

    if (timezone === null) {
      localStorage.setItem('timezone', moment.tz.guess())
      setUserTimezone(moment.tz.guess())
    }
    else {
      localStorage.setItem('timezone', timezone)
      setUserTimezone(timezone);
    }

    setCurrentUser(user)
    setIsAuthenticated('true')
  }

  const updateUserTimezone = (timezone) => {
    if (timezone === null) {
      localStorage.setItem('timezone', moment.tz.guess())
      setUserTimezone(moment.tz.guess())
    }
    else {
      localStorage.setItem('timezone', timezone)
      setUserTimezone(timezone);
    }
  }

  const logout = () => {
    let user = {
      "id": null,
      "name": null,
      "role": null,
      "isEnterprise": null
    }

    localStorage.removeItem('user')
    localStorage.setItem('isAuthenticated', 'false')
    localStorage.removeItem('timezone')
    setCurrentUser(user)
    setUserTimezone(null)
    setIsAuthenticated('false')
  }

  const value = {
    isAuthenticated,
    currentUser,
    userTimezone,
    updateUserTimezone,
    setUser,
    logout
  }

  const onIdle = (e) => {
    logout();
  }

  return (
    <AuthContext.Provider value={value}>
      <IdleTimerProvider
        timeout={1000 * 60 * 30}// logout user after 30 minutes idle
        onIdle={onIdle}
      >
        {children}
      </IdleTimerProvider>
    </AuthContext.Provider >
  )
}