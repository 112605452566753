import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Container, Row, Table, Form } from 'react-bootstrap';
import { ChevronRight, Search, Calendar4 } from 'react-bootstrap-icons';
import StaffDwellModalExample from './StaffDwellModalExample';
import { useHistory } from "react-router-dom";

export default function StaffDwellExample() {
  let history = useHistory();
  const [showStaffDwellModal, setShowStaffDwellModal] = useState(false);
  const [roomName, setRoomName] = useState(null)

  const handleShowModal = (name) => {
    setRoomName(name)
  }

  useEffect(() => {
    roomName !== null ? setShowStaffDwellModal(true) : setShowStaffDwellModal(false)
  }, [roomName])

  const handleCloseModal = () => {
    setRoomName(null)
  }

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards/staff-dwell')}>Staff Dwell Time</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Sample Dashboard</span>
            </p>

            <div className='current-time'>
              <Calendar4 color='#F2E9FA' size={18} />
              {moment().tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}
            </div>
          </div>

          <div className='page-wrapper'>
            <div className='summary-area'>
              <h5>Summary</h5>
              <div className='d-flex'>
                <div className='summary-box'>
                  <div className='name'>Number of Room</div>
                  <div className='result'>5</div>
                </div>
                <div className='summary-box'>
                  <div className='name'>Current Average Dwell Time</div>
                  <div className='result'>00:01:00</div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='staff-search-group'>
                <Search className='search-icon' />
                <Form.Control placeholder="Search Room Name" className='staff-search-box' disabled />
              </div>
            </div>
            <div className='scrollableTable'>
              <Table className='staff-dwell-table'>
                <thead>
                  <tr>
                    <th>ROOM NAME</th>
                    <th>ENTER TIME</th>
                    <th>EXIT TIME</th>
                    <th>DWELL TIME</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Room 101</td>
                    <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>{moment().add(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>00:01:00</td>
                    <td>
                      <div className='details-btn' onClick={() => handleShowModal('Room 101')}>Details</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Room 102</td>
                    <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>{moment().add(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>00:01:00</td>
                    <td>
                      <div className='details-btn' onClick={() => handleShowModal('Room 102')}>Details</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Room 103</td>
                    <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>{moment().add(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>00:01:00</td>
                    <td>
                      <div className='details-btn' onClick={() => handleShowModal('Room 103')}>Details</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Room 104</td>
                    <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>{moment().add(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>00:01:00</td>
                    <td>
                      <div className='details-btn' onClick={() => handleShowModal('Room 104')}>Details</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Room 105</td>
                    <td>{moment().format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>{moment().add(1, 'minute').format('MM/DD/YYYY HH:mm:ss')}</td>
                    <td>00:01:00</td>
                    <td>
                      <div className='details-btn' onClick={() => handleShowModal('Room 105')}>Details</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Row>
      <StaffDwellModalExample show={showStaffDwellModal} handleClose={handleCloseModal} name={roomName} />
    </Container>
  )
}
