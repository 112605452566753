import axios from "axios";

const dashboard_axios = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_URL
})

const fast_api_axios = axios.create({
  baseURL: process.env.REACT_APP_FAST_API_URL
})

function getFastApiAccessToken() {
  return localStorage.getItem('fast_api_token');
}

function getFastApiRefreshToken() {
  return localStorage.getItem('fast_api_refresh_token');
}

function setFastApiToken(tokenObj) {
  localStorage.setItem('fast_api_token', tokenObj.access_token);
  localStorage.setItem('fast_api_refresh_token', tokenObj.refresh_token);
}

function getDashboardAccessToken() {
  return localStorage.getItem('dashboard_token');
}

function getDashboardId() {
  return localStorage.getItem('dashboardId');
}

function getUser() {
  return JSON.parse(localStorage.getItem('user')).name
}

dashboard_axios.interceptors.request.use(
  config => {
    const token = getDashboardAccessToken();
    config.headers = {
      'Authorization': 'token ' + token,
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

fast_api_axios.interceptors.request.use(
  config => {
    const token = getFastApiAccessToken();
    const dtoken = getDashboardAccessToken();
    const dId = getDashboardId();

    config.headers = {
      'Authorization': 'Bearer ' + token,
      'DashboardToken': dtoken,
      'DashboardId': dId,
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

fast_api_axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const originalRequest = err.config;

    if (err.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return fast_api_axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        fast_api_axios
          .post('/users/token', {
            'email': getUser(),
            'token': getFastApiRefreshToken()
          })
          .then(({ data }) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
            setFastApiToken(data)
            processQueue(null, data.access_token);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

/*fast_api_axios.interceptors.response.use((response) => {
  return response
},
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {

      originalRequest._retry = true;
      return fast_api_axios.post('/auth/token',
        {
          "refresh_token": getFastApiRefreshToken()
        })
        .then(res => {
          if (res.status >= 200 && res.status <= 299) {
            setFastApiToken(res.data);
            //fast_api_axios.defaults.headers.common['Authorization'] = 'Bearer ' + getFastApiAccessToken();
            return fast_api_axios(originalRequest);
          }
        })
    }

    return Promise.reject(error);
  });*/

export {
  dashboard_axios,
  fast_api_axios
};