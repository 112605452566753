import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function ChangeDashboardModal(props) {

  const closeModal = () => {
    props.handleClose()
  }

  const changeDashboardType = () => {
    props.changeDashboardType(props.type)
    closeModal();
  }

  return (
    <Modal show={props.show} onHide={closeModal} centered>
      <Modal.Body className='text-center'>
        <p>If you change the dashboard type, the information will be deleted. Are you sure to change the dashboard type?</p>
        <div className='d-flex justify-content-center'>
          <Button className="orange-outline-button w-25 mx-3" onClick={changeDashboardType}>
            Yes
          </Button>
          <Button className="orange-button w-25 mx-3" onClick={(closeModal)}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
