import React from 'react';
import Chart from "react-apexcharts";
import NoDataImage from '../../assets/images/restricted.png';
import { ChevronRight } from 'react-bootstrap-icons';
import SliderItem from '../dwell/SliderItem';
import { useHistory } from "react-router-dom";
import DwellExampleSliderItem from './DwellExampleSliderItem';
import moment from 'moment';

const pieColors = [
  '#22FCDB',
  '#492CE6',
  '#60EFFF',
  '#FF00F8',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#26909C'
]
export default function DwellExample() {
  let history = useHistory();
  const dwellData = [
    {
      "class_title": "person",
      "zone": "Parking",
      "device": "Shopping Mall",
      "camera": "G1",
      "avg_dwell_time": 5.24,
      "count": 2000
    },
    {
      "class_title": "car",
      "zone": "Parking",
      "device": "Shopping Mall",
      "camera": "G1",
      "avg_dwell_time": 4.1,
      "count": 500
    },
    {
      "class_title": "person",
      "zone": "Food Hall",
      "device": "Shopping Mall",
      "camera": "F1",
      "avg_dwell_time": 6.2,
      "count": 4500
    }
  ];

  const averageVisitDuration = 5.18;
  const barDataClasses = [
    9340,
    6200
  ];
  const barDataClassesLabel = [
    "Parking",
    "Food Hall"
  ];

  const chartLabels = [
    "01/01/2023 00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "01/01/2023 23:00"
  ];

  const chartDataset = [
    {
      "name": "Parking",
      "data": [
        100,
        50,
        75,
        50,
        75,
        20,
        80,
        120,
        130,
        90,
        210,
        175,
        225,
        250,
        250,
        175,
        125,
        80,
        30,
        90,
        20,
        40,
        25,
        35
      ],
      "fill": false,
      "borderColor": "#22FCDB",
      "backgroundColor": "#22FCDB",
      "tension": 0.1
    },
    {
      "name": "Food Hall",
      "data": [
        20,
        10,
        10,
        30,
        50,
        120,
        50,
        25,
        75,
        10,
        30,
        70,
        80,
        60,
        20,
        60,
        0,
        20,
        10,
        100,
        50,
        20,
        10,
        20
      ],
      "fill": false,
      "borderColor": "#492CE6",
      "backgroundColor": "#492CE6",
      "tension": 0.1
    },
  ];
  const chartDraw = [
    "01/01/2023 00:00",
    "01/01/2023 01:00",
    "01/01/2023 02:00",
    "01/01/2023 03:00",
    "01/01/2023 04:00",
    "01/01/2023 05:00",
    "01/01/2023 06:00",
    "01/01/2023 07:00",
    "01/01/2023 08:00",
    "01/01/2023 09:00",
    "01/01/2023 10:00",
    "01/01/2023 11:00",
    "01/01/2023 12:00",
    "01/01/2023 13:00",
    "01/01/2023 14:00",
    "01/01/2023 15:00",
    "01/01/2023 16:00",
    "01/01/2023 17:00",
    "01/01/2023 18:00",
    "01/01/2023 19:00",
    "01/01/2023 20:00",
    "01/01/2023 21:00",
    "01/01/2023 22:00",
    "01/01/2023 23:00",
  ];
  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  return (
    <>
      <div className='page-content-header d-flex justify-content-between align-items-center'>
        <p className='breadcrumb'>
          <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
          <ChevronRight size={14} />
          <span className='main-breadcrumb' onClick={() => history.push('/dashboards/dwell')}>Dwell Time</span>
          <ChevronRight size={14} />
          <span className='inner-breadcrumb'>Sample Dashboard</span>
        </p>
      </div>

      <div className='page-wrapper'>
        <div className='example-dashboard-header mb-3'>
          Example Dashboard
        </div>

        <div className='d-flex align-items-center'>
          <div className='average-duration-box'>
            <h5>Avg. Visit<br></br>Duration</h5>
            <div>00:0{averageVisitDuration.toFixed(2)}</div>
          </div>
          <div className='dwell-slider-box'>
            <DwellExampleSliderItem
              isLoading={false}
              dwellData={dwellData}
            />
          </div>
        </div>

        <div className='chart-box'>
          <div className='w-100'>
            <h5>Class-Based Average Duration</h5>
            <Chart
              series={
                [{
                  name: '',
                  data: barDataClasses
                }]
              }
              type="bar"
              height="350"
              options={{
                chart: {
                  toolbar: {
                    show: false
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    horizontal: false,
                    dataLabels: {
                      position: 'top'
                    }
                  }
                },
                dataLabels: {
                  enabled: false,
                  style: {
                    colors: ['#333']
                  },
                  formatter: function (value) {
                    return moment.duration(value, 'milliseconds').format('mm:ss.SSS', { trim: false });
                  }
                },
                yaxis: {
                  title: {
                    text: 'Avg. duration (mm:ss.SSS)',
                  },
                  labels: {
                    formatter: function (value) {
                      return moment.duration(value, 'milliseconds').format('mm:ss.SSS', { trim: false });
                    }
                  }
                },
                xaxis: {
                  categories: barDataClassesLabel,
                },
                tooltip: {
                  y: {
                    formatter: function (val, opt) {
                      return moment.duration(val, 'milliseconds').format('mm:ss.SSS', { trim: false });
                    }
                  }
                },
                colors: [
                  function ({ value, seriesIndex, dataPointIndex, w }) {
                    return pieColors[dataPointIndex];
                  }
                ]
              }}
            />
          </div>
        </div>

        <div className='chart-box'>
          <h5>Data Collection Points</h5>
          <Chart
            options={{
              chart: {
                animations: {
                  enabled: false,
                },
                fontFamily: 'Lato, sans-serif',
                type: 'line',
                toolbar: {
                  show: false
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'straight',
                width: 3
              },
              grid: {
                row: {
                  colors: ['transparent']
                },
                padding: {
                  top: 0,
                  right: 20,
                  bottom: 50,
                  left: 20
                },
              },

              xaxis: {
                categories: lineData.labels,
                tooltip: {
                  formatter: function (value, opts) {
                    const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                    if (v === undefined || v === '') {
                      return ''
                    } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                      return opts.w.globals.categoryLabels[opts.dataPointIndex]
                    } else {
                      return v;
                    }
                  },
                },
                labels: {
                  rotate: -70,
                  style: {
                    fontSize: '11px',
                    fontWeight: 400,
                  },
                  rotateAlways: true,
                  formatter: function (value) {
                    var xaxisLabel = document.querySelectorAll('.apexcharts-xaxis-label tspan')
                    return value;
                  }
                }
              },
              yaxis: {
                title: {
                  text: 'Data Collection Points'
                }
              },
              tooltip: {
                fixed: {
                  enabled: false
                },
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map(seriesX => {
                    return seriesX.findIndex(xData => xData === hoverXaxis);
                  });

                  let hoverList = '';
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (hoverIndex >= 0) {
                      hoverList += `<span class="apexcharts-tooltip-marker circlemake" style="background-color: ${pieColors[seriesEachIndex % 13]};"></span><span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  });
                  const formatHoverX = chartDraw[dataPointIndex]

                  return `<div class="card">
                              <div class="card-header p-1">${formatHoverX}</div>
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                }
              },
              colors: pieColors,
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                showForSingleSeries: true
              },
            }}
            series={
              lineData.datasets
            }
            height={350}
            type="line"
          />
        </div>
      </div>
    </>
  )
}
