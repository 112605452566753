import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import { InfoCircle } from 'react-bootstrap-icons'
import { useHistory } from "react-router-dom";
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { fast_api_axios } from '../../utils/axiosFast';
import ChangeDashboardModal from './ChangeDashboardModal';
/*import tableau from '../../assets/images/tableau.png';
import qlik from '../../assets/images/qlik.png';
import powerbi from '../../assets/images/powerbi.png';
import superset from '../../assets/images/superset.png';
import grafana from '../../assets/images/grafana.png';*/

export default function AddDashboard() {
  let history = useHistory();
  const { updateMessage } = useToast()
  const { logout } = useAuth();
  const [showChangeModal, setShowChangeModal] = useState(false);
  //const [changeToType, setChangeToType] = useState(null);
  const [dashboardType, setDashboardType] = useState('TABLEAU');
  const [workspace, setWorkspace] = useState('');
  const [view, setView] = useState('');
  const [username, setUsername] = useState('');
  const [dashboardName, setDashboardName] = useState('');
  const [explanation, setExplanation] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  //const [isDashboardSelected, setIsDashboardSelected] = useState(false);
  const [canAdd, setCanAdd] = useState(false);

  const handleCloseChangeModal = () => setShowChangeModal(false);
  /*const handleShowChangeModal = (type) => {
    setIsDashboardSelected(true);
    if (dashboardType !== null) {
      if (type !== dashboardType) {
        setChangeToType(type);
        setShowChangeModal(true);
      }
    }
    else {
      setDashboardType(type)
    }
  }*/

  const goToDashboards = (id) => {
    history.push('/dashboards')
  }

  const addDashboard = () => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/dashboards/',
        data: {
          "dashboard_type": dashboardType,
          "security_key": 'security_key',
          "username": username,
          "password": 'password',
          "workspace_name": workspace,
          "view_name": view,
          "dashboard_name": dashboardName,
          "explanation": explanation,
          "is_public": isPublic
        }
      })
      .then(res => {
        clearInputFields();
        history.push('/dashboards')
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const clearInputFields = () => {
    setDashboardType(null);
    setWorkspace('');
    setView('');
    setUsername('');
    setDashboardName('');
    setExplanation('');
    setIsPublic(false);
  }

  const changeDashboardType = (type) => {
    setDashboardType(type)
    setWorkspace('');
    setView('');
    setUsername('')
    setDashboardName('')
    setExplanation('')
    setIsPublic(false)
  }

  useEffect(() => {
    if (dashboardType !== null && workspace.length > 0 && view.length > 0 && username.length > 0 && dashboardName.length > 0) {
      setCanAdd(true);
    }
    else {
      setCanAdd(false);
    }
  }, [dashboardType, workspace, view, username, dashboardName])

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h5 className='add-dashboard-header mt-3 mb-3'><span onClick={goToDashboards} className="dashboards-link">Dashboards</span> <span className='db-name'>{'>'} Add Dashboard</span></h5>
            {/*<div className='add-box'>
              <h5>Select Dashboard Type
                <OverlayTrigger
                  key={'tt'}
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top'>
                      Select Dashboard Type
                    </Tooltip>
                  }
                >
                  <span className="mx-1 info-overlay"><InfoCircle /></span>
                </OverlayTrigger>
              </h5>

              <div className='d-flex'>
                {isDashboardSelected ?
                  <>
                    <div className={`dashboard-type ${dashboardType === 'TABLEAU' ? `selected-dashboard-type` : `unselected-dashboard-type`}`} onClick={() => handleShowChangeModal('TABLEAU')}><img src={tableau} alt="tableau" /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'QLIK' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={qlik} alt="qlik" /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'POWER' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={powerbi} alt="powerbi" /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'SUPERSET' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={superset} alt="superset" style={{ width: '90%' }} /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'GRAFANA' ? `selected-dashboard-type` : `unselected-dashboard-type`}`}><img src={grafana} alt="grafana" /></div>

                  </>
                  :
                  <>
                    <div className={`dashboard-type ${dashboardType === 'TABLEAU' ? `selected-dashboard-type` : null}`} onClick={() => handleShowChangeModal('TABLEAU')}><img src={tableau} alt="tableau" /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'QLIK' ? `selected-dashboard-type not-available` : null}`}><img src={qlik} alt="qlik" /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'POWER' ? `selected-dashboard-type not-available` : null}`}><img src={powerbi} alt="powerbi" /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'SUPERSET' ? `selected-dashboard-type not-available` : null}`}><img src={superset} alt="superset" style={{ width: '90%' }} /></div>
                    <div className={`dashboard-type not-available ${dashboardType === 'GRAFANA' ? `selected-dashboard-type not-available` : null}`}><img src={grafana} alt="grafana" /></div>

                  </>
                }
              </div>
              </div>*/}

            {/*<div className='add-box'>
              <h5>Your Dashboard Code
                <OverlayTrigger
                  key={'ttt'}
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-top'>
                      Choose your Tableau workbook, copy the embed code and paste it here! You can find your embed code in the share option.
                    </Tooltip>
                  }
                >
                  <span className="mx-1 info-overlay"><InfoCircle /></span>
                </OverlayTrigger>
              </h5>
              <Form.Control className='input-box' type="text" placeholder="Enter your dashboard code" value={dashboardCode} onChange={(e) => setDashboardCode(e.target.value)} />
            </div>*/}

            <div className='tableau-box'>
              <h4>Enter Tableau Information</h4>
              <Row className='mt-3'>
                <Col>
                  <h5>Username</h5>
                  <Form.Control className='input-box' type="text" placeholder="Enter your username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Col>
                <Col>
                  <h5>Workspace</h5>
                  <Form.Control className='input-box' type="text" placeholder="Enter your workspace name" value={workspace} onChange={(e) => setWorkspace(e.target.value)} />
                </Col>
                <Col>
                  <h5>View</h5>
                  <Form.Control className='input-box' type="text" placeholder="Enter your view name" value={view} onChange={(e) => setView(e.target.value)} />
                </Col>
              </Row>
            </div>

            <div className='add-box'>
              <Row className='mt-3'>
                <Col>
                  <h5>Dashboard Name</h5>
                  <Form.Control className='input-box' type="text" placeholder="Dashboard name" value={dashboardName} onChange={(e) => setDashboardName(e.target.value)} />
                </Col>
                <Col>
                  <h5>Explanation</h5>
                  <Form.Control className='input-box' type="text" placeholder="Write your explanation" value={explanation} onChange={(e) => setExplanation(e.target.value)} />
                </Col>

                {/*<div className='d-flex'>
                  <Form.Group className="mt-3 d-flex" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Make Public" checked={isPublic} onChange={() => setIsPublic(!isPublic)} className='me-5' />
                  </Form.Group>
          </div>*/}
              </Row>
            </div>

            {canAdd ?
              <Button
                className={`add-dashboard-btn w-100 active-dashboard`}
                onClick={addDashboard}
              >
                Add Dashboard
              </Button>
              :
              <Button
                className={`add-dashboard-btn w-100`}
                disabled
              >
                Add Dashboard
              </Button>
            }


          </Col>
        </Row>
      </Container >

      <ChangeDashboardModal
        show={showChangeModal}
        handleClose={handleCloseChangeModal}
        changeDashboardType={changeDashboardType}
        //type={changeToType}
        type={null}
      />

    </>
  )
}
