import React from 'react'

export default function RuleInfoBox(props) {
  return (
    <div className='rule-info-box'>
      <h5>Rule Properties</h5>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='rule-temporal'>
          <p>Rule Temporal</p>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='count-box'>
              {props.data.temporal ? props.data.temporal.split(':')[0] : '-'}
            </div>
            :
            <div className='count-box'>
              {props.data.temporal ? props.data.temporal.split(':')[1] : '-'}
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center box-names'>
            <p>Minute</p>
            <p>Second</p>
          </div>
        </div>

        <div className='presence'>
          <p>Presence</p>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='count-box'>
              {props.data.rule_count !== null ? props.data.rule_count : '-'}
            </div>
            :
            <div className='count-box'>
              {props.data.count_logic ? props.data.count_logic : '-'}
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center box-names'>
            <p>Count</p>
            <p>Logic</p>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='proximity'>
          <p>Proximity</p>
          <div>{props.data.proximity ? props.data.proximity : '-'}</div>
        </div>
        <div className='zone'>
          <p>Zone</p>
          <div>{props.data.zones ? props.data.zones : '-'}</div>
        </div>
      </div>
    </div>
  )
}
