import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="rightReserved"> 
          All rights reserved © Chooch Smart Analytics
        </div>
       </div>
    </>
  );
}
