import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Form, Table, OverlayTrigger, Popover, Button, Dropdown, Modal } from 'react-bootstrap';
import { Filter, Calendar2, Search, X, ThreeDotsVertical, ExclamationCircleFill } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import 'moment-duration-format';
import Pagination from '../../utils/Pagination';
import useWebSocket from 'react-use-websocket';
import noStock from '../../assets/images/noStock.svg'
import criticalStock from '../../assets/images/criticalStock.svg'
import lowStock from '../../assets/images/lowStock.svg'
import mediumStock from '../../assets/images/mediumStock.svg'
import highStock from '../../assets/images/highStock.svg'
import overfillStock from '../../assets/images/overfillStock.svg'
import warning from '../../assets/images/warning.svg'
import TimeBasedModal from './TimeBasedModal';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import ExportModal from './ExportModal';
import SnapshotModal from './SnapshotModal';
import Loader from '../Loader';
import { fast_api_axios } from '../../utils/axiosFast';
import PeriodIcon from '../../assets/images/PeriodIcon.png'
import SearchIcon from '../../assets/images/SearchIcon.png'
import SnapshotIcon from '../../assets/images/SnapshotImage.png';
import { findDeviceNameByDeviceId, findStreamNameByStreamId } from '../../utils/generic';

export default function InventoryManagement() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [no, setNo] = useState(0);
  const [critical, setCritical] = useState(0);
  const [low, setLow] = useState(0);
  const [medium, setMedium] = useState(0);
  const [high, setHigh] = useState(0);
  const [overfill, setOverfill] = useState(0);
  const [inventoryToSearch, setInventoryToSearch] = useState([]);
  const [showLower, setShowLower] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedBoxFilter, setSelectedBoxFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [showTimeBasedModal, setShowTimeBasedModal] = useState(false);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [selectedSnapshotItem, setSelectedSnapshotItem] = useState(null);
  const [selectedItemId, setselectedItemId] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showClearModal, setShowClearModal] = useState(false);

  const activeToken = localStorage.getItem('fast_api_token');
  const alertSocketUrl = process.env.REACT_APP_WS_BASE_URL + "/ws/stock_socket?token=" + activeToken
  const [alertWebSocketUrl, setAlertWebSocketUrl] = useState(alertSocketUrl);

  const { sendMessage: sendAlertSocketMessage, lastMessage: lastAlertSocketMessage, readyState: alertSocketReadyState, getWebSocket } = useWebSocket(alertWebSocketUrl, {
    onOpen: () => console.log('Alert WebSocket connected!'),
    shouldReconnect: (closeEvent) => closeEvent.code !== 4001,
    reconnectAttempts: 10,
    reconnectInterval: 1000,
    onMessage: (event) => {
      const data = JSON.parse(event.data);
      data.result['0'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['0-25'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['25-50'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['50-75'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['75-100'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['overfill'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      setInventoryData(data.result)
      setInventories(data.result['0'].concat(data.result['0-25']).concat(data.result['25-50']).concat(data.result['50-75']).concat(data.result['75-100']).concat(data.result['overfill']))
      setInventoryToSearch(data.result['0'].concat(data.result['0-25']).concat(data.result['25-50']).concat(data.result['50-75']).concat(data.result['75-100']).concat(data.result['overfill']))
    },
    onError: (event) => {
      console.log('Alert Socket Connection Status Error: ', event);
    },
    onClose: (event) => {
      if (event.code === 1005) {
        console.log(`Alert Socket Connection Status: 1005`);
      }

      if (event.code === 1006) {
        console.log(`Alert Socket Connection Status: 1006.`);
      }

      if (event.code === 4001) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }
    },
  });


  useEffect(() => {
    setNo(inventoryData['0'] ? inventoryData['0'].length : 0);
    setCritical(inventoryData['0-25'] ? inventoryData['0-25'].length : 0);
    setLow(inventoryData['25-50'] ? inventoryData['25-50'].length : 0);
    setMedium(inventoryData['50-75'] ? inventoryData['50-75'].length : 0);
    setHigh(inventoryData['75-100'] ? inventoryData['75-100'].length : 0);
    setOverfill(inventoryData['overfill'] ? inventoryData['overfill'].length : 0)
  }, [inventoryData])

  useEffect(() => {
    filterTable(showLower, true, selectedBoxFilter)
  }, [showLower, searchText, selectedBoxFilter])

  useEffect(() => {
    filterTable(showLower, false, selectedBoxFilter)
  }, [inventoryToSearch])

  const filterTable = (isShowLower, changePageNum, selectedBoxFilterTitle) => {
    if (changePageNum) {
      changePage(1)
    }

    let showLowerResult = inventoryToSearch;
    if (isShowLower) {
      showLowerResult = inventoryToSearch.filter(i => i.status === 'alert');
    }
    let boxFilterResult = showLowerResult;

    if (selectedBoxFilter === 'all') {
      boxFilterResult = showLowerResult;
    }
    else {
      boxFilterResult = showLowerResult.filter(i => i.class_title === selectedBoxFilterTitle);
    }

    let searchResult = boxFilterResult.filter(
      obj => Object.values(obj).some(
        val => typeof val === "string" && val.toLowerCase().includes(searchText.toLowerCase())
      )
    );

    setInventories(searchResult)
  }

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const openTimeBasedModal = (sku) => {
    setselectedItemId(sku)
    setShowTimeBasedModal(true)
  }

  const closeTimeBasedModal = () => {
    setShowTimeBasedModal(false)
  }

  const openSnapshotModal = (item) => {
    setSelectedSnapshotItem(item)
    setShowSnapshotModal(true)
  }

  const closeSnapshotModal = () => {
    setShowSnapshotModal(false)
  }

  const closeExportModal = () => {
    setShowExportModal(false);
  }

  const closeClearModal = (shouldClear) => {
    if (shouldClear) {
      handleClearAll()
    }
    else {
      setShowClearModal(false);
    }
  }

  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus);

  const handleFilterSelect = (filter) => {
    if (filter === "all") {
      setSelectedFilters([]);
    }
    else {
      setSelectedFilters((prevSelectedFilters) => {
        if (prevSelectedFilters.includes(filter)) {
          return prevSelectedFilters.filter(item => item !== filter);
        } else {
          return [...prevSelectedFilters, filter];
        }
      });
    }
  };

  const sendDemandSignal = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/report_page/export',
        params: {
          "date": moment().unix(),
          "type": "csv"
        }
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv')
        document.body.appendChild(link);
        link.click();
        link.remove()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const handleClearAll = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/item/delete_all',
      })
      .then(res => {
        setShowClearModal(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const goToProductDetail = (productID) => {
    history.push('/dashboards/inventory-management/' + productID)
  }

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='inner-breadcrumb'>Inventory Management</span>
            </p>

            <p className='end-date-box'>
              <Calendar2 size={20} />
              {moment().format('MM/DD/YYYY HH:mm')}
            </p>
          </div>

          <div className='page-wrapper'>
            <div className='info-boxes'>
              <div className={`all-products-box ${selectedBoxFilter === 'all' && 'active'}`} onClick={() => setSelectedBoxFilter('all')}>
                <div className='all-products-box-header'>
                  # All Products
                </div>
                <div className='all-products-box-count'>
                  {inventoryData['0-25'] ? inventoryData['0'].length + inventoryData['0-25'].length + inventoryData['25-50'].length + inventoryData['50-75'].length + inventoryData['75-100'].length + inventoryData['overfill'].length : 0}
                </div>
              </div>
              <div className={`stock-box darkRed ${selectedBoxFilter === '0' && 'active'}`} onClick={() => setSelectedBoxFilter('0')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={noStock} alt="noStock" />
                  <p className='stock-box-header'>No Stock</p>
                </div>
                <div className='stock-count'>{no}</div>
              </div>
              <div className={`stock-box red ${selectedBoxFilter === '0-25' && 'active'}`} onClick={() => setSelectedBoxFilter('0-25')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={criticalStock} alt="criticalStock" />
                  <p className='stock-box-header'>Critical Stock</p>
                </div>
                <div className='stock-count'>{critical}</div>
              </div>
              <div className={`stock-box orange ${selectedBoxFilter === '25-50' && 'active'}`} onClick={() => setSelectedBoxFilter('25-50')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={lowStock} alt="lowStock" />
                  <p className='stock-box-header'>Low Stock</p>
                </div>
                <div className='stock-count'>{low}</div>
              </div>
              <div className={`stock-box yellow ${selectedBoxFilter === '50-75' && 'active'}`} onClick={() => setSelectedBoxFilter('50-75')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={mediumStock} alt="mediumStock" />
                  <p className='stock-box-header'>Medium Stock</p>
                </div>
                <div className='stock-count'>{medium}</div>
              </div>
              <div className={`stock-box green ${selectedBoxFilter === '75-100' && 'active'}`} onClick={() => setSelectedBoxFilter('75-100')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={highStock} alt="highStock" />
                  <p className='stock-box-header'>High Stock</p>
                </div>
                <div className='stock-count'>{high}</div>
              </div>
              <div className={`stock-box darkGreen ${selectedBoxFilter === 'overfill' && 'active'}`} onClick={() => setSelectedBoxFilter('overfill')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={overfillStock} alt="overfillStock" />
                  <p className='stock-box-header'>Overfilled</p>
                </div>
                <div className='stock-count'>{overfill}</div>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-center gap-1 mb-2">
              {/*<div className='product-turnover-btn'>
                <Button onClick={() => history.push('/dashboards/inventory-management/time-based-usage')}>Product Turnover</Button>
              </div>*/}

              {/*<Button className='send-demand-signal' onClick={sendDemandSignal}>Send Demand Signal</Button>*/}

              <div className="d-flex align-items-center gap-1 ms-auto">
                <div className='inventory-search-group'>
                  <Search className='search-icon' />
                  <Form.Control placeholder="Search in Product Details" className='inventory-search-box my-3' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                  {searchText.length > 0 && <X size={24} color='#535353' className='delete-icon' onClick={(e) => setSearchText('')} />}
                </div>

                {/*<div className='inventory-filter-dropdown' ref={dropdownRef}>
                  <Button className="purple-bordered-transparent-btn" onClick={() => setShowFilterBox(!showFilterBox)}>
                    <Filter size={24} />
                    &nbsp;
                    Filters
                    &nbsp;
                    {
                      selectedFilters.length > 0 || showLower ?
                        showLower ?
                          <span className='filter-selected-count'>
                            {selectedFilters.length + 1}
                          </span>
                          :
                          <span className='filter-selected-count'>
                            {selectedFilters.length}
                          </span>
                        :
                        null
                    }
                  </Button>
                  {showFilterBox ?
                    <div className='filters-box'>
                      <div className='box-header'>Filters</div>

                      <Form.Group className="box-content" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" id="0" label="Lower than expected" checked={showLower} onChange={() => setShowLower(!showLower)} className='me-5' />
                        <Form.Check type="checkbox" id="1" label="No Stock" checked={selectedFilters.includes('0')} onChange={() => handleFilterSelect('0')} className='me-5' />
                        <Form.Check type="checkbox" id="2" label="Critical Stock" checked={selectedFilters.includes('0-25')} onChange={() => handleFilterSelect('0-25')} className='me-5' />
                        <Form.Check type="checkbox" id="3" label="Low Stock" checked={selectedFilters.includes('25-50')} onChange={() => handleFilterSelect('25-50')} className='me-5' />
                        <Form.Check type="checkbox" id="4" label="Medium Stock" checked={selectedFilters.includes('50-75')} onChange={() => handleFilterSelect('50-75')} className='me-5' />
                        <Form.Check type="checkbox" id="5" label="High Stock" checked={selectedFilters.includes('75-100')} onChange={() => handleFilterSelect('75-100')} className='me-5' />
                        <Form.Check type="checkbox" id="6" label="Overfilled" checked={selectedFilters.includes('overfill')} onChange={() => handleFilterSelect('overfill')} className='me-5' />
                      </Form.Group>
                    </div>
                    :
                    null
                  }
                </div>*/}
                {/*<Button className='clear-btn' onClick={() => setShowClearModal(true)}>Clear All</Button>*/}
              </div>

              {/*<Button className='export-btn' onClick={() => setShowExportModal(true)}>Export</Button>*/}
            </div>

            <Table className='inventory-table' responsive>
              <thead>
                <tr>
                  <th>STATUS</th>
                  <th>PRODUCT NAME</th>
                  <th>STREAM</th>
                  <th>DEVICE</th>
                  <th>BIN FULLNESS</th>
                </tr>
              </thead>
              <tbody>
                {inventories.slice(0 + ((currentPage - 1) * 10), currentPage * 10).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          item.class_title === '0' ?
                            <div className='no'>
                              <img src={noStock} alt="noStock" />
                              &nbsp; No Stock &nbsp;
                              {item.status === "alert" &&
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Popover id="warning-tooltip" arrowProps={null}>
                                      Lower than expected
                                    </Popover>
                                  }
                                >
                                  <img src={warning} alt="warning" />
                                </OverlayTrigger>
                              }
                            </div>
                            :
                            item.class_title === '0-25' ?
                              <div className='critical'>
                                <img src={criticalStock} alt="criticalStock" />
                                &nbsp; Critical Stock &nbsp;
                                {item.status === "alert" &&
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Popover id="warning-tooltip" arrowProps={null}>
                                        Lower than expected
                                      </Popover>
                                    }
                                  >
                                    <img src={warning} alt="warning" />
                                  </OverlayTrigger>
                                }
                              </div>
                              :
                              item.class_title === '25-50' ?
                                <div className='low'>
                                  <img src={lowStock} alt="lowStock" />
                                  &nbsp; Low Stock &nbsp;
                                  {item.status === "alert" &&
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Popover id="warning-tooltip" arrowProps={null}>
                                          Lower than expected
                                        </Popover>
                                      }
                                    >
                                      <img src={warning} alt="warning" />
                                    </OverlayTrigger>
                                  }
                                </div>
                                :
                                item.class_title === '50-75' ?
                                  <div className='medium'>
                                    <img src={mediumStock} alt="mediumStock" />
                                    &nbsp;Medium Stock &nbsp;
                                    {item.status === "alert" &&
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Popover id="warning-tooltip" arrowProps={null}>
                                            Lower than expected
                                          </Popover>
                                        }
                                      >
                                        <img src={warning} alt="warning" />
                                      </OverlayTrigger>
                                    }
                                  </div>
                                  :
                                  item.class_title === '75-100' ?
                                    <div className='high'>
                                      <img src={highStock} alt="highStock" />
                                      &nbsp; High Stock &nbsp;
                                      {item.status === "alert" &&
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Popover id="warning-tooltip" arrowProps={null}>
                                              Lower than expected
                                            </Popover>
                                          }
                                        >
                                          <img src={warning} alt="warning" />
                                        </OverlayTrigger>
                                      }
                                    </div>
                                    :
                                    <div className='high'>
                                      <img src={overfillStock} alt="overfillStock" />
                                      &nbsp; Overfilled Stock &nbsp;
                                      {item.status === "alert" &&
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Popover id="warning-tooltip" arrowProps={null}>
                                              Lower than expected
                                            </Popover>
                                          }
                                        >
                                          <img src={warning} alt="warning" />
                                        </OverlayTrigger>
                                      }
                                    </div>
                        }
                      </td>
                      <td className='name' title={item.sku}>{item.sku}</td>
                      {/*<td>{item.name}</td>*/}
                      <td>{item.camera_name || item.stream_name}</td>
                      <td>{item.device_name}</td>
                      <td>{item.class_title}%</td>
                      {/*<td onClick={() => openTimeBasedModal(item.sku)}><img src={period} alt="period" /></td>*/}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {inventories.length === 0 && <p className="empty-table">There is no data!</p>}

            <div className="paginationBlock">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={inventories.length}
                pageSize={10}
                showItemsPerPage={false}
                onPageChange={(page) => changePage(page)}
              />
            </div>
          </div>
        </div>
      </Row>

      <TimeBasedModal show={showTimeBasedModal} id={selectedItemId} handleClose={closeTimeBasedModal} />
      <SnapshotModal show={showSnapshotModal} handleClose={closeSnapshotModal} item={selectedSnapshotItem} />
      <ExportModal show={showExportModal} handleClose={closeExportModal} />
      <ClearAllModal show={showClearModal} handleClose={closeClearModal} />
    </Container >
  )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ color: "#878787" }}
  >
    <ThreeDotsVertical />
    {children}
  </a>
));

function ClearAllModal(props) {

  const closeModal = (shouldClear) => {
    if (shouldClear) {
      props.handleClose(true)
    }
    else {
      props.handleClose(false)
    }
  }

  return (
    <Modal show={props.show} onHide={() => closeModal(false)} size='md' centered className='clear-all-modal'>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        You are about to clear all data. Are you sure you want to continue?

      </Modal.Body>
      <Modal.Footer>
        <Button
          className='cancel-btn'
          onClick={() => closeModal(false)}
        >
          Cancel
        </Button>
        <Button
          className='yes-btn'
          onClick={() => closeModal(true)}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}