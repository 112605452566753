import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import { Container, Row } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Search } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import moment from 'moment';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import NoDataModal from './NoDataModal';
import Select from 'react-select';
import momentTimezone from 'moment-timezone';
import { useAuth } from '../../contexts/AuthContext'
import NoDataBoard from '../exampleDashboards/NoDataBoard';

const {
  combine,
  before,
  afterToday,
  allowedMaxDays,
} = RDateRangePicker;

const pieColors = ['#1F54CF', '#FF00F8', /*'#0C0043',*/ '#FFD000'];
const barColors = ['#1F54CF', '#FF00F8', '#FFD000'];
const emotionColors = ['#8020CF', '#22FCDB', '#0C0043', '#FF00F8', '#FFD000', '#1F54CF', '#D47235']
const pieLabels = ['Men', 'Women', /*'Masked',*/ 'Children'];
const emotionLabels = ['Happy', 'Sad', 'Neutral', 'Angry', 'Fear', 'Disgust', 'Surprise'];
const barLabels = ['0-4', '5-12', '13-17', '18-24', '25-34', '35-44', '45-54', '55+'];

export default function DemographicsGraph() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const streamOptions = JSON.parse(localStorage.getItem("streamData"));
  //const [filterStream, setFilterStream] = useState(streamOptions[0]);
  const [filterStream, setFilterStream] = useState(streamOptions[localStorage.getItem('selectedStreamOptionDemographics') ? parseInt(localStorage.getItem('selectedStreamOptionDemographics')) - 1 : 0]);
  const { updateMessage } = useToast();
  const [isPieLoading, setIsPieLoading] = useState(true);
  const [isEmotionLoading, setIsEmotionLoading] = useState(true);
  const [isBarLoading, setIsBarLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [pieData, setPieData] = useState([]);
  const [emotionData, setEmotionData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState();
  const [lineLabels, setLineLabels] = useState();
  const [chartDraw, setChartDraw] = useState([]);
  const [barVisitor, setBarVisitor] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    'start': moment().startOf('day'),
    'end': moment()
  })
  const [granularity, setGranularity] = useState({
    'label': "Hourly",
    'value': "hour"
  });
  const [hourlySelectedDay, setHourlySelectedDay] = useState(moment(dateFilter['start']).startOf('day'));
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const handleCloseNoDataModal = () => setShowNoDataModal(false);

  const handleStreamSelect = item => {
    setFilterStream(item)
  }

  const handleGranularitySelect = (opt) => {
    if (opt.value === "hour") {
      setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));
    }
    setGranularity(opt);
    getLineData(opt.value, moment(dateFilter['start']).startOf('day'));
  }

  const getPieData = () => {
    setIsPieLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/report/gender/total/',
        params: {
          'camera_id': filterStream.value,
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix()
        }
      })
      .then(res => {
        let myTotalData = res.data;
        let myPieData = [
          myTotalData.find(item => item.gender === 'male') ? myTotalData.find(item => item.gender === 'male').count : 0,
          myTotalData.find(item => item.gender === 'female') ? myTotalData.find(item => item.gender === 'female').count : 0,
          /*myTotalData.find(item => item.gender === 'mask') ? myTotalData.find(item => item.gender === 'mask').count : 0,*/
          myTotalData.find(item => item.gender === 'child') ? myTotalData.find(item => item.gender === 'child').count : 0
        ]
        setPieData(myPieData);
        setTotalVisitors(myPieData.reduce((a, b) => a + b, 0))
        setIsPieLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong', 'error')
        }
      });
  }

  const getEmotionData = () => {
    setIsEmotionLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/report/gender/emotion/',
        params: {
          'camera_id': filterStream.value,
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix()
        }
      })
      .then(res => {
        let happy = 0;
        let sad = 0;
        let neutral = 0;
        let angry = 0;
        let fear = 0;
        let disgust = 0;
        let surprise = 0;

        res.data.forEach(item => {
          if (item.hasOwnProperty('happy')) {
            happy = happy + item.happy
          }
          if (item.hasOwnProperty('sad')) {
            sad = sad + item.sad
          }
          if (item.hasOwnProperty('neutral')) {
            neutral = neutral + item.neutral
          }
          if (item.hasOwnProperty('angry')) {
            angry = angry + item.angry
          }
          if (item.hasOwnProperty('fear')) {
            fear = fear + item.fear
          }
          if (item.hasOwnProperty('disgust')) {
            disgust = disgust + item.disgust
          }
          if (item.hasOwnProperty('surprise')) {
            surprise = surprise + item.surprise
          }
        })

        var totalEmotion = happy + sad + neutral + angry + fear + disgust + surprise

        let myEmotionData = [
          (happy / totalEmotion * 100).toFixed(2),
          (sad / totalEmotion * 100).toFixed(2),
          (neutral / totalEmotion * 100).toFixed(2),
          (angry / totalEmotion * 100).toFixed(2),
          (fear / totalEmotion * 100).toFixed(2),
          (disgust / totalEmotion * 100).toFixed(2),
          (surprise / totalEmotion * 100).toFixed(2)
        ];

        setEmotionData(myEmotionData);
        setIsEmotionLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong', 'error')
        }
      });
  }

  const getBarData = () => {
    setIsBarLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/report/gender/age/',
        params: {
          'camera_id': filterStream.value,
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix()
        }
      })
      .then(res => {
        let newAgeArray = res.data;

        let myMaleData = [
          newAgeArray.find(item => item.gender === 'male' && item.age === '0-4') ? newAgeArray.find(item => item.gender === 'male' && item.age === '0-4').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '5-12') ? newAgeArray.find(item => item.gender === 'male' && item.age === '5-12').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '13-17') ? newAgeArray.find(item => item.gender === 'male' && item.age === '13-17').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '18-24') ? newAgeArray.find(item => item.gender === 'male' && item.age === '18-24').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '25-34') ? newAgeArray.find(item => item.gender === 'male' && item.age === '25-34').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '35-44') ? newAgeArray.find(item => item.gender === 'male' && item.age === '35-44').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '45-54') ? newAgeArray.find(item => item.gender === 'male' && item.age === '45-54').count : 0,
          newAgeArray.find(item => item.gender === 'male' && item.age === '55+') ? newAgeArray.find(item => item.gender === 'male' && item.age === '55+').count : 0,
        ];

        let myFemaleData = [
          newAgeArray.find(item => item.gender === 'female' && item.age === '0-4') ? newAgeArray.find(item => item.gender === 'female' && item.age === '0-4').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '5-12') ? newAgeArray.find(item => item.gender === 'female' && item.age === '5-12').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '13-17') ? newAgeArray.find(item => item.gender === 'female' && item.age === '13-17').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '18-24') ? newAgeArray.find(item => item.gender === 'female' && item.age === '18-24').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '25-34') ? newAgeArray.find(item => item.gender === 'female' && item.age === '25-34').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '35-44') ? newAgeArray.find(item => item.gender === 'female' && item.age === '35-44').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '45-54') ? newAgeArray.find(item => item.gender === 'female' && item.age === '45-54').count : 0,
          newAgeArray.find(item => item.gender === 'female' && item.age === '55+') ? newAgeArray.find(item => item.gender === 'female' && item.age === '55+').count : 0,
        ];

        let myChildData = [
          newAgeArray.find(item => item.gender === 'child' && item.age === '0-4') ? newAgeArray.find(item => item.gender === 'child' && item.age === '0-4').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '5-12') ? newAgeArray.find(item => item.gender === 'child' && item.age === '5-12').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '13-17') ? newAgeArray.find(item => item.gender === 'child' && item.age === '13-17').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '18-24') ? newAgeArray.find(item => item.gender === 'child' && item.age === '18-24').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '25-34') ? newAgeArray.find(item => item.gender === 'child' && item.age === '25-34').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '35-44') ? newAgeArray.find(item => item.gender === 'child' && item.age === '35-44').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '45-54') ? newAgeArray.find(item => item.gender === 'child' && item.age === '45-54').count : 0,
          newAgeArray.find(item => item.gender === 'child' && item.age === '55+') ? newAgeArray.find(item => item.gender === 'child' && item.age === '55+').count : 0,
        ];

        setBarData(
          [{
            name: 'Men',
            data: myMaleData
          }, {
            name: 'Women',
            data: myFemaleData
          }, {
            name: 'Children',
            data: myChildData
          }]
        )
        setBarVisitor(!myMaleData.every(item => item === 0) && !myFemaleData.every(item => item === 0) && !myChildData.every(item => item === 0))
        setIsBarLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong', 'error')
        }
      });
  }

  const getLineData = (graVal, hsd) => {
    setIsChartLoading(true);

    let start = moment(dateFilter['start']).unix()
    let end = graVal === 'hour' ? moment(dateFilter['end']).format('MM/DD/YYYY') === moment(hsd).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hsd).endOf('day').unix() : moment(dateFilter['end']).unix()

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/report/gender/hourly/',
        params: {
          'camera_id': filterStream.value,
          'start': start,
          'end': end,
          'granularity': graVal,
        }
      })
      .then(res => {
        let myLineLabels = [];
        let myMaleData = [];
        let myFemaleData = [];
        /*let myMaskedData = [];*/
        let myChildData = [];
        let dateArray = res.data;

        dateArray.forEach(item => {
          myLineLabels.push(item.prediction_time);
          myMaleData.push(item.male);
          myFemaleData.push(item.female);
          /*myMaskedData.push(item.masked_count)*/
          myChildData.push(item.child);
        })

        let myLineData = [
          {
            name: "Men",
            data: myMaleData
          },
          {
            name: 'Women',
            data: myFemaleData
          },
          /*{
            name: 'Masked',
            data: myMaskedData
          },*/
          {
            name: 'Children',
            data: myChildData
          }
        ];

        let updatedLineLabels = [];
        let draw = [];

        myLineLabels.forEach((item, index) => {
          let timeItem = item;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          myLineLabels[index] = timeItem;
        });


        myLineLabels.forEach((item, index) => {
          if (graVal === 'hour') {
            draw.push(moment(item).format('MM/DD/YYYY HH:mm'))
            if (index === 0 || index === myLineLabels.length - 1) {
              updatedLineLabels.push(moment(item).format('MM/DD/YYYY HH:mm'))
            }
            else{
              updatedLineLabels.push(moment(item).format('HH:mm'))
            }
          }
          else {
            draw.push(moment(item).format('MM/DD/YYYY'))
            updatedLineLabels.push(moment(item).format('MM/DD/YYYY'))
          }
        })
        setLineLabels(updatedLineLabels);
        setChartDraw(draw)
        setLineData(myLineData);
        setHourlySelectedDay(moment.unix(start))
        setIsChartLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong', 'error')
        }
      });
  }

  const getLineDataHourly = (start, end) => {
    setIsChartLoading(true);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/report/gender/hourly/',
        params: {
          'camera_id': filterStream.value,
          'start': start,
          'end': end,
          'granularity': 'hour',
        }
      })
      .then(res => {
        let myLineLabels = [];
        let myMaleData = [];
        let myFemaleData = [];
        /*let myMaskedData = [];*/
        let myChildData = [];
        let dateArray = res.data;

        dateArray.forEach(item => {
          myLineLabels.push(item.prediction_time);
          myMaleData.push(item.male);
          myFemaleData.push(item.female);
          /*myMaskedData.push(item.masked_count)*/
          myChildData.push(item.child);
        })

        let myLineData = [
          {
            name: "Men",
            data: myMaleData
          },
          {
            name: 'Women',
            data: myFemaleData
          },
          /*{
            name: 'Masked',
            data: myMaskedData
          },*/
          {
            name: 'Children',
            data: myChildData
          }
        ];

        let updatedLineLabels = [];
        let draw = [];

        myLineLabels.forEach((item, index) => {
          let timeItem = item;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          myLineLabels[index] = timeItem;
        });


        myLineLabels.forEach((item, index) => {
          draw.push(moment(item).format('MM/DD/YYYY HH:mm'))
          if (index === 0 || index === myLineLabels.length - 1) {
            updatedLineLabels.push(moment(item).format('MM/DD/YYYY HH:mm'))
          }
          else {
            updatedLineLabels.push(moment(item).format('HH:mm'))
          }
        })
        setLineLabels(updatedLineLabels);
        setChartDraw(draw)
        setLineData(myLineData);
        setHourlySelectedDay(moment.unix(start))
        setIsChartLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong', 'error')
        }
      });
  }


  const handlePrevDay = () => {
    let start = moment(hourlySelectedDay).subtract(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ? moment(dateFilter['start']).unix() : moment(hourlySelectedDay).subtract(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).subtract(1, 'days').endOf('day').unix();

    getLineDataHourly(start, end);
  }

  const handleNextDay = () => {
    let start = moment(hourlySelectedDay).add(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).add(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hourlySelectedDay).add(1, 'days').endOf('day').unix();
    getLineDataHourly(start, end);
  }

  useEffect(() => {
    getPieData();
    getEmotionData();
    getBarData();
    setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));

    if (moment(dateFilter['end']).diff(moment(dateFilter['start']), 'days') > 0) {
      setGranularity({
        'label': "Daily",
        'value': "day"
      })
      getLineData('day', moment(dateFilter['start']).startOf('day'))
    }
    else {
      //var endUnix = moment(moment(dateFilter['start']).endOf('day')).isAfter(moment(dateFilter['end'])) ? moment(dateFilter['end']).unix() : moment(dateFilter['start']).endOf('day').unix();
      //getLineData(moment(dateFilter['start']).unix(), endUnix);
      getLineData(granularity.value, moment(dateFilter['start']).startOf('day'))
    }

    let index = streamOptions.findIndex(o => o.value === filterStream.value)
    localStorage.setItem('selectedStreamOptionDemographics', index + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, filterStream])

  const getAssets = () => {
    dashboard_axios({
      method: "get",
      url: "/edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: localStorage.getItem("dashboardId"),
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    })
      .then((res) => {
        localStorage.setItem('assets', JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAssets()
  }, [])

  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Demographics</span>
              {/*<ChevronRight size={14} />
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards/sample/demographics')}>Sample Dashboard</span>*/}
            </p>
            <div className='d-flex'>
              <Select
                name="streamOptions"
                options={streamOptions}
                className="basic-single stream-filter me-3"
                classNamePrefix="select"
                placeholder={<span><Search className="mb-1 me-1" /> Select Streams</span>}
                onChange={(items) => handleStreamSelect(items)}
                value={filterStream}
              />
              <RDateRangePicker
                placeholder="Default"
                editable={false}
                className="date-filter-button me-3"
                placement="bottomEnd"
                disabledDate={combine(allowedMaxDays(90), afterToday())}
                format="MM/dd/yyyy HH:mm"
                value={[
                  new Date(moment(dateFilter['start']).format('MM/DD/YYYY HH:mm')),
                  new Date(moment(dateFilter['end']).format('MM/DD/YYYY HH:mm'))
                ]}
                ranges={[
                  {
                    label: 'Current Time',
                    value: [
                      new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'yesterday',
                    value: [
                      new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last Hour',
                    value: [
                      new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 3 Days',
                    value: [
                      new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 15 Days',
                    value: [
                      new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 30 Days',
                    value: [
                      new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                ]}
                cleanable={false}
                onChange={(e) => {
                  setDateFilter({
                    'start': moment(e[0]).add(getDiffer(), 'hours'),
                    'end': moment(e[1]).add(getDiffer(), 'hours')
                  })
                }}
              />
              <div className="demographics-total d-flex justify-content-between ms-3">
                <p className='total-name'>Total Number of Visitors</p>
                <p className='total-value'>{totalVisitors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              </div>
            </div>
          </div>

          <div className='page-wrapper'>
            {totalVisitors > 0 || isPieLoading ?
              <>
                <div className='d-flex mt-2 justify-content-between w-100'>
                  <div className="demographics-column-pie">
                    <div className='w-100'>
                      <h5 className='demographics-column-header'>Total Gender Distribution of Visitors (%)</h5>
                      {isPieLoading ?
                        <Loader />
                        :
                        pieData.every(x => x === 0) ?
                          <p className='no-data-graph-text'>No data found!</p>
                          :
                          <Chart
                            series={pieData}
                            type="pie"
                            height="370"
                            options={{
                              chart: {
                                toolbar: {
                                  show: true
                                },
                                type: 'pie',
                                height: 450,
                                fontFamily: 'Lato, sans-serif',
                              },
                              tooltip: {
                                y: {
                                  formatter: function (val, opt) {
                                    var total = opt.config.series.reduce((a, b) => a + b, 0)
                                    var percent = (opt.config.series[opt.seriesIndex] / total * 100).toFixed(1)
                                    return opt.config.series[opt.seriesIndex] + " - " + percent + "%"
                                  }
                                }
                              },
                              labels: pieLabels,
                              dataLabels: {
                                style: {
                                  fontWeight: 'normal',
                                  colors: ["#F7F7F7", "#F7F7F7", "#F7F7F7", "#303030"],
                                },
                              },
                              legend: {
                                position: 'bottom',
                                horizontalAlign: 'center',
                              },
                              colors: pieColors
                            }}
                          />
                      }
                    </div>
                  </div>

                  <div className="demographics-column-bar">
                    <div className='demographics-bar'>
                      <h5 className='demographics-column-header'>Age and Gender <br /> Distribution of Visitors (#)</h5>
                      {isBarLoading ?
                        <Loader />
                        :
                        barData.every(x => x.data.every(a => a === 0)) ?
                          <p className='no-data-graph-text'>No data found!</p>
                          :
                          <Chart
                            series={barData}
                            type="bar"
                            height="350"
                            options={{
                              chart: {
                                toolbar: {
                                  show: true,
                                  /*tools: {
                                    download: barVisitor
                                  }*/
                                },
                                type: 'bar',
                                height: 550,
                                stacked: true,
                                fontFamily: 'Lato, sans-serif',
                                events: {
                                  legendClick: (chartContext, seriesIndex, config) => {
                                    const { series } = config.config;
                                    const selectedSeriesItemName = series[seriesIndex].name; // seçilen
                                    var falseCount = 0;
                                    series.forEach((seriesItem, index) => {
                                      const currentSeriesItemName = seriesItem.name;
                                      if (selectedSeriesItemName === currentSeriesItemName) {
                                        if (!chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                                          falseCount++;
                                        };
                                      } else {
                                        if (chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                                          falseCount++;
                                        };
                                      }
                                      if (falseCount === series.length) {
                                        setTimeout(function () { chartContext.showSeries(selectedSeriesItemName) }, 0);
                                      }
                                    });
                                  }
                                },
                              },
                              tooltip: {
                                enabled: true,
                                enabledOnSeries: undefined,
                                shared: true,
                                followCursor: false,
                                intersect: false,
                                inverseOrder: false,
                                custom: undefined,
                                fillSeriesColor: false,
                                onDatasetHover: {
                                  highlightDataSeries: false
                                },
                                y: {
                                  formatter: function (value) {
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                }
                              },
                              plotOptions: {
                                bar: {
                                  horizontal: false,
                                },
                              },
                              dataLabels: {
                                enabled: true,
                                style: {
                                  colors: ["#F7F7F7", "#F7F7F7", "#303030"],
                                },
                                formatter: function (value) {
                                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              },
                              stroke: {
                                width: 1,
                                colors: ['#fff']
                              },
                              xaxis: {
                                categories: barLabels,
                              },
                              yaxis: {
                                title: {
                                  text: '# of Visitors'
                                }
                              },
                              fill: {
                                opacity: 1
                              },
                              legend: {
                                position: 'top',
                                horizontalAlign: 'center',
                              },
                              colors: barColors
                            }}
                          />
                      }
                    </div>
                  </div>
                </div>

                <div className="demographics-column-chart mt-3">

                  <div className='d-flex justify-content-between'>
                    <h5 className='demographics-column-header'>Gender Distribution of Visitors by Hour</h5>


                    {granularity.label === "Hourly" &&
                      <div className='d-flex align-items-center'>
                        {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ?
                          <ChevronLeft className='chevron-disabled' size={20} />
                          :
                          <ChevronLeft className='chevron-enabled' size={20} onClick={() => handlePrevDay()} />
                        }

                        <div className='current-day mx-2'>
                          {hourlySelectedDay ? moment(hourlySelectedDay).format('MM/DD/YYYY') : moment.utc(dateFilter['start']).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY')}
                        </div>

                        {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ?
                          <ChevronRight className='chevron-disabled' size={20} />
                          :
                          <ChevronRight className='chevron-enabled' size={20} onClick={() => handleNextDay()} />
                        }
                      </div>
                    }
                    <div className='d-flex me-3'>
                      <div
                        className={granularity.label === "Hourly" ? 'activeGranularity' : 'granularity'}
                        onClick={() => handleGranularitySelect({
                          'label': "Hourly",
                          'value': "hour",
                        })}
                      >
                        Hourly
                      </div>
                      <div>&nbsp;|&nbsp;</div>
                      <div
                        className={granularity.label === "Daily" ? 'activeGranularity' : 'granularity'}
                        onClick={() => handleGranularitySelect({
                          'label': "Daily",
                          'value': "day",
                        })}
                      >
                        Daily
                      </div>
                      <div>&nbsp;|&nbsp;</div>
                      <div
                        className={granularity.label === "Weekly" ? 'activeGranularity' : 'granularity'}
                        onClick={() => handleGranularitySelect({
                          'label': "Weekly",
                          'value': "week",
                        })}
                      >
                        Weekly
                      </div>
                    </div>
                  </div>

                  {isChartLoading ?
                    <Loader />
                    :
                    lineData.filter(x => x.data.length > 0).length === 0 ?
                      <p className='no-data-graph-text'>No data found!</p>
                      :
                      < Chart
                        options={{
                          chart: {
                            toolbar: {
                              show: true,
                              tools: {
                                download: !lineLabels.every(item => item === 0) // <== line to add // <== line to add
                              }
                            },
                            fontFamily: 'Lato, sans-serif',
                            height: 350,
                            type: 'line',
                            zoom: {
                              enabled: false
                            },
                            events: {
                              legendClick: (chartContext, seriesIndex, config) => {
                                const { series } = config.config;
                                const selectedSeriesItemName = series[seriesIndex].name; // seçilen
                                var falseCount = 0;
                                series.forEach((seriesItem, index) => {
                                  const currentSeriesItemName = seriesItem.name;
                                  if (selectedSeriesItemName === currentSeriesItemName) {
                                    if (!chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                                      falseCount++;
                                    };
                                  } else {
                                    if (chartContext.series.isSeriesHidden(seriesItem.name).isHidden) {
                                      falseCount++;
                                    };
                                  }
                                  if (falseCount === series.length) {
                                    setTimeout(function () { chartContext.showSeries(selectedSeriesItemName) }, 300);
                                  }
                                });
                              }
                            },
                          },
                          tooltip: {
                            enabled: true,
                            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                              const hoverIndexes = w.globals.seriesX.map(seriesX => {
                                return seriesX.findIndex(xData => xData === hoverXaxis);
                              });

                              let hoverList = '';
                              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                                if (hoverIndex >= 0) {
                                  hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                                }
                              });
                              const formatHoverX = chartDraw[dataPointIndex]
                              return `<div class="card">
                        <div class="card-header p-1">${formatHoverX}</div>
                        <div class="card-body p-1">
                          ${hoverList}
                        </div>
                      </div>`;
                            },
                          },
                          dataLabels: {
                            enabled: false
                          },
                          stroke: {
                            curve: 'straight',
                            width: 3
                          },
                          grid: {
                            row: {
                              colors: ['transparent']
                            },
                          },
                          xaxis: {
                            categories: lineLabels,
                            labels: {
                              rotate: -70,
                              rotateAlways: true,
                              style: {
                                fontSize: '11px',
                                fontWeight: 400,
                              },
                            }
                          },
                          yaxis: {
                            title: {
                              text: '# of Visitors'
                            }
                          },
                          colors: pieColors,
                          legend: {
                            position: 'bottom',
                            horizontalAlign: 'center',
                          },
                        }}
                        series={lineData}
                        type="line"
                        height={350}
                      />
                  }
                </div>

                <div className='demographics-column-chart mt-3'>

                  <h5 className='demographics-column-header'>Sentiment Distribution of Visitors (%)</h5>
                  {isEmotionLoading ?
                    <Loader />
                    :
                    emotionData.every(x => x === 0) ?
                      <p className='no-data-graph-text'>No data found!</p>
                      :
                      <Chart
                        series={
                          [{
                            name: '',
                            data: emotionData
                          }]
                        }
                        type="bar"
                        height="350"
                        options={{
                          chart: {
                            toolbar: {
                              show: true,
                            },
                            type: 'bar',
                            height: 550,
                            fontFamily: 'Lato, sans-serif',
                          },
                          tooltip: {
                            intersect: false,
                            marker: {
                              show: false
                            },
                            y: {
                              formatter: function (value) {
                                return value + '%';
                              }
                            }
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            style: {
                              colors: [
                                function ({ value, seriesIndex, dataPointIndex, w }) {
                                  if (w.globals.seriesLog[0][dataPointIndex] < 5) {
                                    return "#303030";
                                  }
                                  else {
                                    if (dataPointIndex % 7 === 1 || dataPointIndex % 7 === 4) {
                                      return "#303030";
                                    } else {
                                      return "#F7F7F7";
                                    }
                                  }

                                }
                              ]
                            },
                            formatter: function (value) {
                              return value + '%';
                            }
                          },
                          stroke: {
                            width: 1,
                            colors: ['#F7F7F7']
                          },
                          xaxis: {
                            categories: emotionLabels,
                          },
                          yaxis: {
                            title: {
                              text: '# of Visitors'
                            }
                          },
                          fill: {
                            opacity: 1
                          },
                          colors: [
                            function ({ value, seriesIndex, dataPointIndex, w }) {
                              return emotionColors[dataPointIndex % 7];
                            }
                          ]
                        }}
                      />
                  }
                </div>
              </>
              :
              <NoDataBoard exampleRoute='/dashboards/sample/demographics' />
            }

          </div>
        </div>
      </Row>

      <NoDataModal show={showNoDataModal} handleClose={handleCloseNoDataModal} />

    </Container>
  )
}
