import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap';
import welcomeModalImg from "../assets/images/welcomeModalImg.png";
import { useAuth } from "../contexts/AuthContext";

export default function IsEnterprise(props) {
  const { logout } = useAuth();

  const handleUpgrade = () => {
    window.open("https://chooch.com/contact-us/", "_blank");
  };

  return (
    <Modal show={true} onHide={false} centered size="xl" fullscreen={false}>
      <Modal.Body>
        <Row className='mt-5'>
          <Col sm={5} className="d-flex align-items-center justify-content-center">
            <img src={welcomeModalImg} alt="welcomeModalImg" />
          </Col>
          <Col sm={7}>
            <div className='enterprise mt-5'>
              <div className='title'>Welcome to Chooch Smart Analytics! </div>
              <p>Looks like your current plan is the trial plan. To unlock Chooch Smart Analytics contact us and <span>upgrade your plan</span> to Enterprise! </p>
              <p>With Chooch Smart Analytics, you can watch the alert videos, analyze the alert trends, and conduct with industry-specific dashboards.</p>
            </div>
            <div className='enterprise-button'>
              <p className='goto' onClick={logout}>Logout</p>
              <p className='upgrade' onClick={handleUpgrade}>Upgrade Your Plan</p>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
