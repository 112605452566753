import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Button,
  Accordion,
  Dropdown, Popover, OverlayTrigger
} from 'react-bootstrap';
import {  Star, Search, ChevronRight, ExclamationCircleFill, Filter, GridFill, ListTask, SortDown } from 'react-bootstrap-icons';
import Alert from '../alerts/Alert';  
import Frames from '../alerts/Frames';
import GroupAlertFrames from './GroupAlertFrames';
import ModalCreatePreset from "../alerts/ModalCreatePreset";
import ModalDeletePreset from '../alerts/ModalDeletePreset';
import moment from 'moment';
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import Select from "react-select";
import Loader from '../Loader';
import trash from '../../assets/images/trash.svg';
import momentTimezone from 'moment-timezone';
import { useHistory } from "react-router-dom";
import AlertListView from '../alerts/AlertListView';
import RefreshIcon
  from '../../assets/images/refresh-current-time.svg';

const {
  afterToday,
  allowedMaxDays,
  combine
} = RDateRangePicker;


export default function GroupAlerts(props) {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const [isDateApplied, setIsDateApplied] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment()
  })
  
  const [showDeletePresetModal, setShowDeletePresetModal] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showAll, setShowAll] = useState(localStorage.getItem('activeAlertPage') ? localStorage.getItem('activeAlertPage') === 'all' ? true : false : false);
  const { updateMessage } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isApplyActive, setIsApplyActive] = useState(false);
  const [showCreatePresetModal, setShowCreatePresetModal] = useState(false);

  const [groupAlert, setGroupAlert] = useState(false);

 /*  const groupAlerts = () => {
    console.log('dateFilter', dateFilter);
    let start = moment(dateFilter['start']).unix()
    let end = moment(dateFilter['end']).unix()
    console.log('start', start);
    console.log('end', end);
    fast_api_axios(
      {
        method: 'get',
        url: `/api/v1/alerts/loomis_custom_alert_page?start=${start}&end=${end}&seconds=10`,
      }
    )
    .then(res => {
      console.log(res.data);
    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    groupAlerts();
  }, []);
 */

  const handleRefresh = () => {
    setShouldRefresh(!shouldRefresh)
  }

  const alreadyFound = () => {
    setIsDateApplied(true);
  }
 
  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  const getCurrentTimeAlerts = () => {
    setDateFilter({
      'start': moment.tz(localStorage.getItem('timezone')).startOf('day'),
      'end': moment.tz(localStorage.getItem('timezone'))
    });
  }
  return (
    <>
      <Container fluid>
        <Row className='d-flex'>
          <div className='alerts-content-wide'>
            <div className='page-content-header'>
              {
                <>
                  <div className='d-flex justify-content-between align-items-center alerts-header'>
                    <div className='page-name'>Group Alerts</div>
                    <div className='d-flex'>
                      
                      <RDateRangePicker
                        placeholder="Default"
                        editable={false}
                        className="date-filter-button me-3"
                        placement="bottomEnd"
                        disabledDate={combine(allowedMaxDays(90), afterToday())}
                        format="MM/dd/yyyy HH:mm"
                        value={[
                          new Date(moment(dateFilter['start']).format('MM/DD/YYYY HH:mm')),
                          new Date(moment(dateFilter['end']).format('MM/DD/YYYY HH:mm'))
                        ]}
                        ranges={[
                          {
                            label: 'Current Time',
                            value: [
                              new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'yesterday',
                            value: [
                              new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last Hour',
                            value: [
                              new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last 3 Days',
                            value: [
                              new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last 15 Days',
                            value: [
                              new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last 30 Days',
                            value: [
                              new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                        ]}
                        cleanable={false}
                        onChange={(e) => {
                          setDateFilter({
                            'start': moment(e[0]).add(getDiffer(), 'hours'),
                            'end': moment(e[1]).add(getDiffer(), 'hours')
                          })
                        }}
                      />

                      {/*<Button className="blue-btn me-3"><PlusLg size={18} /> Create Report</Button>*/}
                      {/*currentUser.name === 'cagan@chooch.com' ? null : <Button disabled={noDataState} className="bordered-transparent-btn" onClick={() => handleExport()}><Download size={18} />&nbsp;Export CSV</Button>*/}

                    </div>
                  </div>
                </>
              }
            </div>

            <div className='page-wrapper'>
              {
                isLoading ?
                  <Loader />
                  :
                  <>
                    <GroupAlertFrames
                      {...props}
                      dateFilter={dateFilter}
                      isReviewed={false}
                      handleRefresh={handleRefresh}
                      shouldRefresh={shouldRefresh}
                      showAll={true}
                      isDateApplied={isDateApplied}
                      alreadyFound={alreadyFound}
                    />
                  </>
              }
            </div>
          </div>
        </Row>
      </Container>

    </>
  );
}
