import React from 'react';
import Chart from "react-apexcharts";
import NoDataImage from '../../assets/images/restricted.png';
import { ChevronRight } from 'react-bootstrap-icons';
import SliderItem from '../counting/SliderItem';
import { useHistory } from "react-router-dom";

const pieColors = [
  '#22FCDB',
  '#492CE6',
  '#60EFFF',
  '#FF00F8',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#26909C'
]
export default function CountingExample() {
  let history = useHistory();
  const summaryData = [
    {
      "id": 0,
      "name": "Car",
      "count": 2500,
      "dirname": "A to B",
      "linename": "North Way",
      "strname": "Beltway",
      "dvcname": "Sunset Park"
    },
    {
      "id": 1,
      "name": "Truck",
      "count": 950,
      "dirname": "B to A",
      "linename": "South Way",
      "strname": "Bannon",
      "dvcname": "Sunset Park"
    },
    {
      "id": 1,
      "name": "Bus",
      "count": 675,
      "dirname": "A to B",
      "linename": "North Way",
      "strname": "Beltway",
      "dvcname": "Sunset Park"
    },
  ];

  const streamBasedBarDataClasses = [3175, 950];
  const streamBasedBarDataClassesLabel = ['Beltway', 'Bannon'];

  const barDataClasses = [2500, 950, 675];
  const barDataClassesLabel = ['Car', 'Truck', 'Bus'];

  const chartLabels = [
    "01/01/2023 00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "01/01/2023 23:00"
  ];

  const chartDataset = [
    {
      "name": "Car",
      "data": [
        100,
        50,
        75,
        50,
        75,
        20,
        80,
        120,
        130,
        90,
        210,
        175,
        225,
        250,
        250,
        175,
        125,
        80,
        30,
        90,
        20,
        40,
        25,
        35
      ],
      "fill": false,
      "borderColor": "#22FCDB",
      "backgroundColor": "#22FCDB",
      "tension": 0.1
    },
    {
      "name": "Truck",
      "data": [
        20,
        10,
        10,
        30,
        50,
        120,
        50,
        25,
        75,
        10,
        30,
        70,
        80,
        60,
        20,
        60,
        0,
        20,
        10,
        100,
        50,
        20,
        10,
        20
      ],
      "fill": false,
      "borderColor": "#492CE6",
      "backgroundColor": "#492CE6",
      "tension": 0.1
    },
    {
      "name": "Bus",
      "data": [
        5,
        10,
        5,
        5,
        10,
        2,
        3,
        15,
        25,
        75,
        50,
        100,
        80,
        70,
        40,
        20,
        30,
        20,
        40,
        15,
        25,
        10,
        5,
        15
      ],
      "fill": false,
      "borderColor": "#492CE6",
      "backgroundColor": "#492CE6",
      "tension": 0.1
    }
  ];
  const chartDraw = [
    "01/01/2023 00:00",
    "01/01/2023 01:00",
    "01/01/2023 02:00",
    "01/01/2023 03:00",
    "01/01/2023 04:00",
    "01/01/2023 05:00",
    "01/01/2023 06:00",
    "01/01/2023 07:00",
    "01/01/2023 08:00",
    "01/01/2023 09:00",
    "01/01/2023 10:00",
    "01/01/2023 11:00",
    "01/01/2023 12:00",
    "01/01/2023 13:00",
    "01/01/2023 14:00",
    "01/01/2023 15:00",
    "01/01/2023 16:00",
    "01/01/2023 17:00",
    "01/01/2023 18:00",
    "01/01/2023 19:00",
    "01/01/2023 20:00",
    "01/01/2023 21:00",
    "01/01/2023 22:00",
    "01/01/2023 23:00",
  ];
  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  return (
    <>
      <div className='page-content-header d-flex justify-content-between align-items-center'>
        <p className='breadcrumb'>
          <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
          <ChevronRight size={14} />
          <span className='main-breadcrumb' onClick={() => history.push('/dashboards/counting')}>Counting</span>
          <ChevronRight size={14} />
          <span className='inner-breadcrumb'>Sample Dashboard</span>
        </p>
      </div>

      <div className='page-wrapper'>
        <div className='example-dashboard-header'>
          Example Dashboard
        </div>

        <div className='counting-slider-box'>
          <SliderItem
            isSummaryLoading={false}
            summaryData={summaryData}
          />
        </div>

        <div className='d-flex w-100'>
          <div className='pie-chart-box me-5'>

            <div className='w-100'>
              <h5>Stream-Based Counting</h5>
              <Chart
                series={
                  [{
                    name: '',
                    data: streamBasedBarDataClasses
                  }]
                }
                type="bar"
                height="220"
                options={{
                  chart: {
                    toolbar: {
                      tools: {
                        download: false
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 4,
                      horizontal: true,
                      dataLabels: {
                        position: 'top'
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    offsetX: 25,
                    style: {
                      colors: ['#333']
                    },
                    formatter: function (value) {
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                  },
                  xaxis: {
                    categories: streamBasedBarDataClassesLabel,
                    labels: {
                      formatter: function (value) {
                        return Number.isInteger(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                    },
                  },
                  tooltip: {
                    y: {
                      formatter: function (val, opt) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                    },
                  },
                  colors: [
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                      return pieColors[dataPointIndex];
                    }
                  ]
                }}
              />
            </div>
          </div>


          <div className='pie-chart-box'>
            <div className='w-100'>
              <h5>Top Classes</h5>
              <Chart
                series={
                  [{
                    name: '',
                    data: barDataClasses
                  }]
                }
                type="bar"
                height="220"
                options={{
                  chart: {
                    toolbar: {
                      tools: {
                        download: false
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 4,
                      horizontal: true,
                      dataLabels: {
                        position: 'top'
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    offsetX: 25,
                    style: {
                      colors: ['#333']
                    },
                    formatter: function (value) {
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                  },
                  xaxis: {
                    categories: barDataClassesLabel,
                    labels: {
                      formatter: function (value) {
                        return Number.isInteger(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                    },
                  },
                  tooltip: {
                    y: {
                      formatter: function (val, opt) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                    }
                  },
                  colors: [
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                      return pieColors[dataPointIndex];
                    }
                  ]
                }}
              />
            </div>
          </div>
        </div>

        <div className='line-chart-box mt-3'>
          <div className='d-flex justify-content-between'>
            <h5>Class based Counting</h5>
          </div>


          <Chart
            options={{

              chart: {
                animations: {
                  enabled: false,
                },
                fontFamily: 'Lato, sans-serif',
                type: 'line',
                toolbar: {
                  autoSelected: 'zoom',
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    selection: false,
                    reset: false,
                    download: false
                  },
                  export: {
                    csv: {
                      filename: 'Alerts-Distribution' + new Date().toISOString().slice(0, 10),
                    },
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'straight',
                width: 3
              },
              grid: {
                row: {
                  colors: ['transparent']
                },
                padding: {
                  top: 0,
                  right: 20,
                  bottom: 50,
                  left: 20
                },
              },

              xaxis: {
                categories: lineData.labels,
                tooltip: {
                  formatter: function (value, opts) {
                    const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                    if (v === undefined || v === '') {
                      return ''
                    } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                      return opts.w.globals.categoryLabels[opts.dataPointIndex]
                    } else {
                      return v;
                    }
                  },
                },
                labels: {
                  rotate: -70,
                  style: {
                    fontSize: '11px',
                    fontWeight: 400,
                  },
                  rotateAlways: true,
                  formatter: function (value) {
                    var xaxisLabel = document.querySelectorAll('.apexcharts-xaxis-label tspan')
                    return value;
                  }
                }
              },
              tooltip: {
                fixed: {
                  enabled: false
                },
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map(seriesX => {
                    return seriesX.findIndex(xData => xData === hoverXaxis);
                  });

                  let hoverList = '';
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (hoverIndex >= 0) {
                      hoverList += `<span class="apexcharts-tooltip-marker circlemake" style="background-color: ${pieColors[seriesEachIndex % 13]};"></span><span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  });
                  const formatHoverX = chartDraw[dataPointIndex]

                  return `<div class="card">
                              <div class="card-header p-1">${formatHoverX}</div>
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                }
              },
              colors: pieColors,
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                showForSingleSeries: true
              },
            }}
            series={
              lineData.datasets
            }
            height={350}
            type="line"
          />
        </div>

      </div>
    </>
  )
}
