import React, { useState, useMemo } from 'react';
import Select from 'react-select';

export default function CustomTimePickerWithMin({ selectedReportTimes, handleSelectedReportTimes, selectedFrequency }) {
  const [timeSlots, setTimeSlots] = useState(selectedReportTimes || [{ hour: 'HH', minute: 'MM' }]);

  const hours = useMemo(() => Array.from({ length: 24 }, (_, i) => ({
    value: i.toString().padStart(2, '0'),
    label: i.toString().padStart(2, '0')
  })), []);

  const minutes = useMemo(() => Array.from({ length: 60 }, (_, i) => ({
    value: i.toString().padStart(2, '0'),
    label: i.toString().padStart(2, '0')
  })), []);

  const isTimeSlotComplete = (timeSlot) =>
    timeSlot.hour !== 'HH' && timeSlot.minute !== 'MM';

  const handleAddTimeSlot = () => {
    if (isTimeSlotComplete(timeSlots[timeSlots.length - 1])) {
      setTimeSlots([...timeSlots, { hour: 'HH', minute: 'MM' }]);
    }
  };

  const handleTimeChange = (index, type, selectedOption) => {
    setTimeSlots((prevTimeSlots) => {
      const newTimeSlots = [...prevTimeSlots];
      newTimeSlots[index] = { ...newTimeSlots[index], [type]: selectedOption.value };

      const filteredTimeSlots = newTimeSlots
        .filter(isTimeSlotComplete)
        .map(timeSlot => `${timeSlot.hour}:${timeSlot.minute}`);
      handleSelectedReportTimes(filteredTimeSlots);

      return newTimeSlots;
    });
  };

  return (
    <div>
      {timeSlots.map((timeSlot, index) => (
        <div key={index} style={styles.container}>
          <div style={{
            ...styles.fakeSelectContainer,
            backgroundColor: selectedFrequency === null ? '#f8f8f8' : '#ffffff'
          }}>
            <Select
              options={hours}
              className="basic-single no_border"
              classNamePrefix="select"
              placeholder="HH"
              onChange={(selectedOption) => handleTimeChange(index, 'hour', selectedOption)}
              value={hours.find(h => h.value === timeSlot.hour)}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
            />
            <span style={styles.separator}>:</span>
            <Select
              options={minutes}
              className="basic-single no_border"
              classNamePrefix="select"
              placeholder="MM"
              onChange={(selectedOption) => handleTimeChange(index, 'minute', selectedOption)}
              value={minutes.find(m => m.value === timeSlot.minute)}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
            />
          </div>
        </div>
      ))}
      {selectedFrequency && (
        <div style={styles.addReportTimeContainer}>
          <button onClick={handleAddTimeSlot} style={styles.addButton}>
            Add Report Time
          </button>
        </div>
      )}
    </div>
  );
}

// Styles
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  fakeSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #ccc',
    padding: '5px 10px',
    width: '120px',
  },
  separator: {
    fontSize: '16px',
    margin: '0 5px',
  },
  addButton: {
    padding: '5px 0',
    fontSize: '14px',
    color: '#6200ee',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  addReportTimeContainer: {
    textAlign: 'left',
    marginTop: '10px',
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    width: '40px',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    border: 0,
    boxShadow: 'none',
  }),
  container: (provided) => ({
    ...provided,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    padding: 0,
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  menuitem: (provided) => ({
    ...provided,
    padding: '5px 0',
    textAlign: 'center',
    width: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    padding: '5px 0',
    textAlign: 'center',
    width: '60px',
  }),
};
