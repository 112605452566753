import React from 'react';

export function ScoreInfoBox(props) {

  return(
    <div className='image-alert-info-box'>
      <span>What is score? How can I adjust the score on AI Vision Studio?</span>
    </div>
  )
}

export function ResultInfoBox(props) {

  return(
    <div className='image-alert-info-box'>
      <span>
        {props.data}
      </span>
    </div>
  )
}

export function PromptInfoBox(props) {

  return(
    <div className='image-alert-info-box'>
      <span>
        {props.data}
      </span>
    </div>
  )
}
