import React, { useEffect, useState } from 'react';
import { Container, Row, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { ChevronRight, Calendar2, InfoCircle } from 'react-bootstrap-icons';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import 'moment-duration-format';
import Pagination from '../../utils/Pagination';
import period from '../../assets/images/period.svg'
import approximate from '../../assets/images/approximate.svg';
import { findStreamNameByStreamId, findDeviceNameByDeviceId } from '../../utils/generic';
import { fast_api_axios } from '../../utils/axiosFast';
import Loader from '../Loader';
import TurnoverChartModal from './TurnoverChartModal';

export default function TimeBasedTable() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [inventories, setInventories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemId, setselectedItemId] = useState(null);
  const [showTurnoverChartModal, setShowTurnoverChartModal] = useState(false);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const getChartData = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/report_page'
      })
      .then(res => {
        setInventories(res.data.data)
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const openTurnoverChartModal = (sku) => {
    setselectedItemId(sku)
    setShowTurnoverChartModal(true)
  }

  const closeTurnoverChartModal = () => {
    setShowTurnoverChartModal(false)
  }

  useEffect(() => {
    getChartData()
  }, [])

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards/inventory-management')}>Inventory Management</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Product Turnover</span>
            </p>

            <p className='end-date-box'>
              <Calendar2 size={20} />
              {moment().format('MM/DD/YYYY HH:mm')}
            </p>
          </div>

          <div className='page-wrapper'>
            {isLoading ?
              <Loader />
              :
              <>
                <Table className='time-based-table'>
                  <thead>
                    <tr>
                      <th>SKU NUMBER</th>
                      <th>PRODUCT NAME</th>
                      <th>STREAM</th>
                      <th>DEVICE</th>
                      <th colspan="3">QUANTITY at</th>
                      <th>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Popover id="warning-tooltip" arrowProps={null}>
                              based on current time
                            </Popover>
                          }
                        >
                          <InfoCircle color='#8020CF' size={16} />
                        </OverlayTrigger>
                        <div className="d-inline-flex">PRODUCT <br></br>TURNOVER</div>
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>09:00</th>
                      <th>12:00</th>
                      <th>15:00</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventories.slice(0 + ((currentPage - 1) * 10), currentPage * 10).map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td className='name' title={item.sku}>{item.sku}</td>
                          <td>{item.camera_name || findStreamNameByStreamId(item.camera_id)}</td>
                          <td>{findDeviceNameByDeviceId(item.device_id)}</td>
                          <td>
                            {
                              item.result['09'].class_title === '0' ?
                                <div className='no'>
                                  No Stock
                                </div>
                                :
                                item.result['09'].class_title === '0-25' ?
                                  <div className='critical'>
                                    Critical Stock
                                  </div>
                                  :
                                  item.result['09'].class_title === '25-50' ?
                                    <div className='low'>
                                      Low Stock
                                    </div>
                                    :
                                    item.result['09'].class_title === '50-75' ?
                                      <div className='medium'>
                                        Medium Stock
                                      </div>
                                      :
                                      item.result['09'].class_title === '75-100' ?
                                        <div className='high'>
                                          High Stock
                                        </div>
                                        :
                                        item.result['09'].class_title === 'overfill' ?
                                          <div className='overfill'>
                                            Overfilled Stock
                                          </div>
                                          :
                                          '-'

                            }
                            <br></br>
                            <span>{item.result['09'].class_count ? item.result['09'].class_count : '-'}</span>
                          </td>
                          <td>
                            {
                              item.result['12'].class_title === '0' ?
                                <div className='no'>
                                  No Stock
                                </div>
                                :
                                item.result['12'].class_title === '0-25' ?
                                  <div className='critical'>
                                    Critical Stock
                                  </div>
                                  :
                                  item.result['12'].class_title === '25-50' ?
                                    <div className='low'>
                                      Low Stock
                                    </div>
                                    :
                                    item.result['12'].class_title === '50-75' ?
                                      <div className='medium'>
                                        Medium Stock
                                      </div>
                                      :
                                      item.result['12'].class_title === '75-100' ?
                                        <div className='high'>
                                          High Stock
                                        </div>
                                        :
                                        item.result['12'].class_title === 'overfill' ?
                                          <div className='overfill'>
                                            Overfilled Stock
                                          </div>
                                          :
                                          '-'

                            }
                            <br></br>
                            <span>{item.result['12'].class_count ? item.result['12'].class_count : '-'}</span>
                          </td>
                          <td>
                            {
                              item.result['15'].class_title === '0' ?
                                <div className='no'>
                                  No Stock
                                </div>
                                :
                                item.result['15'].class_title === '0-25' ?
                                  <div className='critical'>
                                    Critical Stock
                                  </div>
                                  :
                                  item.result['15'].class_title === '25-50' ?
                                    <div className='low'>
                                      Low Stock
                                    </div>
                                    :
                                    item.result['15'].class_title === '50-75' ?
                                      <div className='medium'>
                                        Medium Stock
                                      </div>
                                      :
                                      item.result['15'].class_title === '75-100' ?
                                        <div className='high'>
                                          High Stock
                                        </div>
                                        :
                                        item.result['15'].class_title === 'overfill' ?
                                          <div className='overfill'>
                                            Overfilled Stock
                                          </div>
                                          :
                                          '-'

                            }
                            <br></br>
                            <span>{item.result['15'].class_count ? item.result['15'].class_count : '-'}</span>
                          </td>
                          <td onClick={() => openTurnoverChartModal(item.sku)} style={{ cursor: 'pointer' }}><img src={approximate} alt="approximate" />{item.result.turnover} <img src={period} alt="period" /></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>

                <div className="paginationBlock">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={inventories.length}
                    pageSize={10}
                    showItemsPerPage={false}
                    onPageChange={(page) => changePage(page)}
                  />
                </div>
              </>
            }
          </div>
        </div>
      </Row>

      <TurnoverChartModal show={showTurnoverChartModal} id={selectedItemId} handleClose={closeTurnoverChartModal} />

    </Container>
  )
}
