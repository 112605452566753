import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function AlertEmailEditModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="genericModalTitle">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="genericModalText">
          Are you sure, you want to edit the {props.type === "alert" ? 'alert' : 'report'} setting?
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button className='genericModalBtnApply me-3' onClick={() => props.applyEdit(props.settingId, props.isActive, props.type, props.reportTime?.value, props.reportDate?.value)}>Yes</Button>
          <Button className='genericModalBtnCancel' onClick={props.handleClose}>No</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
