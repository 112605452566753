import React, { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Container, Row, Table } from 'react-bootstrap';
import { CameraVideo, CameraVideoOff, Calendar2, ExclamationCircle, ExclamationCircleFill, CheckCircleFill } from 'react-bootstrap-icons';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { findRuleNameByRuleId, findStreamNameByStreamId, findDeviceNameByDeviceId, findClassTitleByRuleId } from '../../utils/generic';
import { dashboard_axios } from '../../utils/axiosFast';
import Loader from '../Loader';
import AlertModal from './AlertModal';
import Clock from '../../assets/images/clock.svg';

export default function Overview() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  const activeToken = localStorage.getItem('fast_api_token');
  const alertSocketUrl = process.env.REACT_APP_WS_BASE_URL + "/ws/alert_socket?token=" + activeToken

  const [alertWebSocketUrl, setAlertWebSocketUrl] = useState(alertSocketUrl);

  const [isLastAlertsLoading, setIsLastAlertsLoading] = useState(true);
  const [lastAlerts, setLastAlerts] = useState([]);

  const [alertsCounts, setAlertsCounts] = useState(null);
  const [deviceCount, setDeviceCount] = useState(0);
  const [streamStatuses, setStreamStatuses] = useState([]);
  const [isStreamStatusesLoading, setIsStreamStatusesLoading] = useState(true);

  const [selectedVideoUUID, setSelectedVideoUUID] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [currentTime, setCurrentTime] = useState(moment().local().format('YYYY-MM-DD HH:mm:ss'));


  const { sendMessage: sendAlertSocketMessage, lastMessage: lastAlertSocketMessage, readyState: alertSocketReadyState, getWebSocket } = useWebSocket(alertWebSocketUrl, {
    onOpen: () => console.log('Alert WebSocket connected!'),
    shouldReconnect: (closeEvent) => closeEvent.code !== 1005 && closeEvent.code !== 1006 && closeEvent.code !== 4001,
    reconnectAttempts: 10,
    reconnectInterval: 1000,
    onMessage: (event) => {
      const data = JSON.parse(event.data);
      getAlerts(data);
    },
    onError: (event) => {
      console.log('Alert Socket Connection Status Error: ', event);
    },
    onClose: (event) => {
      if (event.code === 1005) {
        console.log(`Alert Socket Connection Status: Closed`);
      }

      if (event.code === 1006) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }

      if (event.code === 4001) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }
    },
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[alertSocketReadyState];

  const getAlerts = (data) => {

    if (data.data_type === 'camera_logs') {
      const streams = data.result.filter((item) => findStreamNameByStreamId(item.camera_id) !== '-' && findStreamNameByStreamId(item.camera_id) !== undefined);
      setDeviceCount(new Set(data.result.map(x => x.device_name)).size);
      setStreamStatuses(streams)
      setIsStreamStatusesLoading(false);
    }

    if (data.data_type === 'last_alerts') {
      setLastAlerts(data.result);
      setIsLastAlertsLoading(false);
    }

    if (data.data_type === 'new_alert') {
      setCurrentTime(moment().local().format('YYYY-MM-DD HH:mm:ss'));
      setLastAlerts(prev => [{ ...data.result, isAlertNew: true, expireDate: moment.utc(data.result.alert_notification_time).local().add(5, 'seconds').format() }, ...prev]);
    }

    if (data.data_type === 'alerts_counts') {
      setAlertsCounts(data.result);
    }
  }

  useEffect(() => {
    let hasNewAlert = true;
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(moment(currentTime).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss'));
    }, 1000);
    return () => {
      hasNewAlert = false;
      clearInterval(currentTimeInterval);
    };
  }, [currentTime]);


  const closeAlertModal = () => {
    setSelectedVideoUUID(null);
    setShowAlertModal(false);
  }

  const getAssets = () => {
    dashboard_axios({
      method: "get",
      url: "/edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: localStorage.getItem("dashboardId"),
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    })
      .then((res) => {
        localStorage.setItem('assets', JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAssets();
  }, []);

  const handleViewAlert = (alertUUID) => {
    setSelectedVideoUUID(alertUUID);
    setShowAlertModal(true);
  }

  return (
    <>
      <Container fluid>
        <Row className='d-flex'>
          <div className='alerts-content-wide'>
            <div className='page-content-header'>

              <div className='d-flex justify-content-between align-items-center alerts-header'>
                <div className='page-name'>Overview</div>

                <div className='d-flex'>
                  <p className='end-date-box'>
                    <Calendar2 size={20} />
                    {moment().format('MM/DD/YYYY HH:mm')}
                  </p>
                </div>
              </div>
            </div>

            <div className='page-wrapper'>
              <div className='statistics-content'>
                <div className='statistics-content-subtitles'>
                  <h5>Summary</h5>
                  <span>
                    <img src={Clock} alt='clock' />
                    Today
                  </span>
                </div>
                <div className='alert-statistics-box'>
                  <div className='statistic-name'>Number of alerts</div>
                  <div className='statistic-count'>{alertsCounts?.total}</div>
                </div>

                <div className='alert-statistics-sub-container'>
                  <div className="alert-statistics-box compound-box">
                    <div className="statistic-name">Reviewed alert number
                    </div>
                    <div className="statistic-count">{alertsCounts?.reviewed}<span>/{alertsCounts?.total}</span>
                    </div>
                  </div>

                  <div className="alert-statistics-box compound-box">
                    <div className="statistic-name">
                      <CheckCircleFill color="#20B526" size={18}/>&nbsp;Correct alert number
                    </div>
                    <div className="statistic-count">{alertsCounts?.is_true}<span>/{alertsCounts?.reviewed}</span>
                    </div>
                  </div>

                  <div className="alert-statistics-box compound-box">
                    <div className="statistic-name">
                      <ExclamationCircleFill color="#EB5857" size={18}/>&nbsp;False alert number
                    </div>
                    <div className="statistic-count">{alertsCounts?.is_false}<span>/{alertsCounts?.reviewed}</span>
                    </div>
                  </div>
                </div>

                <div className="alert-statistics-box">
                  <div className="statistic-name">Unreviewed alert number</div>
                  <div className="statistic-count">{alertsCounts?.not_reviewd}<span>/{alertsCounts?.total}</span>
                  </div>
                </div>

                <div className='alert-statistics-box'>
                  <div className='statistic-name'>Number of devices</div>
                  <div className='statistic-count'>{deviceCount}</div>
                </div>

                <div className='alert-statistics-box'>
                  <div className='statistic-name'>Number of <span className='live'>Live</span> cameras</div>
                  <div className='statistic-count'>{streamStatuses?.filter((i) => i.status === "active").length}<span>/{streamStatuses?.length}</span></div>
                </div>
              </div>

              <div className='last-alerts-box'>
                <div className='d-flex align-items-center justify-content-between'>
                  <h5>Latest 5 Alerts</h5>
                  <p className='view-all' onClick={() => history.push('/alerts')}>View All Alerts</p>
                </div>

                {isLastAlertsLoading ?
                  <Loader />
                  :
                  <Table style={{ borderSpacing: '0 2px', borderCollapse: 'separate' }}>
                    <thead>
                      <tr>
                        <th />
                        <th>DATE</th>
                        <th>CLASS</th>
                        <th>ALERT NAME</th>
                        <th>STREAM</th>
                        <th>DEVICE</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastAlerts.length === 0 && <tr className='table-empty'><td><ExclamationCircleFill size={20} color='#8020CF' />&nbsp;An alert has not been triggered yet.</td></tr>}
                      {lastAlerts.slice(0, 5).map((alertItem, index) => {
                        const alertIsExpired = moment(alertItem?.expireDate).diff(moment(currentTime), 'seconds') >= 0;
                        return (
                          <tr key={index} className={(alertItem?.isAlertNew && alertIsExpired) ? 'new-alert' : 'read-alert'}>
                            <td>{alertItem?.isAlertNew && <span style={{ color: '#FF00F8', fontWeight: 600, transition: '1s all', opacity: alertIsExpired ? 1 : 0 }}>NEW</span>}</td>
                            <td>{moment.utc(alertItem?.alert_notification_time).local().format('MM/DD/YYYY HH:mm:ss')}</td>
                            <td>{findClassTitleByRuleId(alertItem?.rule_id)}</td>
                            <td>{findRuleNameByRuleId(alertItem?.rule_id)}</td>
                            <td>{findStreamNameByStreamId(alertItem?.camera_id)}</td>
                            <td>{findDeviceNameByDeviceId(alertItem?.device_id)}</td>
                            <td className='view-alert'>
                              <button onClick={() => handleViewAlert(alertItem?.alert_uuid)}>View Alert</button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                }
              </div>

              <div className='stream-statuses-box'>
                <div className='d-flex w-100 justify-content-between'>
                  <h5>Stream Status</h5>
                  {streamStatuses.length > 0 && <p className='more-info' onClick={() => history.push('/overview/stream-status')}>More Info</p>}
                </div>
                {isStreamStatusesLoading ?
                  <Loader />
                  :
                  <div className='d-flex flex-wrap'>
                    {streamStatuses.length > 0 ?
                      streamStatuses.map((item, index) => {
                        return (
                          <div className='stream-status-item' key={index}>
                            <div>
                              <div className='stream-name'>
                                {item.camera_name}
                              </div>
                              <div className='device-name'>
                                {item.device_name}
                              </div>
                            </div>
                            <div className='stream-status'>
                              {item.status !== null && item.status !== "inactive" ?
                                <>
                                  <CameraVideo size={20} color='#5A1691' />
                                  <div className='blob live'></div>
                                  <p className='live-text'>live</p>
                                </>
                                :
                                <>
                                  <CameraVideoOff size={20} color='#B7B7B7' />
                                  <p className='offline-text'>offline</p>
                                </>
                              }
                            </div>
                          </div>
                        )
                      })
                      :
                      <div className='no-stream-status-box'>
                        <ExclamationCircle size={20} color='#8020CF' />
                        <p>To check the status of your stream, you must fetch frames from your streams using the Inference Engine.</p>
                      </div>
                    }
                  </div>
                }

              </div>
            </div>
          </div>
        </Row>
      </Container>

      <AlertModal
        show={showAlertModal}
        handleClose={closeAlertModal}
        id={selectedVideoUUID}
      />
    </>
  )
}
