import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import IsEnterprise from '../components/IsEnterpriseModal';


export default function PrivateRoute({ comp: Component, roles, ...rest }) {
  const { currentUser, isAuthenticated } = useAuth()

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated === "true" ?
          roles.includes(currentUser.role) ?
            <>
              {!currentUser.isEnterprise &&
                <IsEnterprise />
              }
              <div className='d-flex all-content'>
                <Menu />
                <div className='page-content w-100'>
                  <Header />
                  <Component {...props} />
                  <Footer />
                </div>
              </div>
            </>
            :
            <Redirect to="/alerts" />
          :
          <Redirect to="/login" />
      }}>
    </Route>
  )
}