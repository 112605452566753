import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function Statistic(props) {
  return (
    <>
      {props.type === 'increase' ?
        props.old === 0 ?
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip
                {...props}
                style={{
                  position: 'absolute',
                  padding: '2px 5px',
                  color: '#595959',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #F2F1F1',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '5px',
                  width: '203px',
                  fontSize: '11px'
                }}
              >
                NAN: It is Not a Number, so cannot be calculated. You can change your period.
              </Tooltip>
            }
          >
            <div className='d-flex neutral-statictics'>
              NaN
            </div>
          </OverlayTrigger >
          :
          <div className='d-flex negative-statictics'>
            {props.value}%
          </div>
        :
        props.type === 'decrease' ?
          <div className='d-flex positive-statictics'>
            {props.value}%
          </div>
          :
          <div className='d-flex neutral-statictics'>
            {props.value}%
          </div>

      }
    </>
  )
}
