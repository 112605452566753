import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalFullScreen = (props) => {

  const closeModal = () => {
    props.handleClose()
  }

  return (
    <Modal show={props.show} onHide={closeModal} centered size='xl' fullscreen={true}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className='d-flex'>
        <img className='fullscreen-img mx-auto' src={props.url} alt="fs" />
        {props.type === 'heatmap' ?
          <div className='colorpicker'>
            <span>Min</span>
            <div className="bg"></div>
            <span>Max</span>
          </div>
          :
          null
        }
      </Modal.Body>
    </Modal>
  );
}

export default ModalFullScreen;
