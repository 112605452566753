import React, { useState, useEffect } from 'react';
import { Button, Accordion, Card } from 'react-bootstrap';
import { ChevronDown, PlusLg, ThreeDotsVertical } from 'react-bootstrap-icons';
import AddLineAreaModal from './AddLineAreaModal';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useAuth } from '../../contexts/AuthContext';
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useHistory } from "react-router-dom";
import { findDirectionNameByDirectionId, findLineNameByLineId, findStreamNameByStreamId } from '../../utils/generic';
import DeleteLineAreaModal from './DeleteLineAreaModal';
import EditLineAreaModal from './EditLineAreaModal';
import Loader from '../Loader';

export default function RealTimeSettings() {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showLineAreaModal, setShowLineAreaModal] = useState(false);
  const [showDeleteLineAreaModal, setShowDeleteLineAreaModal] = useState(false);
  const [showEditLineAreaModal, setShowEditLineAreaModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [lineAreaData, setLineAreaData] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(0);
  const [activeRow, setActiveRow] = useState(null);

  const handleOpenMenu = (index) => {
    activeDropdown !== index + 1 ? setActiveDropdown(index + 1) : setActiveDropdown(0)
  };

  const closeOpenMenus = (e) => {
    if (e.target.className !== 'line-options-item') {
      setActiveDropdown(0)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const getAreas = () => {
    setIsLoading(true)
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line_direction/',
      })
      .then(res => {
        setLineAreaData(res.data.reverse())
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getAreas()
  }, [])

  const refreshTable = () => {
    getAreas();
  }

  const handlehowDeleteLineModal = (id) => {
    setDeleteId(id);
    setShowDeleteLineAreaModal(true);
  }

  const handlehowEditLineModal = (item) => {
    setEditItem(item);
    setShowEditLineAreaModal(true);
  }

  const closeEditModal = () => {
    setShowEditLineAreaModal(false)
    setEditItem(null)
  }

  const changeActive = (i) => {
    activeRow === i ? setActiveRow(null) : setActiveRow(i)
  }

  return (
    <>
      {isLoading ?
        <Loader />
        :
        lineAreaData.length > 0 ?
          <div className='line-settings-area'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='table-header'>Line Settings</div>
              <Button className='purple-bordered-transparent-btn' onClick={() => setShowLineAreaModal(true)}><PlusLg color='#4E4576' size={20} /> Add New</Button>
            </div>

            <div className="table-headers">
              <div className="column-header">Area Name</div>
              <div className="column-header">Camera Name</div>
              <div className="column-header">Class</div>
              <div className="column-header">Line Name</div>
              <div className="column-header">Direction</div>
            </div>
            <Accordion className='area-accordion'>
              {lineAreaData.map((item, i) => {
                return (
                  <Card key={i} className={activeRow === i && 'active-card'}>
                    <Card.Header>
                      <div className="data-row">
                        <div className="data-box">{item.name}</div>
                        <div className="data-box">{findStreamNameByStreamId(item.line_group_directions[0].camera_id)}</div>
                        <div className="class-col">
                          <div className='class-box'>{item.class_title}</div>
                        </div>
                        <div className="data-box">{findLineNameByLineId(item.line_group_directions[0].line_id) + '-' + findDirectionNameByDirectionId(item.line_group_directions[0].direction_id)}</div>
                        <div className="direction-col">
                          <div className='direction-box'>{item.line_group_directions[0].line_type}</div>
                          <CustomToggle eventKey={i} changeActive={changeActive}><ChevronDown className='chevron-down' /></CustomToggle>
                          <ThreeDotsVertical className='dots' onClick={() => handleOpenMenu(i)} />
                          {activeDropdown === i + 1 ?
                            <div className='line-options-box'>
                              <div className='line-options-item' onClick={() => handlehowEditLineModal(item)}>Edit</div>
                              <div className="line-options-item" onClick={() => handlehowDeleteLineModal(item.id)}>Delete</div>
                            </div>
                            :
                            null
                          }
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                      <Card.Body>
                        {item.line_group_directions.map((directionItem, index) => {
                          if (index === 0) {
                            return;
                          }
                          else {
                            return (
                              <div className="data-row" key={index}>
                                <div className="class-col">
                                  <div className='class-box'>{item.class_title}</div>
                                </div>
                                <div className="data-box">{findLineNameByLineId(directionItem.line_id) + '-' + findDirectionNameByDirectionId(directionItem.direction_id)}</div>
                                <div className="direction-col">
                                  <div className='direction-box'>{directionItem.line_type}</div>
                                </div>
                              </div>
                            )
                          }
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </div>
          :
          <div className='add-new-box'>
            <div className='box-header'><PlusLg color='#4E4576' size={20} /> Create Area</div>
            <div className='explanation'>By creating a designated area and selecting cameras associated with this area, real-time counting is performed based on the lines and directions drawn within this area.</div>
            <Button className='purple-gradient-btn' onClick={() => setShowLineAreaModal(true)}>Add New</Button>
          </div>
      }

      <AddLineAreaModal show={showLineAreaModal} handleRefresh={refreshTable} closeModal={() => setShowLineAreaModal(false)} />
      <DeleteLineAreaModal show={showDeleteLineAreaModal} closeModal={() => setShowDeleteLineAreaModal(false)} handleRefresh={refreshTable} deleteId={deleteId} />
      <EditLineAreaModal show={showEditLineAreaModal} closeModal={closeEditModal} handleRefresh={refreshTable} item={editItem} />
    </>
  )
}

function CustomToggle({ eventKey, changeActive }) {
  const decoratedOnClick = useAccordionButton(eventKey, (e) =>
    changeActive(eventKey)
  );

  return (
    <ChevronDown className='chevron-down' onClick={decoratedOnClick} />
  );
}
