import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form
} from 'react-bootstrap';
import Select from 'react-select';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

export default function EditGroupAlertModal({ show, handleClose, handleDone, selectedGroupAlert }) {
  const history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [groupName, setGroupName] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);

  // get options from local storage
  const deviceOptions = JSON.parse(localStorage.getItem("deviceData"));
  const [streamOptions, setStreamOptions] = useState([]);
  const [alertOptions, setAlertOptions] = useState([]);

  const [initialState, setInitialState] = useState({
    name: '',
    devices: [],
    streams: [],
    alerts: []
  });

  useEffect(() => {
    if (selectedGroupAlert) {
      setGroupName(selectedGroupAlert.name);

      // set selected devices
      const selectedDeviceOptions = deviceOptions.filter(device =>
        selectedGroupAlert.filters.devices.includes(device.value)
      );
      setSelectedDevices(selectedDeviceOptions);

      // update stream options
      const selectedStreamOptions = JSON.parse(localStorage.getItem('streamData'))
        .filter(stream => selectedGroupAlert.filters.cameras.includes(stream.value));
      setSelectedStreams(selectedStreamOptions);

      // update alert options
      const selectedAlertOptions = JSON.parse(localStorage.getItem('eventData'))
        .filter(alert => selectedGroupAlert.filters.rules.includes(alert.value));
      setSelectedAlerts(selectedAlertOptions);

      // initial state
      setInitialState({
        name: selectedGroupAlert.name,
        devices: selectedDeviceOptions,
        streams: selectedStreamOptions,
        alerts: selectedAlertOptions
      });
    }
  }, [selectedGroupAlert]);

  const hasChanges = () => {
    const nameChanged = groupName !== initialState.name;
    const devicesChanged = JSON.stringify(selectedDevices) !== JSON.stringify(initialState.devices);
    const streamsChanged = JSON.stringify(selectedStreams) !== JSON.stringify(initialState.streams);
    const alertsChanged = JSON.stringify(selectedAlerts) !== JSON.stringify(initialState.alerts);

    return nameChanged || devicesChanged || streamsChanged || alertsChanged;
  };

  useEffect(() => {

    const isNameValid = groupName.trim() !== '';
    const hasValidChanges = hasChanges();

    setIsSaveDisabled(!isNameValid || !hasValidChanges || groupName.length > 120);
  }, [groupName, selectedDevices, selectedStreams, selectedAlerts]);

  const handleDeviceChange = (selectedOptions) => {
    setSelectedDevices(selectedOptions || []);
    // clear selected streams and alerts when device changes
    setSelectedStreams([]);
    setSelectedAlerts([]);
  };

  const handleStreamChange = (selectedOptions) => {
    setSelectedStreams(selectedOptions || []);
    // clear selected alerts when stream changes
    setSelectedAlerts([]);
  };

  const handleAlertChange = (selectedOptions) => {
    setSelectedAlerts(selectedOptions || []);
  };

  const handleSave = () => {
    const data = {
      user_id: parseInt(localStorage.getItem('userId')),
      name: groupName,
      filters: {
        devices: selectedDevices.map(d => d.value),
        cameras: selectedStreams.map(s => s.value),
        rules: selectedAlerts.map(a => a.value)
      }
    };

    fast_api_axios({
      method: 'patch',
      url: `/api/v1/presets/${selectedGroupAlert.id}`,
      data: data
    })
      .then(res => {
        updateMessage('Group alert updated successfully.', 'success');
        handleDone();
        handleClose();
      })
      .catch(error => {
        if (error.response?.data?.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login');
        } else {
          updateMessage(error.response?.data?.detail || 'An error occurred', 'error');
        }
      });
  };

  return (
    <Modal className='add-report-modal' show={show} onHide={handleClose} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">Edit Group Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label className='select-asset-label'>Group Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Write Group Name"
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
            isInvalid={groupName.length > 120}
          />
          <Form.Control.Feedback type="invalid">Group name must be less than 120 characters.</Form.Control.Feedback>
        </Form.Group>

        <p className="select-asset-label">Device</p>
        <Select
          isMulti
          closeMenuOnSelect={false}
          name="deviceOptions"
          components={{
            DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ?
              <CaretUpFill className='select-icon me-2' /> :
              <CaretDownFill className='select-icon me-2' />
          }}
          options={deviceOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Device"
          onChange={handleDeviceChange}
          value={selectedDevices}
        />

        <p className="select-asset-label">Stream</p>
        <Select
          isMulti
          closeMenuOnSelect={false}
          name="streamOptions"
          components={{
            DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ?
              <CaretUpFill className='select-icon me-2' /> :
              <CaretDownFill className='select-icon me-2' />
          }}
          options={JSON.parse(localStorage.getItem('streamData'))}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Stream"
          onChange={handleStreamChange}
          value={selectedStreams}
        />

        <p className="select-asset-label">Alert Type</p>
        <Select
          isMulti
          closeMenuOnSelect={false}
          name="alertOptions"
          components={{
            DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ?
              <CaretUpFill className='select-icon me-2' /> :
              <CaretDownFill className='select-icon me-2' />
          }}
          options={JSON.parse(localStorage.getItem('eventData'))}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Alert Type"
          onChange={handleAlertChange}
          value={selectedAlerts}
        />
      </Modal.Body>
      <Modal.Footer>
        {isSaveDisabled ? (
          <Button className="add-report-disabled-btn w-50">Save</Button>
        ) : (
          <Button className="add-report-active-btn w-50" onClick={handleSave}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
