import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import Loader from '../Loader';
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Chart from "react-apexcharts";
import momentTimezone from 'moment-timezone';
import { findStreamNameByStreamId } from '../../utils/generic';

const pieColors = [
  '#492CE6',
  '#22FCDB',
  '#60EFFF',
  '#FF00F8',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#26909C'
]

export default function StaffDwellModal(props) {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [averageDwellTime, setAverageDwellTime] = useState(0);
  const [staffData, setStaffData] = useState([])

  const [chartLabels, setChartLabels] = useState([]);
  const [chartDataset, setChartDataset] = useState([]);
  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  const closeModal = () => {
    setIsLoading(true)
    props.handleClose();
  }

  const getStaffData = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/eyewatch/dwell_detail/' + props.selectedId,
      })
      .then(res => {
        let data = [{
          name: "Dwell Time",
          data: res.data.result.reverse().map(a => a.avg_dwell_time),
          fill: false,
          borderColor: pieColors[0],
          backgroundColor: pieColors[0],
          tension: 0.1
        }]

        let labels = res.data.result.map(a => moment(a.exit_time).format('HH:mm:ss'))

        setChartDataset(data)
        setChartLabels(labels)
        setStaffData(res.data.result)
        setAverageDwellTime(displayHourFormat(res.data.avg_dwell_time))

        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    props.selectedId !== null && getStaffData()
  }, [props.selectedId])

  const displayHourFormat = totalSeconds => {
    let totalSecondsRounded = Math.round(totalSeconds)
    let hours = Math.floor(totalSecondsRounded / 3600);
    totalSecondsRounded = totalSecondsRounded % 3600;
    let minutes = Math.floor(totalSecondsRounded / 60);
    let seconds = totalSecondsRounded % 60;

    return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
  }

  return (
    <Modal show={props.show} onHide={closeModal} size='xl' centered className='staff-dwell-detail-modal'>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">{findStreamNameByStreamId(props.selectedId)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ?
          <Loader />
          :
          <>
            <p className='average-time'>Average Daily Dwell Time: <span>{averageDwellTime}</span></p>

            <Chart
              options={{
                chart: {
                  animations: {
                    enabled: false,
                  },
                  fontFamily: 'Lato, sans-serif',
                  type: 'line',
                  toolbar: {
                    show: false
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'straight',
                  width: 3
                },
                grid: {
                  row: {
                    colors: ['transparent']
                  },
                  padding: {
                    top: 0,
                    right: 20,
                    bottom: 50,
                    left: 20
                  },
                },

                xaxis: {
                  categories: lineData.labels,
                  tooltip: {
                    formatter: function (value, opts) {
                      const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                      if (v === undefined || v === '') {
                        return ''
                      } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                        return opts.w.globals.categoryLabels[opts.dataPointIndex]
                      } else {
                        return v;
                      }
                    },
                  },
                  labels: {
                    rotate: -70,
                    style: {
                      fontSize: '11px',
                      fontWeight: 400,
                    },
                    rotateAlways: true,
                    formatter: function (value) {
                      return value;
                    }
                  }
                },
                yaxis: {
                  title: {
                    text: 'Dwell Time'
                  }
                },
                tooltip: {
                  fixed: {
                    enabled: false
                  },
                  enabled: true,
                  custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                    const hoverIndexes = w.globals.seriesX.map(seriesX => {
                      return seriesX.findIndex(xData => xData === hoverXaxis);
                    });

                    let hoverList = '';
                    hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                      if (hoverIndex >= 0) {
                        hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    });

                    return `<div class="card">
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                  }
                },
                colors: pieColors,
                legend: {
                  showForSingleSeries: false
                },
              }}
              series={
                lineData.datasets
              }
              height={350}
              type="line"
            />
            <div className='scrollableTable'>
              <Table className='modal-time-table'>
                <thead>
                  <tr>
                    <th>ENTER TIME</th>
                    <th>EXIT TIME</th>
                    <th>DWELL TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {staffData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{moment(item.enter_time).format('MM/DD/YYYY HH:mm:ss')}</td>
                        <td>{moment(item.exit_time).format('MM/DD/YYYY HH:mm:ss')}</td>
                        <td>{displayHourFormat(item.avg_dwell_time)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </>
        }
      </Modal.Body>
    </Modal>
  )
}
