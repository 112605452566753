import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import ResendButton from './ResendButton';
import DeleteIcon from '../../assets/images/trash.svg';

export default function AddUserModal(props) {
  let history = useHistory();
  const { logout, currentUser } = useAuth();
  const { updateMessage } = useToast()
  const [userEmail, setUserEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [usersToAdd, setUsersToAdd] = useState(props.addedUserList ? props.addedUserList : []);
  const [isLoading, setIsLoading] = useState(false);
  const [isUsersChanged, setIsUsersChanged] = useState(false);

  const closeModal = () => {
    clearInputFields();
    setIsUsersChanged(false)
    if (props.addedUserList.length !== usersToAdd.length) {
      setUsersToAdd(props.addedUserList);
      return handleAddUserDone();
    }
    props.handleClose();
  }

  const handleAddUserDone = () => {
    setIsUsersChanged(false)
    clearInputFields();
    props.handleDone(props.type);
  }

  const validateEmail = (email) => {
    return email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const handleUserEmailChange = (e) => {
    setUserEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      setIsEmailValid(true);
    }
    else {
      setIsEmailValid(false);
    }
    if (e.target.value === '') {
      setIsEmailValid(true);
    }
  }

  const addUserToList = (userData) => {
    setUsersToAdd([...usersToAdd, userData]);
    setIsEmailValid(true);
    setUserEmail('');
    setIsUsersChanged(true)
  }

  const removeUserFromList = (id) => {
    let addedToList = usersToAdd.filter(item => item.id !== id);
    setUsersToAdd(addedToList);
    setIsUsersChanged(true)
  }

  const clearInputFields = () => {
    setUserEmail('');
    setIsEmailValid(true);
  }

  const addUser = () => {
    setIsLoading(true);
    let api_url = props.type === 'alert' ? '/api/v1/setting/alert/user' : '/api/v1/setting/report/user'
    fast_api_axios(
      {
        method: 'put',
        url: api_url,
        data: {
          "setting_id": props.settingId,
          "email": userEmail
        }
      })
      .then(res => {
        addUserToList(res.data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const removeUser = (user_id) => {
    setIsLoading(true);
    let api_url = props.type === 'alert' ? '/api/v1/setting/alert/' : '/api/v1/setting/report/'
    fast_api_axios(
      {
        method: 'delete',
        url: api_url + props.settingId + '/user/' + user_id,
      })
      .then(res => {
        removeUserFromList(user_id);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    if (props.show) {
      setUsersToAdd(props.addedUserList)
    }
  }, [props.show])

  return (
    <Modal className='settings-modal' show={props.show} onHide={closeModal} centered>
      <Modal.Header className='text-center mb-4' closeButton>
        <Modal.Title className="w-100">ADD USER</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label className='mb-2'>User E-mail</Form.Label>
        <InputGroup>
          <Form.Control type="email" placeholder='Add user e-mail' disabled={usersToAdd.length === 5} value={userEmail} onChange={(e) => handleUserEmailChange(e)}></Form.Control>
          <InputGroup.Text className={`add-emails-btn ${(userEmail === '' || !isEmailValid) && 'disabled'}`} onClick={addUser}>
            Add
          </InputGroup.Text>
        </InputGroup>
        {isEmailValid ? null : <p className='text-danger mb-0 mt-2'>Please enter a valid email.</p>}

        {isLoading ?
          <Loader />
          :
          <>
            {usersToAdd.length > 0 ?
              <>
                <Form.Label className='added-emails-label mt-5 mb-0'>Added E-mails</Form.Label>
                {usersToAdd.map((item, index) => {
                  return (
                    <div className='w-100 d-flex justify-content-between align-items-center' key={index}>
                      <div className='d-flex align-items-center w-50'>
                        {
                          !item.is_accepted ?
                            <div className='email-field not-accepted'>
                              {item.email}
                              <div className='ex-circle ms-2'>
                                <ExclamationCircleFill size={15} color="#EB5857" />
                                <div className="tooltiptext">
                                  Waiting for the users's email confirmation.
                                </div>
                              </div>
                            </div>
                            :
                            <div className='email-field '>
                              {item.email}
                            </div>
                        }

                      </div>
                      <div className='d-flex align-items-center'>
                        {!item.is_accepted &&
                          <ResendButton email={item.email} emailCode={item.email_code} />
                        }
                        {
                          item.email !== currentUser.name &&
                          <img className='remove-btn' src={DeleteIcon} alt='delete-mail' onClick={() => removeUser(item.id)}/>
                        }

                      </div>
                    </div>
                  )
                })}
              </>
              :
              null
            }
          </>
        }

        {usersToAdd.length === 5 && <div className='red-text w-100 text-center mt-5'>Max. 5 people allowed.</div>}

        <Button className={`mt-5 w-100  ${(isEmailValid && userEmail !== '') || (isUsersChanged === true) ? 'create-group-btn-active' : 'create-group-btn'}  `} onClick={handleAddUserDone}>Save</Button>


      </Modal.Body>
    </Modal>
  )
}
