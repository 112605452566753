import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ExclamationCircleFill } from 'react-bootstrap-icons';

export default function NoDataModal(props) {

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Body>
        <div className='d-flex px-3 pt-5'>
          <ExclamationCircleFill size={32} color="#8020CF" />
          <p className='ms-3 modal-no-data-text'>There is no data between the dates you selected, please change the filter and try again!</p>
        </div>
        <div className='d-flex align-items-center justify-content-end'>
          <p className='close-no-data-button mt-3' onClick={props.handleClose}>Close</p>
        </div>
      </Modal.Body>
    </Modal>
  )
}
