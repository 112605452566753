import React, { useState, useRef, useEffect } from "react";
import { Modal, Carousel, Button } from "react-bootstrap";
import Slide1 from "../../assets/images/slide1.jpg";
import Slide2 from "../../assets/images/slide2.jpg";
import Slide3 from "../../assets/images/slide3.jpg";
import Slide4 from "../../assets/images/slide4.jpg";
import backicon from "../../assets/images/backicon.svg";
import nexticon from "../../assets/images/nexticon.svg";

const ModalFullScreen = (props) => {
  const [index, setIndex] = useState(0);
  const ref = useRef(null);
  const handleSelect = (selectedIndex, e) => {
    console.log(selectedIndex, e)
    setIndex(selectedIndex);
  };

  useEffect(()=>{
    setTimeout(localStorage.setItem('creatingdata', false), 5000)
  }, [])

  const closeModal = () => {
    props.handleClose();
    setIndex(0)
  };

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    if (index === 3 ) {
      doneClick();

    } else {
      ref.current.next();
    }
  };

  const doneClick = () => {
    closeModal();
    // localStorage.setItem('creatingdata', true);
    // window.location.reload();
  };

  return (
    <Modal
      show={props.show}
      onHide={closeModal}
      centered
      size="xl"
      fullscreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-left">Chooch Inference Engine</Modal.Title>
      </Modal.Header>
      <div className="modal-pagination">{index + 1 }/4</div>
      <Modal.Body className="d-flex modalTutorial">
        <div className="row">
          <div className="col-md-12">
            <Carousel
              ref={ref}
              interval={null}
              controls={false}
              pause={false}
              onSelect={handleSelect}
              autoPlay={false}
            >

              <Carousel.Item>
                <img className="d-block w-100" src={Slide1} alt="slide1" />
                <div className="modalTutorialdiv">
                  <p>Go to your Inference Engine and click ‘Predictions’ </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={Slide2} alt="slide2" />
                <div className="modalTutorialdiv">
                  <p>Click the stream that you want to captura a frame</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={Slide3} alt="Slide3" />
                <div className="modalTutorialdiv">
                  <p>Click ‘Fetch Frame’ to capture a frame from the stream</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={Slide4} alt="Slide4" />
                <div className="modalTutorialdiv">
                  <p>Click ‘Send to Cloud’ button. After that you’ll be able to get the prediction data in Smart Analytics within 1-2 hours.</p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-md-12">
              <div className="pagination-buttons">
                <Button className={index === 0 ? "hide" : "purple-button prev me-3"} onClick={onPrevClick} >
                  <img
                    src={backicon}
                    alt="backicon"
                    className="backicon"
                  />
                  Back
                </Button>
                <Button
                 className="purple-button next"
                 onClick={onNextClick}>
                 {index === 3 ? 'Done' : <>Next <img src={nexticon} alt="nexticon" className="nexticon" /></>}
                </Button>
              </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalFullScreen;
