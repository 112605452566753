import React, { useEffect, useState } from 'react';
import { Container, Row, Form, Table, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { ChevronRight, Calendar2, Search, X } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import 'moment-duration-format';
import Pagination from '../../utils/Pagination';
import useWebSocket from 'react-use-websocket';
import noStock from '../../assets/images/noStock.svg'
import criticalStock from '../../assets/images/criticalStock.svg'
import lowStock from '../../assets/images/lowStock.svg'
import mediumStock from '../../assets/images/mediumStock.svg'
import highStock from '../../assets/images/highStock.svg'
import overfillStock from '../../assets/images/overfillStock.svg'
import period from '../../assets/images/period.svg'
import warning from '../../assets/images/warning.svg'
import { findStreamNameByStreamId, findDeviceNameByDeviceId } from '../../utils/generic';
import Switch from "react-switch";
import TimeBasedModal from './TimeBasedModal';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ExportModal from './ExportModal';

export default function InventoryManagement() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();

  const [inventoryData, setInventoryData] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [no, setNo] = useState(0);
  const [critical, setCritical] = useState(0);
  const [low, setLow] = useState(0);
  const [medium, setMedium] = useState(0);
  const [high, setHigh] = useState(0);
  const [overfill, setOverfill] = useState(0);
  const [inventoryToSearch, setInventoryToSearch] = useState([]);
  const [showLower, setShowLower] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedBoxFilter, setSelectedBoxFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [showTimeBasedModal, setShowTimeBasedModal] = useState(false);
  const [selectedItemId, setselectedItemId] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);

  const activeToken = localStorage.getItem('fast_api_token');
  const alertSocketUrl = process.env.REACT_APP_WS_BASE_URL + "/ws/stock_socket?token=" + activeToken
  const [alertWebSocketUrl, setAlertWebSocketUrl] = useState(alertSocketUrl);

  const { sendMessage: sendAlertSocketMessage, lastMessage: lastAlertSocketMessage, readyState: alertSocketReadyState, getWebSocket } = useWebSocket(alertWebSocketUrl, {
    onOpen: () => console.log('Alert WebSocket connected!'),
    shouldReconnect: (closeEvent) => closeEvent.code !== 1005 && closeEvent.code !== 1006 && closeEvent.code !== 4001,
    reconnectAttempts: 10,
    reconnectInterval: 1000,
    onMessage: (event) => {
      const data = JSON.parse(event.data);
      data.result['0-25'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['25-50'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['50-75'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['75-100'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      data.result['overfill'].forEach(element => {
        element.stream_name = findStreamNameByStreamId(element.camera_id)
        element.device_name = findDeviceNameByDeviceId(element.device_id)
      });
      setInventoryData(data.result)
      setInventories(data.result['0-25'].concat(data.result['25-50']).concat(data.result['50-75']).concat(data.result['75-100']).concat(data.result['overfill']))
      setInventoryToSearch(data.result['0-25'].concat(data.result['25-50']).concat(data.result['50-75']).concat(data.result['75-100']).concat(data.result['overfill']))
    },
    onError: (event) => {
      console.log('Alert Socket Connection Status Error: ', event);
    },
    onClose: (event) => {
      if (event.code === 1005) {
        console.log(`Alert Socket Connection Status: Closed`);
      }

      if (event.code === 1006) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }

      if (event.code === 4001) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }
    },
  });


  useEffect(() => {
    setNo(0);
    setCritical(inventoryData['0-25'] ? inventoryData['0-25'].length : 0);
    setLow(inventoryData['25-50'] ? inventoryData['25-50'].length : 0);
    setMedium(inventoryData['50-75'] ? inventoryData['50-75'].length : 0);
    setHigh(inventoryData['75-100'] ? inventoryData['75-100'].length : 0);
    setOverfill(inventoryData['overfill'] ? inventoryData['overfill'].length : 0)
  }, [inventoryData])

  useEffect(() => {
    filterTable(showLower, true, selectedBoxFilter)
  }, [showLower, searchText, selectedBoxFilter])

  useEffect(() => {
    filterTable(showLower, false, selectedBoxFilter)
  }, [inventoryToSearch])

  const filterTable = (isShowLower, changePageNum, selectedBoxFilterTitle) => {
    if (changePageNum) {
      changePage(1)
    }

    let showLowerResult = inventoryToSearch;
    if (isShowLower) {
      showLowerResult = inventoryToSearch.filter(i => i.status === 'alert');
    }
    let boxFilterResult = showLowerResult;

    if (selectedBoxFilter === 'all') {
      boxFilterResult = showLowerResult;
    }
    else {
      boxFilterResult = showLowerResult.filter(i => i.class_title === selectedBoxFilterTitle);
    }

    let searchResult = boxFilterResult.filter(
      obj => Object.values(obj).some(
        val => typeof val === "string" && val.toLowerCase().includes(searchText.toLowerCase())
      )
    );

    setInventories(searchResult)
  }

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const openTimeBasedModal = (sku) => {
    setselectedItemId(sku)
    setShowTimeBasedModal(true)
  }

  const closeTimeBasedModal = () => {
    setShowTimeBasedModal(false)
  }

  const closeExportModal = () => {
    setShowExportModal(false);
  }

  const ExportToExcel = () => {
    let arrayObj = inventories.map(item => {
      return {
        status: item.class_title === '0' ? 'No Stock' : item.class_title === '0-25' ? 'Critical Stock' : item.class_title === '25-50' ? 'Low Stock' : item.class_title === '50-75' ? 'Medium Stock' : item.class_title === '75-100' ? 'High Stock' : 'Overfilled Stock',
        product_name: item.sku,
        sku: item.name,
        stream: item.camera_name || item.stream_name,
        device: item.device_name,
        approx_unit: item.class_count,
        min_max: item.min_max,
      };
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(arrayObj);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'InventoryData-' + moment().format('MM/DD/YYYY-HH:mm') + fileExtension);
  }

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Inventory Management</span>
            </p>

            <p className='end-date-box'>
              <Calendar2 size={20} />
              {moment().format('MM/DD/YYYY HH:mm')}
            </p>
          </div>

          <div className='page-wrapper'>
            <div className='d-flex'>
              <div className={`all-products-box ${selectedBoxFilter === 'all' && 'active'}`} onClick={() => setSelectedBoxFilter('all')}>
                <div className='all-products-box-header'>
                  # All Products
                </div>
                <div className='all-products-box-count'>
                  {inventoryData['0-25'] ? inventoryData['0-25'].length + inventoryData['25-50'].length + inventoryData['50-75'].length + inventoryData['75-100'].length + inventoryData['overfill'].length : 0}
                </div>
              </div>
              <div className={`stock-box darkRed ${selectedBoxFilter === '0' && 'active'}`}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={noStock} alt="noStock" />
                  <p className='stock-box-header'>No Stock</p>
                </div>
                <div className='stock-count'>{no}</div>
              </div>
              <div className={`stock-box red ${selectedBoxFilter === '0-25' && 'active'}`} onClick={() => setSelectedBoxFilter('0-25')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={criticalStock} alt="criticalStock" />
                  <p className='stock-box-header'>Critical Stock</p>
                </div>
                <div className='stock-count'>{critical}</div>
              </div>
              <div className={`stock-box orange ${selectedBoxFilter === '25-50' && 'active'}`} onClick={() => setSelectedBoxFilter('25-50')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={lowStock} alt="lowStock" />
                  <p className='stock-box-header'>Low Stock</p>
                </div>
                <div className='stock-count'>{low}</div>
              </div>
              <div className={`stock-box yellow ${selectedBoxFilter === '50-75' && 'active'}`} onClick={() => setSelectedBoxFilter('50-75')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={mediumStock} alt="mediumStock" />
                  <p className='stock-box-header'>Medium Stock</p>
                </div>
                <div className='stock-count'>{medium}</div>
              </div>
              <div className={`stock-box green ${selectedBoxFilter === '75-100' && 'active'}`} onClick={() => setSelectedBoxFilter('75-100')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={highStock} alt="highStock" />
                  <p className='stock-box-header'>High Stock</p>
                </div>
                <div className='stock-count'>{high}</div>
              </div>
              <div className={`stock-box darkGreen ${selectedBoxFilter === 'overfill' && 'active'}`} onClick={() => setSelectedBoxFilter('overfill')}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={overfillStock} alt="overfillStock" />
                  <p className='stock-box-header'>Overfilled</p>
                </div>
                <div className='stock-count'>{overfill}</div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div className='switch-box'>
                <Switch
                  className='me-2'
                  offColor='#9B9B9B'
                  onColor='#8020CF'
                  height={20}
                  width={40}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={showLower}
                  onChange={() => setShowLower(!showLower)}
                />
                <div className='switch-text'>{showLower ? 'ON' : 'OFF'}</div>
                <div className='switch-exp'>Show products lower than expected</div>
              </div>

              <div className='inventory-search-group'>
                <Search className='search-icon' />
                <Form.Control placeholder="Search in Product Details" className='inventory-search-box my-3' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                {searchText.length > 0 && <X size={24} color='#535353' className='delete-icon' onClick={(e) => setSearchText('')} />}
              </div>
              <Button className='time-based-usage-btn' onClick={() => history.push('/dashboards/inventory-management/time-based-usage')}>Product Turnover</Button>
              {/*<Button className='time-based-usage-btn' onClick={ExportToExcel}>Export Table</Button>*/}
              <Button className='time-based-usage-btn' onClick={() => setShowExportModal(true)}>Export</Button>
            </div>

            <Table className='inventory-table'>
              <thead>
                <tr>
                  <th>STATUS</th>
                  <th>PRODUCT NAME</th>
                  <th>SKU NUMBER</th>
                  <th>STREAM</th>
                  <th>DEVICE</th>
                  <th>APPROX UNIT<br></br><span>Min-Max Value</span></th>
                  <th>TIME-BASED USAGE</th>
                </tr>
              </thead>
              <tbody>
                {inventories.slice(0 + ((currentPage - 1) * 10), currentPage * 10).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          item.class_title === '0' ?
                            <div className='no'>
                              <img src={noStock} alt="noStock" />
                              &nbsp; No Stock &nbsp;
                              {item.status === "alert" &&
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Popover id="warning-tooltip" arrowProps={null}>
                                      Lower than expected
                                    </Popover>
                                  }
                                >
                                  <img src={warning} alt="warning" />
                                </OverlayTrigger>
                              }
                            </div>
                            :
                            item.class_title === '0-25' ?
                              <div className='critical'>
                                <img src={criticalStock} alt="criticalStock" />
                                &nbsp; Critical Stock &nbsp;
                                {item.status === "alert" &&
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Popover id="warning-tooltip" arrowProps={null}>
                                        Lower than expected
                                      </Popover>
                                    }
                                  >
                                    <img src={warning} alt="warning" />
                                  </OverlayTrigger>
                                }
                              </div>
                              :
                              item.class_title === '25-50' ?
                                <div className='low'>
                                  <img src={lowStock} alt="lowStock" />
                                  &nbsp; Low Stock &nbsp;
                                  {item.status === "alert" &&
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Popover id="warning-tooltip" arrowProps={null}>
                                          Lower than expected
                                        </Popover>
                                      }
                                    >
                                      <img src={warning} alt="warning" />
                                    </OverlayTrigger>
                                  }
                                </div>
                                :
                                item.class_title === '50-75' ?
                                  <div className='medium'>
                                    <img src={mediumStock} alt="mediumStock" />
                                    &nbsp;Medium Stock &nbsp;
                                    {item.status === "alert" &&
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Popover id="warning-tooltip" arrowProps={null}>
                                            Lower than expected
                                          </Popover>
                                        }
                                      >
                                        <img src={warning} alt="warning" />
                                      </OverlayTrigger>
                                    }
                                  </div>
                                  :
                                  item.class_title === '75-100' ?
                                    <div className='high'>
                                      <img src={highStock} alt="highStock" />
                                      &nbsp; High Stock &nbsp;
                                      {item.status === "alert" &&
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Popover id="warning-tooltip" arrowProps={null}>
                                              Lower than expected
                                            </Popover>
                                          }
                                        >
                                          <img src={warning} alt="warning" />
                                        </OverlayTrigger>
                                      }
                                    </div>
                                    :
                                    <div className='high'>
                                      <img src={overfillStock} alt="overfillStock" />
                                      &nbsp; Overfilled Stock &nbsp;
                                      {item.status === "alert" &&
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Popover id="warning-tooltip" arrowProps={null}>
                                              Lower than expected
                                            </Popover>
                                          }
                                        >
                                          <img src={warning} alt="warning" />
                                        </OverlayTrigger>
                                      }
                                    </div>
                        }
                      </td>
                      <td className='name' title={item.sku}>{item.sku}</td>
                      <td>{item.name}</td>
                      <td>{item.camera_name || item.stream_name}</td>
                      <td>{item.device_name}</td>
                      <td>{item.class_count}<br></br><span>{item.min_max}</span></td>
                      <td onClick={() => openTimeBasedModal(item.sku)}><img src={period} alt="period" /></td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            <div className="paginationBlock">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={inventories.length}
                pageSize={10}
                showItemsPerPage={false}
                onPageChange={(page) => changePage(page)}
              />
            </div>
          </div>
        </div>
      </Row>

      <TimeBasedModal show={showTimeBasedModal} id={selectedItemId} handleClose={closeTimeBasedModal} />
      <ExportModal show={showExportModal} handleClose={closeExportModal} />
    </Container>
  )
}
