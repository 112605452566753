import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import Select from 'react-select';
import CustomTimePickerWithMin from '../CustomTimePickerWithMin';
import CustomTimePickerHourly
  from '../CustomTimePickerHourly';

const alertOptions = [
  {
    label: 'All Alerts',
    value: -1
  }
]

const weeklyOptions = [
  {
    label: 'Monday',
    value: 'Monday'
  },
  {
    label: 'Tuesday',
    value: 'Tuesday'
  },
  {
    label: 'Wednesday',
    value: 'Wednesday'
  },
  {
    label: 'Thursday',
    value: 'Thursday'
  },
  {
    label: 'Friday',
    value: 'Friday'
  },
  {
    label: 'Saturday',
    value: 'Saturday'
  },
  {
    label: 'Sunday',
    value: 'Sunday'
  }
]

export default function AddPeriodicReportModal(props) {
  let history = useHistory();
  const { logout, currentUser } = useAuth();
  const { updateMessage } = useToast()
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const deviceOptions = JSON.parse(localStorage.getItem("deviceData"));
  const [streamOptions, setStreamOptions] = useState(JSON.parse(localStorage.getItem("streamData")));
  const [eventOptions, setEventOptions] = useState(JSON.parse(localStorage.getItem("allEventData")));

  const [newStreamOptions, setNewStreamOptions] = useState(JSON.parse(localStorage.getItem("streamData")));
  const [newEventOptions, setNewEventOptions] = useState(JSON.parse(localStorage.getItem("allEventData")));

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedReportAlert, setSelectedReportAlert] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedWeekly, setSelectedWeekly] = useState([]);

  const [selectedReportTimes, setSelectedReportTimes] = useState(null);

  const closeModal = () => {
    setSelectedDevice(null);
    setSelectedStream(null);
    setSelectedFrequency(null);
    setSelectedReportTimes(null);
    props.handleClose();
  }

  const handleAddAlert = (device, stream, reportAlert, frequency, reportTimes, weeklyDays) => {
    props.handleDone(device, stream, reportAlert, frequency, reportTimes, weeklyDays);
    closeModal();
  }

  useEffect(() => {
    if (selectedDevice !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedDeviceData = assets.find(d => d.device_id === selectedDevice.value)

      let newStreamOptions = [];
      selectedDeviceData.cameras.forEach(element => {
        newStreamOptions.push(
          {
            value: element.camera_id,
            label: element.camera_name
          }
        )
      });
      setNewStreamOptions(newStreamOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice])

  useEffect(() => {

    if (selectedReportAlert !== null) {
      setSelectedReportAlert(null)
    }

    if (selectedStream !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedStreamData = assets.find(d => d.device_id === selectedDevice.value).cameras.find(c => c.camera_id === selectedStream.value)

      let newAlertOptions = [];
      selectedStreamData.zones.forEach(element => {
        element.rules.forEach(ruleElement => {
          if (!newAlertOptions.some(a => a.value === ruleElement.rule_id)) {
            // if class title is empty then add only event
            newAlertOptions.push(
              {
                value: ruleElement.rule_id,
                label: ruleElement.event + (ruleElement.class_title ? ' - ' + ruleElement.class_title : '')
              }
            )
          }
        })
      });

      let allAlertOptions = [];
      assets.forEach(device => {
        device.cameras.forEach(camera => {
          camera.zones.forEach(zone => {
            zone.rules.forEach(event => {
              // if class title is empty then add only event
              allAlertOptions.push(
                {
                  value: event.rule_id,
                  label: event.event + (event.class_title ? ' - ' + event.class_title : '')
                }
              )
            })
          })
        })
      })

      setEventOptions(allAlertOptions)
      setNewEventOptions([...alertOptions, ...newAlertOptions])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStream])
  useEffect(() => {
    if (selectedFrequency !== null || (selectedFrequency?.value === 'DAILY' && selectedReportTimes !== null) || (selectedFrequency?.value === 'Weekly' && selectedWeekly.length > 0)) {
      setIsSaveDisabled(false)
    }
    else{
      setIsSaveDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFrequency])



  /*const createAlertSetting = () => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/alert',
        data: {
          "rule_id": selectedEvent.value,
          "camera_id": selectedStream.value,
          "device_id": selectedDevice.value,
        }
      })
      .then(res => {
        /!*setIsAddAlertActive(false);
        getAlertSettings();*!/
        clearAddAlertFields();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        if (error.response.data.detail === 'Rule id already exists') {
          updateMessage('Alert email already exists.', 'error');
          clearAddAlertFields();
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }*/

  /*const handleAddAlertDone = (event, stream, device) => {
    console.log('as', event, stream, device);
    props.handleDone(event, stream, device);
    closeModal();
  }*/

  const handleSelectedDevice = (item) => {
    setSelectedDevice(item);
    setSelectedStream(null);
    setSelectedReportAlert(null);
    setSelectedFrequency(null);
    setSelectedReportTimes(null);
    setSelectedWeekly(null);
  }

  return (
    <Modal className='add-report-modal' show={props.show} onHide={closeModal} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">
          Add Periodic Report
        </Modal.Title>

      </Modal.Header>
      <span style={{textAlign: 'center'}}> You can add periodic report for all alerts.</span>
      <Modal.Body>
        <p className="select-asset-label">Device</p>
        <Select
          name="deviceOptions"
          options={deviceOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Device"
          onChange={(item) => handleSelectedDevice(item)}
          value={selectedDevice}
        />

        <p className="select-asset-label">Stream</p>
        <Select
          isDisabled={selectedDevice === null}
          name="streamOptions"
          options={newStreamOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Stream"
          onChange={(item) => setSelectedStream(item)}
          value={selectedStream}
        />
        <p className="select-asset-label">Alert Type</p>
        <Select
          isDisabled={selectedStream === null}
          name="alertOptions"
          options={newEventOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Alert Type"
          onChange={(item) => setSelectedReportAlert(item)}
          value={selectedReportAlert === null ? null : newEventOptions.find(e => e.value === selectedReportAlert?.value)}
        />
        <p className="select-asset-label">Report Frequency</p>
        <Select
          isDisabled={selectedReportAlert === null}
          name="alertOptions"
          options={props.frequency}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Frequency"
          onChange={(item) => setSelectedFrequency(item)}
          value={selectedReportAlert === null ? null : props.frequency.find(f => f.value === selectedFrequency?.value)}
        />
        {/* if frequency is daily */}
        {
          selectedFrequency?.value === 'DAILY' &&
          <>
            <p className="select-asset-label">Report Time <i>(UTC)</i>
            </p>
            <CustomTimePickerHourly
              selectedReportTimes={selectedReportTimes}
              handleSelectedReportTimes={setSelectedReportTimes}
              selectedFrequency={selectedFrequency}
            />
          </>
        }
        {
          selectedFrequency?.value === 'WEEKLY' &&
          <>
            <p className="select-asset-label">Report Day <i>(07:00 AM UTC)</i></p>
            <Select
              isDisabled={selectedReportAlert === null}
              name="weeklyOptions"
              options={weeklyOptions}
              className="basic-single table-select"
              classNamePrefix="select"
              placeholder="Select a Day"
              onChange={(item) => setSelectedWeekly(item?.value)}
              value={weeklyOptions.find(w => w.value === selectedWeekly)}
            />
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {isSaveDisabled ?
          <Button className="add-report-disabled-btn w-50">Save</Button>
          :
          <Button className="add-report-active-btn w-50" onClick={() => handleAddAlert(selectedDevice, selectedStream, selectedReportAlert, selectedFrequency, selectedReportTimes, selectedWeekly)}>Save</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}
