import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { fast_api_axios } from '../../utils/axiosFast';
import { DatePicker as RDatePicker } from 'rsuite';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
//import { isAfter } from 'rsuite/esm/utils/dateUtils';

export default function ExportModal(props) {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { updateMessage } = useToast();
  const { logout } = useAuth();
  const [exportDate, setExportDate] = useState(new Date(moment().format('MM/DD/YYYY HH:mm')))
  const [timeSlot, setTimeSlot] = useState(0);
  const [selectedExportDate, setSelectedExportDate] = useState(null)

  const selectTimeSlot = (slt) => {
    setExportDate(new Date(moment().format('MM/DD/YYYY HH:mm')))
    setTimeSlot(slt)
    slt === 9 ?
      setSelectedExportDate(moment().set('hour', 9).startOf('hour').format('MM/DD/YYYY HH:mm'))
      :
      slt === 12 ?
        setSelectedExportDate(moment().set('hour', 12).startOf('hour').format('MM/DD/YYYY HH:mm'))
        :
        setSelectedExportDate(moment().set('hour', 15).startOf('hour').format('MM/DD/YYYY HH:mm'))

  }

  const handleExport = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/report_page/export',
        params: {
          "date": moment(selectedExportDate).unix()
        }
      })
      .then(res => {
        const link = document.createElement('a');
        link.download = 'report.txt';
        link.href = 'data:text/plain;charset=utf-8,' + encodeURI(res.data);
        link.click();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const closeModal = () => {
    props.handleClose();
  }

  return (
    <Modal show={props.show} onHide={closeModal} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title className='genericModalTitle'>EXPORT</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className='d-flex align-items-center justify-content-between'>
          <div className='option-box'>
            <div className='option-box-header'>
              Select Time Slot
            </div>
            <div className='d-flex justify-content-between'>
              <div className={`time-slot ${timeSlot === 9 && 'active'}`} onClick={() => selectTimeSlot(9)}>09:00</div>
              <div className={`time-slot ${timeSlot === 12 && 'active'}`} onClick={() => selectTimeSlot(12)}>12:00</div>
              <div className={`time-slot ${timeSlot === 15 && 'active'}`} onClick={() => selectTimeSlot(15)}>15:00</div>
            </div>
          </div>
          <div>or</div>
          <div className='option-box'>
            <div className='option-box-header'>
              Custom Date <span>(Enter the exact time)</span>
            </div>
            <RDatePicker
              editable={false}
              //disabledDate={date => isAfter(date, new Date())}
              className='date-filter-button'
              placeholder="Default"
              placement="bottomEnd"
              format="MM/dd/yyyy HH:mm"
              value={exportDate}
              cleanable={false}
              ranges={[]}
              onChange={(e) => {
                console.log(new Date(moment(e)))
                setTimeSlot(0)
                setExportDate(new Date(moment(e)))
                setSelectedExportDate(moment(new Date(moment(e))).format('MM/DD/YYYY HH:mm'))
              }}
            />
          </div>
        </div>
        <Button className='ms-auto me-auto  mt-3 time-based-usage-btn' onClick={handleExport}>Export</Button>
      </Modal.Body>
    </Modal>
  )
}
