import React, { useState } from 'react';
import Chart from "react-apexcharts";
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { DateRangePicker as RDateRangePicker } from 'rsuite';

const {
  combine,
  afterToday,
  allowedMaxDays,
} = RDateRangePicker;

const pieVestColors = [
  '#29BC2F',
  '#D72C0D'
];
const pieHelmetColors = [
  '#128A58',
  '#871B0D'
];
const pieBarColors = [
  '#29BC2F',
  '#D72C0D',
  '#128A58',
  '#871B0D'
];
const pieChartColors = [
  '#871B0D',
  '#D72C0D'
];

export default function Ppe() {
  let history = useHistory();
  const [dateFilter, setDateFilter] = useState({
    'start': moment().subtract(1, 'days').startOf('day'),
    'end': moment().subtract(1, 'days').endOf('day')
  })

  return (
    <Container fluid>
      <Row className='mt-3 d-flex'>
        <div className='heatmap-content-wide'>
          <p className='breadcrumb'><span className='dashboard-link' onClick={() => history.push('/dashboards')}>Dashboards</span>{'>'}<span className='current'>Personal Protective Equipment (PPE)</span> </p>

          <div className='d-flex justify-content-between w-100 mt-2'>
            <div className="ppe-column-pie">
              <h5 className='ppe-column-header mb-5'>Safety Vest and Helmet Detection Rates (%)</h5>
              <div className='d-flex justify-content-between w-100'>
                <div className='w-50'>
                  <Chart
                    series={[78, 22]}
                    type="pie"
                    height="400"
                    options={{
                      chart: {
                        toolbar: {
                          show: true
                        },
                        type: 'pie',
                        fontFamily: 'Lato, sans-serif',
                      },
                      labels: ['Have a Safety Vest', 'No Safety Vest'],
                      dataLabels: {
                        style: {
                          fontWeight: 'normal',
                        }
                      },
                      legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                      },
                      colors: pieVestColors
                    }}
                  />
                </div>

                <div className='w-50'>
                  <Chart
                    series={[60, 40]}
                    type="pie"
                    height="400"
                    options={{
                      chart: {
                        toolbar: {
                          show: true
                        },
                        type: 'pie',
                        fontFamily: 'Lato, sans-serif',
                      },
                      labels: ['Have a Helmet', 'No Helmet'],
                      dataLabels: {
                        style: {
                          fontWeight: 'normal',
                        }
                      },
                      legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                      },
                      colors: pieHelmetColors
                    }}
                  />
                </div>
              </div>
            </div>


            <div className='ppe-column-bars'>
              <div className='d-flex align-items-center'>
                <div className="me-3 mb-3">
                  <RDateRangePicker
                    className='rdatepicker'
                    appearance="default"
                    editable={false}
                    placeholder="Default"
                    style={{ width: 'auto' }}
                    placement="bottom"
                    disabledDate={combine(allowedMaxDays(2), afterToday())}
                    format="MM/dd/yyyy HH:mm"
                    value={[moment(dateFilter['start']).toDate(), moment(dateFilter['end']).toDate()]}
                    ranges={
                      []
                    }
                    cleanable={false}
                    onChange={(e) => {
                      setDateFilter({
                        'start': moment(e[0]),
                        'end': moment(e[1])
                      })
                    }}
                  />
                </div>
                <div className="ppe-total d-flex justify-content-between align-items-center mb-3">
                  <p className='total-name'>Total Number of Detections</p>
                  <p className='total-value'>200</p>
                </div>
              </div>

              <div className="ppe-column-bar">
                <h5 className='ppe-column-header'>Number of Safety Vest and Helmet Detections</h5>
                <Chart
                  series={
                    [{
                      name: 'Men',
                      data: [78, 22, 60, 40]
                    }]
                  }
                  type="bar"
                  height="350"
                  options={{
                    chart: {
                      type: 'bar',
                      height: 550,
                      stacked: true,
                      fontFamily: 'Lato, sans-serif',
                    },
                    plotOptions: {
                      bar: {
                        distributed: true,
                        horizontal: true,
                      },
                    },
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      width: 1,
                      colors: ['#fff']
                    },
                    xaxis: {
                      categories: ['Have a Safety Vest', 'No Safety Vest', 'Have a Helmet', 'No Helmet'],
                    },
                    fill: {
                      opacity: 1,
                    },
                    legend: {
                      position: 'top',
                      horizontalAlign: 'center',
                    },
                    colors: pieBarColors
                  }}
                />
              </div>
            </div>

          </div>

          <div className="ppe-column-chart mt-3">
            <h5 className='ppe-column-header'>Number of Detections by Wearing Safety Vests and Helmets per hour</h5>
            <Chart
              options={{
                chart: {
                  fontFamily: 'Lato, sans-serif',
                  height: 350,
                  type: 'area',
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'smooth'
                },
                fill: {
                  type: 'solid'
                },
                grid: {
                  row: {
                    colors: ['transparent']
                  },

                },
                xaxis: {
                  categories: ['09:00 am', '11:00 am', '01:00 pm', '03:00 pm', '05:00 pm', '07:00 pm', '09:00 pm', '11:00 pm'],
                },
                colors: pieChartColors,
                legend: {
                  position: 'top',
                  horizontalAlign: 'center',
                },
              }}
              series={
                [{
                  name: "No Helmet",
                  data: [5, 3, 6, 8, 2, 5, 4, 7]
                },
                {
                  name: 'No Safety Vest',
                  data: [4, 0, 2, 5, 3, 1, 0, 7]
                }]
              }
              height={350}
              type="area"
            />
          </div>

        </div>
      </Row>
    </Container>
  )
}
