import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function AlertEmailDeactivateModal(props) {

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="genericModalTitle">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="genericModalText">
          You’re about to <span>disable</span> email {props.type === "alert" ? 'alerts' : 'reports'}. Are you sure?
          <br></br>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button className='genericModalBtnCancel me-3' onClick={props.handleClose}>No</Button>
          <Button className='genericModalBtnApply' onClick={() => props.deactivateEmail(props.type)}>Yes</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
