import React from 'react';
import chooch from '../../assets/images/chooch-logo.png';
import recaptcha from '../../assets/images/recaptcha.png';
import banner from '../../assets/images/chooch-banner.jpg';

import { Button, Form } from 'react-bootstrap';

export default function ForgotPassword() {
  return (
    <div className='forgot-password-content'>
      <div className='header'>
        <img className="logo" src={chooch} alt="logo" />
      </div>

      <div className='d-flex justify-content-center'>
        <div className="banner-content">
          <img src={banner} alt="banner" />
          <div className="banner-text">
            GET A FREE ACCOUNT ON THE COMPUTER VISION PLATFORM
          </div>
        </div>
      </div>

      <div className='mx-auto mt-1 form-box'>
        <h1 className='mx-auto'>Set your password</h1>
        <Form.Group className="mx-auto mt-2">
          <Form.Control type='password' placeholder="Password *" />
        </Form.Group>

        <Form.Group className="mx-auto mt-3">
          <Form.Control type='password' placeholder="Confirm Password *" />
        </Form.Group>

        <div className='d-flex mt-3'>
          <img className="recaptcha" src={recaptcha} alt="recaptcha" />
          <div className='info-text'>
            Chooch AI needs the contact information you provide to us to contact you about our products and services. Please review our Privacy Policy
          </div>
        </div>
        <Button className='w-100 mt-3'>SUBMIT</Button>
      </div>
    </div>
  )
}
