import { Button, Modal } from 'react-bootstrap';
import React from 'react';

export default function InventoryEditModal({ show, handleClose, settingId, isActive,  applyEdit }) {
  return (
    <Modal className='inventory-edit-modal' show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="genericModalTitle">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="genericModalText">
          Are you certain you want to disable report, which will send you an email when they occur? Please be aware that if you receive too many emails, they may end up in your spam folder. Don't forget to check your spam folder regularly.
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button className='genericModalBtnApply me-3' onClick={() => applyEdit(settingId, isActive)}>Yes</Button>
          <Button className='genericModalBtnCancel' onClick={handleClose}>No</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
