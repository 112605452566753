import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from '../Loader';
import { CameraVideo } from 'react-bootstrap-icons';
import { findDeviceNameByDeviceId, findStreamNameByStreamId, findZoneNameByZoneId} from '../../utils/generic';
import moment from 'moment';

export default function SliderItem(props) {
  const [activeSlide, setActiveSlide] = useState(0);

  var settings = {
    nextArrow: <Arrow type="next" activeSlide={activeSlide} />,
    prevArrow: <Arrow type="prev" activeSlide={activeSlide} />,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  return (
    <>
      {
        props.isLoading ?
          <>
            <Loader />
          </>
          :
          <>
            <div className='px-4'>
              <Slider {...settings}>
                {props.dwellData.map((item, index) => {
                  return (
                    <div className='count-box' key={index}>
                      <div className='info'>
                        <div className='d-flex justify-content-between w-100'>
                          <div className='name'>{item.class_title}</div>
                          <div className='count'>{moment.duration(item.avg_dwell_time, 'milliseconds').format('mm:ss.SSS', { trim: false })}</div>
                        </div>
                        <div className='class-box'>
                            <p>Zone :</p>
                            <div>{findZoneNameByZoneId(item.zone_id)}</div>
                          </div>
                      </div>
                      <div className='device-data'>
                        <div className='stream-box'><CameraVideo size={16} color='#352B63' className='ms-1 me-2' />{findStreamNameByStreamId(item.camera_id)}</div>
                        <div className="device-box">{findDeviceNameByDeviceId(item.device_id)}</div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </>
      }
    </>

  );
}

function Arrow(props) {
  const { className, style, onClick, type, activeSlide } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray" }}
      onClick={onClick}
    />
  )
}