import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import CustomTimeRangePicker from '../CustomTimeRangePicker';
import CustomTimePickerHourly from '../CustomTimePickerHourly';

export default function EditPeriodicReportModal(props) {
  const {
    show,
    handleClose,
    handleDone,
    selectedReport,
    deviceOptions,
    streamOptions,
    eventOptions,
    frequencyOptions,
    weeklyOptions
  } = props;

  const [selectedReportDevice, setSelectedReportDevice] = useState(null);
  const [selectedReportStream, setSelectedReportStream] = useState(null);
  const [selectedReportAlert, setSelectedReportAlert] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedReportTimes, setSelectedReportTimes] = useState([]);
  const [selectedWeekly, setSelectedWeekly] = useState(null);
  const [timeRangeStart, setTimeRangeStart] = useState([]);
  const [timeRangeEnd, setTimeRangeEnd] = useState([]);

  // initial state for comparison
  const [initialState, setInitialState] = useState({
    frequency: null,
    reportTimes: [],
    weekly: null,
    timeRangeStart: [],
    timeRangeEnd: []
  });

  // check if there are changes
  const hasChanges = useMemo(() => {
    if (!selectedReport || !initialState.frequency) return false;

    if (selectedFrequency?.value !== initialState.frequency?.value) return true;

    if (selectedFrequency?.value === 'DAILY') {
      const startTime = typeof timeRangeStart[0] === 'object' ? timeRangeStart[0]?.hour : timeRangeStart[0];
      const endTime = typeof timeRangeEnd[0] === 'object' ? timeRangeEnd[0]?.hour : timeRangeEnd[0];
      const initialStartTime = typeof initialState.timeRangeStart[0] === 'object' ? initialState.timeRangeStart[0]?.hour : initialState.timeRangeStart[0];
      const initialEndTime = typeof initialState.timeRangeEnd[0] === 'object' ? initialState.timeRangeEnd[0]?.hour : initialState.timeRangeEnd[0];

      if (startTime !== initialStartTime || endTime !== initialEndTime) return true;
      if (selectedReportTimes[0] !== initialState.reportTimes[0]) return true;
    }

    if (selectedFrequency?.value === 'WEEKLY') {
      if (selectedWeekly?.value !== initialState.weekly?.value) return true;
    }

    return false;
  }, [
    selectedFrequency,
    timeRangeStart,
    timeRangeEnd,
    selectedReportTimes,
    selectedWeekly,
    initialState,
    selectedReport
  ]);

  useEffect(() => {
    if (selectedReport) {
      setSelectedReportDevice(deviceOptions.find(x => x.value === selectedReport.device_id));
      setSelectedReportStream(streamOptions.find(x => x.value === selectedReport.camera_id));
      setSelectedReportAlert(eventOptions.find(x => x.value === selectedReport.rule_id));
      setSelectedFrequency(frequencyOptions.find(x => x.value === selectedReport.report_frequency));

      if (selectedReport.report_time) {
        const reportTimes = typeof selectedReport.report_time === 'string' ?
          [selectedReport.report_time] : selectedReport.report_time;
        setSelectedReportTimes(reportTimes);
      }

      if (selectedReport.report_date) {
        setSelectedWeekly(weeklyOptions.find(x => x.value === selectedReport.report_date));
      }

      if (selectedReport.start_time) {
        setTimeRangeStart([{ hour: selectedReport.start_time }]);
      }

      if (selectedReport.end_time) {
        setTimeRangeEnd([{ hour: selectedReport.end_time }]);
      }

      // set initial state for comparison
      setInitialState({
        frequency: frequencyOptions.find(x => x.value === selectedReport.report_frequency),
        reportTimes: selectedReport.report_time ?
          (typeof selectedReport.report_time === 'string' ? [selectedReport.report_time] : selectedReport.report_time) : [],
        weekly: selectedReport.report_date ?
          weeklyOptions.find(x => x.value === selectedReport.report_date) : null,
        timeRangeStart: selectedReport.start_time ? [{ hour: selectedReport.start_time }] : [],
        timeRangeEnd: selectedReport.end_time ? [{ hour: selectedReport.end_time }] : []
      });
    }
  }, [selectedReport, deviceOptions, streamOptions, eventOptions, frequencyOptions, weeklyOptions]);

  const handleSelectedFrequency = (item) => {
    setSelectedFrequency(item);

    if (item?.value === 'DAILY') {
      setSelectedReportTimes(['07:00 AM']);
      setTimeRangeStart(['07:00 AM']);
      setTimeRangeEnd(['07:00 AM']);
    } else {
      setSelectedReportTimes([]);
      setTimeRangeStart([]);
      setTimeRangeEnd([]);
      setSelectedWeekly(null);
    }
  }

  const handleSave = () => {
    const startTime = typeof timeRangeStart[0] === 'object' ? timeRangeStart[0]?.hour : timeRangeStart[0];
    const endTime = typeof timeRangeEnd[0] === 'object' ? timeRangeEnd[0]?.hour : timeRangeEnd[0];
    handleDone(
      selectedReport.id,
      selectedReport.is_active,
      'report',
      selectedFrequency?.value === 'DAILY' ?
        (Array.isArray(selectedReportTimes) ? selectedReportTimes[0] : selectedReportTimes) : null,
      selectedFrequency?.value === 'WEEKLY' ? selectedWeekly?.value : null,
      startTime,
      endTime,
      selectedFrequency?.value
    );
    handleClose();
  };

  return (
    <Modal className='add-report-modal' show={show} onHide={handleClose} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">
          Edit Periodic Report
        </Modal.Title>
      </Modal.Header>
      <span style={{ textAlign: 'center' }}> You can edit periodic report for all alerts.</span>
      <Modal.Body>
        <p className="select-asset-label">Device</p>
        <Select
          name="deviceOptions"
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          options={deviceOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Device"
          isDisabled={true}
          value={selectedReportDevice}
        />

        <p className="select-asset-label">Stream</p>
        <Select
          isDisabled={true}
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          name="streamOptions"
          options={streamOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Stream"
          value={selectedReportStream}
        />

        <p className="select-asset-label">Alert Type</p>
        <Select
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          isDisabled={true}
          name="alertOptions"
          options={eventOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder={selectedReportAlert === null || selectedReportAlert === undefined ? 'All Alerts' : selectedReportAlert}
          value={selectedReportAlert}
        />

        <p className="select-asset-label">Report Frequency</p>
        <Select
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          name="frequencyOptions"
          options={frequencyOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Frequency"
          onChange={(item) => handleSelectedFrequency(item)}
          value={selectedFrequency}
        />

        {selectedFrequency?.value === 'DAILY' && (
          <>
            <div>
              <p className="select-asset-label">Custom Time Ranges for Data <i>(UTC)</i></p>
              <div className='d-flex align-items-center gap-3'>
                <CustomTimeRangePicker
                  selectedTime={timeRangeStart}
                  handleSelectedTime={setTimeRangeStart}
                />
                <p>to</p>
                <CustomTimeRangePicker
                  selectedTime={timeRangeEnd}
                  handleSelectedTime={setTimeRangeEnd}
                />
              </div>
            </div>
            <p className="select-asset-label">Report Time <i>(UTC)</i></p>
            <CustomTimePickerHourly
              selectedReportTimes={selectedReportTimes}
              handleSelectedReportTimes={setSelectedReportTimes}
              selectedFrequency={selectedFrequency}
              isEditMode={true}
            />
          </>
        )}

        {selectedFrequency?.value === 'WEEKLY' && (
          <>
            <p className="select-asset-label">Report Day <i>(07:00 AM UTC)</i></p>
            <Select
              components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
              name="weeklyOptions"
              options={weeklyOptions}
              className="basic-single table-select"
              classNamePrefix="select"
              placeholder="Select a Day"
              onChange={(item) => setSelectedWeekly(item)}
              value={selectedWeekly}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={`add-report-${hasChanges ? 'active' : 'disabled'}-btn w-50`}
          onClick={handleSave}
          disabled={!hasChanges}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
