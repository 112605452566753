import React from 'react'

export default function Loader() {
  return (
    <div className="lds-spinner w-100 d-flex justify-content-center my-5">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
