import React, { useState, useEffect } from 'react';
import ModalDisplayVideo from '../alerts/ModalDisplayVideo';
import ModalDeleteAlerts from '../alerts/ModalDeleteAlerts';
import { fast_api_axios } from '../../utils/axiosFast';
import moment from 'moment';
import Cow from '../../assets/images/restricted.png'
import { useToast } from '../../contexts/ToastContext';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { Form, Dropdown, Button, Placeholder, Card } from 'react-bootstrap';
import {
  ChevronRight,
  ExclamationCircleFill,
  SortDown,
} from 'react-bootstrap-icons';
import NoDataModal from '../alerts/NoDataModal';
import Pagination from "../../utils/Pagination";
import FrameType from '../alerts/FrameType';
import { findClassTitleByRuleId } from '../../utils/generic';


export default function GroupAlertFrames(props) {
  console.log('props', props);
  const { updateMessage } = useToast()
  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  console.log('deviceData', deviceData);
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isAllVisible, setIsAllVisible] = useState(false);
  const [showDeleteAlertsModal, setShowDeleteAlertsModal] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [unreviewedFrameData, setUnreviewedFrameData] = useState([]);
  const [alertIndex, setAlertIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDisplayVideoModal, setShowDisplayVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [groupAlertsData, setGroupAlertsData] = useState([]);
  const [groupAlertCount, setGroupAlertCount] = useState(0);
  let history = useHistory();
  const { logout, currentUser } = useAuth();

  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const handleCloseNoDataModal = () => setShowNoDataModal(false);

  const handleCloseDisplayVideoModal = () => {
    setShowDisplayVideoModal(false);
    props.handleRefresh()
  }

  const handleCloseDeleteAlertsModal = () => {
    setShowDeleteAlertsModal(false);
  }

  const setDeviceName = (id) => {
    console.log('deviceData', deviceData.find(x => x.value === id));
    return deviceData.find(x => x.value === id) ? deviceData.find(x => x.value === id).label : '-';
  }

  const setStreamName = (id) => {
    console.log('streamData.id', id);
    return streamData.find(x => x.value === id) ? streamData.find(x => x.value === id).label : '-';
  }

  const setZoneName = (ids) => {
    console.log('ids', ids);
    return ids.length > 0 ? zoneData.find(x => x.value === ids[0]) ? zoneData.find(x => x.value === ids[0]).label : 'Entire Scene' : 'Entire Scene';
  }

  const setEventName = (id) => {
    return allEventData.find(x => x.value === id) ? allEventData.find(x => x.value === id).label : '-';
  }

  const getGroupAlerts = () => {
   let start = moment(props.dateFilter['start']).unix()
   let end = moment(props.dateFilter['end']).unix()
   console.log('start', start);
   console.log('end', end);

    fast_api_axios(
      {
        method: 'get',
        url: `/api/v1/alerts/loomis_custom_alert_page?start=${start}&end=${end}&seconds=10`,
      }
    )
    .then(res => {
      setGroupAlertsData(res.data);
      console.log('res.data', res.data);
      let count = 0;
      res.data.forEach(item => {
        count += item.alerts.length;
      });
      console.log('count', count);
      setGroupAlertCount(count);
      setIsLoading(false);
    
    })
    .catch(error => {
      console.log('error', error);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getGroupAlerts();
  }, [props.dateFilter]);

  const handleCloseDeleteAlertsModalUpdate = () => {
    setShowDeleteAlertsModal(false);
  }

  const handleShowDisplayVideoModal = (e, id, item, index) => {
    setAlertIndex(index);
    setSelectedItem(item);
    setShowDisplayVideoModal(true);
    setSelectedVideo(id);
  }

  const handleOpenNewVideoModal = (id, item, index) => {
    setShowDisplayVideoModal(false);
    const timeout = setTimeout(() => {
      setAlertIndex(index);
      setSelectedVideo(id);
      setSelectedItem(item);
      setShowDisplayVideoModal(true);
    }, 500)
    return () => clearTimeout(timeout)
  }

  const handleCheckboxClick = (e, id, index) => {
    console.log('id', id);
    console.log('index', index);
  }

  //console.log('review props', props)
  return (
    <>
      <div className='frame-container'>
        <div className='d-flex justify-content-between mb-3'>
          <h4>
            Detection Frames
            &nbsp;
            <span>{groupAlertCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} alerts</span>
          </h4>
        </div>
        {currentUser.isEnterprise && isLoading ?
          new Array(8).fill(null).map((item, index) => {
            return <Card className='cardload justify-content-between' key={index} style={{
              'height': '108px', 'width': '190px', 'float': 'left',
              'marginRight': '15px', 'border': 'none', 'marginBottom': '10px'
            }}>
              <Placeholder animation="wave" style={{ 'height': '108px', 'width': '190px' }} />
              <Placeholder xs={6} className="placehodercardp" />
              <Placeholder xs={9} className="placehodercardp" />

            </Card>
          })

          :
          <>
            <div className='img-box-wrapper-group'>
              {groupAlertsData?.length > 0 ?
                groupAlertsData.map((alertData, index) => {
                  return (
                    <div className='group-alert-container' key={index}>
                      <h5 className='group-date'>
                        {moment.utc(alertData.date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}
                      </h5>
                      <div className='group-images-container'>
                        {alertData.alerts.map((item, index) => {
                          return (
                            <div className='img-box' key={item.alert_uuid} onClick={(e) => handleShowDisplayVideoModal(e, item.alert_uuid, item, index)}>
                              <img className='thumbnail'
                                //src={CDN_URL + '/analytics/images/' + moment(item.alert_date).year() + '/' + (moment(item.alert_date).month() + 1) + '/' + moment(item.alert_date).date() + '/' + item.alert_uuid + '.png'}
                                src={item.image_url}
                                alt={item.alert_uuid}
                                onError={(e) => {
                                  e.target.onerror = null
                                  e.target.src = Cow
                                }}
                              />
                              <FrameType item={item} />
                              <div className="middle">
                                {props.showAll ?
                                  <Form.Check
                                    type="checkbox"
                                    className={isAllVisible ? 'checkbox cb-visible' : 'checkbox'}
                                    id={item.alert_uuid}
                                    onClick={(e) => handleCheckboxClick(e, item.alert_uuid, index)}
                                  />
                                  : null
                                }
                                {item.alert_type === 2 ?
                                  <div className="frame-info">
                                    <p className='frame-info-date-visible'>{moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}</p>
                                  </div>
                                  :
                                  <div className="frame-info">
                                    <div className='frame-info-details'>
                                      <p className='event-name'>{setEventName(item.rule_id)}  {findClassTitleByRuleId(item.rule_id)}</p>
                                      <p>{setDeviceName(item.device_id)} </p>
                                      <p>{setStreamName(item.camera_id)} </p>
                                      <p>{setZoneName(item.zone_ids)} </p>
                                    </div>
                                    <p className='frame-info-date'>{moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}</p>
                                  </div>
                                }
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })
                :
                <>
                  <div className='w-100 text-center no-data-text'>There is no data!</div>
                </>
              }

            </div>
          </>
        }
      </div>

      <ModalDisplayVideo
        show={showDisplayVideoModal}
        handleClose={handleCloseDisplayVideoModal}
        id={selectedVideo}
        isReviewed={props.isReviewed}
        handleRefresh={props.handleRefresh}
        alertIndex={alertIndex}
        showAll={props.showAll}
        data={unreviewedFrameData}
        item={selectedItem}
        handleOpenNewVideoModal={handleOpenNewVideoModal}
      />

      <ModalDeleteAlerts
        show={showDeleteAlertsModal}
        handleClose={handleCloseDeleteAlertsModal}
        handleCloseUpdate={handleCloseDeleteAlertsModalUpdate}
        handleRefresh={props.handleRefresh}
        selectedCheckboxes={selectedCheckboxes}
      />

      <NoDataModal show={showNoDataModal} handleClose={handleCloseNoDataModal} getLatest={props.getLatest} />

    </>
  );
}
