import React, { useState, useEffect } from 'react';
import ModalDisplayVideo from './ModalDisplayVideo';
import ModalDeleteAlerts from './ModalDeleteAlerts';
import { fast_api_axios } from '../../utils/axiosFast';
import moment from 'moment';
import Cow from '../../assets/images/restricted.png'
import { useToast } from '../../contexts/ToastContext';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { Form, Dropdown, Button, Placeholder, Card } from 'react-bootstrap';
import {
  ChevronRight,
  ExclamationCircleFill,
  SortDown,
} from 'react-bootstrap-icons';
import NoDataModal from './NoDataModal';
import Pagination from "../../utils/Pagination";
import FrameType from './FrameType';
import { findClassTitleByRuleId } from '../../utils/generic';

export default function Frames(props) {
  const { updateMessage } = useToast()
  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isAllVisible, setIsAllVisible] = useState(false);
  const [showDeleteAlertsModal, setShowDeleteAlertsModal] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [unreviewedFrameData, setUnreviewedFrameData] = useState([]);
  const [alertIndex, setAlertIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDisplayVideoModal, setShowDisplayVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortType, setSortType] = useState('descending');
  const [alertType, setAlertType] = useState(props.alertType);
  let history = useHistory();
  const { logout, currentUser } = useAuth();
  const [paginationObj, setPaginationObj] = useState({
    currentPage: 1,
    itemsPerPage: 25
  })

  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const handleCloseNoDataModal = () => setShowNoDataModal(false);

  const handleCloseDisplayVideoModal = () => {
    setShowDisplayVideoModal(false);
    props.handleRefresh()
  }

  const handleCloseDeleteAlertsModal = () => {
    setShowDeleteAlertsModal(false);
  }

  const handleCloseDeleteAlertsModalUpdate = () => {
    setShowDeleteAlertsModal(false);
    setIsAllVisible(false);
    setSelectedCheckboxes([]);
    let pagecount = Math.ceil(alertCount / paginationObj.itemsPerPage);
    if (pagecount === paginationObj.currentPage && (selectedCheckboxes.length === alertCount % paginationObj.itemsPerPage || selectedCheckboxes.length === paginationObj.itemsPerPage) && paginationObj.currentPage !== 1) {
      setPaginationObj(paginationObj => ({
        ...paginationObj,
        currentPage: paginationObj.currentPage - 1,
      }))
    }
    else {
      getAlerts(props.dateFilter['start'], props.dateFilter['end'], paginationObj.currentPage)
    }
  }

  const handleCheckboxClick = (e, id, index) => {
    e.stopPropagation();

    if (selectedCheckboxes.includes(id)) {
      var removed = selectedCheckboxes.filter(function (e) { return e !== id })
      removed.length === 0 && setIsAllVisible(false)
      setSelectedCheckboxes(removed);
    }
    else {
      setIsAllVisible(true)
      setSelectedCheckboxes(oldArray => [...oldArray, id]);
    }
  }

  const selectAllCheckboxes = () => {
    Array.from(document.querySelectorAll("input[type=checkbox]"))
      .forEach((checkbox) => {
        document.getElementById(checkbox.id).checked = true;
      });

    let allItems = [];
    unreviewedFrameData.forEach(item => allItems.push(item.alert_uuid));
    setSelectedCheckboxes(allItems)
  }

  const deselectAllCheckboxes = () => {
    Array.from(document.querySelectorAll("input[type=checkbox]"))
      .forEach((checkbox) => {
        document.getElementById(checkbox.id).checked = false;
      });

    setSelectedCheckboxes([])
    setIsAllVisible(false)
  }

  const deleteSelectedItems = () => {
    setShowDeleteAlertsModal(true);
  }

  const handleShowDisplayVideoModal = (e, id, item, index) => {
    setAlertIndex(index);
    setSelectedItem(item);
    setShowDisplayVideoModal(true);
    setSelectedVideo(id);
  }

  const handleOpenNewVideoModal = (id, item, index) => {
    setShowDisplayVideoModal(false);
    const timeout = setTimeout(() => {
      setAlertIndex(index);
      setSelectedVideo(id);
      setSelectedItem(item);
      setShowDisplayVideoModal(true);
    }, 500)
    return () => clearTimeout(timeout)
  }


  const changePage = page => {
    setPaginationObj(paginationObj => ({
      ...paginationObj,
      currentPage: page,
    }))
    //getAlerts(props.dateFilter['start'], props.dateFilter['end'], page)
  }

  const handleItemPerPageChange = perPageSize => {
    setPaginationObj(paginationObj => ({
      ...paginationObj,
      currentPage: 1,
      itemsPerPage: perPageSize,
    }))
  }

  const getAlerts = (startdate, enddate, page) => {
    setIsLoading(true)
    let start = moment(startdate).unix()
    let end = moment(enddate).unix()
    let devices = props.filterDevices.map(device => device.value);
    let cameras = props.filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    props.filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    props.filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);
    //let rules = props.filterEvents.map(rule => rule.value);
    //let url = '/api/v1/alerts/';
    let url = null;
    if (props.isReviewed) {
      url = '/api/v1/alerts/feedbacks/'
    }
    else {
      url = '/api/v1/alerts/'
    }
    fast_api_axios(
      {
        method: 'get',
        url: url,
        params: {
          "application": props.appId,
          "is_reviewed": props.isReviewed,
          "start": start,
          "end": end,
          "alert_type": props.alertType,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
          "page": page,
          "size": props.showAll ? paginationObj.itemsPerPage : 12,
          "ordering": sortType === 'ascending' ? "alert_date" : "-alert_date"
        }
      })
      .then(res => {
        //console.log('resData',res.data)
        if (res.data.count === 0) {
          if (!props.isReviewed && !props.isDateApplied) {
            setShowNoDataModal(true);
            setAlertCount(0);
            setUnreviewedFrameData([]);
            setIsLoading(false);
            //props.getLatest();
          }
          else if (props.isReviewed && !props.isDateApplied) {
            setAlertCount(0);
            setUnreviewedFrameData([]);
            setIsLoading(false);
          }
          else {
            setAlertCount(0);
            setUnreviewedFrameData([]);
            setIsLoading(false);
          }
        }
        else {
          props.alreadyFound()
          setAlertCount(res.data.count)
          setUnreviewedFrameData(res.data.results)
          setIsLoading(false)
        }
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const setDeviceName = (id) => {
    return deviceData.find(x => x.value === id) ? deviceData.find(x => x.value === id).label : '-';
  }
  const setStreamName = (id) => {
    return streamData.find(x => x.value === id) ? streamData.find(x => x.value === id).label : '-';
  }
  const setZoneName = (ids) => {
    return ids.length > 0 ? zoneData.find(x => x.value === ids[0]) ? zoneData.find(x => x.value === ids[0]).label : 'Entire Scene' : 'Entire Scene';
  }
  const setEventName = (id) => {
    return allEventData.find(x => x.value === id) ? allEventData.find(x => x.value === id).label : '-';
  }

  useEffect(() => {
    deselectAllCheckboxes();
    currentUser.isEnterprise && getAlerts(props.dateFilter['start'], props.dateFilter['end'], paginationObj.currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterObj, sortType, paginationObj, alertType]);

  //console.log('review props', props)
  return (
    <>
      <div className='frame-container'>
        <div className='d-flex justify-content-between mb-3'>
          {props.showAll ?
            <>
              <h4>
                {props.isReviewed ? 'Reviewed Detection Frames' : 'Unreviewed Detection Frames'}
                &nbsp;
                <span>{alertCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} alerts</span>
              </h4>
              {/*<div className='d-flex justify-content-between align-items-baseline mb-3'>
                <Form.Check
                  inline
                  label="All Alerts"
                  name="alerts"
                  type={'radio'}
                  id={`all-alerts`}
                  defaultChecked={true}
                  onChange={(e) => {
                    setAlertType(e.target.checked ? null : '');
                  }}
                />

                <Form.Check
                  inline
                  label="Alerts"
                  name="alerts"
                  type={'radio'}
                  id={`alerts`}
                  onChange={(e) => {
                    setAlertType(e.target.checked ? '0' : '1');
                  }}
                />
                <Form.Check
                  inline
                  label="Image Chat"
                  name="alerts"
                  type={'radio'}
                  id={`image-chat`}
                  onChange={(e) => {
                    setAlertType(e.target.checked ? '1' : '0');
                  }}
                  style={{marginRight: '25px'}}
                />
              </div>*/}
              <Dropdown className='sort-dropdown'>
                <Dropdown.Toggle>
                  <SortDown size={24} />
                  &nbsp;
                  Sort
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSortType('descending')} active={sortType === 'descending'}>Newest to Oldest</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortType('ascending')} active={sortType === 'ascending'}>Oldest to Newest</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
            :
            <>
              <h4 className='frames-header'>
                {props.isReviewed ? 'Reviewed Detection Frames' : 'Unreviewed Detection Frames'}
                &nbsp;
                <span>{alertCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} alerts</span>
              </h4>
              {unreviewedFrameData.length > 0 && <div className='see-all-btn' onClick={() => props.handleShowAll(props.isReviewed)}>See All</div>}
            </>
          }
        </div>
        {currentUser.isEnterprise && isLoading ?
          new Array(8).fill(null).map((item, index) => {
            return <Card className='cardload justify-content-between' key={index} style={{
              'height': '108px', 'width': '190px', 'float': 'left',
              'marginRight': '15px', 'border': 'none', 'marginBottom': '10px'
            }}>
              <Placeholder animation="wave" style={{ 'height': '108px', 'width': '190px' }} />
              <Placeholder xs={6} className="placehodercardp" />
              <Placeholder xs={9} className="placehodercardp" />

            </Card>
          })

          :
          <>
            {props.showAll && isAllVisible ?
              <div className='d-flex justify-content-between delete-area mb-3'>
                <div>
                  {unreviewedFrameData.length === selectedCheckboxes.length ?
                    <Button className='me-3 cb-actions' onClick={deselectAllCheckboxes}>
                      Deselect all
                    </Button>
                    :
                    <Button className='me-3 cb-actions' onClick={selectAllCheckboxes}>
                      Select all
                    </Button>
                  }
                </div>
                <div className='d-flex align-items-center'>
                  {selectedCheckboxes.length > 0 ?
                    selectedCheckboxes.length === 1 ?
                      <p className='me-3'>{selectedCheckboxes.length} item selected</p>
                      :
                      <p className='me-3'>{selectedCheckboxes.length} item selected</p>
                    :
                    null
                  }
                  <Button className='me-3 cb-actions' onClick={deselectAllCheckboxes}>Cancel</Button>
                  <Button className='me-3 delete-btn' onClick={deleteSelectedItems} disabled={selectedCheckboxes.length === 0}><ChevronRight size={18} /> Delete</Button>
                </div>
              </div>
              :
              null
            }
            <div className='img-box-wrapper'>
              {unreviewedFrameData.length > 0 ?
                unreviewedFrameData.map((item, index) => {
                  //console.log('item', props.isReviewed, item.alert_type);

                  return (
                    !props.isReviewed ?
                      /*item.alert_uuid === '6b56f7c4-c623-4a8c-982c-ceeca2b91ea0' ?
                        <div className='img-box' key={item.alert_uuid} onClick={(e) => handleShowDisplayVideoModal(e, item.alert_uuid, item, index)}>
                          <img className='thumbnail'
                            src={beachFrame}
                            alt={item.alert_uuid}
                            onError={(e) => {
                              e.target.onerror = null
                              e.target.src = Cow
                            }}
                          />
                          <FrameType item={item} />
                          <div className="middle">
                            {props.showAll ?
                              <Form.Check
                                type="checkbox"
                                className={isAllVisible ? 'checkbox cb-visible' : 'checkbox'}
                                id={item.alert_uuid}
                                onClick={(e) => handleCheckboxClick(e, item.alert_uuid, index)}
                              />
                              : null
                            }
                            <div className="frame-info">
                              <div className='frame-info-details'>
                                <p>Device: {setDeviceName(item.device_id)} </p>
                                <p>Camera: Beach Bar </p>
                                <p>Zone: {setZoneName(item.zone_ids)} </p>
                                <p>Event: {setEventName(item.rule_id)} </p>
                              </div>
                              <p className='frame-info-date'>{moment.utc(item.alert_date).local().format('MM/DD/YYYY HH:mm')}</p>
                            </div>
                          </div>
                        </div>
                        :
                        item.alert_uuid === 'dccf5754-4f62-4fbb-8622-68ae13092226' ?
                          <div className='img-box' key={item.alert_uuid} onClick={(e) => handleShowDisplayVideoModal(e, item.alert_uuid, item, index)}>
                            <img className='thumbnail'
                              src={bakeryFrame}
                              alt={item.alert_uuid}
                              onError={(e) => {
                                e.target.onerror = null
                                e.target.src = Cow
                              }}
                            />
                            <FrameType item={item} />
                            <div className="middle">
                              {props.showAll ?
                                <Form.Check
                                  type="checkbox"
                                  className={isAllVisible ? 'checkbox cb-visible' : 'checkbox'}
                                  id={item.alert_uuid}
                                  onClick={(e) => handleCheckboxClick(e, item.alert_uuid, index)}
                                />
                                : null
                              }
                              <div className="frame-info">
                                <div className='frame-info-details'>
                                  <p>Device: {setDeviceName(item.device_id)} </p>
                                  <p>Camera: Tokyo Bakery </p>
                                  <p>Zone: {setZoneName(item.zone_ids)} </p>
                                  <p>Event: {setEventName(item.rule_id)} </p>
                                </div>
                                <p className='frame-info-date'>{moment.utc(item.alert_date).local().format('MM/DD/YYYY HH:mm')}</p>
                              </div>
                            </div>
                          </div>
                          :
*/
                      <div className='img-box' key={item.alert_uuid} onClick={(e) => handleShowDisplayVideoModal(e, item.alert_uuid, item, index)}>
                        <img className='thumbnail'
                          //src={CDN_URL + '/analytics/images/' + moment(item.alert_date).year() + '/' + (moment(item.alert_date).month() + 1) + '/' + moment(item.alert_date).date() + '/' + item.alert_uuid + '.png'}
                          src={item.image_url}
                          alt={item.alert_uuid}
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src = Cow
                          }}
                        />
                        <FrameType item={item} />
                        <div className="middle">
                          {props.showAll ?
                            <Form.Check
                              type="checkbox"
                              className={isAllVisible ? 'checkbox cb-visible' : 'checkbox'}
                              id={item.alert_uuid}
                              onClick={(e) => handleCheckboxClick(e, item.alert_uuid, index)}
                            />
                            : null
                          }
                          {item.alert_type === 2 ?
                            <div className="frame-info">
                              <p className='frame-info-date-visible'>{moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}</p>
                            </div>
                            :
                            <div className="frame-info">
                              <div className='frame-info-details'>
                                <p className='event-name'>{setEventName(item.rule_id)}  {findClassTitleByRuleId(item.rule_id)}</p>
                                <p>{setDeviceName(item.device_id)} </p>
                                <p>{setStreamName(item.camera_id)} </p>
                                <p>{setZoneName(item.zone_ids)} </p>
                              </div>
                              <p className='frame-info-date'>{moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}</p>
                            </div>
                          }
                        </div>
                      </div>
                      :
                      <div className='img-box-reviewed' key={item.alert_uuid} onClick={(e) => handleShowDisplayVideoModal(e, item.alert_uuid, item, index)}>
                        <div className='img-info'>
                          <img className='thumbnail'
                            //src={CDN_URL + '/analytics/images/' + moment(item.alert_date).year() + '/' + (moment(item.alert_date).month() + 1) + '/' + moment(item.alert_date).date() + '/' + item.alert_uuid + '.png'}
                            src={item.image_url}
                            alt={item.alert_uuid}
                            onError={(e) => {
                              e.target.onerror = null
                              e.target.src = Cow
                            }}
                          />
                          <FrameType item={item} />
                          <div className="middle">
                            {props.showAll ?
                              <Form.Check
                                type="checkbox"
                                className={isAllVisible ? 'checkbox cb-visible' : 'checkbox'}
                                id={item.alert_uuid}
                                onClick={(e) => handleCheckboxClick(e, item.alert_uuid, index)}
                              />
                              : null
                            }
                            <div className="frame-info">
                              <div className='frame-info-details'>
                                <p className='event-name'>{setEventName(item.rule_id)}  {findClassTitleByRuleId(item.rule_id)}</p>
                                <p>{setDeviceName(item.device_id)} </p>
                                <p>{setStreamName(item.camera_id)} </p>
                                <p>{setZoneName(item.zone_ids)} </p>
                              </div>
                              <p className='frame-info-date'>{moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm')}</p>
                            </div>
                          </div>
                        </div>
                        <h5 className='review-results-header'>Review Results</h5>
                        <p className='review-result'>Is this prediction correct?</p>

                        <div className='d-flex justify-content-between align-items-center px-2'>
                          {item.is_correct ? <span className='correct'>YES</span> : <span className='incorrect'>NO</span>}
                          <div className='reviewed-people'>
                            {
                              item.email ? item.email.slice(0, 2).toUpperCase() : '-'
                            }
                            <div className="tooltiptext">
                              <h5>COMMENT</h5>
                              <p>{item.message}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                  )
                })
                :
                props.isReviewed ?
                  <div className='w-100 text-center no-data-text'>There is no data!</div>
                  :
                  <div className='w-100 d-flex justify-content-center mt-4 no-data-text'>
                    <ExclamationCircleFill size={20} color="#8020CF" className="mx-2" />
                    <p className='no-data-text'>No data within the selected dates. Check your filter and try again or go to the <span onClick={props.getLatest}>latest data. </span></p>
                  </div>
              }

            </div>

            {props.showAll ?
              <div className="paginationBlock">
                <Pagination
                  className="pagination-bar"
                  currentPage={paginationObj.currentPage}
                  totalCount={alertCount}
                  pageSize={paginationObj.itemsPerPage}
                  showItemsPerPage={true}
                  onPageChange={(page) => changePage(page)}
                  onItemSizeChange={(pageSize) => handleItemPerPageChange(pageSize)}
                />
              </div>
              :
              null
            }

          </>
        }
      </div>

      <ModalDisplayVideo
        show={showDisplayVideoModal}
        handleClose={handleCloseDisplayVideoModal}
        id={selectedVideo}
        isReviewed={props.isReviewed}
        handleRefresh={props.handleRefresh}
        alertIndex={alertIndex}
        showAll={props.showAll}
        data={unreviewedFrameData}
        item={selectedItem}
        handleOpenNewVideoModal={handleOpenNewVideoModal}
      />

      <ModalDeleteAlerts
        show={showDeleteAlertsModal}
        handleClose={handleCloseDeleteAlertsModal}
        handleCloseUpdate={handleCloseDeleteAlertsModalUpdate}
        handleRefresh={props.handleRefresh}
        selectedCheckboxes={selectedCheckboxes}
      />

      <NoDataModal show={showNoDataModal} handleClose={handleCloseNoDataModal} getLatest={props.getLatest} />

    </>
  );
}
