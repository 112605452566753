import React, { useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import dashboardsLogo from '../assets/images/dashboards-logo.svg'
import overviewLogo from '../assets/images/overview-logo.svg'
import alertsLogo from '../assets/images/alerts-logo.svg'
import trendsLogo from '../assets/images/trends-logo.svg'
import settingsLogo from '../assets/images/settings-logo.svg'
import { Button, OverlayTrigger, Popover, Accordion } from 'react-bootstrap'
import { X, QuestionCircle } from 'react-bootstrap-icons'
import { useBurger } from '../contexts/BurgerContext';
import choochLogo from '../assets/images/choochLogo.png';
import choochLogoSmall from '../assets/images/choochLogoSmall.png';
import chevronRight from '../assets/images/chevron-right.svg';
import chevronLeft from '../assets/images/chevron-left.svg';
import caretDown from '../assets/images/caretDown.svg';
import caretUp from '../assets/images/caretUp.svg';
import { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'

export default function Menu() {
  const loc = useLocation();
  const { isSidebarSmall, changeSidebar } = useBurger();
  const { currentUser } = useAuth()
  const [showVersionDetail, setShowVersionDetail] = useState(false);
  let history = useHistory();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = (e) => {
    e.preventDefault();
    if(window.innerWidth < 845){
      setIsMobile(true)
    }
    else{
      setIsMobile(false)
    }
  }

  const handleOpenDocs = () => {
    window.open(' https://chooch.ai/contact/', "_blank");
  }

  const goToAlerts = () => {
    currentUser.name === "choochkaiserpilot@gmail.com" || currentUser.name === "cbalci@cdt.com.tr" ? history.push("/dashboards") : history.push("/overview")
  }

  console
  return (
    <div className={isMobile || isSidebarSmall ? 'menu-small' : 'menu'}>
      <div className="app-logo-box">
        <img
          className="app-logo"
          src={isMobile || isSidebarSmall ? choochLogoSmall : choochLogo}
          alt="app-logo"
          onClick={goToAlerts}
        />
      </div>



      {isMobile ?
        null
        :
        isSidebarSmall ? (
          <>
            <div
              className="me-auto align-self-center menu-collapse tooltipx"
              onClick={changeSidebar}
            >

              <img src={chevronRight} alt="chevronRight" />
              <span className="tooltiptext">Expand</span>
            </div>
          </>
        ) : (
          <>
            <div
              className="me-auto align-self-center menu-collapse tooltipx"
              onClick={changeSidebar}
            >
              <img src={chevronLeft} alt="chevronLeft" />
              <span className="tooltiptext">Collapse</span>
            </div>
          </>
        )}

      {currentUser.name === "choochkaiserpilot@gmail.com" ?
        <>
          {/*<Link to="/alerts" onClick={() => localStorage.setItem('activeAlertPage', 'base')} className={loc.pathname === "/alerts" ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
            <div className='menu-item-active-border'></div>
            <img src={alertsLogo} alt="alert" />
            {isMobile || isSidebarSmall ?
              <span className="tooltiptext">Alerts</span>
              :
              <span className='page-name'>Alerts</span>
            }
          </Link>*/}
          <Link to="/dashboards" className={loc.pathname.includes("/dashboard") ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
            <div className='menu-item-active-border'></div>
            <img src={dashboardsLogo} alt="alert" />
            {isMobile || isSidebarSmall ?
              <span className="tooltiptext">Dashboards</span>
              :
              <span className='page-name'>Dashboards</span>
            }
          </Link>
        </>
        :
        currentUser.name === "cbalci@cdt.com.tr" ?
          <>
            <Link to="/dashboards" className={loc.pathname.includes("/dashboard") ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
              <div className='menu-item-active-border'></div>
              <img src={dashboardsLogo} alt="alert" />
              {isMobile || isSidebarSmall ?
                <span className="tooltiptext">Dashboards</span>
                :
                <span className='page-name'>Dashboards</span>
              }
            </Link>
          </>
          :
          <>
            <Link to="/overview" className={loc.pathname.includes("/overview") ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
              <div className='menu-item-active-border'></div>
              <img src={overviewLogo} alt="overview" />
              {isMobile || isSidebarSmall ?
                <span className="tooltiptext">Overview</span>
                :
                <span className='page-name'>Overview</span>
              }
            </Link>
            <Link to="/alerts" onClick={() => localStorage.setItem('activeAlertPage', 'base')} className={loc.pathname === "/alerts" ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
              <div className='menu-item-active-border'></div>
              <img src={alertsLogo} alt="alert" />
              {isMobile || isSidebarSmall ?
                <span className="tooltiptext">Alerts</span>
                :
                <span className='page-name'>Alerts</span>
              }
            </Link>
            {
              currentUser.name === "choochloomispilot@gmail.com" &&
                <Link to="/group-alerts" className={loc.pathname.includes('/group-alerts') ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
                  <div className='menu-item-active-border'></div>
                  <img src={trendsLogo} alt="trend" />
                  {isMobile || isSidebarSmall ?
                    <span className="tooltiptext">Group Alerts</span>
                    :
                    <span className='page-name'>Group Alerts</span>
                  }
                </Link>
              
            }
            <Link to="/trends" className={loc.pathname.includes('/trends') ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
              <div className='menu-item-active-border'></div>
              <img src={trendsLogo} alt="trend" />
              {isMobile || isSidebarSmall ?
                <span className="tooltiptext">Alert Trends</span>
                :
                <span className='page-name'>Alert Trends</span>
              }
            </Link>
            <Link to="/dashboards" className={loc.pathname.includes("/dashboard") ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
              <div className='menu-item-active-border'></div>
              <img src={dashboardsLogo} alt="alert" />
              {isMobile || isSidebarSmall ?
                <span className="tooltiptext">Dashboards</span>
                :
                <span className='page-name'>Dashboards</span>
              }
            </Link>
            <Link to="/settings" className={loc.pathname === "/settings" ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
              <div className='menu-item-active-border'></div>
              <img src={settingsLogo} alt="alert" />
              {isMobile || isSidebarSmall ?
                <span className="tooltiptext">Settings</span>
                :
                <span className='page-name'>Settings</span>
              }
            </Link>
          </>
      }

      {/*<Link to="/settingsnew" className={loc.pathname === "/settingsnew" ? 'menu-item tooltipx menu-item-active' : 'menu-item tooltipx'}>
        <div className='menu-item-active-border'></div>
        <img src={settingsLogo} alt="alert" />
        {isSidebarSmall ?
          <span className="tooltiptext">SettingsNEW</span>
          :
          <span className='page-name'>SettingsNEW</span>
        }
      </Link>*/}

      {isSidebarSmall ?
        null
        :
        <>
          <div className='documentation-box mt-auto mx-2'>
            <Button className='documentation-btn' onClick={handleOpenDocs}>Contact Us&nbsp;<QuestionCircle size={25} /></Button>
          </div>

          <OverlayTrigger
            show={showVersionDetail}
            placement={'top'}
            overlay={
              <Popover className='version-popover'>
                <Popover.Header as="h3"><div>What’s New in Chooch Smart Analytics?</div> <X size={30} onClick={() => setShowVersionDetail(false)} /></Popover.Header>
                <Popover.Body>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div>
                          8.1.0
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Version History & Updates
                        <br></br>
                        <ul>
                          <li>Added ImageChat Model Video Alerts</li>
                          <li>Minor Bug Fixes</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div>
                          8.0.0
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Version History & Updates
                        <br></br>
                        <ul>
                          <li>Added Real-Time Counting Dashboard & Settings.</li>
                          <li>Introduced Staff Dwell Time Dashboard (exclusive to v8.0.EWL).</li>
                          <li>Added List View to the Alert screen.</li>
                          <li>Updated and improved the Overview screen.</li>
                          <li>Integrated with Inference Engine v8.1.</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div>
                          7.2.0
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Version History & Updates
                        <br></br>
                        <ul>
                          <li>Added rule details to Alert modal.</li>
                          <li>Added dwell time dashboard.</li>
                          <li>Added line counting dashboard.</li>
                          <li>Integrated with Inference Engine v7.1 demographics dashboard and alert video system..</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div>
                          7.1.0
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Version History & Updates
                        <br></br>
                        <ul>
                          <li>The system has been updated to include time zone settings and e-mail alerts.</li>
                          <li>The alert distribution chart has been updated to include hourly, daily, and weekly data.</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <div>
                          7.0.0
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Version History & Updates
                        <br></br>
                        <ul>
                          <li>Rebranding.</li>
                          <li>Minor bug fixes.</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <div>
                          6.x.x & Older Versions
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Version History & Updates
                        <br></br>
                        <ul>
                          <li>Changed system for on-prem and fixed email.</li>
                          <li>Demographics API update</li>
                          <li>Alert e-mail updates.</li>
                          <li>Keycloak is disabled for the enterprise account.</li>
                          <li>Fixed session update and if state and empty values.</li>
                          <li>Added e-mail report system and alert notification.</li>
                          <li>Added semantic release for v6.0.0</li>
                          <li>Added missing packages</li>
                          <li>Updated query seconds</li>
                          <li>Removed unnecessary blue areas from the heatmap.</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Popover.Body>
              </Popover>
            }
          >
            <div className='version-history-area d-flex align-items-center justify-content-between mx-3'>
              Version 8.1.0
              {showVersionDetail ?
                <img src={caretUp} className="version-history-btn active" alt="caret" onClick={() => setShowVersionDetail(!showVersionDetail)} />
                :
                <img src={caretDown} className="version-history-btn" alt="caret" onClick={() => setShowVersionDetail(!showVersionDetail)} />
              }
            </div>
          </OverlayTrigger>
        </>
      }
    </div>
  )
}
