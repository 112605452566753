import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';
import Loader from '../Loader';
import { personColorCodes } from '../../utils/constants';
import moment from 'moment';
import { fast_api_axios } from '../../utils/axiosFast';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";

const ModalViewComment = (props) => {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [reviewData, setReviewData] = useState([]);

  const getAlertDetail = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id
      })
      .then(res => {
        setYear(moment(res.data.alert_date).year())
        setMonth((moment(res.data.alert_date).month() + 1))
        setDay(moment(res.data.alert_date).date())

        setImageUrl(res.data.image_url)
        setVideoUrl(res.data.video_url)

        getFeedbackDetail();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getFeedbackDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id + '/feedback/'
      })
      .then(res => {
        setReviewData(res.data.results[0])
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    if (props.show) {
      getAlertDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const closeModal = () => {
    props.handleClose()
  }

  const openPrevious = () => {
    if (props.alertIndex > 0) {
      setIsLoading(true);
      closeModal();
      props.handleOpenNewVideoModal(props.data[props.alertIndex - 1].alert_uuid, props.alertIndex - 1)
    }
  }

  const openNext = () => {
    if (props.alertIndex + 1 < props.data.length) {
      setIsLoading(true);
      closeModal();
      props.handleOpenNewVideoModal(props.data[props.alertIndex + 1].alert_uuid, props.alertIndex + 1)
    }
  }

  const calculateTimeDifference = (time) => {
    const duration = moment.duration(moment.utc().tz(localStorage.getItem('timezone')).diff(moment.utc(time).tz(localStorage.getItem('timezone'))));
    if (duration.asDays() < 1) {
      if (duration.asHours() < 1) {
        if (duration.asMinutes() < 1) {
          return Math.floor(duration.asSeconds()) + ' seconds ago.'
        }
        else {
          return Math.floor(duration.asMinutes()) + ' minutes ago.'
        }
      }
      else {
        return Math.floor(duration.asHours()) + ' hours ago.'
      }
    }
    else {
      return Math.floor(duration.asDays()) + ' days ago.'
    }
  }

  return (
    <Modal show={props.show} onHide={closeModal} centered size='lg'>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        {isLoading ?
          <Loader />
          :
          <>
            <div className='d-flex justify-content-center align-items-center mb-3'>
              {
                props.alertIndex === 0 ?
                  <img src={arrowLeft} alt="left" style={{ height: 'fit-content', marginRight: 'auto', opacity: "0" }} />
                  :
                  <img src={arrowLeft} alt="left" style={{ height: 'fit-content', marginRight: 'auto', cursor: 'pointer' }} onClick={openPrevious} />
              }
              {
                imageUrl ?
                  <img  className='view-comment-alert-image' src={imageUrl} alt="img"/>
                  :
                  <ReactPlayer
                    //url={CDN_URL + '/analytics/videos/' + year + '/' + month + '/' + day + '/' + props.id + '.mp4'}
                    url={videoUrl}
                    muted={true}
                    controls={true}
                    playbackRate={1}
                    light={imageUrl}
                  />
              }
              {
                props.data.length === props.alertIndex + 1 ?
                  <img src={arrowRight} alt="right" style={{ height: 'fit-content', marginLeft: 'auto', opacity: "0" }} />
                  :
                  <img src={arrowRight} alt="right" style={{ height: 'fit-content', marginLeft: 'auto', cursor: 'pointer' }} onClick={openNext} />
              }
            </div>
            <div className='review-box mb-3'>
              <div className='operator-review'>
                <p className='review-owner'>
                  Review Results
                </p>
                <p className='review-question'>
                  Is this detection correct?
                </p>

                {reviewData.is_correct !== null ?
                  reviewData.is_correct === true ?
                    <p className='review-answer green'>Yes</p>
                    :
                    <p className='review-answer red'>No</p>
                  :
                  <p className='review-answer'>-</p>
                }
              </div>
            </div>
            <h5 className='comment-header mb-3'>COMMENTS</h5>

            <div className='d-flex comment-box'>
              <div className='comment-owner comment-owner-tooltip' style={{ backgroundColor: personColorCodes[0].color }}>
                {reviewData.user.username.slice(0, 2)}
                <span className='tooltiptext'>{reviewData.user.username}</span>
              </div>
              <div className='d-flex flex-column justify-content-between'>
                <div className='comment'>{reviewData.message}</div>
                <p className='comment-date'>{calculateTimeDifference(reviewData.updated_at)}</p>
              </div>
            </div>
          </>
        }
      </Modal.Body>
    </Modal>
  );
}

export default ModalViewComment;
