export const personColorCodes = [
  {
    "color": "#FF0000"
  },
  {
    "color": "#008080"
  },
  {
    "color": "#008000"
  },
  {
    "color": "#FFC0CB"
  },
  {
    "color": "#0000FF"
  },
  {
    "color": "#800080"
  },
  {
    "color": "#00FFFF"
  },
  {
    "color": "#808080"
  },
  {
    "color": "#A52A2A"
  },
  {
    "color": "#000000"
  }
];
