import React, { useState, useEffect } from 'react';
import { fast_api_axios } from '../../utils/axiosFast';
import moment from 'moment';
import { useToast } from '../../contexts/ToastContext';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CameraVideo, CheckCircleFill, DashCircleFill, ExclamationCircleFill, InfoCircle } from 'react-bootstrap-icons';
import { findClassTitleByRuleId } from '../../utils/generic';
import Loader from '../Loader';
import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';
import ReactPlayer from 'react-player/lazy'
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from './ImageModal';
import RuleInfoBox from '../alerts/RuleInfoBox';

export default function AlertListView(props) {
  let history = useHistory();
  const { updateMessage } = useToast()
  const { logout, currentUser } = useAuth();

  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))

  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(true);
  const [alertData, setAlertData] = useState([]);
  const [activeAlertData, setActiveAlertData] = useState(null);
  const [activeFeedbackData, setActiveFeedbackData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDetailLoading, setIsDetailLoading] = useState(true);
  const [showNoData, setShowNoData] = useState(false);
  const [zoneName, setZoneName] = useState('');

  const [isPlaying, setIsPlaying] = useState(true);
  const [isVideo, setIsVideo] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);

  const [ruleInfoObj, setRuleInfoObj] = useState({
    "rule_count": null,
    "count_logic": null,
    "proximity": null,
    "temporal": null,
    "zones": null
  })

  const getAlerts = (startdate, enddate) => {
    setIsLoading(true)
    let start = moment(startdate).unix()
    let end = moment(enddate).unix()
    let devices = props.filterDevices.map(device => device.value);
    let cameras = props.filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    props.filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    props.filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/alerts_list/',
        params: {
          "start": start,
          "end": end,
          "page": 1,
          "order_by": props.sortType === 'ascending' || props.sortType === 'descending' ? null : props.sortType,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
          "ordering": props.sortType === 'ascending' ? "alert_date" : "-alert_date",
        }
      })
      .then(res => {
        setCurrentPage(1)
        if (res.data.count > 0) {
          props.changeSortView(true)
          setHasMore(res.data.next !== null)
          setAlertData(res.data.results)
          getAlertDetail(res.data.results[0].alert_uuid, 0, res.data.results)
          setIsLoading(false)
        }
        else {
          props.changeSortView(false)
          setShowNoData(true)
          setIsLoading(false)
          setIsDetailLoading(false)
        }

      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getNewAlerts = (startdate, enddate, page) => {
    let start = moment(startdate).unix()
    let end = moment(enddate).unix()

    let devices = props.filterDevices.map(device => device.value);
    let cameras = props.filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    props.filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    props.filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/alerts_list/',
        params: {
          "start": start,
          "end": end,
          "page": page,
          "order_by": props.sortType === 'ascending' || props.sortType === 'descending' ? null : props.sortType,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
          "ordering": props.sortType === 'ascending' ? "alert_date" : "-alert_date",
        }
      })
      .then(res => {
        setCurrentPage(currentPage+1)
        setHasMore(res.data.next !== null)
        setAlertData([...alertData, ...res.data.results]);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getAlertDetail = (id, index, allData) => {
    setIsDetailLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + id,
      })
      .then(res => {
        var activeData = res.data
        activeData["index"] = index
        activeData["prev"] = index > 0 ? allData[index - 1].alert_uuid : null
        activeData["next"] = allData.length > 1 && index + 1 < allData.length ? allData[index + 1].alert_uuid : null
        setActiveAlertData(res.data)
        getFeedbackDetail(id);
        setZoneName(res.data.zones || 'Entire Scene')
        setRuleInfoObj({
          "rule_count": res.data.rule_count,
          "count_logic": res.data.count_logic,
          "proximity": res.data.proximity,
          "temporal": res.data.temporal,
          "zones": res.data.zones
        })
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getFeedbackDetail = (id) => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + id + '/feedback',
      })
      .then(res => {
        setActiveFeedbackData(res.data.results)
        setIsDetailLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    currentUser.isEnterprise && getAlerts(props.dateFilter['start'], props.dateFilter['end'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterObj, props.sortType]);

  const getDeviceName = (id) => {
    return deviceData.find(x => x.value === id) ? deviceData.find(x => x.value === id).label : '-';
  }
  const getStreamName = (id) => {
    return streamData.find(x => x.value === id) ? streamData.find(x => x.value === id).label : '-';
  }
  const getZoneName = (ids) => {
    return ids.length > 0 ? zoneData.find(x => x.value === ids[0]) ? zoneData.find(x => x.value === ids[0]).label : 'Entire Scene' : 'Entire Scene';
  }
  const getEventName = (id) => {
    return allEventData.find(x => x.value === id) ? allEventData.find(x => x.value === id).label : '-';
  }

  const handleNotVideo = (e) => {
    if (e.srcElement.error['code'] === 4) {
      setIsVideo(false);
    } else {
      console.log(e)
    }
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    setIsVideo(true);
    setIsPlaying(false);
  }, [activeAlertData])

  const sendReview = (id, isCorrect) => {
    fast_api_axios(
      {
        method: 'put',
        url: '/api/v1/alerts/' + id + '/feedback',
        data: {
          "user_id": currentUser.id,
          "is_correct": isCorrect,
          "message": ''
        }
      })
      .then(res => {
        var feedbackData = []
        feedbackData.push(res.data)
        setActiveFeedbackData(feedbackData)
        updateListReview(id, isCorrect)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong.', 'error')
        }
      });
  }

  const updateListReview = (id, isCorrect) => {
    const newData = alertData.map(item => {
      return item.alert_uuid === id ? { ...item, is_reviewed: true, is_correct: isCorrect } : item
    });

    setAlertData(newData);
  }

  const closeModal = () => {
    setShowImageModal(false);
  }

  return (
    <>
      {isLoading ?
        <Loader />
        :
        showNoData ?
          <p className="not-found-text-list">There is no data!</p>
          :
          <div className='list-view'>
            <div id="scrollableDiv" className='alert-list'>
              <InfiniteScroll
                dataLength={currentPage * 20}
                next={() => getNewAlerts(props.dateFilter['start'], props.dateFilter['end'], currentPage+1)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {alertData.map((item, index) => {
                  return (
                    <div className={`alert-item ${item.alert_uuid === activeAlertData?.alert_uuid ? 'active' : ''}`} key={index} onClick={() => getAlertDetail(item.alert_uuid, index, alertData)}>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='alert-name'>
                          {getEventName(item.rule_id)}
                          {item.alert_type !== 1 ?
                            <>
                              -{findClassTitleByRuleId(item.rule_id)}
                            </>
                            :
                            null
                          }
                        </div>
                        <div className='alert-date'>{moment(item.alert_date).format('MMM DD YYYY - hh:mm:ss')}</div>
                      </div>
                      {item.alert_type === 1 ?
                        <div className='imagechat-question'>{item.extra.prompt}</div>
                        :
                        null
                      }

                      <div className='d-flex justify-content-between align-items-center mt-auto'>
                        <div className='device-name'>{getDeviceName(item.device_id)}</div>
                        <div className='zone-name'>{zoneName}</div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='stream-name'>
                          <CameraVideo size={12} />
                          {getStreamName(item.camera_id)}</div>
                        <div className='review-status'>
                          {item.is_reviewed ?
                            item.is_correct ?
                              <>
                                <CheckCircleFill color='#20B526' size={18} />
                                &nbsp;Reviewed
                              </>
                              :
                              <>
                                <ExclamationCircleFill color='#EB5857' size={18} />
                                &nbsp;Reviewed
                              </>
                            :
                            <>
                              <DashCircleFill color='#B7B7B7' size={18} />
                              &nbsp;Unreviewed
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>

            {isDetailLoading ?
              <Loader />
              :
              <div className='alert-detail-view'>
                <div className='d-flex justify-content-between p-3'>
                  <div className='d-flex flex-column'>
                    <div className='alert-name'>
                      Alert:&nbsp;
                      <span>
                        {getEventName(activeAlertData.rule_id)}
                      {activeAlertData.alert_type !== 1 ?
                        <>
                          -{findClassTitleByRuleId(activeAlertData.rule_id)}
                          &nbsp;
                          <OverlayTrigger
                            placement="right-end"
                            overlay={
                              <Popover id="properties-tooltip" arrowProps={null}>
                                <RuleInfoBox data={ruleInfoObj} />
                              </Popover>
                            }
                          >
                            <InfoCircle color='#352B63' size={16} style={{marginBottom:"3px", cursor:"pointer"}} />
                          </OverlayTrigger>
                        </>
                        :
                        null
                      }
                      </span>
                    </div>
                    <div className='stream-name'>Stream: <span>{getStreamName(activeAlertData.camera_id)}</span></div>
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='device-name'>Device: <span>{getDeviceName(activeAlertData.device_id)}</span></div>
                    <div className='zone-name'>Zone: <span>{zoneName}</span></div>
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='alert-date'>{moment(activeAlertData.alert_date).format('MMM DD YYYY - hh:mm:ss')}</div>
                    <div className='review-status'>
                      {activeFeedbackData.length > 0 ?
                        activeFeedbackData[0].is_correct ?
                          <>
                            <CheckCircleFill color='#20B526' size={18} />
                            &nbsp;Reviewed
                          </>
                          :
                          <>
                            <ExclamationCircleFill color='#EB5857' size={18} />
                            &nbsp;Reviewed
                          </>
                        :
                        <>
                          <DashCircleFill color='#B7B7B7' size={18} />
                          &nbsp;Unreviewed
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className='review-content'>
                  <div className='d-flex align-items-center gap-1'><InfoCircle size={18} /> Is this detection correct?</div>
                  {activeFeedbackData.length > 0 ?
                    <>
                      <button className={`yes-btn ${activeFeedbackData[0].is_correct && 'active'}`} onClick={() => sendReview(activeAlertData.alert_uuid, true)}>Yes</button>
                      <button className={`no-btn ${activeFeedbackData[0].is_correct === false && 'active'}`} onClick={() => sendReview(activeAlertData.alert_uuid, false)}>No</button>
                    </>
                    :
                    <>
                      <button className='yes-btn' onClick={() => sendReview(activeAlertData.alert_uuid, true)}>Yes</button>
                      <button className='no-btn' onClick={() => sendReview(activeAlertData.alert_uuid, false)}>No</button>
                    </>
                  }

                  <div className='image-view'>Alert Image: <span onClick={() => setShowImageModal(true)}>View Image</span></div>
                </div>
                <div className='source-content'>
                  <img src={arrowLeft} alt="leftarrow" className={`arrow ${activeAlertData.index === 0 && 'disabled'}`} onClick={() => getAlertDetail(activeAlertData.prev, activeAlertData.index - 1, alertData)} />
                  {isVideo && activeAlertData.alert_type !== 2 ?
                    <ReactPlayer
                      url={activeAlertData.video_url}
                      muted={true}
                      playing={isPlaying}
                      controls={true}
                      playbackRate={1}
                      playsinline
                      onPause={onPause}
                      width={'540px'}
                      onError={(e) => handleNotVideo(e)}
                      light={activeAlertData.image_url}
                    />
                    :
                    <img src={activeAlertData.image_url} alt="aif" className='img-file' />
                  }
                  <img src={arrowRight} alt="rightarrow" className={`arrow ${activeAlertData.index + 1 === alertData.length && 'disabled'}`} onClick={() => getAlertDetail(activeAlertData.next, activeAlertData.index + 1, alertData)} />
                </div>
                {activeAlertData.alert_type === 1 ?
                  <div className='prompt-content'>
                    <div className='prompt'><span>Prompt: </span>{activeAlertData.extra.prompt}</div>
                    <div className='result' title={activeAlertData.extra.result}><span>Result: </span>{activeAlertData.extra.result}</div>
                  </div>
                  :
                  null
                }
              </div>
            }
          </div>
      }
      <ImageModal show={showImageModal} handleClose={closeModal} imageUrl={activeAlertData?.image_url} />
    </>
  )
}
