import React, { useState } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import classnames from "classnames";
import { usePagination } from "./usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    onItemSizeChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    hideNumber,
    showItemsPerPage
  } = props;

  const [gotoPage, setGotoPage] = useState(0);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  let pagecount = Math.ceil(totalCount / pageSize);


  const first = () => {
    onPageChange(1);
  };

  const last = () => {
    onPageChange(pagecount);
  };

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onchange = (e) => {
    let { value, min, max } = e.target;

    if (value === '') {
      setGotoPage(value)
    }
    else {
      var regex = /^[0-9]*$/
      if (regex.test(value)) {
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setGotoPage(parseInt(value))
      }
    }
  };

  const onKeyPress = (e) => {
    if (e.charCode === 13 && e.target.value !== '') {
      onPageChange(parseInt(e.target.value));
    }
  };

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      {showItemsPerPage &&
        <div className="items-per-page">
          <p className="item-1">Items per page</p>
          <Dropdown className='item-size-dropdown'>
            <Dropdown.Toggle>
              {pageSize}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onItemSizeChange(25)} active={pageSize === 25}>25</Dropdown.Item>
              <Dropdown.Item onClick={() => onItemSizeChange(50)} active={pageSize === 50}>50</Dropdown.Item>
              <Dropdown.Item onClick={() => onItemSizeChange(75)} active={pageSize === 75}>75</Dropdown.Item>
              <Dropdown.Item onClick={() => onItemSizeChange(100)} active={pageSize === 100}>100</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      }

      <Button
        className={classnames("paginationfirst pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={first}>
        First
      </Button>

      <ul
        className={classnames("pagination-container opt", {
          [className]: className,
        })}
      >
        <li
          key='left'
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {!hideNumber && paginationRange.map((pageNumber) => {
          return (
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
              key={pageNumber}
            >
              {pageNumber}
            </li>
          );
        })}

        <li
          key='right'
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
      </ul>
      <Button
        className={classnames("paginationlast pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={last}>
        Last
      </Button>
      {!hideNumber &&
        <>
          <p>Go to page</p>
          <Form.Control
            className="gotopage"
            type="text"
            step="any"
            min="1"
            max={pagecount}
            value={gotoPage !== 0 ? gotoPage : currentPage}
            placeholder=""
            onChange={(e) => onchange(e)}
            onKeyPress={(e) => onKeyPress(e)}
          />
        </>}
    </div>
  );
};

export default Pagination;
