import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";

export default function AddGroupAlertModal(props) {
  const { currentUser, logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();

  const [presetName, setPresetName] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const closeModal = () => {
    setPresetName('');
    setSelectedDevices([]);
    setSelectedStreams([]);
    setSelectedEvents([]);
    setIsSaveDisabled(true);
    props.handleClose();
  }

  const handleNameChange = (val) => {
    setPresetName(val);
    checkIfCanSave(val, selectedDevices, selectedStreams, selectedEvents);
  }

  const handleDeviceSelect = (items) => {
    setSelectedDevices(items);
    checkIfCanSave(presetName, items, selectedStreams, selectedEvents);
  }

  const handleStreamSelect = (items) => {
    setSelectedStreams(items);
    checkIfCanSave(presetName, selectedDevices, items, selectedEvents);
  }

  const handleEventSelect = (items) => {
    setSelectedEvents(items);
    checkIfCanSave(presetName, selectedDevices, selectedStreams, items);
  }

  const checkIfCanSave = (name, devices, streams, events) => {
    if (name !== null && name !== '' && presetName.length < 120 &&
      (devices.length > 0 || streams.length > 0 || events.length > 0)) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }

  const handleAddGroupAlert = () => {
    let presetFilter = {
      "devices": selectedDevices.map(item => item.value),
      "cameras": selectedStreams.map(item => item.value),
      "rules": selectedEvents.map(item => item.value)
    }

    fast_api_axios({
      method: 'post',
      url: '/api/v1/presets/',
      headers: {
        Authorization: "Bearer " + localStorage.getItem("fast_api_token"),
      },
      data: {
        "user_id": currentUser.id,
        "name": presetName,
        "filters": presetFilter,
      },
    })
      .then(res => {
        updateMessage('Saved filter is complete !', 'success');
        props.handleDone();
        let presetData = JSON.parse(localStorage.getItem('presets'));
        presetData.results.push(res.data);
        localStorage.setItem('presets', JSON.stringify(presetData));
        closeModal();
      })
      .catch(error => {
        if (error.response.status === 422) {
          updateMessage(presetName + ' already exists!', 'error')
        } else if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <Modal className='add-report-modal' show={props.show} onHide={closeModal} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">Add Group Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Group Name*</Form.Label>
          <Form.Control
            placeholder="Write Group Name"
            value={presetName}
            onChange={e => handleNameChange(e.target.value)}
            isInvalid={presetName.length >= 120}
          />
          <Form.Control.Feedback type="invalid">Group name must be less than 120 characters.</Form.Control.Feedback>
        </Form.Group>

        <p className='select-asset-label'>Device</p>
        <Select
          isMulti
          closeMenuOnSelect={false}
          name="deviceOptions"
          components={{
            DropdownIndicator: ({ selectProps }) =>
              selectProps.menuIsOpen ?
                <CaretUpFill className='select-icon me-2' /> :
                <CaretDownFill className='select-icon me-2' />
          }}
          options={JSON.parse(localStorage.getItem('deviceData'))}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder='Select Device'
          onChange={handleDeviceSelect}
          value={selectedDevices}
        />

        <p className='select-asset-label'>Stream</p>
        <Select
          isMulti
          closeMenuOnSelect={false}
          components={{
            DropdownIndicator: ({ selectProps }) =>
              selectProps.menuIsOpen ?
                <CaretUpFill className='select-icon me-2' /> :
                <CaretDownFill className='select-icon me-2' />
          }}
          name="streamOptions"
          options={JSON.parse(localStorage.getItem('streamData'))}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder='Select Stream'
          onChange={handleStreamSelect}
          value={selectedStreams}
        />

        <p className='select-asset-label'>Alert</p>
        <Select
          isMulti
          closeMenuOnSelect={false}
          components={{
            DropdownIndicator: ({ selectProps }) =>
              selectProps.menuIsOpen ?
                <CaretUpFill className='select-icon me-2' /> :
                <CaretDownFill className='select-icon me-2' />
          }}
          name="alertOptions"
          options={JSON.parse(localStorage.getItem('eventData'))}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder='Select Alert'
          onChange={handleEventSelect}
          value={selectedEvents}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={isSaveDisabled ? 'add-report-disabled-btn w-50' : 'add-report-active-btn w-50'}
          onClick={handleAddGroupAlert}
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
