import React from 'react'
import NotFound from '../../assets/images/restricted.png'
import { Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";

export default function NoDataBoard(props) {
  let history = useHistory();

  return (
    <div className="dashboard-not-found">
      <img src={NotFound} alt="not-found" className="not-found-img" />
      <p className="not-found-text">Looks like no data found!</p>
      {/*<p>Explanation</p>*/}
      <div className='show-example-box mx-auto' onClick={() => history.push(props.exampleRoute)}>
        <Button className='show-example-btn'>Show Example Dashboard</Button>
      </div>
    </div>
  )
}
