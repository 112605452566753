import React, { useEffect, useState } from "react";
import { Table, Container, Row, OverlayTrigger } from "react-bootstrap";
import { ChevronRight, ChevronUp, ChevronDown } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Pagination from "../../utils/Pagination";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Cow from "../../assets/images/restricted.png";
import { fast_api_axios } from "../../utils/axiosFast";
import Loader from "../Loader";
import { useToast } from "../../contexts/ToastContext";
import { useAuth } from "../../contexts/AuthContext";
import ModalViewComment from "./ModalViewComment";
import NotFound from '../../assets/images/restricted.png'

export default function TrendsComment() {
  const { updateMessage } = useToast();
  const params = useParams();
  const cameraData = params.camera;
  const deviceData = params.device;
  const options = [
    { label: "All", value: "*" },
    { label: "Prediction Correct ‘YES’", value: "yes" },
    { label: "Prediction Correct ‘NO’", value: "no" },
    { label: "Comments", value: "comments" },
    { label: "No Comments", value: "nocomments" },
  ];

  const dateFilter = {
    start: localStorage.getItem("filterObj") !== null ? moment(JSON.parse(localStorage.getItem("filterObj")).start) : moment().tz(localStorage.getItem('timezone')).startOf('day'),
    end: localStorage.getItem("filterObj") !== null ? moment(JSON.parse(localStorage.getItem("filterObj")).end) : moment().tz(localStorage.getItem('timezone')),
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [alertIndex, setAlertIndex] = useState(0);
  const [sorting, setSorting] = useState(false);
  const [getData, setGetData] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState(options);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showViewCommentModal, setShowViewCommentModal] = useState(false);
  const handleCloseViewCommentModal = () => setShowViewCommentModal(false);

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  let history = useHistory();
  const { logout } = useAuth();

  const handleShowViewCommentModal = (id, item, index) => {
    setAlertIndex(index);
    setSelectedVideo(id);
    setShowViewCommentModal(true);
  };

  const onChange = (value, event) => {
    setCurrentPage(1)
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedOptions(options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedOptions([]);
    } else {
      if (value.length === 5) {
        setSelectedOptions(value);
      } else if (
        value.length === 4 &&
        !value.some((obj) => obj.value === "*")
      ) {
        setSelectedOptions(options);
      } else {
        setSelectedOptions(value.filter((x) => x.value !== "*"));
      }
    }
  };

  const setSortedField = () => {
    setIsLoading(true);
    getAlertsDetail(currentPage, getData, !sorting);
    setSorting(!sorting)
  };

  const handleOpenNewVideoModal = (id, index) => {
    setShowViewCommentModal(false);
    const timeout = setTimeout(() => {
      setAlertIndex(index);
      setSelectedVideo(id);
      setShowViewCommentModal(true);
    }, 500);
    return () => clearTimeout(timeout);
  };

  const changePage = (page, data, sorting) => {
    setIsLoading(true);
    setCurrentPage(page);
    getAlertsDetail(page, data, sorting);
  };

  const getAlertsDetail = (page, data, sort) => {
    setIsLoading(true);
    setGetData(data);
    let start = moment(dateFilter["start"]).unix();
    let end = moment(dateFilter["end"]).unix();
    let camera = cameraData;
    let device = deviceData;
    let correct = null;
    let correctno = null;

    correct = data ? (data.find((o) => o.value === "yes") ? true : null) : null;
    correctno = data
      ? data.find((o) => o.value === "no")
        ? true
        : null
      : null;

    let isMessage = data
      ? data.find((o) => o.value === "comments")
        ? true
        : null
      : null;
    let isMessageNo = data
      ? data.find((o) => o.value === "nocomments")
        ? true
        : null
      : null;
    if (page !== 0) {
      setTableData([]);
      setTotalCount([]);
      fast_api_axios({
        method: "get",
        url: "/api/v1/alerts/feedbacks/",
        params: {
          start: start,
          end: end,
          devices: device,
          cameras: camera,
          rules: params.rule,
          page: page,
          size: 10,
          is_correct:
            correct === null && correctno === null
              ? null
              : correct && correctno
                ? null
                : (correct ? true : false) && (correctno ? false : true),
          is_reviewed: true,
          is_message:
            isMessage === null && isMessageNo === null
              ? null
              : isMessage && isMessageNo
                ? null
                : (isMessage ? true : false) && (isMessageNo ? false : true),
          ordering: sort ? "alert_date" : "-alert_date",
        },
      })
        .then((res) => {
          setTableData(res.data.results);
          setTotalCount(res.data.count);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response.data.detail === "Please re-login") {
            updateMessage(
              "Your session has expired, please login again.",
              "error"
            );
            logout();
            history.push("/login");
          }
        });
    } else {
      setTableData([]);
      setTotalCount([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAlertsDetail(1, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <>
      <Container fluid>
        <Row className="d-flex">
          <div className="trends-content-wide">

            <div className='page-content-header d-flex justify-content-between'>
              <p className='breadcrumb'>
                <span className='main-breadcrumb' onClick={() => history.push('/trends')}>Alert Trends</span>
                <ChevronRight size={14} />
                <span className='main-breadcrumb' onClick={() => history.push('/trends/details')}>Alert Details</span>
                <ChevronRight size={14} />
                <span className='inner-breadcrumb'>View Comment</span>
              </p>

              <div className="multi-select-checkboxes-btn">
                <ReactMultiSelectCheckboxes
                  rightAligned={true}
                  className="multiSelectClass"
                  options={options}
                  placeholderButtonLabel="Filters"
                  getDropdownButtonLabel={
                    //({ placeholderButtonLabel, value }) => { return value.length > 0 ? placeholderButtonLabel + ' ' + value.length : placeholderButtonLabel }
                    ({ placeholderButtonLabel, value }) => { return value.length > 0 ? placeholderButtonLabel : placeholderButtonLabel }
                  }
                  hideSearch={true}
                  value={selectedOptions}
                  onChange={onChange}
                  setState={setSelectedOptions}
                />
              </div>
            </div>

            <div className='page-wrapper'>
              <div className="table-box table-box-comments mt-3">
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Table>
                      <thead className="text-center">
                        <tr>
                          <th>ALERT</th>
                          <th className="sort-column" onClick={() => setSortedField()}>
                            ALERT DATE &nbsp;
                            {
                              sorting ? <ChevronUp className="mb-1" size={20} /> : <ChevronDown className="mb-1" size={20} />
                            }
                          </th>
                          <th>
                            REVIEW RESULTS
                            <br />
                            <span className="is-correct-header">Is this prediction correct?</span>
                          </th>
                          <th>USER</th>
                          <th>COMMENTS</th>
                          <th>COMMENT DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length > 0 ?
                          tableData.map((item, index) => {
                            return (
                              <tr className="comments-table" key={index} onClick={() => handleShowViewCommentModal(item.alert_uuid, item, index)}>
                                <td>
                                  <img
                                    className="alert-image"
                                    //src={CDN_URL + "/analytics/images/" + moment(item.alert_date).year() + "/" + (moment(item.alert_date).month() + 1) + "/" + moment(item.alert_date).date() + "/" + item.alert_uuid + ".png"}
                                    src={item.image_url}
                                    alt={item.alert_uuid}
                                    onError={(e) => (
                                      e.target.src = Cow
                                    )}
                                  />
                                </td>
                                <td>
                                  <span className="alert-date">
                                    {moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format("MM/DD/YYYY")}
                                  </span>
                                  <br />
                                  <span className="alert-date">
                                    {moment.utc(item.alert_date).tz(localStorage.getItem('timezone')).format("HH:mm")}
                                  </span>
                                </td>
                                <td>
                                  {item.is_correct ?
                                    <span className="green">YES</span>
                                    :
                                    <span className="red">NO</span>
                                  }
                                </td>
                                <td className="d-flex justify-content-center">
                                  {item.email !== null ?
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <div
                                          style={{
                                            position: 'absolute',
                                            padding: '2px 5px',
                                            color: '#595959',
                                            backgroundColor: '#FFFFFF',
                                            border: '1px solid #F2F1F1',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '5px',
                                            width: 'auto',
                                            fontSize: '11px'
                                          }}
                                        >
                                          {item.email}
                                        </div>
                                      }
                                    >
                                      <div className="comment-owner-user">
                                        {item.email.slice(0, 2)}
                                      </div>
                                    </OverlayTrigger>
                                    :
                                    null
                                  }
                                </td>
                                <td>
                                  {item.message === "" || item.message === null ?
                                    <div className="no-comment-text">
                                      There is no comment
                                    </div>
                                    :
                                    <div className="comment-text">
                                      {item.message}
                                    </div>
                                  }
                                </td>
                                <td>
                                  {item.message !== null ?
                                    <>
                                      <span className="alert-date">
                                        {moment.utc(item.created_at).tz(localStorage.getItem('timezone')).format("MM/DD/YYYY")}
                                      </span>
                                      <br />
                                      <span className="alert-date">
                                        {moment.utc(item.created_at).tz(localStorage.getItem('timezone')).format("HH:mm")}
                                      </span>
                                    </>
                                    :
                                    null
                                  }
                                </td>
                              </tr>
                            )
                          })
                          :
                          <tr className="not-found">
                            <td className="text-center" colSpan={6}>
                              <img src={NotFound} alt="not-found" className="not-found-img" />
                              <p className="not-found-text">Looks like there are no comments here!</p>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </Table>

                    <div className="paginationBlock">
                      <Pagination
                        //currentPage={paginationObj.currentPage}
                        //pageSize={paginationObj.itemsPerPage}
                        //onItemSizeChange={(pageSize) => handleItemPerPageChange(pageSize)}
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalCount}
                        pageSize={10}
                        showItemsPerPage={false}
                        onPageChange={(page) => changePage(page, getData, sorting)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <ModalViewComment
        id={selectedVideo}
        alertIndex={alertIndex}
        data={tableData}
        show={showViewCommentModal}
        handleClose={handleCloseViewCommentModal}
        handleOpenNewVideoModal={handleOpenNewVideoModal}
      />
    </>
  );
}
