import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { Calendar2, ChevronRight, ExclamationCircleFill, PencilSquare, PlusLg } from 'react-bootstrap-icons';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import { findDeviceNameByDeviceId, findStreamNameByStreamId } from '../../utils/generic';
import noStock from '../../assets/images/noStock.svg'
import criticalStock from '../../assets/images/criticalStock.svg'
import lowStock from '../../assets/images/lowStock.svg'
import mediumStock from '../../assets/images/mediumStock.svg'
import highStock from '../../assets/images/highStock.svg'
import overfillStock from '../../assets/images/overfillStock.svg'
import warning from '../../assets/images/warning.svg'
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../Loader';
import ProductTimeGraph from './ProductTimeGraph';

export default function ProductDetail() {
  const params = useParams();
  const history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [maxCount, setMaxCount] = useState(null);
  const [minMax, setMinMax] = useState(null);
  const [showEditMaxCountModal, setShowEditMaxCountModal] = useState(false);

  const getProductDetails = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/item/' + params.productID,
      })
      .then(res => {
        setProduct(res.data);
        setMaxCount(res.data.max_count);
        setMinMax(res.data.min_max);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getProductDetails();
  }, [params.productID])

  const handleShowEditMaxCountModal = () => {
    setShowEditMaxCountModal(true);
  }

  const handleCloseEditMaxCountModal = (shouldUpdate, count) => {
    if (shouldUpdate) {
      updateMaxCount(count)
    }
    else {
      setShowEditMaxCountModal(false);
    }
  }

  const updateMaxCount = (count) => {
    fast_api_axios(
      {
        method: 'patch',
        url: '/api/v1/tracker/stock/item/' + params.productID,
        data: {
          "max_count": count
        }
      })
      .then(res => {
        setMaxCount(res.data.max_count);
        setMinMax(res.data.min_max);
        setShowEditMaxCountModal(false);
        updateMessage('The bin fullness value has been successfully changed.', 'success')
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <>
      <Container fluid>
        <div className='heatmap-content-wide'>

          <div className='page-content-header'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards/inventory-management')}>Inventory Management</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>{product !== null ? product.name : null}</span>
            </p>

            {isLoading ?
              <Loader />
              :
              <div className='product-info'>
                <div className='info-item'>
                  <div className='info-header'>UID Number</div>
                  <div className='info-content'>{product.inv_cart_id + product.inv_item_id}</div>
                </div>
                <div className='info-item'>
                  <div className='info-header'>Product Name</div>
                  <div className='info-content' title={product.name}>{product.name}</div>
                </div>
                <div className='info-item'>
                  <div className='info-header'>Compartment</div>
                  <div className='info-content'>{product.compartment || 'asjkdasgvjdabsh'}</div>
                </div>
                <div className='info-item'>
                  <div className='info-header'>Stream</div>
                  <div className='info-content'>{findStreamNameByStreamId(product.camera_id)}</div>
                </div>
                <div className='info-item'>
                  <div className='info-header'>Device</div>
                  <div className='info-content'>{findDeviceNameByDeviceId(product.device_id)}</div>
                </div>

                <div className='info-item'>
                  <div className='end-date-box'>
                    <Calendar2 size={20} />
                    {moment().format('MM/DD/YYYY HH:mm')}
                  </div>
                </div>
              </div>
            }
          </div>
          {isLoading ?
            <Loader />
            :
            <div className='page-wrapper'>
              <div className='product-count-info'>
                <div className='product-count-item'>
                  {
                    product.class_title === 'obstructed' || product.class_title === null ?
                      <div className='no'>
                        No Data
                      </div>
                      :
                      product.class_title === '0' ?
                        <div className='no'>
                          <img src={noStock} alt="noStock" />
                          &nbsp; No Stock &nbsp;
                          {product.status === "alert" &&
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Popover id="warning-tooltip" arrowProps={null}>
                                  Lower than expected
                                </Popover>
                              }
                            >
                              <img src={warning} alt="warning" />
                            </OverlayTrigger>
                          }
                        </div>
                        :
                        product.class_title === '0-25' ?
                          <div className='critical'>
                            <img src={criticalStock} alt="criticalStock" />
                            &nbsp; Critical Stock &nbsp;
                            {product.status === "alert" &&
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Popover id="warning-tooltip" arrowProps={null}>
                                    Lower than expected
                                  </Popover>
                                }
                              >
                                <img src={warning} alt="warning" />
                              </OverlayTrigger>
                            }
                          </div>
                          :
                          product.class_title === '25-50' ?
                            <div className='low'>
                              <img src={lowStock} alt="lowStock" />
                              &nbsp; Low Stock &nbsp;
                              {product.status === "alert" &&
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Popover id="warning-tooltip" arrowProps={null}>
                                      Lower than expected
                                    </Popover>
                                  }
                                >
                                  <img src={warning} alt="warning" />
                                </OverlayTrigger>
                              }
                            </div>
                            :
                            product.class_title === '50-75' ?
                              <div className='medium'>
                                <img src={mediumStock} alt="mediumStock" />
                                &nbsp;Medium Stock &nbsp;
                                {product.status === "alert" &&
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Popover id="warning-tooltip" arrowProps={null}>
                                        Lower than expected
                                      </Popover>
                                    }
                                  >
                                    <img src={warning} alt="warning" />
                                  </OverlayTrigger>
                                }
                              </div>
                              :
                              product.class_title === '75-100' ?
                                <div className='high'>
                                  <img src={highStock} alt="highStock" />
                                  &nbsp; High Stock &nbsp;
                                  {product.status === "alert" &&
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Popover id="warning-tooltip" arrowProps={null}>
                                          Lower than expected
                                        </Popover>
                                      }
                                    >
                                      <img src={warning} alt="warning" />
                                    </OverlayTrigger>
                                  }
                                </div>
                                :
                                product.class_title === 'overfill' ?
                                  <div className='high'>
                                    <img src={overfillStock} alt="overfillStock" />
                                    &nbsp; Overfilled Stock &nbsp;
                                    {product.status === "alert" &&
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Popover id="warning-tooltip" arrowProps={null}>
                                            Lower than expected
                                          </Popover>
                                        }
                                      >
                                        <img src={warning} alt="warning" />
                                      </OverlayTrigger>
                                    }
                                  </div>
                                  :
                                  null
                  }
                </div>
                <div className='product-count-item approx'>
                  <div>APPROX UNIT: {product.class_count === "obstructed" ? 'No Data' : product.class_title}</div>
                  <div>Min-Max Value: {minMax}</div>
                </div>
                <div className='product-count-item poId'>
                  <div>POID: <span>{product.po_id || '000000000000'}</span></div>
                  {/*<Button>
                    <PlusLg />
                    <span>Need More</span>
                  </Button>*/}
                </div>
                <div className='product-count-item binFullness'>
                  <div>
                    BIN FULLNESS:
                    <span>{maxCount}</span>
                    <PencilSquare color='#878787' onClick={handleShowEditMaxCountModal} />
                  </div>
                  <div>
                    {product.is_lower_then_excepted ?
                      <>
                        <ExclamationCircleFill color='#FF8B00' />
                        Onelink should order
                      </>
                      :
                      <>
                        Onelink shouldn't order
                      </>
                    }

                  </div>
                </div>
              </div>

              <ProductTimeGraph productID={params.productID} />
            </div>
          }
        </div>
      </Container>
      <EditMaxCountModal show={showEditMaxCountModal} count={maxCount} handleClose={handleCloseEditMaxCountModal} />
    </>
  )
}


function EditMaxCountModal(props) {
  const [count, setCount] = useState(null)

  useEffect(() => {
    if (props.show) {
      setCount(props.count)
    }
  }, [props.show])

  const closeModal = (shouldUpdate) => {
    if (shouldUpdate) {
      props.handleClose(true, count)
    }
    else {
      props.handleClose(false, props.count)
    }
  }


  return (
    <Modal show={props.show} onHide={() => closeModal(false)} size='md' centered className='edit-count-modal'>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">EDIT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="my-3">
          <Form.Label>Bin Fullness</Form.Label>
          <Form.Control type="number" value={count} onChange={(e) => setCount(e.target.value)} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='save-btn'
          disabled={props.count == count || count === ''}
          onClick={() => closeModal(true)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}