import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import Select from 'react-select';

export default function AddReportModal(props) {
  let history = useHistory();
  const { logout, currentUser } = useAuth();
  const { updateMessage } = useToast()
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const deviceOptions = JSON.parse(localStorage.getItem("deviceData"));
  const [streamOptions, setStreamOptions] = useState(JSON.parse(localStorage.getItem("streamData")));
  const [eventOptions, setEventOptions] = useState(JSON.parse(localStorage.getItem("allEventData")));

  const [newStreamOptions, setNewStreamOptions] = useState(JSON.parse(localStorage.getItem("streamData")));
  const [newEventOptions, setNewEventOptions] = useState(JSON.parse(localStorage.getItem("allEventData")));

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const closeModal = () => {
    setSelectedDevice(null);
    setSelectedStream(null);
    setSelectedEvent(null);
    props.handleClose();
  }

  const handleAddAlert = (event, stream, device) => {
    props.handleDone(event, stream, device);
    closeModal();
  }

  useEffect(() => {
    if (selectedDevice !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedDeviceData = assets.find(d => d.device_id === selectedDevice.value)

      let newStreamOptions = [];
      selectedDeviceData.cameras.forEach(element => {
        newStreamOptions.push(
          {
            value: element.camera_id,
            label: element.camera_name
          }
        )
      });
      setNewStreamOptions(newStreamOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice])

  useEffect(() => {
    if (selectedEvent !== null) {
      setSelectedEvent(null)
    }
    if (selectedStream !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedStreamData = assets.find(d => d.device_id === selectedDevice.value).cameras.find(c => c.camera_id === selectedStream.value)

      let newAlertOptions = [];
      selectedStreamData.zones.forEach(element => {
        element.rules.forEach(ruleElement => {
          if (!newAlertOptions.some(a => a.value === ruleElement.rule_id)) {
            newAlertOptions.push(
              {
                value: ruleElement.rule_id,
                label: ruleElement.event + ' - ' + ruleElement.class_title
              }
            )
          }
        })
      });

      let allAlertOptions = [];
      assets.forEach(device => {
        device.cameras.forEach(camera => {
          camera.zones.forEach(zone => {
            zone.rules.forEach(event => {
              allAlertOptions.push(
                {
                  value: event.rule_id,
                  label: event.event + ' - ' + event.class_title
                }
              )
            })
          })
        })
      })

      setEventOptions(allAlertOptions)
      setNewEventOptions(newAlertOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStream])

  useEffect(() => {
    if (selectedEvent !== null) {
      setIsSaveDisabled(false)
    }
    else{
      setIsSaveDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent])


  /*const createAlertSetting = () => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/alert',
        data: {
          "rule_id": selectedEvent.value,
          "camera_id": selectedStream.value,
          "device_id": selectedDevice.value,
        }
      })
      .then(res => {
        /!*setIsAddAlertActive(false);
        getAlertSettings();*!/
        clearAddAlertFields();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        if (error.response.data.detail === 'Rule id already exists') {
          updateMessage('Alert email already exists.', 'error');
          clearAddAlertFields();
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }*/

  /*const handleAddAlertDone = (event, stream, device) => {
    console.log('as', event, stream, device);
    props.handleDone(event, stream, device);
    closeModal();
  }*/


  return (
    <Modal className='add-report-modal' show={props.show} onHide={closeModal} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">Add Instant Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='select-asset-label'>Device</p>
        <Select
          name="deviceOptions"
          options={deviceOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder='Select Device'
          onChange={(item) => setSelectedDevice(item)}
          value={selectedDevice}
        />

        <p className='select-asset-label'>Stream</p>
        <Select
          isDisabled={selectedDevice === null}
          name="streamOptions"
          options={newStreamOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder='Select Stream'
          onChange={(item) => setSelectedStream(item)}
          value={selectedStream}
        />
        <p className='select-asset-label'>Alert</p>
        <Select
          isDisabled={selectedStream === null || newEventOptions.length === 0}
          name="alertOptions"
          options={newEventOptions}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder='Select Alert'
          onChange={(item) => setSelectedEvent(item)}
          value={selectedEvent}
        />
      </Modal.Body>
      <Modal.Footer>
        {isSaveDisabled ?
          <Button className='add-report-disabled-btn w-50'>Save</Button>
          :
          <Button className='add-report-active-btn w-50' onClick={() => handleAddAlert(selectedEvent, selectedStream, selectedDevice)}>Save</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}
