import React, { Component } from 'react'
import choochLogo from '../assets/images/choochLogo.png';
import statusImage from '../assets/images/404.png';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log('Logging ', error, errorInfo)
  }

  handleOpenDocs = () => {
    window.open('https://chooch.ai/contact/', "_blank");
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary-content'>
          <img className='logo-image' src={choochLogo} />
          <h5>Oops!</h5>
          <p>You are lost.</p>
          <img src={statusImage} />
          <div className='refresh-btn-area'>
            <button className='refresh-btn' onClick={() => window.location.reload()}>Refresh the Page</button>
          </div>
          <p className='sth-is-wrong'>Do you think something is wrong? <span onClick={this.handleOpenDocs}>Contact Us</span></p>
        </div>
      )
    }
    return this.props.children
  }
}