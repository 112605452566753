import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function StreamStatusEmailActiveModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="genericModalTitle">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="genericModalText">
          Are you certain you want to <span>enable</span> alerts, which will send you an email when they occur? Please be aware that if you receive too many emails, they may end up in your spam folder. Don't forget to check your spam folder regularly.
          <br></br>
          <br></br>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button className='genericModalBtnCancel me-3' onClick={props.handleClose}>No</Button>
          <Button className='genericModalBtnApply' onClick={() => props.activateEmail()}>Yes</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
