import { Modal, Form, Button } from "react-bootstrap";
import Select from 'react-select';
import React, { useState } from 'react';

export default function AddInventoryReportModal({ show, handleClose, frequencyOptions, handleDone, inventorySettings }) {
  const [selectedFrequency, setSelectedFrequency] = useState('');

  const handleFrequencyChange = (item) => {
    setSelectedFrequency(item);
  }
  const handleSelectedFrequency = (item) => {
    handleDone(-1, -1, 'REPORT_STOCK', item);
    console.log('selectedFrequency', item);
    setSelectedFrequency(null);
    closeModal();
  }

  const closeModal = () => {
    setSelectedFrequency(null);
    handleClose();
  }

  return (
    <Modal className="inventory-modal" show={show} onHide={closeModal} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Inventory Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='select-asset-label'>Report Frequency</p>
        <Select
          name="deviceOptions"
          options={
            frequencyOptions.filter((o1, i) => {
              return !inventorySettings.some((o2) => {
                return o1.value === o2.report_frequency;
              });
            })

          }
          className="basic-single table-select inventory-frequency-select"
          classNamePrefix="select"
          placeholder='Select Report Frequency'
          onChange={(item) => handleFrequencyChange(item)}
          value={selectedFrequency}
          //menuIsOpen={true}
        />
        {/*<Form className='mx-auto'>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Report Frequency</Form.Label>
            <Select
              name="frequencyOptions"
              options={frequencyOptions}
              className="basic-single inventory-frequency-select"
              classNamePrefix="select"
              placeholder="Select Frequency"
              //onChange={(item) => setSelectedFrequency(item)}
              //value={props.frequency.find(f => f.value === selectedFrequency?.value)}
              onChange={(item) => handleFrequencyChange(item)}
            />
          </Form.Group>
          <Button variant="primary" onClick={() => handleSelectedFrequency(selectedFrequency)}>
            Save
          </Button>
        </Form>*/}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" className={selectedFrequency !== null ? 'add-inventory-btn' : 'add-inventory-btn disabled'} onClick={() => handleSelectedFrequency(selectedFrequency)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
