import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, InputGroup, Badge } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { personColorCodes } from '../../utils/constants';
import Loader from '../Loader';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";

export default function ShareModal(props) {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState('');
  const [informUsers, setInformUsers] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [unsharedUsers, setUnsharedUsers] = useState([]);

  const closeModal = () => {
    setUsers([]);
    setNewUser('');
    setInformUsers(false);
    setIsEmailValid(true);
    setSharedUsers([]);
    setUnsharedUsers([]);
    props.handleClose();
  }

  const addNewUser = () => {
    if (validateEmail(newUser)) {
      setUsers([...users, newUser]);
      setNewUser('');
      setIsEmailValid(true);
    }
    else {
      setIsEmailValid(false);
    }
  }

  const removeUserEmail = (userItem) => {
    setUsers(users.filter((i) => (i !== userItem)))
  }

  const validateEmail = (email) => {
    return email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const getSharedUsers = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/dashboards/' + props.dashboardId
      })
      .then(res => {
        setSharedUsers(res.data.users);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else{
          updateMessage('Something went wrong', 'error')
        }       
      });
  }

  const shareWithUsers = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'put',
        url: '/api/v1/dashboards/' + props.dashboardId + '/share',
        data: {
          'user_emails': users,
          'inform': informUsers
        }
      })
      .then(res => {
        let unshareds = unsharedUsers;
        users.forEach(function (element) {
          if (!res.data.find(o => o.email === element)) {
            unshareds.push(element);
          }
        });
        setUnsharedUsers(unshareds);
        getSharedUsers();
        setUsers([]);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else{
          updateMessage('Something went wrong', 'error')
        }       
      });
  }

  const unShare = (unshareId) => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'delete',
        url: '/api/v1/dashboards/' + props.dashboardId + '/share/' + unshareId,
      })
      .then(res => {
        getSharedUsers();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else{
          updateMessage('Something went wrong', 'error')
        }       
      });
  }

  useEffect(() => {
    if (props.show) {
      getSharedUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal show={props.show} onHide={closeModal} centered>
      <Modal.Header closeButton>
        SHARE WITH PEOPLE
      </Modal.Header>
      <Modal.Body>
        {isLoading ?
          <Loader />
          :
          <>
            <Form.Label>Share with people</Form.Label>
            <InputGroup>
              <Form.Control type="email" placeholder='Add email' value={newUser} onChange={(e) => setNewUser(e.target.value)}></Form.Control>
              {newUser.length > 0 ?
                <InputGroup.Text className='add-email-btn' onClick={() => addNewUser()}>
                  Add Email
                </InputGroup.Text>
                :
                null
              }
            </InputGroup>
            {isEmailValid ? null : <p className='text-danger'>Email is not valid.</p>}

            <Form.Group className="d-flex mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Inform Users" checked={informUsers} onChange={() => setInformUsers(!informUsers)} className='me-5' />
            </Form.Group>

            <Form.Label>Users</Form.Label>
            <div className='shared-users'>
              {sharedUsers.map((userItem, index) => {
                return (
                  <div className='user-item' key={index}>
                    <div className='user-img me-3' style={{ backgroundColor: personColorCodes[index % 10].color }}>
                      {
                        userItem.email.charAt(0).toUpperCase()
                      }
                    </div>
                    {userItem.email}
                    <p className='user-remove' onClick={() => unShare(userItem.id)}>Remove</p>
                  </div>
                )
              })
              }

              {unsharedUsers.length > 0 ?
                unsharedUsers.map((userItem, index) => {
                  return (
                    <div className='user-item' key={index}>
                      <div className='user-img me-3' style={{ backgroundColor: personColorCodes[(sharedUsers.length + index) % 10].color }}>
                        {
                          userItem.charAt(0).toUpperCase()
                        }
                      </div>
                      {userItem}
                      <p className='user-error'>Error</p>
                    </div>
                  )
                })
                :
                null
              }

              {users.length > 0 ?
                users.map((userItem, index) => {
                  return (
                    <Badge className='user-email-badge w-50 mb-1' key={index}>{userItem} <span onClick={() => removeUserEmail(userItem)}>x</span></Badge>
                  )
                })
                :
                null
              }
            </div>

            {users.length > 0 ?
              <Button className='add-people-btn-active w-100 mt-3' onClick={shareWithUsers}>Share</Button>
              :
              <Button className='add-people-btn w-100 mt-3' disabled>Share</Button>
            }

          </>
        }
      </Modal.Body>
    </Modal>
  )
}
