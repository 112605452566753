import React from 'react'
import { Modal } from 'react-bootstrap';

export default function ImageModal(props) {

  return (
    <Modal show={props.show} onHide={props.handleClose} centered fullscreen>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='fullscreenContent'>
          <img className='fullscreenImg' src={props.imageUrl} alt="img" />
        </div>
      </Modal.Body>
    </Modal>
  )
}
