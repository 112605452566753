import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";

export default function DeleteLineAreaModal(props) {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  
  const deleteLineArea = () => {
    fast_api_axios(
      {
        method: 'delete',
        url: '/api/v1/tracker/line_direction/' + props.deleteId,
      })
      .then(res => {
        updateMessage('The line settings have been successfully deleted.', 'success')
        props.handleRefresh()
        props.closeModal()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <Modal show={props.show} onHide={props.closeModal} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='modal-delete-alert-text'>
        <p>All information related to this area will be deleted. Are you sure, you want to delete the area?</p>
        <div className='d-flex justify-content-end mt-3'>
          <Button className="yes-btn" onClick={deleteLineArea}>
            Yes
          </Button>
          <Button className="no-btn" onClick={props.closeModal}>
            No
          </Button>
        </div>

      </Modal.Body>
    </Modal>
  )
}
