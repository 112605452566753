import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Button, Table, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { ChevronRight, QuestionCircle, ArrowDown, ArrowUp, ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { fast_api_axios } from '../../utils/axiosFast';
import Loader from '../Loader';
import Chart from "react-apexcharts";
import { DatePicker as RDatePicker } from 'rsuite';

const pieColors = [
  '#492CE6',
  '#22FCDB',
  '#26909C',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#FF00F8',
  '#60EFFF'
]

export default function RealTimeCounting() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { updateMessage } = useToast();
  const { logout } = useAuth();
  const activeToken = localStorage.getItem('fast_api_token');
  const alertSocketUrl = process.env.REACT_APP_WS_BASE_URL + "/ws/line_group?token=" + activeToken
  const [alertWebSocketUrl, setAlertWebSocketUrl] = useState(alertSocketUrl);
  const [lineData, setLineData] = useState([]);
  const [showSample, setShowSample] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [questionActive, setQuestionActive] = useState(false);
  const questionTarget = useRef(null);
  const [areaData, setAreaData] = useState([]);

  const [sortedLineData, setSortedLineData] = useState([]);
  const [sortType, setSortType] = useState('desc_count');

  const containerRef = useRef();

  const [chartLabels, setChartLabels] = useState([]);
  const [chartDataset, setChartDataset] = useState([]);
  const lineGraphData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  const [dateFilter, setDateFilter] = useState(new Date(moment()))

  const { sendMessage: sendAlertSocketMessage, lastMessage: lastAlertSocketMessage, readyState: alertSocketReadyState, getWebSocket } = useWebSocket(alertWebSocketUrl, {
    onOpen: () => console.log('Alert WebSocket connected!'),
    shouldReconnect: (closeEvent) => closeEvent.code !== 1005 && closeEvent.code !== 1006 && closeEvent.code !== 4001,
    reconnectAttempts: 10,
    reconnectInterval: 1000,
    onMessage: (event) => {
      const data = JSON.parse(event.data);
      getData(data, areaData);
    },
    onError: (event) => {
      console.log('Alert Socket Connection Status Error: ', event);
    },
    onClose: (event) => {
      if (event.code === 1005) {
        console.log(`Alert Socket Connection Status: Closed`);
      }

      if (event.code === 1006) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }

      if (event.code === 4001) {
        console.log(`Alert Socket Connection Status: Closed. The session has expired. Please login again.`);
        updateMessage('Your session has expired. Please login again.');
        logout();
        history.push('/login');
      }
    },
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[alertSocketReadyState];

  const getData = (data) => {
    let newData = []

    var lineInfo = [...areaData];
    lineInfo.find(i => i.id === data.result.id).count = data.result.count
    lineInfo.find(i => i.id === data.result.id).oldCount = data.old_result !== null ? data.old_result.count : 0
    lineInfo.find(i => i.id === data.result.id).updateDate = moment()
    //lineInfo.find(i => i.id === data.result.id).isCountNew = true
    lineInfo.find(i => i.id === data.result.id).expireDate = moment().add(5, 'seconds').format()
    setAreaData(lineInfo)

    /*if (lineData.some(i => i.id === data.result.id)) {
      var lineInfo = [...lineData];
      lineInfo.find(i => i.id === data.result.id).count = data.result.count
      lineInfo.find(i => i.id === data.result.id).oldCount = data.old_result !== null ? data.old_result.count : 0
      lineInfo.find(i => i.id === data.result.id).updateDate = moment()
      //lineInfo.find(i => i.id === data.result.id).isCountNew = true
      lineInfo.find(i => i.id === data.result.id).expireDate = moment().add(5, 'seconds').format()
      setLineData(lineInfo)
    }
    else {
      let myData = data.result
      myData.oldCount = 0
      myData.updateDate = moment()
      //myData.isCountNew = true
      myData.expireDate = moment().add(5, 'seconds').format()
      newData.push(myData)
      setLineData([...lineData, ...newData])
    }*/
  }

  const resetArea = id => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line_group_reset/' + id
      })
      .then(res => {
        //updateMessage('The line settings have been successfully added.', 'success');
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getAreas = () => {
    setIsLoading(true)
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line_direction/',
      })
      .then(res => {
        setAreaData(res.data)
        if (res.data.length === 0) {
          setShowSample(true)
          setShowModal(true)
          setIsLoading(false)
        }
        else {
          getCount(res.data)
          setShowSample(false)
        }
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getCount = (areasData) => {
    var allData = []
    let labelItems = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line_group_count/',
        params: {
          "date": moment(dateFilter).format('YYYY-MM-DD')
        }
      })
      .then(res => {
        let resp = res.data;

        resp.forEach(function (items, index) {
          if (items.length > 0) {


            let countData = []
            for (let i = 0; i < 24; i++) {
              if (items.find(x => moment(x.calculate_time).format('HH:mm') === labelItems[i])) {
                countData.push(items.find(x => moment(x.calculate_time).format('HH:mm') === labelItems[i]).count)
              }
              else {
                countData.push(0)
              }
            }

            let data = {
              name: areasData.find(x => x.id === items[0].line_group_id).name,
              data: countData,
              fill: false,
              borderColor: pieColors[index],
              backgroundColor: pieColors[index],
              tension: 0.1,
              enabled: false
            }

            allData.push(data)
          }
        })

        setChartDataset(allData)
        setChartLabels(labelItems)
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getAreas();
  }, [dateFilter])

  const closeModal = () => {
    setShowModal(false)
  }

  const sortTableData = (type) => {
    var data = [...areaData];
    if (type === 'asc_count') {
      data.sort((a, b) => a.count - b.count)
    }
    else if (type === 'desc_count') {
      data.sort((a, b) => b.count - a.count)
    }
    else if (type === 'asc_name') {
      data.sort((a, b) => (a.name < b.name ? -1 : 1))
    }
    else if (type === 'desc_name') {
      data.sort((a, b) => (a.name > b.name ? -1 : 1))
    }
    else if (type === 'asc_class') {
      data.sort((a, b) => (a.class_title < b.class_title ? -1 : 1))
    }
    else {
      data.sort((a, b) => (a.class_title > b.class_title ? -1 : 1))
    }
    setSortedLineData(data);
  }

  useEffect(() => {
    sortTableData(sortType);
  }, [sortType, areaData])

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Real-Time Counting</span>
            </p>
          </div>

          <div className='page-wrapper'>
            <div className='d-flex justify-content-between'>
              <h5 className='content-header'>Real-Time Counting</h5>
              {showSample && !isLoading ?
                <>
                  <Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                    <QuestionCircle size={24} />
                  </Button>
                  <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                    {(props) => (
                      <Tooltip {...props} className='manage-email-alerts'>
                        <strong>Define Lines and Directions:</strong>
                        <br></br>
                        <ul>
                          <li>First, go to the Inference Engine to fetch a frame.</li>
                          <li>Then, go to AI Vision Studio to draw lines and name the directions.</li>
                          <li>Ensure that the exact boundaries where you want the counting to occur are outlined.</li>
                        </ul>
                        <strong>Navigate to Real-Time Settings:</strong>
                        <br></br>
                        <ul>
                          <li>Go to the Smart Analytics settings and select Real-Time Settings.</li>
                        </ul>
                        <strong>Create a Designated Area:</strong>
                        <br></br>
                        <ul>
                          <li>Within Real-Time Settings, click on the Create Area button.</li>
                          <li>Use the tools provided to draw the desired area on the screen.</li>
                        </ul>
                        <strong>Select Associated Cameras:</strong>
                        <br></br>
                        <ul>
                          <li>After creating the area, select the cameras that are associated with this area.</li>
                          <li>You can choose multiple cameras to cover the designated area if necessary.</li>
                        </ul>
                        <strong>Save and Activate:</strong>
                        <br></br>
                        <ul>
                          <li>After setting up the area and cameras, click Save to apply your settings.</li>
                          <li>Your real-time counting will now be active and visible on the dashboard.</li>
                        </ul>
                      </Tooltip>
                    )}
                  </Overlay>
                </>
                :
                null
              }
            </div>

            {isLoading ?
              <Loader />
              :
              showSample ?
                <Table className='realtime-counting-table'>
                  <thead>
                    <tr>
                      <th>LINE AREA</th>
                      <th>CLASS</th>
                      <th>TOTAL NUMBER</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Floor 1</td>
                      <td>person</td>
                      <td>92</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 2</td>
                      <td>person</td>
                      <td>71</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 3</td>
                      <td>person</td>
                      <td>45</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 4</td>
                      <td>person</td>
                      <td>84</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 5</td>
                      <td>person</td>
                      <td>56</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 6</td>
                      <td>person</td>
                      <td>24</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 7</td>
                      <td>person</td>
                      <td>32</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Floor 8</td>
                      <td>person</td>
                      <td>112</td>
                      <td>
                        <Button className='d-none purple-bordered-transparent-btn'>Reset</Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                :
                <>
                  <div className='white-bg'>
                    <Table className='realtime-counting-table'>
                      <thead>
                        <tr>
                          <th>LINE AREA &nbsp;
                            {sortType === "asc_name" ?
                              <ChevronUp size={16} style={{ cursor: "pointer" }} onClick={() => setSortType("desc_name")} />
                              :
                              <ChevronDown size={16} style={{ cursor: "pointer" }} onClick={() => setSortType("asc_name")} />
                            }
                          </th>
                          <th>CLASS &nbsp;
                            {sortType === "asc_class" ?
                              <ChevronUp size={16} style={{ cursor: "pointer" }} onClick={() => setSortType("desc_class")} />
                              :
                              <ChevronDown size={16} style={{ cursor: "pointer" }} onClick={() => setSortType("asc_class")} />
                            }
                          </th>
                          <th>TOTAL NUMBER &nbsp;
                            {sortType === "asc_count" ?
                              <ChevronUp size={16} style={{ cursor: "pointer" }} onClick={() => setSortType("desc_count")} />
                              :
                              <ChevronDown size={16} style={{ cursor: "pointer" }} onClick={() => setSortType("asc_count")} />
                            }
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedLineData.map((item, index) => {
                          return (
                            <tr key={index} className='new-count'>
                              <td>{item.name}</td>
                              <td>{item.class_title}</td>
                              <td>{item.count !== null && item.count !== undefined ? item.count : '-'}
                                {item.oldCount > item.count ?
                                  <ArrowDown color="red" style={{ marginBottom: "5px" }} />
                                  :
                                  item.oldCount < item.count ?
                                    <ArrowUp color="green" style={{ marginBottom: "5px" }} />
                                    :
                                    null
                                }
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 className='content-header'>Real-Time Density Graph</h5>

                    <div ref={containerRef} style={{ position: 'relative' }}>
                      <RDatePicker
                        editable={false}
                        className='date-filter-button-white'
                        placeholder="Default"
                        placement="bottomEnd"
                        format="MM-dd-yyyy"
                        oneTap
                        value={dateFilter}
                        cleanable={false}
                        ranges={[]}
                        onChange={(e) => {
                          setDateFilter(new Date(moment(e)))
                        }}
                        container={() => containerRef.current}
                      />
                    </div>


                  </div>
                  <div className='realtime-line-chart-box'>
                    <Chart
                      options={{
                        chart: {
                          animations: {
                            enabled: false,
                          },
                          fontFamily: 'Lato, sans-serif',
                          type: 'line',
                          toolbar: {
                            show: false
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          curve: 'straight',
                          width: 3
                        },
                        grid: {
                          row: {
                            colors: ['transparent']
                          },
                          padding: {
                            top: 0,
                            right: 20,
                            bottom: 50,
                            left: 20
                          },
                        },

                        xaxis: {
                          categories: lineGraphData.labels,
                          tooltip: {
                            formatter: function (value, opts) {
                              const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                              if (v === undefined || v === '') {
                                return ''
                              } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                                return opts.w.globals.categoryLabels[opts.dataPointIndex]
                              } else {
                                return v;
                              }
                            },
                          },
                          labels: {
                            rotate: -70,
                            style: {
                              fontSize: '11px',
                              fontWeight: 400,
                            },
                            rotateAlways: true,
                            formatter: function (value) {
                              return value;
                            }
                          }
                        },
                        tooltip: {
                          fixed: {
                            enabled: false
                          },
                          enabled: true,
                          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                            const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                            const hoverIndexes = w.globals.seriesX.map(seriesX => {
                              return seriesX.findIndex(xData => xData === hoverXaxis);
                            });

                            let hoverList = '';
                            hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                              if (hoverIndex >= 0) {
                                hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                              }
                            });

                            return `<div class="card">
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                          }
                        },
                        colors: pieColors,
                        legend: {
                          showForSingleSeries: false
                        },
                      }}
                      series={
                        lineGraphData.datasets
                      }
                      height={350}
                      type="line"
                    />
                  </div>
                </>
            }
          </div>
        </div>
      </Row>
      <ExplanationModal show={showModal} closeModal={closeModal} />
    </Container >
  )
}

function ExplanationModal(props) {
  return (
    <Modal className='explanation-modal' onHide={props.closeModal} show={props.show} centered>
      <Modal.Header closeButton>Defining Lines and Directions</Modal.Header>
      <Modal.Body className='modal-delete-alert-text'>
        <p>First, go to the <span>Inference Engine</span> to fetch a frame. Then, go to <span>AI Vision Studio</span> to draw lines and name the directions. Ensure that the exact boundaries where you want the counting to occur are outlined.</p>
        <div className='d-flex justify-content-end mt-3'>
          <Button className="no-btn" onClick={props.closeModal}>
            Okay
          </Button>
        </div>

      </Modal.Body>
    </Modal>
  )
}
