import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './contexts/ToastContext';
import Routes from './utils/Routes';
import ToastMessage from './components/ToastMessage';
import { useEffect } from 'react';
import { HEADER_NAME } from '@brandVariables/constants';
import { BurgerProvider } from './contexts/BurgerContext';
import { hotjar } from 'react-hotjar';
import ErrorBoundary from './utils/ErrorBoundary';

function App() {

  useEffect(() => {
    document.title = HEADER_NAME

    hotjar.initialize(3012681, 6);
    // Identify the user
    hotjar.identify('USER_ID', { userProperty: 'value' });
  }, []);

  return (
    <AuthProvider>
      <ErrorBoundary>
      <BurgerProvider>
        <ToastProvider>
          <BrowserRouter>
            <Routes />
            <ToastMessage />
          </BrowserRouter>
        </ToastProvider>
      </BurgerProvider>
      </ErrorBoundary>
    </AuthProvider >
  );
}

export default App;
