import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";

export default function ModalDeletePreset(props) {
  const { updateMessage } = useToast();
  const { logout } = useAuth();
  let history = useHistory();

  const deletePreset = () => {
    fast_api_axios(
      {
        method: 'delete',
        url: '/api/v1/presets/' + props.preset.id
      })
      .then(res => {
        updateMessage('Saved filter is deleted !', 'success');
        props.handleClose();
        localStorage.removeItem('filterObj');
        window.location.reload()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-delete-alert-text'>
        <p>Are you sure you want to delete '{props.presetName}' ? <br /> This action cannot be undone and the saved filter will no longer be accessible.</p>
        <div className='d-flex justify-content-end'>
          <Button className="transparent-btn me-1" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button className="purple-button" onClick={deletePreset}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
