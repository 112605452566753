import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
//import bell from "../assets/images/bell.svg";
import external from "../assets/images/external.svg";
import profile from "../assets/images/profile.svg";
import out from "../assets/images/logout.svg";
import { Dropdown } from 'react-bootstrap';

export default function Header() {
  const { logout, currentUser } = useAuth();
  let history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    logout();
    history.push("/");
  };

  const handleOpenChoochPlatform = () => {
    window.open("https://app.chooch.ai/feed/home", "_blank");
  };

  const handleOpenChoochPlatformAccount = () => {
    window.open("https://app.chooch.ai/feed/user-settings", "_blank");
  };

  const goToAlerts = () => {
    history.push("/alerts")
  }

  return (
    <>
      <div className="header">

        <Dropdown className="navigateAppsDropdown">
          <Dropdown.Toggle>
            Chooch Smart Analytics
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item className="disabled">Chooch Smart Analytics</Dropdown.Item>
            <Dropdown.Item onClick={handleOpenChoochPlatform}>
              Chooch AI Vision Studio
              <img src={external} alt="external" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/*<img className="bell" src={bell} alt="bell" onClick={"#"} />*/}

        <Dropdown className="userMenuDropdown">
          <Dropdown.Toggle>
            <div className="comment-box headerboxreset">
              <div className="comment-owner comment-owner-tooltip">
                {currentUser.name.slice(0, 2)}
                <span className="tooltiptext">{currentUser.name}</span>
              </div>
            </div>
            <div>{currentUser.name}</div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleOpenChoochPlatformAccount}>
              <img src={profile} alt="profile" />
              Account
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <img src={out} alt="logout" />
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
