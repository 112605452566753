import { useState, useEffect } from 'react';
import Select from 'react-select';

export default function CustomTimeRangePicker({ selectedTime, handleSelectedTime }) {
  const [timeSlots, setTimeSlots] = useState([{ hour: 'HH:MM' }]);

  useEffect(() => {
    if (selectedTime === null) {
      setTimeSlots([{ hour: 'HH:MM' }]);
    }
    else if (Array.isArray(selectedTime) && selectedTime.length > 0) {
      const formattedSlots = selectedTime.map(time => ({
        hour: typeof time === 'string' ? time : time.hour
      }));
      setTimeSlots(formattedSlots);
    }
    else if (typeof selectedTime === 'string') {
      setTimeSlots([{ hour: selectedTime }]);
    }
  }, [selectedTime]);

  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i;
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    return {
      value: `${hour12.toString().padStart(2, '0')}:00 ${ampm}`,
      label: `${hour12.toString().padStart(2, '0')}:00 ${ampm}`
    };
  });

  const isTimeSlotComplete = (timeSlot) =>
    timeSlot && timeSlot.hour && timeSlot.hour !== 'HH:MM';

  const handleTimeChange = (index, type, selectedOption) => {
    setTimeSlots((prevTimeSlots) => {
      const newTimeSlots = [...prevTimeSlots];
      newTimeSlots[index] = { ...newTimeSlots[index], [type]: selectedOption.value };

      const filteredTimeSlots = newTimeSlots
        .filter(isTimeSlotComplete)
        .map(timeSlot => timeSlot.hour);

      handleSelectedTime(filteredTimeSlots.length > 0 ? filteredTimeSlots : null);

      return newTimeSlots;
    });
  };

  return (
    <div>
      {timeSlots.map((timeSlot, index) => (
        <div key={index} style={styles.container}>
          <div style={{
            ...styles.fakeSelectContainer,
            backgroundColor: selectedTime === null ? '#f8f8f8' : '#ffffff'
          }}>
            <Select
              options={hours}
              className="basic-single no_border"
              classNamePrefix="select"
              placeholder="HH:MM"
              onChange={(selectedOption) => handleTimeChange(index, 'hour', selectedOption)}
              value={hours.find(h => h.value === timeSlot.hour)}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

// Styles
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  fakeSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #ccc',
    padding: '5px 10px',
    width: '120px',
    backgroundColor: 'ffffff'
  },
  separator: {
    fontSize: '16px',
    margin: '0 5px',
  },
  addButton: {
    padding: '5px 0',
    fontSize: '14px',
    color: '#6200ee',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  addReportTimeContainer: {
    textAlign: 'left',
    marginTop: '10px',
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    border: 0,
    boxShadow: 'none',
  }),
  container: (provided) => ({
    ...provided,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    padding: 0,
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  menuitem: (provided) => ({
    ...provided,
    padding: '5px 0',
    textAlign: 'center',
    width: '100px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    padding: '5px 0',
    textAlign: 'center',
    width: '100px',
  }),
};
