import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from '../Loader';
import { CameraVideo } from 'react-bootstrap-icons';


export default function SliderItem(props) {
  const [activeSlide, setActiveSlide] = useState(0);

  var settings = {
    nextArrow: <Arrow type="next" activeSlide={activeSlide} />,
    prevArrow: <Arrow type="prev" activeSlide={activeSlide} />,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  return (
    <>
      {
        props.isSummaryLoading ?
          <>
            <Loader />
          </>
          :
          <>
            <div className={activeSlide === 0 ? 'pe-4 mt-3' : 'px-4 mt-3'}>
              <Slider {...settings}>
                {props.summaryData.map((item, index) => {
                  return (
                    <div className='count-box' key={item.id}>
                      <div className='info'>
                        <div className='d-flex justify-content-between w-100'>
                          <div className='name'>{item.name}</div>
                          <div className='count'>{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </div>
                        <div className='d-flex w-100 pt-3 justify-content-between'>
                          <div className='direction-box'>
                            <p>Direction :</p>
                            <div>{item.dirname}</div>
                          </div>
                          <div className="line-box">
                            <p>Line :</p>
                            <div>{item.linename}</div>
                          </div>
                        </div>
                      </div>
                      <div className='device-data'>
                        <div className='stream-box'><CameraVideo size={16} color='#352B63' className='ms-1 me-2' />{item.strname}</div>
                        <div className="device-box">{item.dvcname}</div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </>
      }
    </>

  );
}

function Arrow(props) {
  const { className, style, onClick, type, activeSlide } = props;

  if (type === "prev" && activeSlide === 0) {
    return null;
  }
  else {
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "gray" }}
        onClick={onClick}
      />
    )
  }
}