import React, { useEffect, useState } from 'react';
import { Modal, Form, OverlayTrigger, Popover, Button, Row, Col } from 'react-bootstrap';
import { ArrowLeft, InfoCircle } from 'react-bootstrap-icons';
import ReactPlayer from 'react-player/lazy'
import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';
import { personColorCodes } from '../../utils/constants';
import { fast_api_axios } from '../../utils/axiosFast';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../Loader';
import { useToast } from '../../contexts/ToastContext';
import { IS_THIS_PREDICTION_CORRECT_YES_BTN, IS_THIS_PREDICTION_CORRECT_NO_BTN } from '../../branding/chooch/constants';
import RuleInfoBox from './RuleInfoBox';
import {
  findClassTitleByRuleId,
  findDeviceNameByDeviceId,
  findStreamNameByStreamId, findZoneNameByZoneId
} from '../../utils/generic';
import {
  ResultInfoBox,
  ScoreInfoBox
} from './ImageAlertInfoBoxes';

export default function ModalDisplayVideo(props) {
  const { currentUser } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const { logout } = useAuth();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isViewImage, setIsViewImage] = useState(false);
  const [isVideo, setIsVideo] = useState(true);

  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))

  const [review, setReview] = useState('');
  const [isReviewed, setIsReviewed] = useState(props.isReviewed);
  const [isVideoReviewed, setIsVideoReviewed] = useState(props.isReviewed);
  const [isImageReviewed, setIsImageReviewed] = useState(props.isReviewed);
  const [isReviewCheckActive, setIsReviewCheckActive] = useState(false);
  const [isPredictionCorrect, setIsPredictionCorrect] = useState('');
  const [isAnyReviewed, setIsAnyReviewed] = useState(false);

  const [alertDate, setAlertDate] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [device, setDevice] = useState(null);
  const [stream, setStream] = useState(null);
  const [zone, setZone] = useState(null);
  const [ruleInfoObj, setRuleInfoObj] = useState({
    "rule_count": null,
    "count_logic": null,
    "proximity": null,
    "temporal": null,
    "zones": null
  })
  const [countInfo, setCountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const [imageList, setImageList] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const changeActiveImage = (index) => {
    setActiveImageIndex(index)
    setImageUrl(imageList[index])
  }

  const getAlertDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id
      })
      .then(res => {
        setYear(moment(res.data.alert_date).year())
        setMonth((moment(res.data.alert_date).month() + 1))
        setDay(moment(res.data.alert_date).date())

        setImageUrl(res.data.image_url)
        setVideoUrl(res.data.video_url)

        if (res.data.alert_type === 2) {
          setImageList(res.data.image_list)
          setActiveImageIndex(0);
        }

        //setDevice(deviceData.find(x => x.value === res.data.device_id) ? deviceData.find(x => x.value === res.data.device_id).label : '-');
        setDevice(findDeviceNameByDeviceId(res.data.device_id))
        //setStream(streamData.find(x => x.value === res.data.camera_id) ? streamData.find(x => x.value === res.data.camera_id).label : '-');
        setStream(findStreamNameByStreamId(res.data.camera_id))
        //res.data.zone_ids.length > 0 ? setZone(zoneData.find(x => x.value === res.data.zone_ids[0]) ? zoneData.find(x => x.value === res.data.zone_ids[0]).label : '-') : setZone('Entire Scene');
        setZone(findZoneNameByZoneId(res.data.zone_ids[0]))
        var event_name = allEventData.find(x => x.value === res.data.rule_id) ? allEventData.find(x => x.value === res.data.rule_id).label : '-'
        var class_title = findClassTitleByRuleId(res.data.rule_id)
        var eventAndClass = class_title ? event_name + ' - ' + class_title : event_name
        setAlertName(res.data.alert_name ? res.data.alert_name : eventAndClass);
        setAlertDate(moment.utc(res.data.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm'))
        setRuleInfoObj({
          "rule_count": res.data.rule_count,
          "count_logic": res.data.count_logic,
          "proximity": res.data.proximity,
          "temporal": res.data.temporal,
          "zones": res.data.zones
        })
        setCountInfo(res.data.counts)
        getFeedbackDetail()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getFeedbackDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id + '/feedback'
      })
      .then(res => {
        let reviewedBy = []
        res.data.results.forEach(item => {
          reviewedBy.push(
            {
              "name": item.user.username
            }
          )
        })
        if (res.data.results.length > 0) {
          setIsReviewed(true)
          setIsVideoReviewed(true)
          setIsImageReviewed(true)
          setReview(res.data.results.find(item => item.user_id === JSON.parse(localStorage.getItem('user')).id).message)
          setIsPredictionCorrect(res.data.results.find(item => item.user_id === JSON.parse(localStorage.getItem('user')).id).is_correct ? 'true' : 'false')
        }
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const sendReview = () => {
    fast_api_axios(
      {
        method: 'put',
        url: '/api/v1/alerts/' + props.id + '/feedback',
        data: {
          "user_id": currentUser.id,
          "is_correct": isPredictionCorrect === 'true' ? true : false,
          "message": review
        }
      })
      .then(res => {
        setIsAnyReviewed(true)
        updateMessage('Congratulations! You reviewed the alert.', 'success');
        openNext();
        //props.handleRefresh()
        //closeModal()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong.', 'error')
        }
      });
  }

  const onProgress = (info) => {
    if (info.played >= 0.7) {
      //setIsReviewCheckActive(true)
      setIsVideoReviewed(true)
    }
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    if (props.show) {
      getAlertDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const handleReviewChange = (e) => {
    setReview(e.target.value)
  }

  const openPrevious = () => {
    if (props.alertIndex > 0) {
      setIsLoading(true);
      closeModal();
      props.handleOpenNewVideoModal(props.data[props.alertIndex - 1].alert_uuid, props.data[props.alertIndex - 1], props.alertIndex - 1)
    }
  }

  const openNext = () => {
    if (props.data[props.alertIndex + 1] !== undefined && props.data[props.alertIndex + 1].alert_uuid !== undefined) {
      setIsLoading(true);
      closeModal();
      props.handleOpenNewVideoModal(props.data[props.alertIndex + 1].alert_uuid, props.data[props.alertIndex + 1], props.alertIndex + 1)
    }
    else {
      closeModal()
      props.handleClose()
      window.location.reload();
    }
  }

  const closeModal = () => {
    setReview('')
    setIsReviewed(props.isReviewed)
    setIsImageReviewed(props.isReviewed)
    setIsVideo(true);
    setIsVideoReviewed(props.isReviewed)
    setIsReviewCheckActive(false)
    setIsPredictionCorrect('')
    setAlertName(null)
    setDevice(null)
    setStream(null)
    setZone(null)
    setIsLoading(true)
  }

  const closeModals = () => {
    setReview('')
    setIsReviewed(props.isReviewed)
    setIsImageReviewed(props.isReviewed)
    setIsVideo(true);
    setIsVideoReviewed(props.isReviewed)
    setIsReviewCheckActive(false)
    setIsPredictionCorrect('')
    setAlertName(null)
    setDevice(null)
    setStream(null)
    setZone(null)
    setIsLoading(true)
    setIsViewImage(false)
    props.handleClose()
    if (isAnyReviewed) {
      window.location.reload();
    }
    setIsAnyReviewed(false);
  }

  const handleNotVideo = (e) => {
    if (e.srcElement.error['code'] === 4) {
      setIsVideoReviewed(true);
      setIsVideo(false);
    } else {
      console.log(e)
    }
  }

  return (
    <Modal show={props.show} onHide={closeModals} centered size={isViewImage ? 'xl' : 'xl'} fullscreen={isViewImage} dialogClassName={isVideo ? 'modal-95w' : ''}>
      <Modal.Header closeButton>
        {isViewImage ? <ArrowLeft className='video-modal-back' size={30} onClick={() => setIsViewImage(false)} /> : null}
      </Modal.Header>
      <Modal.Body className="video-modal">
        {isLoading ?
          <Loader />
          :
          isViewImage ?
            <>
              <div className='fullscreenContent'>
                <img className='fullscreenImg' src={imageUrl} alt="img" />
                <p className='video-date'><span>{alertDate}</span></p>
              </div>
            </>
            :
            <>
              <div className='d-flex justify-content-center align-items-center'>
                {props.alertIndex > 0 ?
                  <img src={arrowLeft} alt="left" style={{ height: 'fit-content', marginRight: 'auto', cursor: 'pointer' }} onClick={openPrevious} />
                  :
                  <img src={arrowLeft} alt="left" style={{ height: 'fit-content', marginRight: 'auto', cursor: 'not-allowed' }} />
                }
                {isVideo ?
                  <ReactPlayer
                    //url={CDN_URL + '/analytics/videos/' + year + '/' + month + '/' + day + '/' + props.id + '.mp4'}
                    url={videoUrl}
                    muted={true}
                    playing={isPlaying}
                    controls={true}
                    playbackRate={1}
                    onProgress={onProgress}
                    playsinline
                    onPause={onPause}
                    //fluid={'false'}
                    width="91%"
                    height="500px"
                    onError={(e) => handleNotVideo(e)}
                    //light={CDN_URL + '/analytics/images/' + year + '/' + month + '/' + day + '/' + props.id + '.png'}
                    light={imageUrl}
                  />
                  :

                  props.item?.alert_type === 2 ?
                    <div className='multiple-img-view'>
                      <img className='alert-image' src={imageUrl} alt="alert_image" />

                      <div className='thumbnail-box'>
                        {imageList.map((listItem, index) => {
                          return <img className={`thumbnail-item ${activeImageIndex === index && 'active'}`} src={listItem} key={index} alt={index} onClick={() => changeActiveImage(index)} />
                        })}
                      </div>
                    </div>
                    :
                    <img className='alert-image' src={imageUrl} alt="img" />
                }

                {props.showAll ?
                  props.alertIndex < 23 && props.data.length - 1 > props.alertIndex ?
                    <img src={arrowRight} alt="right" style={{ height: 'fit-content', marginLeft: 'auto', cursor: 'pointer' }} onClick={openNext} />
                    :
                    <img src={arrowRight} alt="right" style={{ height: 'fit-content', marginLeft: 'auto', cursor: 'not-allowed' }} />
                  :
                  props.alertIndex < 7 && props.data.length - 1 > props.alertIndex ?
                    <img src={arrowRight} alt="right" style={{ height: 'fit-content', marginLeft: 'auto', cursor: 'pointer' }} onClick={openNext} />
                    :
                    <img src={arrowRight} alt="right" style={{ height: 'fit-content', marginLeft: 'auto', cursor: 'not-allowed' }} />

                }
              </div>

              <div className='d-flex justify-content-between align-items-center mx-5'>
                <p className='mb-4 video-date'><span>{alertDate}</span></p>
                {props.item?.alert_type !== 0 &&
                  <Form.Group controlId="reviewed" className="video-reviewed">
                    <Form.Check type="checkbox" checked={isImageReviewed} label="Review the image" disabled={isReviewed} onChange={() => setIsImageReviewed(!isImageReviewed)} />
                  </Form.Group>
                }
              </div>

              {props.item?.alert_type === 2 ?
                <div className='d-flex flex-wrap ms-5'>
                  {countInfo.map((cntItem, index) => {
                    return (
                      <p className='box-count-name mb-2' key={index}>{cntItem.class_title}: <span>{cntItem.count}</span></p>
                    )
                  })}
                </div>
                :
                <Row className='mt-3'>
                  <Col className='ms-5'>
                    <p className='prediction-name-header'>
                      Alert:
                      <span className='alert-name'>
                        {alertName}
                        &nbsp;
                        {props.item?.alert_type !== 1 &&
                          <OverlayTrigger
                            placement="right-end"
                            overlay={
                              <Popover id="properties-tooltip" arrowProps={null}>
                                <RuleInfoBox data={ruleInfoObj} />
                              </Popover>
                            }
                          >
                            <InfoCircle color='#0052CC' size={16} />
                          </OverlayTrigger>
                        }
                      </span>
                    </p>
                    <p className='prediction-name-header'>Stream: <span>{stream}</span></p>
                  </Col>
                  <Col>
                    <p className='prediction-name-header'>Device: <span>{device}</span></p>
                    <p className='prediction-name-header'>Alert Zone: <span>{zone}</span></p>
                  </Col>
                  <Col lg={5}>
                    {(props.item?.alert_type === 0) &&
                      <>
                        <p className='prediction-name-header mb-0'>Alert Image: <span className='alert-link' onClick={() => setIsViewImage(true)}>View Image</span></p>
                        <Form.Group controlId="reviewed" className="video-reviewed">
                          <Form.Check type="checkbox" checked={isVideoReviewed} label="Watched the video" disabled={!isReviewCheckActive} onChange={() => setIsVideoReviewed(!isVideoReviewed)} />
                        </Form.Group>
                      </>
                    }
                    {
                      (props.item?.alert_type === 1) &&
                      <>
                        <p className="prediction-name-header">Prompt:
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Popover id="properties-tooltip" arrowProps={null}>
                                <ResultInfoBox data={props.item?.extra?.prompt} />
                              </Popover>
                            }
                          >
                            <span className='prompt-tooltip'>{props.item?.extra?.prompt}</span>
                          </OverlayTrigger>
                        </p>
                        <p className='prediction-name-header'>Result:
                          <OverlayTrigger
                            placement={'bottom'}
                            overlay={
                              <Popover id="properties-tooltip" arrowProps={null}>
                                <ResultInfoBox data={props.item?.extra?.result} />
                              </Popover>
                            }
                          >
                            <span>{props.item?.extra?.result}</span>
                          </OverlayTrigger>
                        </p>
                      </>
                    }
                  </Col>
                  {
                    currentUser.name === 'eyewatchtest@chooch.com' ?
                      <Col>
                        <p className='prediction-name-header'>Confidence Score: <span>{(props.item?.extra?.score * 100).toFixed(1)}%</span></p>
                      </Col>
                      :
                      null
                  }

                  {!isVideo && <p className='text-center alert-images-btn-disabled me-4 mt-2'>*There is no video for this alert, you can see the detection on the image</p>}
                </Row>
              }



              {(isVideoReviewed || isImageReviewed) ?
                isReviewed ?
                  <>
                    <div className='mx-5'>
                      <div className='border-top mt-2'>
                        <div className='d-flex justify-content-between mt-2'>
                          <div className='prediction-question'>
                            <p className='prediction-question-header'>Is this detection correct?*</p>
                            <div className='d-flex justify-content-between'>
                              {isPredictionCorrect === 'true' ?
                                <Button className='orange-button' disabled>{IS_THIS_PREDICTION_CORRECT_YES_BTN}</Button>
                                :
                                <Button className='orange-button' disabled>{IS_THIS_PREDICTION_CORRECT_NO_BTN}</Button>
                              }
                            </div>
                          </div>

                          <Form.Group className="mb-3 prediction-answer" controlId="formBasic">
                            <Form.Control as="textarea" rows={3} value={review} onChange={(e) => handleReviewChange(e)}  />
                            {/*
                              <Form.Control as="textarea" rows={3} value={review} disabled />
                                <p className={`send-review-btn mt-3`}>Save</p>
                            */}
                            <p
                              className={`send-review-btn ${isPredictionCorrect === "true" ? "active-btn" : review != null && review.length > 0 && isPredictionCorrect === "false" ? "active-btn" : ""}`}
                              onClick={isPredictionCorrect === "false" ? review != null && review.length > 0 ? sendReview : null : isPredictionCorrect === "true" ? sendReview : null}
                            >
                              Save
                            </p>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className='mx-5'>
                      <div className='border-top mt-2'>
                        <div className='d-flex justify-content-between mt-2'>
                          <div className='prediction-question'>
                            <p className='prediction-question-header'>Is this detection correct?*</p>
                            <div className='d-flex justify-content-between'>
                              {isPredictionCorrect === 'true' ?
                                <Button className='purple-button' onClick={() => setIsPredictionCorrect('true')}>{IS_THIS_PREDICTION_CORRECT_YES_BTN}</Button>
                                :
                                <Button className='gray-button' onClick={() => setIsPredictionCorrect('true')}>{IS_THIS_PREDICTION_CORRECT_YES_BTN}</Button>
                              }
                              {isPredictionCorrect === 'false' ?
                                <Button className='purple-button' onClick={() => setIsPredictionCorrect('false')}>{IS_THIS_PREDICTION_CORRECT_NO_BTN}</Button>
                                :
                                <Button className='gray-button' onClick={() => setIsPredictionCorrect('false')}>{IS_THIS_PREDICTION_CORRECT_NO_BTN}</Button>
                              }
                            </div>
                          </div>

                          <Form.Group className="mb-3 prediction-answer">
                            {isPredictionCorrect === "true" ?
                              <Form.Control as="textarea" rows={3} placeholder="Add a message (optional)" value={review} onChange={(e) => handleReviewChange(e)} />
                              :
                              <Form.Control as="textarea" rows={3} placeholder="Add a message (Required)" value={review} onChange={(e) => handleReviewChange(e)} />
                            }
                          </Form.Group>
                        </div>
                      </div>
                      <p
                        className={`send-review-btn ${isPredictionCorrect === "true" ? "active-btn" : review != null && review.length > 0 && isPredictionCorrect === "false" ? "active-btn" : ""}`}
                        onClick={isPredictionCorrect === "false" ? review != null && review.length > 0 ? sendReview : null : isPredictionCorrect === "true" ? sendReview : null}
                      >
                        Save
                      </p>
                    </div>
                  </>
                :
                null
              }
            </>
        }

      </Modal.Body>
    </Modal >
  );
}
