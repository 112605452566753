import React, { useState, useEffect, useRef } from 'react';
import Chart from "react-apexcharts";
import { Container, Row, Button, Accordion } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Search, Filter, ChevronDown } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import moment from 'moment';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import Select from 'react-select';
import SliderItem from './SliderItem';
import { useAuth } from '../../contexts/AuthContext';
import { getDevices, getStreams, getZones, getClasses } from '../../utils/generic';
import momentTimezone from 'moment-timezone';
import 'moment-duration-format';
import NoDataBoard from '../exampleDashboards/NoDataBoard';
import NotFound from '../../assets/images/restricted.png'

const {
  afterToday,
} = RDateRangePicker;

const pieColors = [
  '#22FCDB',
  '#492CE6',
  '#60EFFF',
  '#FF00F8',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#26909C'
]

export default function Dwell() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { updateMessage } = useToast();
  const { logout } = useAuth();

  const deviceOptions = getDevices()
  const streamOptions = getStreams();
  const zoneOptions = getZones();
  const classOptions = getClasses();

  const [filterDevices, setFilterDevices] = useState([]);
  const [filterStreams, setFilterStreams] = useState([]);
  const [filterZones, setFilterZones] = useState([]);
  const [filterClasses, setFilterClasses] = useState([]);
  const [isApplyActive, setIsApplyActive] = useState(false);

  const [dateFilter, setDateFilter] = useState({
    'start': moment().startOf('day'),
    'end': moment()
  });

  const [isBarChartLoading, setIsBarChartLoading] = useState(true);
  const [dwellData, setDwellData] = useState([]);
  const [averageVisitDuration, setAverageVisitDuration] = useState(null);
  const [barDataClasses, setBarDataClasses] = useState([]);
  const [barDataClassesLabel, setBarDataClassesLabel] = useState([]);

  const [isLineChartLoading, setIsLineChartLoading] = useState(true);
  const [granularity, setGranularity] = useState({
    'label': "Hourly",
    'value': "hour"
  });
  const [hourlySelectedDay, setHourlySelectedDay] = useState(moment(dateFilter['start']).startOf('day'));
  const [chartLabels, setChartLabels] = useState([]);
  const [chartDataset, setChartDataset] = useState([]);
  const [chartDraw, setChartDraw] = useState([]);
  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  const [showFilterBox, setShowFilterBox] = useState(false);
  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const handleDeviceSelect = (items) => {
    setFilterDevices(items);
  };
  const handleStreamSelect = (items) => {
    setFilterStreams(items);
  };
  const handleZoneSelect = (items) => {
    setFilterZones(items);
  };
  const handleClassSelect = (items) => {
    setFilterClasses(items);
  };


  const handleFilterApply = () => {
    setIsApplyActive(false);
    setShowFilterBox(false);
    getAllData();
  }

  useEffect(() => {
    if (filterDevices.length + filterStreams.length + filterZones.length + filterClasses.length === 0) {
      setIsApplyActive(false);
    }
    else {
      setIsApplyActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDevices, filterStreams, filterZones, filterClasses])

  const clearAllFilters = () => {
    setFilterDevices([]);
    setFilterStreams([]);
    setFilterZones([]);
    setFilterClasses([]);
    setDateFilter({
      'start': moment().tz(localStorage.getItem('timezone')).startOf('day'),
      'end': moment().tz(localStorage.getItem('timezone'))
    });
    setShowFilterBox(false);
  }

  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  const getDwellData = () => {
    setIsBarChartLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/dwell_time',
        params: {
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix(),
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : getDevices().map(a => a.value).toString(),
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'zones': filterZones.length > 0 ? filterZones.map(a => a.value).toString() : null,
          'titles': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        setDwellData(res.data);
        
        var averageDuration = res.data.reduce((total, obj) => obj.avg_dwell_time + total, 0) / res.data.length;
        setAverageVisitDuration(moment.duration(averageDuration, 'milliseconds').format('mm:ss.SSS', { trim: false }));

        let barDataTemp = [];
        res.data.forEach((dwellItem) => {
          const val = (typeof dwellItem === 'object') ? Object.assign({}, dwellItem) : dwellItem;
          if (!barDataTemp.some(e => e.class_title === val.class_title)) {
            barDataTemp.push(val)
          }
          else {
            barDataTemp.find(e => e.class_title === val.class_title).avg_dwell_time += val.avg_dwell_time
          }
        });

        setBarDataClasses(barDataTemp.map(a => a.avg_dwell_time))
        setBarDataClassesLabel(barDataTemp.map(a => a.class_title))
        setIsBarChartLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const handleGranularitySelect = (opt) => {
    if (opt.value === "hour") {
      setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));
    }
    setGranularity(opt);
    getDwellDistributionData(opt.value, moment(dateFilter['start']).startOf('day'));
  }

  const handlePrevDay = () => {
    let start = moment(hourlySelectedDay).subtract(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ? moment(dateFilter['start']).unix() : moment(hourlySelectedDay).subtract(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).subtract(1, 'days').endOf('day').unix();

    getDistributionHourly(start, end);
  }

  const handleNextDay = () => {
    let start = moment(hourlySelectedDay).add(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).add(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hourlySelectedDay).add(1, 'days').endOf('day').unix();
    getDistributionHourly(start, end);
  }

  const getDwellDistributionData = (graVal, hsd) => {
    setIsLineChartLoading(true);

    let start = moment(dateFilter['start']).unix()
    let end = graVal === 'hour' ? moment(dateFilter['end']).format('MM/DD/YYYY') === moment(hsd).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hsd).endOf('day').unix() : moment(dateFilter['end']).unix()

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/dwell/distribution',
        params: {
          'granularity': graVal,
          'start': start,
          'end': end,
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : getDevices().map(a => a.value).toString(),
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'zones': filterZones.length > 0 ? filterZones.map(a => a.value).toString() : null,
          'titles': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        let distData = res.data;
        let labels = [];

        distData.forEach((a, alertIndex) => a.forEach((x, index) => {
          let timeItem = x.time;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          distData[alertIndex][index].time = timeItem;

          var index = labels.findIndex(i => i === moment(timeItem).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(timeItem).format('MM/DD/YYYY HH:mm'))
        }));

        var duration = moment.duration(dateFilter['end'].diff(dateFilter['start'])).asDays();

        if (graVal === 'hour') {
          setChartDraw([])
          var labelDatesRemoved = []
          var draw = []

          labels.forEach((item, index) => {
            if (index === 0 || index === labels.length - 1) {
              labelDatesRemoved.push(item)
            }
            else {
              labelDatesRemoved.push(item.slice(11, 16))
            }
            draw.push(item)
          });


          setChartDraw(draw)
          setChartLabels(labelDatesRemoved)
        } else if (graVal === 'day') {
          setChartDraw([])
          var labelDatesRemovedtwo = []
          var drawtwo = []
          var tzOffset = moment().utcOffset() / 60;

          labels.forEach((item, index) => {
            if (duration <= 60) {
              labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))
            }
            else {
              if (index % 2 === 0) {
                labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))
              }
              else {
                labelDatesRemovedtwo.push('')
              }
            }

            drawtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'));

          });


          setChartDraw(drawtwo)
          setChartLabels(labelDatesRemovedtwo)
        }
        else if (graVal === 'week') {
          setChartDraw([])
          var labelDatesRemovedtwo = []
          var drawtwo = []
          var tzOffset = moment().utcOffset() / 60;

          labels.forEach((item, index) => {
            drawtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'));
            labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))

          });
          setChartDraw(drawtwo)
          setChartLabels(labelDatesRemovedtwo)
        }



        let datasets = [];
        distData.forEach((item, index) => {
          let innerDataset = [];
          labels.forEach(function (labelItem) {
            let cnt = item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem) ? item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem).count : 0;
            innerDataset.push(cnt)
          })

          let chartDataset = {
            name: item[0].class_title,
            data: innerDataset,
            fill: false,
            borderColor: pieColors[index % 13],
            backgroundColor: pieColors[index % 13],
            tension: 0.1
          }
          datasets.push(chartDataset)
        })

        let sortedDatasets = datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0))

        sortedDatasets.forEach((item, index) => {
          item.backgroundColor = pieColors[index % 13]
          item.borderColor = pieColors[index % 13]
        })

        setChartDataset(sortedDatasets)
        setIsLineChartLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getDistributionHourly = (start, end) => {
    setIsLineChartLoading(true);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/dwell/distribution',
        params: {
          'granularity': 'hour',
          'start': start,
          'end': end,
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : getDevices().map(a => a.value).toString(),
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'zones': filterZones.length > 0 ? filterZones.map(a => a.value).toString() : null,
          'titles': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        let distData = res.data;
        let labels = [];

        distData.forEach((a, alertIndex) => a.forEach((x, index) => {
          let timeItem = x.time;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          distData[alertIndex][index].time = timeItem;

          var index = labels.findIndex(i => i === moment(timeItem).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(timeItem).format('MM/DD/YYYY HH:mm'))
        }));

        setChartDraw([])
        var labelDatesRemoved = []
        var draw = []

        labels.forEach((item, index) => {
          if (index === 0 || index === labels.length - 1) {
            labelDatesRemoved.push(item)
          }
          else {
            labelDatesRemoved.push(item.slice(11, 16))
          }
          draw.push(item)
        });


        setChartDraw(draw)
        setChartLabels(labelDatesRemoved)



        let datasets = [];
        distData.forEach((item, index) => {
          let innerDataset = [];
          labels.forEach(function (labelItem) {
            let cnt = item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem) ? item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem).count : 0;
            innerDataset.push(cnt)
          })

          let chartDataset = {
            name: item[0].class_title,
            data: innerDataset,
            fill: false,
            borderColor: pieColors[index % 13],
            backgroundColor: pieColors[index % 13],
            tension: 0.1
          }
          datasets.push(chartDataset)
        })

        let sortedDatasets = datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0))

        sortedDatasets.forEach((item, index) => {
          item.backgroundColor = pieColors[index % 13]
          item.borderColor = pieColors[index % 13]
        })

        setChartDataset(sortedDatasets)
        setHourlySelectedDay(moment.unix(start))
        setIsLineChartLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getAllData = (gra = granularity.value, hsd = moment(dateFilter['start']).startOf('day')) => {
    getDwellData()
    getDwellDistributionData(gra, hsd)
  }

  useEffect(() => {
    setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));

    if (moment(dateFilter['end']).diff(moment(dateFilter['start']), 'days') > 0) {
      setGranularity({
        'label': "Daily",
        'value': "day"
      })
      getAllData("day", moment(dateFilter['start']).startOf('day'))
    }
    else {
      setGranularity({
        'label': "Hourly",
        'value': "hour"
      })
      getAllData("hour", moment(dateFilter['start']).startOf('day'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Dwell Time</span>
            </p>
            <div className='d-flex'>
              <div className='filter-dropdown' ref={dropdownRef}>
                <Button className="light-purple-btn me-3" onClick={() => setShowFilterBox(!showFilterBox)}>
                  <Filter size={24} />
                  &nbsp;
                  Filter
                  &nbsp;
                  {filterDevices.length + filterStreams.length + filterZones.length + filterClasses.length > 0 ?
                    <span className='filter-selected-count'>{(filterDevices.length + filterStreams.length + filterZones.length + filterClasses.length)}</span>
                    :
                    null
                  }
                  &nbsp;
                  <ChevronDown size={16} />
                </Button>
                {showFilterBox ?
                  <div className='filters-box'>
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className={filterDevices.length > 0 && 'selected-filters-name'}>
                          {filterDevices.length > 0 ? filterDevices.map(itm => itm.label).join(',') : 'Device'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="deviceOptions"
                            options={deviceOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Devices</span>}
                            onChange={(items) => handleDeviceSelect(items)}
                            value={filterDevices}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className={filterStreams.length > 0 && 'selected-filters-name'}>
                          {filterStreams.length > 0 ? filterStreams.map(itm => itm.label).join(',') : 'Stream'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="streamOptions"
                            options={streamOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Streams</span>}
                            onChange={(items) => handleStreamSelect(items)}
                            value={filterStreams}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header className={filterZones.length > 0 && 'selected-filters-name'}>
                          {filterZones.length > 0 ? filterZones.map(itm => itm.label).join(',') : 'Zones'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="zoneOptions"
                            options={zoneOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Zones</span>}
                            onChange={(items) => handleZoneSelect(items)}
                            value={filterZones}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header className={filterClasses.length > 0 && 'selected-filters-name'}>
                          {filterClasses.length > 0 ? filterClasses.map(itm => itm.label).join(',') : 'Class'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="classOptions"
                            options={classOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Classes</span>}
                            onChange={(items) => handleClassSelect(items)}
                            value={filterClasses}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className='d-flex justify-content-between align-items-center px-4 py-2'>
                      <div className={filterDevices.length + filterStreams.length + filterZones.length + filterClasses.length > 0 ? 'clear-filter-active' : 'clear-filter'} onClick={clearAllFilters}>Clear Filter</div>
                      <div className='d-flex justify-content-between'>
                        <Button className="cancel-filter me-3" onClick={() => setShowFilterBox(false)}>Cancel</Button>
                        <Button className="apply-filter-enabled"
                          disabled={!isApplyActive}
                          onClick={handleFilterApply}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <RDateRangePicker
                editable={false}
                className='date-filter-button'
                placeholder="Default"
                placement="bottomEnd"
                disabledDate={afterToday()}
                format="MM/dd/yyyy HH:mm"
                value={[moment(dateFilter['start']).toDate(), moment(dateFilter['end']).toDate()]}
                cleanable={false}
                ranges={[
                  {
                    label: 'Current Time',
                    value: [
                      new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'yesterday',
                    value: [
                      new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last Hour',
                    value: [
                      new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 3 Days',
                    value: [
                      new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 15 Days',
                    value: [
                      new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 30 Days',
                    value: [
                      new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                ]}
                onChange={(e) => {
                  setDateFilter({
                    'start': moment(e[0]),
                    'end': moment(e[1])
                  })
                }}
              />
            </div>
          </div>

          <div className='page-wrapper'>
            {isBarChartLoading ?
              <Loader />
              :
              dwellData.length > 0 ?
                <>
                  {isBarChartLoading ?
                    <Loader />
                    :
                    <>
                      <div className='d-flex align-items-center'>
                        <div className='average-duration-box'>
                          <h5>Avg. Visit<br></br>Duration</h5>
                          <div>
                            {
                              averageVisitDuration
                            }
                          </div>
                        </div>
                        <div className='dwell-slider-box'>
                          <SliderItem
                            isLoading={isBarChartLoading}
                            dwellData={dwellData}
                          />
                        </div>
                      </div>

                      <div className='chart-box'>
                        <div className='w-100'>
                          <h5>Class-Based Average Duration</h5>
                          <Chart
                            series={
                              [{
                                name: '',
                                data: barDataClasses
                              }]
                            }
                            type="bar"
                            height="350"
                            options={{
                              chart: {
                                toolbar: {
                                  show: true
                                },
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 4,
                                  //columnWidth: '20px',
                                  horizontal: false,
                                  dataLabels: {
                                    position: 'top'
                                  }
                                }
                              },
                              dataLabels: {
                                enabled: false,
                                style: {
                                  colors: ['#333']
                                },
                                formatter: function (value) {
                                  return moment.duration(value, 'milliseconds').format('mm:ss.SSS', { trim: false });
                                }
                              },
                              yaxis: {
                                title: {
                                  text: 'Avg. duration (mm:ss.SSS)',
                                },
                                labels: {
                                  formatter: function (value) {
                                    return moment.duration(value, 'milliseconds').format('mm:ss.SSS', { trim: false });
                                  }
                                }
                              },
                              xaxis: {
                                categories: barDataClassesLabel,
                              },
                              tooltip: {
                                y: {
                                  formatter: function (val, opt) {
                                    return moment.duration(val, 'milliseconds').format('mm:ss.SSS', { trim: false });
                                  }
                                }
                              },
                              colors: [
                                function ({ value, seriesIndex, dataPointIndex, w }) {
                                  return pieColors[dataPointIndex];
                                }
                              ]
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }

                  <div className='chart-box'>
                    <div className='w-100 d-flex justify-content-between'>
                      <h5>Data Collection Points</h5>

                      {granularity.label === "Hourly" &&
                        <div className='d-flex align-items-center'>
                          {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ?
                            <ChevronLeft className='chevron-disabled' size={20} />
                            :
                            <ChevronLeft className='chevron-enabled' size={20} onClick={() => handlePrevDay()} />
                          }

                          <div className='current-day mx-2'>
                            {hourlySelectedDay ? moment(hourlySelectedDay).format('MM/DD/YYYY') : moment.utc(dateFilter['start']).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY')}
                          </div>

                          {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ?
                            <ChevronRight className='chevron-disabled' size={20} />
                            :
                            <ChevronRight className='chevron-enabled' size={20} onClick={() => handleNextDay()} />
                          }
                        </div>
                      }
                      <div className='d-flex me-3'>
                        <div
                          className={granularity.label === "Hourly" ? 'activeGranularity' : 'granularity'}
                          onClick={() => handleGranularitySelect({
                            'label': "Hourly",
                            'value': "hour",
                          })}
                        >
                          Hourly
                        </div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className={granularity.label === "Daily" ? 'activeGranularity' : 'granularity'}
                          onClick={() => handleGranularitySelect({
                            'label': "Daily",
                            'value': "day",
                          })}
                        >
                          Daily
                        </div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className={granularity.label === "Weekly" ? 'activeGranularity' : 'granularity'}
                          onClick={() => handleGranularitySelect({
                            'label': "Weekly",
                            'value': "week",
                          })}
                        >
                          Weekly
                        </div>
                      </div>
                    </div>

                    {isLineChartLoading ?
                      <Loader />
                      :
                      lineData.datasets.length === 0 ?
                        <div className="line-graph-not-found">
                          <div className="text-center">
                            <img src={NotFound} alt="not-found" className="not-found-img" />
                            <p className="not-found-text">Looks like no data found!</p>
                            <p>Please select another day.</p>
                          </div>
                        </div>
                        :
                        <Chart
                          options={{
                            chart: {
                              animations: {
                                enabled: false,
                              },
                              fontFamily: 'Lato, sans-serif',
                              type: 'line',
                              toolbar: {
                                autoSelected: 'zoom',
                                show: true,
                                tools: {
                                  zoom: false,
                                  zoomin: false,
                                  zoomout: false,
                                  pan: false,
                                  selection: false,
                                  reset: false,
                                  download: true
                                },
                                export: {
                                  csv: {
                                    filename: 'Alerts-Distribution' + new Date().toISOString().slice(0, 10),
                                  },
                                },
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            stroke: {
                              curve: 'straight',
                              width: 3
                            },
                            grid: {
                              row: {
                                colors: ['transparent']
                              },
                              padding: {
                                top: 0,
                                right: 20,
                                bottom: 50,
                                left: 20
                              },
                            },
                            xaxis: {
                              categories: lineData.labels,
                              tooltip: {
                                formatter: function (value, opts) {
                                  const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                                  if (v === undefined || v === '') {
                                    return ''
                                  } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                                    return opts.w.globals.categoryLabels[opts.dataPointIndex]
                                  } else {
                                    return v;
                                  }
                                },
                              },
                              labels: {
                                rotate: -70,
                                style: {
                                  fontSize: '11px',
                                  fontWeight: 400,
                                },
                                rotateAlways: true,
                                formatter: function (value) {
                                  var xaxisLabel = document.querySelectorAll('.apexcharts-xaxis-label tspan')
                                  xaxisLabel.forEach((char, i) => {
                                    /*if (char.textContent.length === 5 && char.textContent !== '12:00') {
                                      document.querySelectorAll('.apexcharts-xaxis-label tspan')[i].classList.add('hidetime');
                                    }*/
                                  })
                                  return value;
                                }
                              }
                            },
                            yaxis: {
                              title: {
                                text: 'Data Collection Points'
                              }
                            },
                            tooltip: {
                              fixed: {
                                enabled: false
                              },
                              enabled: true,
                              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                                const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                                const hoverIndexes = w.globals.seriesX.map(seriesX => {
                                  return seriesX.findIndex(xData => xData === hoverXaxis);
                                });

                                let hoverList = '';
                                hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                                  if (hoverIndex >= 0) {
                                    hoverList += `<span class="apexcharts-tooltip-marker circlemake" style="background-color: ${pieColors[seriesEachIndex % 13]};"></span><span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                                  }
                                });
                                const formatHoverX = chartDraw[dataPointIndex]

                                return `<div class="card">
                              <div class="card-header p-1">${formatHoverX}</div>
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                              }
                            },
                            colors: pieColors,
                            legend: {
                              position: 'bottom',
                              horizontalAlign: 'center',
                              showForSingleSeries: true
                            },
                          }}
                          series={
                            lineData.datasets
                          }
                          height={350}
                          type="line"
                        />
                    }
                  </div>
                </>
                :
                <NoDataBoard exampleRoute='/dashboards/sample/dwell' />
            }

          </div>
        </div>
      </Row>
    </Container>
  )
}
