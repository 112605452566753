import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { Spinner } from 'react-bootstrap';
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import axios from "axios";
import { LICENCE_AGREEMENT } from '../../branding/chooch/constants';
import companyLogo from '@brandVariables/logo.png';
import { Eye } from 'react-bootstrap-icons';
import EyeSlash from '../../assets/images/eyeSlash.svg'
import chevronLeft from '../../assets/images/chevron-left_minor.svg'
import arrowRight from '../../assets/images/arrow-right.svg'
import LeftImg from '../../assets/images/SmartAnalyticsLoginGif.png'

import NoticeModal from './NoticeModal';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const [showNoticeModal, setShowNoticeModal] = useState(false);

  const { setUser } = useAuth();
  const { updateMessage } = useToast();

  const handleLogin = () => {
    setIsLoading(true)
    localStorage.clear()
    getFastApiToken()
  }

  const getFastApiToken = () => {
    fast_api_axios(
      {
        method: 'post',
        url: '/users/token',
        data: {
          'email': email,
          'password': password
        }
      })
      .then(res => {
        localStorage.setItem('fast_api_token', res.data.access_token)
        localStorage.setItem('fast_api_refresh_token', res.data.refresh_token)
        getDashboardToken()
      })
      .catch(error => {
        setErrorMessage(error.response.data.detail)
        setIsLoading(false)
        updateMessage(error.response.data.detail, 'error')
      });
  }

  const getDashboardToken = () => {
    axios(
      {
        method: 'post',
        url: '/api-token-auth/',
        baseURL: process.env.REACT_APP_DASHBOARD_URL,
        data: {
          'username': email,
          'password': password
        }
      })
      .then(res => {
        localStorage.setItem('dashboard_token', res.data.token)
        getUserInfo()
      })
      .catch(error => {
        setErrorMessage(error.response.data.non_field_errors[0])
        setIsLoading(false)
        updateMessage(error.response.data.non_field_errors[0], 'error')
      });
  }

  const getUserInfo = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/users/me',
      })
      .then(res => {
        setUser(res.data.id, res.data.username, res.data.role, res.data.company_id, res.data.company_id, res.data.is_enterprise, res.data.timezone)
        getAssets(res.data.company_id, res.data.username)
      })
      .catch(error => {
        setErrorMessage(error.response.data.detail)
        setIsLoading(false)
        updateMessage(error.response.data.detail, 'error')
      });
  }

  const getAssets = (dashboardId, username) => {
    dashboard_axios({
      method: "get",
      url: "/edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: dashboardId,
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    })
      .then((res) => {
        localStorage.setItem('assets', JSON.stringify(res.data));

        const deviceData = res.data.map((item) => {
          return {
            value: item.device_id,
            label: item.device_name,
          };
        });

        const streamData = [];
        res.data.forEach((device) => {
          device.cameras.forEach((camera) => {
            streamData.push({
              value: camera.camera_id,
              label: camera.camera_name,
            });
          });
        });

        const zoneData = [];
        res.data.forEach((device) => {
          device.cameras.forEach((camera) => {
            camera.zones.forEach((zone) => {
              if (zoneData.find(o => o.value === zone.zone_id) === undefined) {
                /*if (!zoneData.some(e => e.label === zone.zone)) {
                  zoneData.push({
                    value: zone.zone_id,
                    label: zone.zone,
                  });
                }*/
                zoneData.push({
                  value: zone.zone_id,
                  label: zone.zone,
                });
              }
            });
          });
        });

        const eventData = [];
        const allEventData = [];
        res.data.forEach((device) => {
          device.cameras.forEach((camera) => {
            camera.zones.forEach((zone) => {
              zone.rules.forEach((rule) => {
                allEventData.push({
                  value: rule.rule_id,
                  label: rule.event,
                })
                if (!eventData.some(e => e.label === rule.event)) {
                  eventData.push({
                    value: rule.rule_id,
                    label: rule.event,
                  });
                }
              });
            });
          });
        });

        localStorage.setItem("deviceData", JSON.stringify(deviceData));
        localStorage.setItem("streamData", JSON.stringify(streamData));
        localStorage.setItem("zoneData", JSON.stringify(zoneData));
        localStorage.setItem("eventData", JSON.stringify(eventData));
        localStorage.setItem('all_devices', JSON.stringify(deviceData));
        localStorage.setItem("allEventData", JSON.stringify(allEventData));

        username === 'choochkaiserpilot@gmail.com' ?
          props.history.push('/dashboards/inventory-management')
          :
          props.history.push('/overview')
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleEmailChange = e => {
    e.preventDefault();
    setEmail(e.target.value)
  }

  const handlePasswordChange = e => {
    e.preventDefault();
    setPassword(e.target.value)
  }

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setCanLogin(true);
    }
    else {
      setCanLogin(false);
    }
  }, [email, password])

  const goToForgotPassword = () => {
    window.open('https://app.chooch.ai/feed/forgot-password', '_blank')
  }

  const goToChoochMain = () => {
    window.open('https://app.chooch.ai', "_self")
  }

  const closeNoticeModal = () => {
    setShowNoticeModal(false);
  }

  const loginGoogle = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/users/social_login?provider=google',
      })
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        setErrorMessage(error.response.data.detail)
        setIsLoading(false)
        updateMessage(error.response.data.detail, 'error')
      });
  }

  const loginFacebook = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/users/social_login?provider=facebook',
      })
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        setErrorMessage(error.response.data.detail)
        setIsLoading(false)
        updateMessage(error.response.data.detail, 'error')
      });
  }

  return (
    <>
      <div className='d-flex'>
        <div className='login-left-banner'>
          <p className='banner-description-header'>
            Smart Analytics
          </p>
          <p className='banner-description'>
            Log In to Smart Analytics to review your prediction alerts and metrics in various graphs and interfaces.
          </p>

          <img src={LeftImg} alt="img" style={{
            width: '70%',
            marginLeft: '10%',
          }} />

        </div>
        <div className='login-right'>
          <img src={chevronLeft} alt="back" className='back-to-main' onClick={goToChoochMain} />
          <div className='login-content'>
            <img src={companyLogo} alt="logo" />
            <div className='login-header'>
              Log In to Smart Analytics
            </div>
            <Form.Group>
              <Form.Control className='login-input' type='email' placeholder="Email" value={email} onChange={e => handleEmailChange(e)} />
            </Form.Group>

            <Form.Group className='password-input'>
              <Form.Control className='login-input' type={isPasswordVisible ? 'text' : 'password'} placeholder="Password" value={password} onChange={e => handlePasswordChange(e)} />

              {isPasswordVisible ?
                <img src={EyeSlash} alt="EyeSlash" className='pass-visibility-btn' onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
                :
                <Eye size={20} className='pass-visibility-btn' onClick={() => setIsPasswordVisible(!isPasswordVisible)} />}

            </Form.Group>

            {errorMessage === 'Verify your email' && <p className='verify-link'>Please check your e-mail to verify. <span onClick={() => window.open("https://app.chooch.ai/feed/resend_verify_mail")}>Click here if you didn't recieve the email.</span></p>}

            <div className='d-flex align-items-center justify-content-end mb-5'>
              <p className='forgot-password'><span onClick={goToForgotPassword}>Forgot your password?</span></p>
            </div>

            <div className='notice-external-users' onClick={() => setShowNoticeModal(true)}>
              Notice for Google or Facebook Sign-In Users
              <img src={arrowRight} alt="arw" />
            </div>

            {
              isLoading ?
                <Button className="purple-btn" disabled={true} ><Spinner animation="border" /></Button>
                :
                canLogin ?
                  <Button className="purple-btn" onClick={handleLogin}>Log In</Button>
                  :
                  <Button className="disabled-btn" disabled={true} >Log In</Button>
            }

            {/*<div className='d-flex justify-content-between mt-3'>
              <div className='login-with-external' onClick={loginGoogle}>
                <img src={Google} alt="google" />
                Login with Google
              </div>
              <div className='login-with-external' onClick={loginFacebook}>
                <img src={Facebook} alt="facebook"/>
                Login with Facebook
              </div>
          </div>*/}

            <p className='copyright text-center'>All rights reserved © {LICENCE_AGREEMENT}</p>
          </div>
        </div>
      </div>

      <NoticeModal show={showNoticeModal} handleClose={closeNoticeModal} />
    </>
  )
}
