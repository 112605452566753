import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import Loader from '../Loader';
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Chart from "react-apexcharts";
import momentTimezone from 'moment-timezone';

export default function ProductTimeGraph(props) {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [chartDataPoints, setChartDataPoints] = useState([]);
  const [granularity, setGranularity] = useState({
    'label': "Hourly",
    'value': "hour"
  });
  const [hourlySelectedDay, setHourlySelectedDay] = useState(moment().startOf('day'));

  useEffect(() => {
    getChartData(granularity.value, moment().startOf('day'));
  }, [])

  const getChartData = (graVal, hsd) => {
    setIsLoading(true);

    let start = graVal === 'hour' ? moment().startOf('day').unix() : moment().subtract(15, 'day').startOf('day').unix()
    let end = graVal === 'hour' ? moment().format('MM/DD/YYYY') === moment(hsd).format('MM/DD/YYYY') ? moment().unix() : moment(hsd).endOf('day').unix() : moment().unix()

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/sku/time_chart',
        params: {
          sku: props.productID,
          start: graVal === 'hour' || graVal === 'day' ? start : null,
          end: graVal === 'hour' || graVal === 'day' ? end : null,
          granularity: graVal,
        }
      })
      .then(res => {
        var chartDataMin = [];
        var chartDataMax = [];
        var chartDataDist = [];
        var time = [];
        var titles = [];

        res.data.reverse().forEach(element => {
          chartDataDist.push(element.uom_approx)
          graVal === 'hour' ? time.push(moment(element.prediction_date).format('HH:mm')) : time.push(moment(element.prediction_date).format('MM/DD/YYYY'))
          titles.push(element.class_title)
          chartDataMin.push(element.min_count)
          chartDataMax.push(element.max_count)
        });

        var chartData = [chartDataDist, time, titles, chartDataMin, chartDataMax];

        setChartDataPoints(chartData);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getChartDataHourly = (start, end) => {
    setIsLoading(true);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/stock/sku/time_chart',
        params: {
          sku: props.productID,
          start: start,
          end: end,
          granularity: 'hour',
        }
      })
      .then(res => {
        var chartDataMin = [];
        var chartDataMax = [];
        var chartDataDist = [];
        var time = [];
        var titles = [];

        res.data.reverse().forEach(element => {
          chartDataDist.push(element.uom_approx)
          time.push(moment(element.prediction_date).format('HH:mm'))
          titles.push(element.class_title)
          chartDataMin.push(element.min_count)
          chartDataMax.push(element.max_count)
        });

        var chartData = [chartDataDist, time, titles, chartDataMin, chartDataMax];

        setChartDataPoints(chartData);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const handleGranularitySelect = (opt) => {
    if (opt.value === "hour") {
      setHourlySelectedDay(moment().startOf('day'));
    }
    setGranularity(opt);
    getChartData(opt.value, moment().startOf('day'));
  }

  const handlePrevDay = () => {
    let start = moment(hourlySelectedDay).subtract(1, 'days').format('MM/DD/YYYY') === moment().format('MM/DD/YYYY') ? moment().unix() : moment(hourlySelectedDay).subtract(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).subtract(1, 'days').endOf('day').unix();
    setHourlySelectedDay(moment.unix(start))
    getChartDataHourly(start, end);
  }

  const handleNextDay = () => {
    let start = moment(hourlySelectedDay).add(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).add(1, 'days').format('MM/DD/YYYY') === moment().format('MM/DD/YYYY') ? moment().unix() : moment(hourlySelectedDay).add(1, 'days').endOf('day').unix();
    setHourlySelectedDay(moment.unix(start))
    getChartDataHourly(start, end);
  }

  return (
    <div className='product-time-graph'>
      {isLoading ?
        <Loader />
        :
        <>
          <div className='d-flex justify-content-center mb-5'>
            {granularity.label === "Hourly" &&
              <div className='d-flex align-items-center'>
                {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment().subtract(15, 'day').format('MM/DD/YYYY') ?
                  <ChevronLeft className='chevron-disabled' size={20} />
                  :
                  <ChevronLeft className='chevron-enabled' size={20} onClick={() => handlePrevDay()} />
                }

                <div className='current-day mx-2'>
                  {hourlySelectedDay ? moment(hourlySelectedDay).format('MM/DD/YYYY') : moment.utc().tz(localStorage.getItem('timezone')).format('MM/DD/YYYY')}
                </div>

                {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY') ?
                  <ChevronRight className='chevron-disabled' size={20} />
                  :
                  <ChevronRight className='chevron-enabled' size={20} onClick={() => handleNextDay()} />
                }
              </div>
            }
            <div className='d-flex ms-auto'>
              <div
                className={granularity.label === "Hourly" ? 'activeGranularity' : 'granularity'}
                onClick={() => handleGranularitySelect({
                  'label': "Hourly",
                  'value': "hour",
                })}
              >
                Hourly
              </div>
              <div>&nbsp;|&nbsp;</div>
              <div
                className={granularity.label === "Daily" ? 'activeGranularity' : 'granularity'}
                onClick={() => handleGranularitySelect({
                  'label': "Daily",
                  'value': "day",
                })}
              >
                Daily
              </div>
              <div>&nbsp;|&nbsp;</div>
              <div
                className={granularity.label === "Weekly" ? 'activeGranularity' : 'granularity'}
                onClick={() => handleGranularitySelect({
                  'label': "Weekly",
                  'value': "week",
                })}
              >
                Weekly
              </div>
            </div>
          </div>

          <Chart
            series={
              [{
                name: 'Approx',
                data: chartDataPoints[0]
              },
              {
                name: 'Min',
                data: chartDataPoints[3]
              },
              {
                name: 'Max',
                data: chartDataPoints[4]
              },
              ]
            }
            type="line"
            height={600}
            options={{
              chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                  show: false
                }
              },
              grid: {
                show: false
              },
              colors: ['#8020CF', '#DE350B', '#00875A'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth',
                width: [5, 3, 3],
                dashArray: [0, 5, 5]
              },
              legend: {
                show: true
              },
              xaxis: {
                categories: chartDataPoints[1],
                labels: {
                  rotateAlways: true,
                  rotate: -70
                }
              },
              yaxis: {
                show: true,
                min: 0,
                decimalsInFloat: false
              },
              tooltip: {
                fixed: {
                  enabled: false
                },
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  let hoverList = '';
                  hoverList += `<span style="font-size: 18px">Time: ${chartDataPoints[1][dataPointIndex]}</span><br />`;
                  hoverList += `<span style="font-size: 18px">Unit: ${series[0][dataPointIndex]}</span><br />`;
                  hoverList += `<span style="font-size: 18px">Min: ${series[1][dataPointIndex]}</span><br />`;
                  hoverList += `<span style="font-size: 18px">Max: ${series[2][dataPointIndex]}</span><br />`;
                  chartDataPoints[2][dataPointIndex] === "0" ?
                    hoverList += `<span style="font-size: 18px">Status: <span style="color: #DE350B">No Stock</span>`
                    :
                    chartDataPoints[2][dataPointIndex] === "0-25" ?
                      hoverList += `<span style="font-size: 18px">Status: <span style="color: #DE607A">Critical Stock</span>`
                      :
                      chartDataPoints[2][dataPointIndex] === "25-50" ?
                        hoverList += `<span style="font-size: 18px">Status: <span style="color: #EA8000">Low Stock</span>`
                        :
                        chartDataPoints[2][dataPointIndex] === "50-75" ?
                          hoverList += `<span style="font-size: 18px">Status: <span style="color: #B39000">Medium Stock</span>`
                          :
                          chartDataPoints[2][dataPointIndex] === "75-100" ?
                            hoverList += `<span style="font-size: 18px">Status: <span style="color: #00875A">High Stock</span>`
                            :
                            hoverList += `<span style="font-size: 18px">Status: <span style="color: #23614C">Overfilled Stock</span>`

                  return `<div class="card">
                      <div class="card-body p-1">
                       ${hoverList}
                      </div>
                    </div>`;
                }
              },
            }}
          />
        </>
      }
    </div>
  )
}
