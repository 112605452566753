import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

export default function NoDataModal(props) {
  const getLatestAndUpdate = () => {
    props.getLatest();
    props.handleClose();
  }
  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Body>
        <div className='d-flex mt-4 mb-4'>
          <InfoCircle size={36} color="#8020CF" />
          <p className='ms-3 modal-no-data-text'>There is no data between the dates you selected, please change the filter and try again!</p>
        </div>
        <div className='d-flex align-items-center justify-content-end'>
          <p className='close-no-data-button mt-3' onClick={props.handleClose}>Cancel</p>
          <Button className="show-latest mx-3" onClick={getLatestAndUpdate}>
            Show the Latest Data
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
