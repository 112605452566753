import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/choochLogo.png'
import warning from '../../assets/images/warning-purple.png'
import success from '../../assets/images/success-purple.png'
import { useHistory } from "react-router-dom";
import { fast_api_axios } from '../../utils/axiosFast';

export default function ConfirmEmail(props) {
  let history = useHistory();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [canShow, setCanShow] = useState(false);

  const goToLogin = () => {
    history.push('/login')
  }

  const confirmCode = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/users/activate/mail/',
        params: {
          'code': props.match.params.uuid
        }
      })
      .then(res => {
        setIsConfirmed(true)
      })
      .catch(error => {
        setIsConfirmed(false)
      });
    setTimeout(function () {
      setCanShow(true);
    }, 1000);
  }

  useEffect(() => {
    confirmCode();
  }, [])

  return (
    <>
      {canShow ?
        <div className='confirm-content'>
          <img className='logo' src={logo} alt="logo" onClick={goToLogin} />
          <div className='text-center'>
            {isConfirmed ?
              <>
                <img src={success} alt="status" />
                <p className='confirm-text'>E-mail is Confirmed! </p>
                <p className='confirm-sub-text'>Your e-mail address is confirmed.</p>
              </>
              :
              <>
                <img src={warning} alt="status" />
                <p className='confirm-text'>Verification E-mail Expired! </p>
                <p className='confirm-sub-text'>Please contact your admistration for re-send verification e-mail.</p>
              </>
            }
          </div>
          <p>All rights reserved © Chooch Intelligence Technologies Co.</p>
        </div>
        :
        null
      }
    </>
  )
}
