import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from "react-bootstrap";
import Select from 'react-select';
import { getCamerasIncludingLinesWithLines, getClasses } from '../../utils/generic';
import { useAuth } from '../../contexts/AuthContext';
import { fast_api_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useHistory } from "react-router-dom";
import { ChevronLeft } from 'react-bootstrap-icons';

export default function AddLineAreaModal(props) {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();

  const [currentStep, setCurrentStep] = useState('area');

  const [areaName, setAreaName] = useState('');
  const [classes, setClasses] = useState([]);
  const [cameras, setCameras] = useState([]);

  const [nextDisabled, setNextDisabled] = useState(true);
  const [classNextDisabled, setClassNextDisabled] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const [lines, setLines] = useState([]);

  const [classOptions, setClassOptions] = useState([]);
  const [cameraLineOptions, setCameraLineOptions] = useState([]);
  const directionOptions = [
    {
      value: 'in',
      label: 'IN'
    },
    {
      value: 'out',
      label: 'OUT'
    },
  ]

  useEffect(() => {
    areaName !== null && areaName !== '' ? setNextDisabled(false) : setNextDisabled(true);
  }, [areaName])

  useEffect(() => {
    classes.length > 0 ? setClassNextDisabled(false) : setClassNextDisabled(true);
  }, [classes])

  const handleClassSelect = items => {
    setClasses(items)
  }

  const handleCameraSelect = items => {
    setCameras(items)
    var lineDirectionInfo = [];
    var cameraWithLineData = getCamerasIncludingLinesWithLines();

    items.forEach(function (selectedItem) {
      var camInfo = cameraWithLineData.find(cam => cam.camera_id == selectedItem.value.split('-')[0])
      var lineInfo = camInfo.lines.find(line => line.id == selectedItem.value.split('-')[1])
      var class_title = selectedItem.value.split('-')[2]

      lineInfo.directions.forEach(function (direction) {
        if(direction.is_active) {
          if (lines.some(x => x.id === direction.id)) {
            lineDirectionInfo.push({
              id: direction.id,
              camera_id: camInfo.camera_id,
              line_id: lineInfo.id,
              name: selectedItem.label.split('-')[0] + '-' + selectedItem.label.split('-')[1] + '-' + direction.name,
              direction: lines.find(l => l.id === direction.id).direction,
              class_title: class_title
            })
          }
          else {
            lineDirectionInfo.push({
              id: direction.id,
              camera_id: camInfo.camera_id,
              line_id: lineInfo.id,
              name: selectedItem.label.split('-')[0] + '-' + selectedItem.label.split('-')[1] + '-' + direction.name,
              direction: null,
              class_title: class_title
            })
          }
        }
      })
    })

    setLines(lineDirectionInfo)
  }

  useEffect(() => {
    let classes = getClasses()
    setClassOptions(classes)
  }, [])

  useEffect(() => {
    let options = [];
    let data = getCamerasIncludingLinesWithLines();

    data.forEach(function (item) {
      item.lines.forEach(function (lineItem) {
        classes.forEach(element => {
          if (lineItem.classes.find(e => e.class_title === element.value)) {
            options.push({
              value: item.camera_id + '-' + lineItem.id + '-' + element.value,
              label: item.camera_name + '-' + lineItem.name + '-' + element.value
            })
          }
        });
      })
    })
    setCameraLineOptions(options)
  }, [classes])

  const handleDirectionSelect = (lineItem, option) => {
    var lineInfo = [...lines];
    lineInfo.find(i => i.id === lineItem.id && i.class_title === lineItem.class_title).direction = option.value
    setLines(lineInfo)
  }

  useEffect(() => {
    console.log(lines)
    if (lines.length > 0 && lines.every(i => i.direction !== null)) {
      setSaveDisabled(false)
    }
    else {
      setSaveDisabled(true)
    }
  }, [lines])

  const handleClose = () => {
    setCurrentStep('area')
    setAreaName('')
    setNextDisabled(true)
    setSaveDisabled(true)
    setClasses([])
    setCameras([])
    setLines([])
    props.closeModal()
  }

  const createArea = () => {
    let directionsDataArr = [];
    lines.forEach(function (item) {
      directionsDataArr.push(
        {
          "camera_id": item.camera_id,
          "line_id": item.line_id,
          "direction_id": item.id,
          "line_type": item.direction,
          "class_title": item.class_title
        },
      )
    })

    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/tracker/line_direction/',
        data: {
          "name": areaName,
          "class_titles": classes.map(a => a.value),
          "line_group_directions": directionsDataArr
        },
      })
      .then(res => {
        updateMessage('The line settings have been successfully added.', 'success');
        handleClose();
        props.handleRefresh();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <Modal className="line-area-modal" show={props.show} onHide={handleClose} size='xl' centered>
      <Modal.Header closeButton>
        {currentStep === 'class' ? <ChevronLeft size={20} style={{ cursor: "pointer", position: "absolute" }} onClick={() => setCurrentStep('area')} /> : currentStep === 'camera' ? <ChevronLeft size={20} style={{ cursor: "pointer", position: "absolute" }} onClick={() => setCurrentStep('class')} /> : null}
        <Modal.Title>Create Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 'area' ?
          <Form.Group>
            <Form.Label>Area Name</Form.Label>
            <Form.Control placeholder="Enter Area Name" value={areaName} onChange={e => setAreaName(e.target.value)} />
          </Form.Group>
          :
          currentStep === 'class' ?
            <>
              <Form.Group>
                <Form.Label>Class Name</Form.Label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  name="options"
                  options={classOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Search & Select Class"
                  onChange={items => handleClassSelect(items)}
                  value={classes}
                />
              </Form.Group>
            </>
            :
            <>
              <Form.Group>
                <Form.Label>Camera & Line Name</Form.Label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  name="options"
                  options={cameraLineOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Search & Select Camera"
                  onChange={items => handleCameraSelect(items)}
                  value={cameras}
                />
              </Form.Group>
              {lines.length > 0 ?
                <>
                  <div className='lines-directions'>
                    <div className='lines'>
                      Line Name
                      {lines.map((lineItem, index) => {
                        return (
                          <div className='line-box' key={index}>
                            {lineItem.name}
                          </div>
                        )
                      })}
                    </div>
                    <div className='classes'>
                      Classes
                      {lines.map((lineItem, index) => {
                        return (
                          <div className='class-box' key={index}>
                            {lineItem.class_title}
                          </div>
                        )
                      })}
                    </div>
                    <div className='directions'>
                      Direction
                      {lines.map((item, index) => {
                        return (
                          <Select
                            key={index}
                            name="options"
                            options={directionOptions}
                            className="basic-select"
                            classNamePrefix="select"
                            placeholder="Select"
                            onChange={opt => handleDirectionSelect(item, opt)}
                          />
                        )
                      })}
                    </div>
                  </div>
                </>
                :
                null
              }
            </>
        }
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {currentStep === 'area' ?
          <Button className={`next-btn ${nextDisabled && 'disabled'}`} onClick={() => setCurrentStep('class')}>Next</Button>
          :
          currentStep === 'class' ?
            <Button className={`next-btn ${classNextDisabled && 'disabled'}`} onClick={() => setCurrentStep('camera')}>Next</Button>
            :
            <Button className={`save-btn ${saveDisabled && 'disabled'}`} onClick={() => createArea()}>Save</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}
