import { createContext, useContext, useState } from 'react';

const BurgerContext = createContext();

export function BurgerProvider({ children }) {
  const [isSidebarSmall, setIsSidebarSmall] = useState(false)

  const changeSidebar = () => {
    setIsSidebarSmall(!isSidebarSmall);
  }

  const value = {
    isSidebarSmall,
    changeSidebar
  }

  return (
    <BurgerContext.Provider value={value}>
      {children}
    </BurgerContext.Provider>
  );
}

export function useBurger() {
  return useContext(BurgerContext);
}