import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../Loader';

export default function Dashboards() {
  let history = useHistory();
  const { currentUser } = useAuth();
  const [isLoading] = useState(false);

  const goToHeatmap = () => {
    history.push('/dashboards/heatmap')
  }
  const goToDemographics = () => {
    localStorage.removeItem('selectedStreamOptionDemographics')
    history.push('/dashboards/demographics')
  }
  const goToCounting = () => {
    history.push('/dashboards/counting')
  }
  const goToRealtimeCounting = () => {
    history.push('/dashboards/real-time-counting')
  }
  const goToPpe = () => {
    history.push('/dashboards/ppe')
  }
  const goToDwell = () => {
    history.push('/dashboards/dwell')
  }
  const goToInventory = () => {
    history.push('/dashboards/inventory-management')
  }
  const goToStaff = () => {
    history.push('/dashboards/staff-dwell')
  }

  return (
    <>
      <Container fluid>
        {isLoading ?
          <Loader />
          :
          <>
            <Row>
              <div className='page-content-header'>
                <div className='page-name'>Dashboards</div>
              </div>

              <div className='page-wrapper'>
                <div className='d-flex flex-wrap mb-4'>
                  {currentUser.name === "cbalci@cdt.com.tr" ?
                    <div
                      className='public-dashboard-box realtime-counting-box'
                      onClick={goToRealtimeCounting}
                    >
                      REAL-TIME COUNTING
                    </div>
                    :
                    <>
                      <div
                        className='public-dashboard-box heatmap-box'
                        onClick={goToHeatmap}
                      >
                        HEATMAP & COMMON <br></br> PATHS
                      </div>

                      -
                      <div
                        className='public-dashboard-box demographics-box'
                        onClick={goToDemographics}
                      >
                        DEMOGRAPHICS
                      </div>

                      <div
                        className='public-dashboard-box counting-box'
                        onClick={goToCounting}
                      >
                        COUNTING
                      </div>

                      <div
                        className='public-dashboard-box realtime-counting-box'
                        onClick={goToRealtimeCounting}
                      >
                        REAL-TIME COUNTING
                      </div>

                      <div
                        className='public-dashboard-box dwell-box'
                        onClick={goToDwell}
                      >
                        DWELL TIME
                      </div>
                      <div
                        className='public-dashboard-box inventory-box'
                        onClick={goToInventory}
                      >
                        INVENTORY MANAGEMENT
                      </div>

                      {
                        currentUser.name === 'jgo@eyewatchlive.com' || currentUser.name === 'hakan.akinci@chooch.com' || currentUser.name === 'emre.gursoy@chooch.com' || currentUser.name === 'muhammed.ali@chooch.com' || currentUser.name === 'mehmet.gumus@chooch.com' ?
                          <div
                            className='public-dashboard-box staff-box'
                            onClick={goToStaff}
                          >
                            STAFF DWELL TIME
                          </div>
                          :
                          null
                      }
                    </>

                  }
                </div>
              </div>
            </Row>
          </>
        }
      </Container>
    </>
  )
}
