import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../Loader';
import { useToast } from '../../contexts/ToastContext';
import { ArrowLeft, InfoCircle } from 'react-bootstrap-icons';
import { fast_api_axios } from '../../utils/axiosFast';
import { getZones, findRuleNameByRuleId, findStreamNameByStreamId, findZoneNameByZoneId, findDeviceNameByDeviceId } from '../../utils/generic';
import RuleInfoBox from '../alerts/RuleInfoBox';

export default function AlertModal(props) {
  const { updateMessage } = useToast();
  let history = useHistory();
  const { logout } = useAuth();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isViewImage, setIsViewImage] = useState(false);
  const [isVideo, setIsVideo] = useState(true);

  const [alertDate, setAlertDate] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [device, setDevice] = useState(null);
  const [stream, setStream] = useState(null);
  const [zone, setZone] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const [ruleInfoObj, setRuleInfoObj] = useState({
    "rule_count": null,
    "count_logic": null,
    "proximity": null,
    "temporal": null,
    "zones": null
  })

  const getAlertDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id
      })
      .then(res => {
        setYear(moment(res.data.alert_date).year())
        setMonth((moment(res.data.alert_date).month() + 1))
        setDay(moment(res.data.alert_date).date())
        setImageUrl(res.data.image_url)
        setVideoUrl(res.data.video_url)
        setDevice(findDeviceNameByDeviceId(res.data.device_id))
        setStream(findStreamNameByStreamId(res.data.camera_id))
        setZone(res.data.zones || findZoneNameByZoneId(res.data.zone_id))
        setAlertName(findRuleNameByRuleId(res.data.rule_id))
        setAlertDate(moment.utc(res.data.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm'))
        setRuleInfoObj({
          "rule_count": res.data.rule_count,
          "count_logic": res.data.count_logic,
          "proximity": res.data.proximity,
          "temporal": res.data.temporal,
          "zones": res.data.zones
        })
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    if (props.show) {
      getAlertDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const closeModal = () => {
    setIsVideo(true);
    setIsViewImage(false);
    setAlertName(null)
    setDevice(null)
    setStream(null)
    setZone(null)
    setIsLoading(true)
    props.handleClose()
  }

  const handleNotVideo = (e) => {
    console.log(e)
    if (e.srcElement.error['code'] === 4) {
      setIsVideo(false);
    } else {
      console.log(e)
    }
  }

  return (
    <Modal show={props.show} onHide={closeModal} centered size={isViewImage ? 'xl' : 'xl'} fullscreen={isViewImage}>
      <Modal.Header closeButton>
        {isViewImage ? <ArrowLeft className='video-modal-back' size={30} onClick={() => setIsViewImage(false)} /> : null}
      </Modal.Header>
      <Modal.Body className="video-modal">
        {isLoading ?
          <Loader />
          :
          isViewImage ?
            <>
              <div className='fullscreenContent'>
                <img className='fullscreenImg' src={imageUrl} alt="img" />
                <p className='video-date'><span>{alertDate}</span></p>
              </div>
            </>
            :
            <>
              <div className='d-flex justify-content-center align-items-center'>
                {isVideo ?
                  <ReactPlayer
                    //url={CDN_URL + '/analytics/videos/' + year + '/' + month + '/' + day + '/' + props.id + '.mp4'}
                    url={videoUrl}
                    muted={true}
                    playing={isPlaying}
                    controls={true}
                    playbackRate={0.25}
                    playsinline
                    onPause={onPause}
                    fluid={'false'}
                    width="91%"
                    height="500px"
                    onError={(e) => handleNotVideo(e)}
                    //light={CDN_URL + '/analytics/images/' + year + '/' + month + '/' + day + '/' + props.id + '.png'}
                    light={imageUrl}
                  />
                  :
                  <img className='alert-image' src={imageUrl} alt="img" />
                }
              </div>

              <p className='mx-5 mb-4 video-date'><span>{alertDate}</span></p>

              <Row className='mt-3'>
                <Col className='ms-5'>
                  <p className='prediction-name-header'>
                    Alert:
                    &nbsp;
                    <span className='alert-name'>
                      {alertName}
                      &nbsp;
                      <OverlayTrigger
                        placement="right-end"
                        overlay={
                          <Popover id="properties-tooltip" arrowProps={null}>
                            <RuleInfoBox data={ruleInfoObj} />
                          </Popover>
                        }
                      >
                        <InfoCircle color='#0052CC' size={16} />
                      </OverlayTrigger>
                    </span>
                  </p>
                  <p className='prediction-name-header'>Stream: <span>{stream}</span></p>
                </Col>
                <Col>
                  <p className='prediction-name-header'>Device: <span>{device}</span></p>
                  <p className='prediction-name-header'>Alert Zone: <span>{zone}</span></p>
                </Col>
                <Col>
                  <p className='prediction-name-header mb-0'>Alert Image: <span className='alert-link' onClick={() => setIsViewImage(true)}>View Image</span></p>
                </Col>
                {!isVideo && <p className='text-center alert-images-btn-disabled me-4 mt-2'>*There is no video for this alert, you can see the detection on the image</p>}
              </Row>
            </>
        }

      </Modal.Body>
    </Modal>
  )
}
